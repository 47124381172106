import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import { useSelector } from "react-redux"
import { selectPlannerListDetailsSlice } from "../../slice"
// import { selectPlaces } from "../../../../app/slice"
// 
import Button from "../../../../../common/components/Button"
// 
import "./index.scss"

const PlannerListPartMentions = ({
    toPDF,
    onClickBrand,
}) => {
    const { t } = useTranslation()
    // 
    const slice = useSelector(selectPlannerListDetailsSlice)
    // 
    const pl = slice.data
    // const placeNames = useSelector(selectPlaces).list
    // 
    const [brands, updateBrands] = useState([])
    const [places, updatePlaces] = useState([])
    const [mentions, updatMentions] = useState([])
    const [hashtags, updateHashtags] = useState([])
    // 
    const [showMore, updateShowMore] = useState(false)

    useEffect(() => {
        updateBrands(pl.brands || []) 
        updatePlaces(pl.places || []) 
        updatMentions(pl.mentions || []) 
        updateHashtags(pl.hashtags || []) 
    }, [pl])

    useEffect(() => {
        if(toPDF) updateShowMore(true)
    }, [])

    return <div className="pl-mentions">
        <div className="title-part">
            {t("Menções")}  

            {!toPDF && 
                <Button
                    className="outline-light"
                    onClick={() => updateShowMore(!showMore)}
                >
                    {t(showMore ? "Ver mais" : "Ver menos")}

                    <span
                        className={`arrow ${showMore ? "" : "open"}`}
                    />
                </Button>
            }
        </div>
        
        <div className={`junction-mentions ${toPDF ? "to-pdf"  : ""}`}>
            <div className="list-mentions">
                <p className="list-title">{t("Marcas")}</p>

                <div className={`mentions ${showMore ? "expanded" : "simple"}`}>
                    {brands.length < 1 &&
                        <p className="text-empty">
                            {t("Nenhuma marca mencionada pelo influenciador")}
                        </p>
                    }

                    {brands && brands.map((mention, i) => 
                        <div 
                            key={i} 
                            className="mention" 
                            onClick={() => onClickBrand && onClickBrand(mention.id)}
                        >
                            {mention.name}

                            <div className="value">{mention.count}</div>
                        </div>
                    )}
                </div>
            </div>
            
            <div className="list-mentions">
                <p className="list-title">{t("Perfil")}</p>

                <div className={`mentions ${showMore ? "expanded" : "simple"}`}>
                    {mentions.length < 1 && 
                        <p className="text-empty">
                            {t("Nenhum perfil mencionada pelo influenciador")}
                        </p>
                    }

                    {mentions && mentions.map((mention, i) => 
                        <div key={i} className="mention">
                            {mention.name}

                            <div className="value">{mention.count}</div>
                        </div>
                    )}
                </div>
            </div>
            
            <div className="list-mentions">
                <p className="list-title">{t("Hashtags")}</p>

                <div className={`mentions ${showMore ? "expanded" : "simple"}`}>
                    {hashtags.length < 1 && 
                        <p className="text-empty">
                            {t("Nenhuma hashtag mencionada pelo influenciador")}
                        </p>
                    }

                    {hashtags && hashtags.map((mention, i) => {
                        return <div key={i} className="mention">
                            {mention.name}

                            <div className="value">{mention.count}</div>
                        </div>
                    })}
                </div>
            </div>

            <div className="list-mentions">
                <p className="list-title">{t("Localizações")}</p>

                <div className={`mentions ${showMore ? "expanded" : "simple"}`}>
                    {places.length < 1 && 
                        <p className="text-empty">
                            {t("Nenhuma localização mencionada pelo influenciador")}
                        </p>
                    }

                    {places && places.map((mention, i) => {
                        // const indexName = placeNames.findIndex(({ value }) => (value === mention))

                        return <div key={i} className="mention">
                            {/* {placeNames[indexName] != -1 && placeNames[indexName].key} */}
                            
                            {mention.name}
                            
                            <div className="value">{mention.count}</div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
}

PlannerListPartMentions.defaultProps = {
    toPDF: false,
}

PlannerListPartMentions.propTypes = {
    toPDF: PropTypes.bool,
    onClickBrand: PropTypes.func,
}
  
export default PlannerListPartMentions