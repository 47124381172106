import React from "react"
// 
import { useTranslation } from "react-i18next"
// 
import { Form } from "antd"
import Input from "../../../../../common/components/Input"
import Button from "../../../../../common/components/Button"

const StrandsForm = () => {
    const { t } = useTranslation()

    return <>
        <p className="title-form">{t("Vertentes")}</p>

        <Form.List name={["sub_brands"]}>
            {(fields, { add, remove }) => {
                if(fields.length < 1) add()

                return (
                    <div>
                        {fields.map((url, index) => (
                            <div className="junction-input-icon" key={index}>
                                <Form.Item
                                    name={[index]}
                                    label={`${t("Submarcas")}:`}
                                >
                                    <Input
                                        placeholder={t("Digite o nome da submarca")}
                                    />

                                </Form.Item>

                                {index > 0 ?
                                    <i
                                        onClick={() => remove(url.name)}
                                        className="icon icon-close hover"
                                    />
                                    :
                                    <Button
                                        onClick={() => add()}
                                        className="outline-dark add-btn"
                                    >
                                        {t("Adicionar")}
                                    </Button>
                                }
                            </div>
                        ))}
                    </div>
                )
            }}
        </Form.List>

        <Form.List name={["products"]}>
            {(fields, { add, remove }) => {
                if(fields.length < 1) add()

                return (
                    <div>
                        {fields.map((url, index) => (
                            <div className="junction-input-icon" key={index}>
                                <Form.Item
                                    name={[index]}
                                    label={`${t("Produtos")}:`}
                                >
                                    <Input
                                        placeholder={t("Digite o nome do produto")}
                                    />

                                </Form.Item>

                                {index > 0 ?
                                    <i
                                        onClick={() => remove(url.name)}
                                        className="icon icon-close hover"
                                    />
                                    :
                                    <Button
                                        onClick={() => add()}
                                        className="outline-dark add-btn"
                                    >
                                        {t("Adicionar")}
                                    </Button>
                                }
                            </div>
                        ))}
                    </div>
                )
            }}
        </Form.List>

        <Form.List name={["hashtags"]}>
            {(fields, { add, remove }) => {
                if(fields.length < 1) add()

                return (
                    <div>
                        {fields.map((url, index) => (
                            <div className="junction-input-icon" key={index}>
                                <Form.Item
                                    name={[index]}
                                    label={`${t("Hashtags")}:`}
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (!value || (value && value.includes("#") && value.length > 1)) {
                                                    return Promise.resolve()
                                                } else {
                                                    return Promise.reject(new Error("Informe uma hashtag válida"))
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        placeholder={t("Digite a hashtag")}
                                    />

                                </Form.Item>

                                {index > 0 ?
                                    <i
                                        onClick={() => remove(url.name)}
                                        className="icon icon-close hover"
                                    />
                                    :
                                    <Button
                                        onClick={() => add()}
                                        className="outline-dark add-btn"
                                    >
                                        {t("Adicionar")}
                                    </Button>
                                }
                            </div>
                        ))}
                    </div>
                )
            }}
        </Form.List>
    </>
}

export default StrandsForm