import React from "react"
import PropTypes from "prop-types"
// 
import { Radio as AntRadio } from "antd"
// 
import "./index.scss"

const Radio = ({
  style,
  value,
  options,
  onChange,
}) => {
    return (<>
      <AntRadio.Group 
        value={value}
        onChange={(e) => onChange(e.target.value)} 
      >
          {options && options.map((option, key) =>{
              return (
                <AntRadio key={key} style={style} value={option.value} className="radio-content">
                  {option.icon && <i className={`icon icon-${option.icon}`} />}
                  
                  <span 
                    className="radio-label"
                    onClick={() => onChange(option.value)}
                  >
                    {option.label}
                  </span>

                  {option.description && <span className="radio-description">{option.description}</span>}
                </AntRadio>
              )
          })}
      </AntRadio.Group>
    </>
    )
}

Radio.propTypes = {
  value: PropTypes.any,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Radio