import React from "react"
import ReactDOM from "react-dom/client"
// 
import { Provider } from "react-redux"
import { HashRouter } from "react-router-dom"
import { configStore } from "./store"
// 
import App from "./app"
// 
import "./languages/i18n"
// 
import "./common/styles/icons.scss"
import "./index.scss"

const store = configStore()

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
)
