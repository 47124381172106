const routes = {
    user: "/auth/me",
    auth: "/auth/hub",
    categories: "/categories",
    brands: "/brands",
    places: "/places",
    user_action: "/user-action",
    // 
    search: "/profile/search",
    // 
    plannerList: "/planner-list",
    toggleShare: "/user/lists/toggle-share/",
    // 
    tableData: "/user/lists/compare/",
    // 
    dataset: "/dataset",
    // 
    curation: "/curation",
    // 
    refreshToken: "/refresh",

    // createPlannerList: "/user/lists/create/",
    // deletePlannerList: "/user/lists/remove/",
    // duplicatePlannerList: "/user/lists/duplicate/",
    // exportPlannerList: "/user/lists/compare/export/",
    // exportAllPlannerList: "/user/lists/compare/exportAll/"
}

// https://api.search.airfluencers.com/v1/user/lists/toggle-share/?detailed=1

export default routes