import React from 'react';
import { Col, Skeleton, Row } from 'antd';
// import PropTypes from 'prop-types';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../common/components/Tooltip';
import Header from '../../../../../common/components/Header';

const SkeletonComparativeTable = () => {
    const { t } = useTranslation();

    const values = [
        {
            text: t('Influenciadores na lista'),
            tooltip: t('Quantidade total de perfis incluídos na lista.'),
        },
        {
            text: t('Airscore médio'),
            tooltip: t(
                'Nota atribuída ao perfil do influenciador que metrifica a qualidade do engajamento, levando em consideração sua base, suas interações, publicações e categorias.',
            ),
        },
        {
            text: t('Total de seguidores'),
            tooltip: t('Somatória do tamanho da base de seguidores dos perfis presentes na lista.'),
        },
        {
            text: t('Potencial de alcance'),
            tooltip: t(
                'Estimativa de alcance que as publicações podem impactar, totalizando todos os perfis presentes na lista.',
            ),
        },
        {
            text: t('Total de interações'),
            tooltip: t('Quantidade total das interações de todos os perfis presentes na lista.'),
        },
    ];

    const columns = [
        {title: t("Influenciador"), skeleton: <Skeleton.Avatar active />, span: 2},
        {title: t("Airscore"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Seguidores"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Posts"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Horário Posts"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Curtidas"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Visualizações"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Comentários"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Interações") , skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Listar"), skeleton: <Skeleton.Avatar active />, span: 2},
    ]

    return (
        <div className="skeleton-coparative-table">
            <Header title={t('Tabela de comparação')}></Header>
            <Row gutter={[4, 8]} className="box-rounded box-bar" justify={'space-between'}>
                <Col span={6} className="skeleton-left">
                    <Skeleton paragraph={false} active />
                </Col>

                <Col span={3} className="skeleton-right">
                    <Skeleton paragraph={false} size={21} active />
                </Col>
            </Row>
            <div className="row-metrics">
                {values.map((item, index) => (
                    <div key={index} className="col-metrics">
                        <div className="box-rounded box-metrics">
                            <div className="tooltip-info">
                                <Tooltip child={item.tooltip} />
                            </div>
                            <div className="box-title">{item.text}</div>
                            <div className="box-content">
                            <Skeleton paragraph={false} active />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Row gutter={[2, 2]} className={"box-rounded box-table-header"} justify={'space-evenly'}>
                {columns.map((item, index) => (
                    <Col key={index} span={item.span}>
                        {item.title}
                    </Col>
                ))}
            </Row>
            
            {[0, 1, 2].map((x, idx) => (
                <Row key={idx} gutter={[2, 2]} className={"box-rounded box-table-row"} justify={'space-evenly'}>
                    {columns.map((item, index) => (
                        <Col key={`${idx} - ${index}`} span={item.span}>
                            {item.skeleton}
                        </Col>
                    ))}
                </Row>
            ))}
        </div>
    );
};

SkeletonComparativeTable.propTypes = {};

export default SkeletonComparativeTable;
