import axios from "axios"

const apiUrl = process.env.REACT_APP_BASEURL

const publicApi = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
})

publicApi.interceptors.request.use(
    (config) => {
        let accessToken = localStorage.getItem("aira_tasting_access_token")

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.error(error)
    },
)

publicApi.interceptors.response.use(undefined, async (err) => {
    const customError = {
        name: "Api Error",
        message: err.response && err.response.status && err.response.status < 500 ?
            err.response.data.message ||
            (err.response.data.errors ? err.response.data.errors.message : "Erro") :
            "Erro no sistema, tente mais tarde!",
        data: err.response ? err.response.data : null,
    }

    if (err.response.status === 401) {
        window.localStorage.removeItem("aira_tasting_access_token")
    }

    return Promise.reject(customError)
})

export default publicApi