import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit"
import {
    getInfluencerDetails
} from "./service"
// 
import InfluencerModel from "../../../models/InfluencerModel"
import StateStatus from "../../../utils/status"

const initialStateInfluencer = {
    status: {
        fetch: StateStatus.idle,
    },

    influencer: new InfluencerModel(),
}

export const fetch = createAsyncThunk("influencer-card-public/fetch", async ({
    briefingId,
    influencerId
}) => {
    try {
        const response = await getInfluencerDetails(briefingId, influencerId)

        return response.data
    } catch (err) {
        console.debug(err)
        
        throw err
    }
})

export const influencerDetailsPublicSlice = createSlice({
    name: "influencerDetailsPublic",
    initialState: initialStateInfluencer,
    reducers: {
        clearState: (state) => {
            state.status.fetch = StateStatus.idle

            state.influencer = new InfluencerModel()
        },
    },
    extraReducers: {
        [fetch.pending]: (state) => {
            state.status.fetch = StateStatus.loading
        },
        [fetch.fulfilled]: (state, {
            payload
        }) => {
            const influencer = new InfluencerModel().detailsPublicFromJson(payload)

            state.influencer = influencer

            state.status.fetch = StateStatus.succeeded
        },
        [fetch.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.fetch = StateStatus.failed
        },
    }
})

export const {
    clearState
} = influencerDetailsPublicSlice.actions


export const selectInfluencerDetailsPublicSlice = (state) => state.influencerDetailsPublic