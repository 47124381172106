import { createSlice } from "@reduxjs/toolkit"

const perAlert = () => { }

const initialState = {
    status: "",
    value: {
        show: false,
        type: "success",
        title: "",
        subtitle: "",
        text: "",
        question: "",
        okText: "",
        cancelText: "",
        className: "",
        onOk: perAlert(),
        onCancel: perAlert(),
    },
  }
  
export const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        showAlert: (state, action) => {
            state.value.show = action.show
        },
        changeObjAlert: (state, {
            payload
        }) => {
            state.value = {
                // values
                show: payload.show || false,
                
                type: payload.type || "success",
                className: payload.className || "",
                
                title: payload.title || "",
                subtitle: payload.subtitle || "",
                text: payload.text || "",
                question: payload.question || "",
                
                okText: payload.okText || "",
                cancelText: payload.cancelText || "",
                
                onOk: payload.onOk || perAlert(),
                onCancel: payload.onCancel || perAlert(),
            }
        }
    }
})

export const { changeObjAlert, showAlert } = alertSlice.actions

export const selectObjAlert = (state) => state.alert.value
