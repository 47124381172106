import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import StateStatus from '../../../utils/status';
import { getProfiles } from './service';

const initialState = {
  status: {
    fetch: StateStatus.idle,
  },
  profiles: [],
  topValues: {},
  worstValues: {},
  errMsg: null,
};

export const fetch = createAsyncThunk('aira-curation-result/fetch', async ({ id }) => {
  const response = await getProfiles(id);

  return response.data;
});

export const airaCurationResultSlice = createSlice({
  name: 'airaCurationResult',
  initialState,
  reducers: {
    updateStatus: (state, { payload }) => {
      state.status[payload.name] = payload.status;
    },
  },
  extraReducers: {
    [fetch.pending]: (state) => {
      state.status.fetch = StateStatus.loading;
    },
    [fetch.fulfilled]: (state) => {
      state.status.fetch = StateStatus.succeeded;
    },
    [fetch.rejected]: (state, { error }) => {
      state.errorMsg = error.message;
      state.status.fetch = StateStatus.failed;
    },
  },
});

export const { updateStatus } = airaCurationResultSlice.actions;

export const selectAiraCurationResult = (state) => state.airaCurationResult;
