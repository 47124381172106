import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import feedback from '../../../common/components/Feedback';
import i18n from '../../../languages/i18n';
import PlannerListModel from '../../../models/PlannerListModel';
import StateStatus from '../../../utils/status';
import {
  addInfluencerInPL,
  createPlannerList,
  deletePlannerList,
  duplicatePlannerList,
  editPlannerList,
  exportPlanner,
  getPlannerListSimple,
  getPlannerListsDetailed,
  uploadImage,
} from './service';

const initialState = {
  status: {
    fetch: StateStatus.idle,
    create: StateStatus.idle,
    edit: StateStatus.idle,
    remove: StateStatus.idle,
    share: StateStatus.idle,
    duplicate: StateStatus.idle,
    download: StateStatus.idle,
    downloadAll: StateStatus.idle,
    toggle: StateStatus.idle,
    uploadAvatar: StateStatus.idle,
  },
  list: [],
  details: {},
  updatedAvatar: '',
  errorMsg: null,
  page: 1,
  totalPages: 2,
  total: 0,
  title: '',
  order: 'updated_at desc',
};

export const fetch = createAsyncThunk(
  'planner-list/fetch-list',
  async ({ page, desc, order, title }) => {
    try {
      let data = {
        page: page,
        desc: desc,
        title: title,
      };

      if (order === 'owner') {
        data['owner'] = true;
      } else {
        data['order'] = order;
      }

      const [resultFavorite, result] = await Promise.all([
        getPlannerListSimple('favorite'),
        getPlannerListsDetailed(data),
      ]);

      const favorite = new PlannerListModel().fromJson(resultFavorite.data, true);

      return {
        title: title,
        data: result.data,
        favorite: favorite,
      };
    } catch (err) {
      console.debug(err);

      throw err;
    }
  },
);

export const create = createAsyncThunk('planner-list/create', async (title, thunkAPI) => {
  const { app } = thunkAPI.getState();

  const result = await createPlannerList(title);

  return {
    ...result.data,
    createdBy: app.user.name,
  };
});

export const edit = createAsyncThunk('planner-list/edit', async ({ title, id, shared }) => {
  try {
    const result = await editPlannerList(title, id, shared);

    feedback.success(i18n.t('Lista atualizada com sucesso'));

    return result.data;
  } catch (err) {
    feedback.error(i18n.t('Erro ao editar lista'));

    throw err;
  }
});

export const remove = createAsyncThunk('planner-list/remove', async (id) => {
  try {
    await deletePlannerList(id);

    feedback.success(i18n.t('Lista removida com sucesso'));

    return id;
  } catch (err) {
    feedback.error(i18n.t('Erro ao remover lista'));

    throw err;
  }
});

export const duplicate = createAsyncThunk('planner-list/duplicate', async (id, thunkAPI) => {
  const { app } = thunkAPI.getState();

  const result = await duplicatePlannerList(id);

  return {
    ...result.data,
    createdBy: app.user.name,
  };
});

export const download = createAsyncThunk('planner-list/download', async (data) => {
  const { id, name } = data;

  const result = await exportPlanner(id);
  const url = window.URL.createObjectURL(new Blob([result.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  setInterval(() => {
    link.remove();
  }, 15000);
});

export const uploadAvatar = createAsyncThunk('planner-list/upload-avatar', async ({ id, file }) => {
  const data = new FormData();
  data.append('image', file);

  const response = await uploadImage(id, data);

  return {
    ...response.data,
    id: id,
  };
});

export const toggle = createAsyncThunk('planner-list/toggle-influencer', async ({ id, nw }) => {
  try {
    const result = await addInfluencerInPL({
      id,
      nw,
    });

    feedback.success(i18n.t('Influenciador adicionado com sucesso'));

    return {
      id,
      list: result.data.updated_user_list,
    };
  } catch (err) {
    feedback.error(i18n.t('Erro ao adicionar influenciador'));

    throw err;
  }
});

export const plannerListSlice = createSlice({
  name: 'plannerList',
  initialState,
  reducers: {
    updateSelectedOrder: (state, action) => {
      state.order = action.payload;
    },
    resetPlannerStatus: (state, action) => {
      state.status[action.payload] = StateStatus.idle;
    },
    clearList: (state) => {
      state.list = [];
      state.status.fetch = StateStatus.idle;
    },
    clearStatus: (state) => {
      state.status.create = StateStatus.idle;
      state.status.edit = StateStatus.idle;
      state.status.uploadAvatar = StateStatus.idle;
    },
    clearAvatar: (state) => {
      state.updatedAvatar = null;
    },
  },
  extraReducers: {
    [fetch.pending]: (state) => {
      state.status.fetch = StateStatus.loading;
    },
    [fetch.fulfilled]: (state, { payload }) => {
      const { data, favorite } = payload;
      let filteredList = [];

      const list = data['items'];

      if (list && list.length > 0) {
        if (favorite && data['page'] === 1) {
          filteredList = [favorite];
        }

        list.map((item) => {
          const model = new PlannerListModel();
          filteredList = [...filteredList, model.fromJson(item)];
        });
      }

      state.list = filteredList;
      state.title = payload['title'];

      state.page = data['page'];
      state.total = data['total'];
      state.totalPages = data['pages'];

      state.status.fetch = StateStatus.succeeded;
    },
    [fetch.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.fetch = StateStatus.failed;
    },
    [edit.pending]: (state) => {
      state.status.edit = StateStatus.loading;
    },
    [edit.fulfilled]: (state, action) => {
      state.list = state.list.map((item) => {
        if (action.payload && item.id === action.payload.uid) {
          item.name = action.payload.title;
          item.isShared = action.payload.shared;
        }

        return item;
      });
      state.status.edit = StateStatus.succeeded;
    },
    [edit.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.edit = StateStatus.succeeded;
    },
    [create.pending]: (state) => {
      state.status.create = StateStatus.pending;
    },
    [create.fulfilled]: (state, { payload }) => {
      let pl = new PlannerListModel().fromJson(payload);

      pl.isOwner = true;
      pl.created = payload.createdBy;
      pl.avatar = state.updatedAvatar;

      state.list = [pl].concat(state.list);

      feedback.success(i18n.t('Lista criada com sucesso'));

      state.status.create = StateStatus.succeeded;
    },
    [create.rejected]: (state, action) => {
      feedback.error(i18n.t('Erro ao criar lista'));

      state.errorMsg = action.error.message;
      state.status.create = StateStatus.failed;
    },
    [uploadAvatar.pending]: (state) => {
      state.status.uploadAvatar = StateStatus.pending;
    },
    [uploadAvatar.fulfilled]: (state, { payload }) => {
      state.list = state.list.map((item) => {
        if (item.id === payload.id) {
          item.avatar = `${process.env.REACT_APP_BASEURL}/${payload['cover_image'].replace(
            '/v1/',
            '',
          )}`;
        }

        return item;
      });

      state.updatedAvatar = `${process.env.REACT_APP_BASEURL}/${payload['cover_image'].replace(
        '/v1/',
        '',
      )}`;
      state.status.uploadAvatar = StateStatus.succeeded;
    },
    [uploadAvatar.rejected]: (state, action) => {
      feedback.success(i18n.t('Erro ao adicionar imagem!'));

      state.errorMsg = action.error.message;
      state.status.uploadAvatar = StateStatus.failed;
    },
    [remove.pending]: (state) => {
      state.status.remove = StateStatus.loading;
    },
    [remove.fulfilled]: (state, { payload }) => {
      state.list = state.list.filter((item) => item.id !== payload);

      if (window.location.hash != '#/planner-lists') {
        window.location.href = '/#/planner-lists';
      }

      state.status.remove = StateStatus.succeeded;
    },
    [remove.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.remove = StateStatus.failed;
    },
    [duplicate.pending]: (state) => {
      state.status.duplicate = StateStatus.loading;
    },
    [duplicate.fulfilled]: (state, { payload }) => {
      let pl = new PlannerListModel().fromJson(payload);

      pl.isOwner = true;
      pl.created = payload.createdBy;

      state.list = [pl].concat(state.list);

      feedback.success(i18n.t('Lista duplicada com sucesso'));
      state.status.duplicate = StateStatus.succeeded;
    },
    [duplicate.rejected]: (state) => {
      feedback.error(i18n.t('Erro ao duplicar lista'));

      state.status.duplicate = StateStatus.failed;
    },
    [toggle.pending]: (state) => {
      state.status.toggle = StateStatus.loading;
    },
    [toggle.fulfilled]: (state, action) => {
      const id = action.payload.id;
      const list = action.payload.list;

      let indexList = id === 'fav' ? 0 : state.list.findIndex((item) => item.id === id);

      let newList = state.list;

      if (indexList > -1) {
        newList[indexList].list = list;

        if (action.payload.include) {
          newList[indexList].size += 1;
        } else {
          newList[indexList].size -= 1;
        }
      }

      state.list = newList;
      state.status.toggle = StateStatus.succeeded;
    },
    [toggle.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.toggle = StateStatus.failed;
    },
    [download.pending]: (state) => {
      state.status.download = StateStatus.loading;
    },
    [download.fulfilled]: (state) => {
      state.status.download = StateStatus.succeeded;
    },
    [download.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.download = StateStatus.failed;
    },
  },
});

export const { clearList, clearStatus, clearAvatar, resetPlannerStatus, updateSelectedOrder } =
  plannerListSlice.actions;

export const selectPlannerListSlice = (state) => state.plannerList;

export const selectList = (state) => state.plannerList.list;
