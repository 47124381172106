import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//
import CheckBox from '../../../../../../../common/components/CheckBox';
//
import './index.scss';

const PickedItemIncludeExclude = ({
    children,
    value,
    include,
    relevances,
    hasRelevances,
    onChange,
    onDelete,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={'picked-item-include-exclude'}>
                <div className={`junction-item ${!hasRelevances ? 'rounded' : ''} ${include ? 'active' : ''}`}>
                    <button
                        className={`btn-action btn-minus ${!include ? 'active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            const data = {
                                id: value,
                                rule: include ? 'exclude' : 'include',
                            }

                            if(relevances) {
                                data.relevances = relevances
                            }

                            onChange(data);
                        }}
                    >
                        <i className="icon icon-minus" />
                    </button>

                    <button
                        className={`btn-action btn-plus ${include ? 'active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            const data = {
                                id: value,
                                rule: include ? 'exclude' : 'include',
                            }

                            if(relevances) {
                                data.relevances = relevances
                            }

                            onChange(data);
                        }}
                    >
                        <i className="icon icon-plus" />
                    </button>

                    <div className="picked-label">
                        <span>{children}</span>
                    </div>

                    <button
                        className="btn-action btn-close"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onDelete();
                        }}
                    >
                        <i className="icon icon-close" />
                    </button>
                </div>
            </div>
            {hasRelevances ? (
                <div className={`picked-item-options ${include ? 'active' : ''}`}>
                    <div className="item-options-title">Frequência de abordagem:</div>
                    <div className="item-options">
                        <CheckBox
                            value="high"
                            checked={relevances?.high}
                            text={t('Alta')}
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                onChange({
                                    id: value,
                                    rule: !include ? 'exclude' : 'include',
                                    relevances: {
                                        high: e.target.checked,
                                        medium: relevances?.medium,
                                        low: relevances?.low,
                                    },
                                });
                            }}
                        />
                        <CheckBox
                            value="medium"
                            checked={relevances?.medium}
                            text={t('Média')}
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                onChange({
                                    id: value,
                                    rule: !include ? 'exclude' : 'include',
                                    relevances: {
                                        high: relevances?.high,
                                        medium: e.target.checked,
                                        low: relevances?.low,
                                    },
                                });
                            }}
                        />
                        <CheckBox
                            value="low"
                            checked={relevances?.low}
                            text={t('Baixa')}
                            onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                onChange({
                                    id: value,
                                    rule: !include ? 'exclude' : 'include',
                                    relevances: {
                                        high: relevances?.high,
                                        medium: relevances?.medium,
                                        low: e.target.checked,
                                    },
                                });
                            }}
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};

PickedItemIncludeExclude.defaultProps = {
    include: true,
};

PickedItemIncludeExclude.propTypes = {
    include: PropTypes.bool,
    value: PropTypes.string,
    relevances: PropTypes.array,
    hasRelevances: PropTypes.bool,
    children: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default PickedItemIncludeExclude;
