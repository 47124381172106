import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// 
import { createBriefing, trialAuth } from "./service"
// 
import feedback from "../../../common/components/Feedback"
import i18n from "../../../languages/i18n"
// 
import StateStatus from "../../../utils/status"
import InfluencerModel from "../../../models/InfluencerModel"

const initialState = {
    profiles: [],
    status: {
        sendBriefing: StateStatus.idle,
        fetchTokens: StateStatus.idle,
    },
    plId: null,
    errMsg: null,
    airaMsgs: [],
    curationFinalized: false,
}

export const sendBriefing = createAsyncThunk("aira-tasting/send-curation", async (data) => {
    const result = await createBriefing(data)
        
        return result.data
   
})

export const fetchTokens = createAsyncThunk("aira-tasting/auth", async (data) => {
    const result = await trialAuth(data)
        
        return result.data
   
})

export const airaTastingSlice = createSlice({
    name: "airaTasting",
    initialState,
    reducers: {
        clearCuration: (state) => {
            state.plId = null
            state.errMsg = null
            state.airaMsgs = []
            state.profiles = []
            state.status.sendBriefing = StateStatus.idle
        },
        updateStatus: (state, { payload }) => {
            state.status[payload.name] = payload.status
        },
    },
    extraReducers: {
        [sendBriefing.pending]: (state) => {
            state.status.sendBriefing = StateStatus.loading
        },
        [sendBriefing.fulfilled]: (state, {
            payload
        }) => {
            state.plId = payload.id      

            if(payload.profiles && payload.profiles.length > 0) {
                const profiles = payload.profiles.map((data) => {
                    const influencer = new InfluencerModel().airaCurationResultFromJson(data)
                    
                    return influencer
                })

                state.profiles = profiles
            }


            state.status.sendBriefing = StateStatus.succeeded      
        },
        [sendBriefing.rejected]: (state, action) => {
            state.airaMsgs.push({text: action.error.message})

            feedback.error(i18n.t("Erro ao enviar briefing. Tente novamente!"))

            state.status.sendBriefing = StateStatus.failed
        },
        // 
        [fetchTokens.pending]: (state) => {
            state.status.fetchTokens = StateStatus.loading
        },
        [fetchTokens.fulfilled]: (state, {
            payload
        }) => {
            window.localStorage.setItem("aira_tasting_access_token", payload["access_token"])     

            state.status.fetchTokens = StateStatus.succeeded      
        },
        [fetchTokens.rejected]: (state, action) => {
            state.airaMsgs.push({text: action.error.message})
            state.status.fetchTokens = StateStatus.failed
        },
        // 
    }
})

export const {
    clearCuration,
    updateStatus
} = airaTastingSlice.actions

export const selectAiraTastingSlice = (state) => state.airaTasting