import React from "react"
import PropTypes from "prop-types"
// 
import { useTranslation } from "react-i18next"
// 
import Button from "../../../../../common/components/Button";
// 
import aira from '../../../../../common/assets/images/aira.png';
import finishedIllustration from '../../../../../common/assets/images/finished-illustration.png';

const CurationConcluded = ({
    onFinish
}) => {
    const { t } = useTranslation()

    return <div className="curation-concluded">
        <img className="aira" src={aira} alt={t("mulher  do cabelo preto acenando")} />
        
        <div className="box-concluded">
            <span>{t("Aira")}</span>

            <p className="aira-title">{t("Curadoria concluída!")}</p>

            <img className="finished-illustration" src={finishedIllustration} alt={t("homem de roupa formal cruzando a linha de chegada")} />

            <p className="aira-text">{t("Nossa curadoria já está pronta para ser acessada na sessão")} <b>{t("Lista de Planejamento.")}</b></p>

            <Button
                className="submit-btn"
                onClick={() => onFinish()}
            >
                {t("Abrir na Lista de Planejamento")}
            </Button>
        </div>
    </div>
}

CurationConcluded.propTypes = {
    onFinish: PropTypes.func.isRequired,
}
  
export default CurationConcluded