import React from "react"
// 
import { useTranslation } from "react-i18next"
// 
import { Form } from "antd"
import Input from "../../../../../common/components/Input"
import Button from "../../../../../common/components/Button"

const BrandForm = () => {
    const { t } = useTranslation()

    return <>
        <p className="title-form">{t("Marca")}:</p>

        <Form.Item
            name="name"
            label={`${t("Nome da marca")}:`}
            rules={[
                {
                    required: true,
                    message: <span className="normal-12">{t("Informe a marca")}</span>,
                },
            ]}
        >
            <Input
                placeholder={t("Digite o nome da marca")}
            />
        </Form.Item>

        <Form.List 
            name={["social_media_url"]}
            initialValue={[null]}
        >
            {(fields, { add, remove }) => {
                return (
                    <div>
                        {fields.map((url, index) => (
                            <div className="junction-input-icon" key={index}>
                                <Form.Item
                                    name={[index]}
                                    label={`${t("Links das redes sociais")}:`}
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                const urlRegex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")

                                                if (value && urlRegex.test(value)) {
                                                    return Promise.resolve()
                                                } else {
                                                    return Promise.reject(new Error("Informe uma url válida"))
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        placeholder="https://"
                                    />

                                </Form.Item>

                                {index == 0 &&
                                    <Button
                                        onClick={() => add()}
                                        className="outline-dark add-btn"
                                    >
                                        {t("Adicionar")}
                                    </Button>
                                }

                                {index > 0 &&
                                    <i
                                        onClick={() => remove(url.name)}
                                        className="icon icon-close hover"
                                    />
                                }
                            </div>
                        ))}
                    </div>
                )
            }}
        </Form.List>
    </>
}

export default BrandForm