import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import './CustomInput.scss';

export const CustomInput = forwardRef(({ label, error, id, ...props }, ref) => {
  return (
    <div className="field_wrapper">
      <input id={id} {...props} ref={ref} />
      <label htmlFor={id}>{label}</label>
      {error && <span className="error_message__field_wrapper">{error}</span>}
    </div>
  );
});

CustomInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

CustomInput.displayName = 'CustomInput';
