import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// 
import { createCategory } from "./service"
// 
import i18n from "../../../languages/i18n"
import StateStatus from "../../../utils/status"
import { changeObjAlert, showAlert } from "../../../common/components/Alert/slice"

const initialState = {
    status: {
        sendCategory: StateStatus.idle,
    },
    errMsg: null
}

export const sendCategory = createAsyncThunk("add-category", async (data, thunkAPI) => {
    try {
        const result = await createCategory(data)
        
        thunkAPI.dispatch(changeObjAlert({
            show: true,
            type: "success",
            title: i18n.t("Solicitação enviada"),
            text: i18n.t("Nossa equipe avaliará sua solicitação e em breve você receberá uma notificação com a resposta."),
            cancelText: `${i18n.t("Fechar")}!`,
            onCancel: () => {
                thunkAPI.dispatch(showAlert(false))
            },
        }))
        
        return result.data
        
    } catch (err) {
        thunkAPI.dispatch(changeObjAlert({
            show: true,
            type: "error",
            title: `${i18n.t("Erro")}!`,
            text: i18n.t("Erro ao adicionar categoria! Teste novamente mais tarde."),
            cancelText: i18n.t("Fechar"),
            onCancel: () => {
                thunkAPI.dispatch(showAlert(false))
            },
        }))

        throw err
    }
})

export const addCategorySlice = createSlice({
    name: "addCategory",
    initialState,
    extraReducers: {
        [sendCategory.pending]: (state) => {
            state.status.sendCategory = StateStatus.loading
        },
        [sendCategory.fulfilled]: (state) => {
            state.status.sendCategory = StateStatus.succeeded      
        },
        [sendCategory.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.sendCategory = StateStatus.failed
        },
    }
})

export const selectAddCategorySlice = (state) => state.addCategory