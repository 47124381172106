import PropTypes from 'prop-types';

import './styles.scss';

export const DirectButton = ({ influencer }) => {
  const instagramLink = `https://ig.me/m/${influencer.username}`;
  const tiktokLink = `https://imagefiles.airstrip.cloud/v1/external/tiktokdm?code=${influencer.posts[0]?.externalId}`;

  const directLink = influencer.network === 'instagram' ? instagramLink : tiktokLink;

  return (
    <a
      href={directLink}
      className="direct_link"
      target="_blank"
      rel="noreferrer"
      onClick={() => window.open(directLink, '_blank')}
    >
      Abrir direct
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8115_36869)">
          <path
            d="M14.6693 1.33301L10.0026 14.6663L7.33594 8.66634M14.6693 1.33301L1.33594 5.99967L7.33594 8.66634M14.6693 1.33301L7.33594 8.66634"
            stroke="#ED276A"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_8115_36869">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};

DirectButton.propTypes = {
  influencer: PropTypes.any,
};
