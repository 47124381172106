import React from "react"
import PropTypes from "prop-types"
// 
import { Button as AntButton } from "antd"
// 
import tiktokImg from "../../assets/icons/tiktok.svg"
import "./index.scss"

const Button = ({
  icon,
  network,
  className,
  children,
  isDisabled,
  isLoading,
  onClick,
  onSumbit,
  ...props
}) => (
  <AntButton
    data-testid="button"
    className={`button ${network ? `background-color-${network}` : ""} ${className}`}
    icon={icon && (
        icon === "tiktok" ? 
          <img src={tiktokImg} className="tiktok-img" rel="tiktok icon" />
        : 
          <i className={`icon icon-${icon}`} />
      )
    }
    loading={isLoading}
    disabled={isDisabled}
    onClick={onClick}
    onSubmit={onSumbit}
    {...props}
  > 
    {!isLoading && children}
  </AntButton>
)

Button.defaultProps = {
  className: "",
  isLoading: false,
  isDisabled: false,
}

Button.propTypes = {
  icon: PropTypes.string,
  network: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onSumbit: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.any,
}

export default Button
