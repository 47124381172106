import React from "react"
import PropTypes from "prop-types"
// 
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// 
import { useSelector } from "react-redux"
import { selectAppSlice } from "../../../app/slice"
// 
import Button from "../Button"
// 
import aira from '../../assets/images/aira.png';
// 
import "./index.scss"

const AiraRecommendation = ({
    btnOutline,
    onClick,
    enableOnClick
}) => {
    const { t } = useTranslation()
    // 
    const navigate = useNavigate()
    // 
    const slice = useSelector(selectAppSlice)
    // 
    const user = slice.user

    const click = () => {
        if(enableOnClick && onClick) onClick()
        else navigate("/aira")
    }

    return user.features && user.features.includes("aira") ? 
        <div 
            className="aira-recommendation hover"
            onClick={() => click()}
        >
            <img src={aira} alt="mulher  do cabelo preto sorrindo" />


            <div className="presentation-text">
                <span className="text-1">{t("Encontre influenciadores com mais facilidade")}.</span>
            
                <span className="text-2">{t("Olá, sou Aira, sua assistente virtual especialista em curadoria.")}</span>
            </div>


            <Button
                className={btnOutline ? "outline-dark" : "pattern-dark"}
                onClick={() => click()}
            >
                {t("Começar")}
            </Button>
        </div>
        : <></>
}

AiraRecommendation.defaultProps = {
    btnOutline: () => {},
    enableOnClick: false
}
  
AiraRecommendation.propTypes = {
    onClick: PropTypes.func,
    btnOutline: PropTypes.func,
    enableOnClick: PropTypes.bool,
}
  
export default AiraRecommendation