import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
// 
import { useDispatch, useSelector } from "react-redux"
import { selectInfluencerDetailsSlice, selectFilterCategory, updateFilterCategory } from "../../slice"
// 
import { Tooltip } from "antd"
import Radio from "../../../../../common/components/Radio"
import Loading from "../../../../../common/components/Loading"
import Select from "../../../../../common/components/Select"
// 
import { exportElementToImage } from "../../../../../utils"
// 
import "./index.scss"

const InteractionChart = ({
    title,
    tooltip,
    chart,
    isScore,
    onGetData,
    isLoading,
    categories,

}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // 
    const slice = useSelector(selectInfluencerDetailsSlice)
    const filterCategory = useSelector(selectFilterCategory)
    // 
    const influencer = slice.influencer
    // 
    const [filterPeriod, updateFilterPeriod] = useState(30)
    const [filterScore, updateFilterScore] = useState("")
    const [chartName, updateChartName] = useState("")
    // 
    const filterScores = [
        { label: t("Airscore geral"), value: "" },
        { label: t("Airscore por assuntos"), value: "all" },
    ]
    const filterPeriods = [
        { key: "30 Dias", value: 30 },
        { key: "60 Dias", value: 60 },
        { key: "90 Dias", value: 90 },
    ]
    
    const dec2hex = (dec) => {
        return dec.toString(16).padStart(2, "0")
    }

    const generateId = (len = 40) => {
        var arr = new Uint8Array((len) / 2)
        window.crypto.getRandomValues(arr)
        
        return Array.from(arr, dec2hex).join('')
    }

    useEffect(() => {
        if(chart) {
            onGetData(filterPeriod, (filterCategory || filterScore))
        }

        updateChartName(!title ? `${generateId()}` : `${title}`.toLowerCase().replaceAll(" ", "-"))
    }, [filterPeriod, filterCategory, filterScore])

    return <div className={`interaction-chart chart-${chartName}`} key={title}>
        <div className="subtitle-filter-category">
            <div className="subtitle-part">
                {title}

                {tooltip}
            </div>

            <div className="filters">
                {isScore && !filterCategory &&
                    <div className="score-options">
                        <Radio
                            value={filterScore}
                            onChange={(value) => updateFilterScore(value)}
                            options={filterScores.map(filter => ({ value: filter.value, label: filter.label }))}
                        />
                    </div>
                }
                
                {categories && categories.length > 1 &&
                    <Select
                        allowClear={true}
                        value={filterCategory}
                        placeholder={t("Selecione uma categoria")}
                        onChange={(value) => dispatch(updateFilterCategory(value))}
                        options={categories.map(category => (
                            { 
                                value: category.id, 
                                key: category.name, 
                                icon: <i 
                                    style={{ color: category.color }}
                                    className={`icon icon-${category.icon}`} 
                                /> 
                            }
                        ))}
                    />
                }
            </div>
        </div>

        <div className="filter-period">
            {filterPeriods.map(filter => 
                <button    
                    key={filter.value}
                    className={`btn-filter ${filterPeriod === filter.value ? "selected" : "unselected"}`}
                    onClick={() => updateFilterPeriod(filter.value)}
                >
                    {filter.key}
                </button>
            )}
        </div>


        {isLoading && <Loading />}

        {!isLoading && 
            <div className="box-chart">
                <Tooltip title={t("Baixar imagem do gráfico")}>
                    <i 
                        role="button"
                        className="icon icon-download hover" 
                        onClick={() => {
                            setTimeout(() => {
                                exportElementToImage(
                                    `chart-${chartName}`, 
                                    `${influencer.name}-${title}`.toLowerCase().replaceAll(" ", "-"),
                                    !filterCategory    
                                )
                            }, 1000);
                        }}    
                    />
                </Tooltip>

                {chart}
            </div>
        }
    </div> 
}


InteractionChart.defaultProps = {
    title: "",
    isScore: false,
    isLoading: false,
}

InteractionChart.propTypes = {
    title: PropTypes.any,
    isScore: PropTypes.bool,
    isLoading: PropTypes.bool,
    chart: PropTypes.element.isRequired,
    tooltip: PropTypes.element,
    onGetData: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(PropTypes.object),
}

export default InteractionChart