import { Tabs, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import unavailableValue from '../../../../../common/assets/images/empty.svg';
import { changeObjAlert, showAlert } from '../../../../../common/components/Alert/slice';
import Button from '../../../../../common/components/Button';
import DonutsChart from '../../../../../common/components/Charts/DonutsChart';
import DoubleBarChart from '../../../../../common/components/Charts/DoubleBarChart';
import Loading from '../../../../../common/components/Loading';
import { exportElementToImage } from '../../../../../utils';
import StateStatus from '../../../../../utils/status';
import { getInfluencerExists } from '../../service';
import {
  fetchDemographicsData,
  fetchDemographicsDataUpdate,
  selectInfluencerDetailsSlice,
} from '../../slice';

import './index.scss';

const PartDemographics = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dataset = {};
  const slice = useSelector(selectInfluencerDetailsSlice);

  const influencer = slice.influencer;
  const demographics = slice.demographics;
  const [exists, updateExists] = useState(false);
  const [selectedSegmentIndex, updateSelectedSegmentIndex] = useState(0);

  const aboutFollowersItems = [
    { name: t('Sexo'), icon: 'user', alias: 'gender', type: 'donuts' },
    { name: t('Tem filhos?'), icon: 'group', alias: 'parental', type: 'donuts' },
    { name: t('Idade'), icon: 'baby', alias: 'census', type: 'bars' },
    { name: t('Local'), icon: 'location', alias: 'location', type: 'bars' },
    { name: t('Estado civil'), icon: 'rings', alias: 'marital', type: 'donuts' },
  ];
  const demographicsLabels = [
    {
      name: t('Persona'),
      alias: 'persona',
      items: [{ alias: 'persona' }],
    },
    {
      name: t('Sexo, Idade e Localização'),
      alias: 'sex-age-place',
      items: [
        { title: t('Gênero da audiência'), alias: 'gender', type: 'donuts' },
        { title: t('Idade da Audiência'), alias: 'census', type: 'bars' },
        { title: t('Localização da Audiência'), alias: 'location', type: 'bars' },
      ],
    },
    {
      name: t('Interesses e Marcas que se conectam'),
      alias: 'interests-brands',
      items: [
        { title: t('Interesses'), alias: 'likes', type: 'bars' },
        { title: t('Marcas que se conectam'), alias: 'brands', type: 'bars' },
      ],
    },
    {
      name: t('Estado Civil e Têm filhos?'),
      alias: 'maritial-status-has-child',
      items: [
        { title: t('Estado civil'), alias: 'marital', type: 'donuts' },
        { title: t('Têm filhos?'), alias: 'parental', type: 'donuts' },
      ],
    },
    {
      name: t('Mídia consumida e Perfis que mais seguem'),
      alias: 'media-consumed-and-profiles-most-followed',
      items: [
        { title: t('Mídia consumida pela audiência'), alias: 'media', type: 'bars' },
        { title: t('Perfis que mais seguem'), alias: 'influencers', type: 'bars' },
      ],
    },
  ];

  const getDemographics = () => {
    dispatch(fetchDemographicsData());
  };

  const showReasons = () => {
    dispatch(
      changeObjAlert({
        show: true,
        type: 'info',
        title: t('Dados da audiência indisponíveis'),
        subtitle: t('Infelizmente não conseguimos analisar os dados de audiência desse perfil.'),
        text: (
          <>
            <span>{t('Algumas situações impedem nossos sistemas de fazer essa análise')}:</span>

            <br />

            <ul>
              <li>
                {t('O perfil tem menos')} <b>{t('de 10 mil seguidores.')}</b>
              </li>
              <li>
                {t('O perfil tem mais de 10 mil seguidores, mas possui diversos')}{' '}
                <b>{t('seguidores de perfil falso.')}</b>
              </li>
              <li>
                {t('O perfil tem mais de 10 mil seguidores, mas possui muitos')}{' '}
                <b>{t('seguidores com conta privada.')}</b>
              </li>
            </ul>
          </>
        ),
        cancelText: t('Fechar'),
        onCancel: () => dispatch(showAlert(false)),
      }),
    );
  };

  useEffect(() => {
    if (influencer.followers > 10000) {
      getInfluencerExists(influencer.id, influencer.username, influencer.network)
        .then((response) => {
          updateExists(response.data.exists);
        })
        .catch(() => {
          updateExists(false);
        });
    }
  }, []);

  useEffect(() => {
    if (exists) {
      dispatch(fetchDemographicsData());
    }
  }, [influencer, exists]);

  useEffect(() => {
    if (
      slice.status.fetchDemographicsData === StateStatus.succeeded &&
      ['idle', 'loading'].includes(demographics.statusLoading)
    ) {
      setTimeout(() => {
        getDemographics();
      }, 2000);
    }
  }, [slice.status.fetchDemographicsData, demographics.statusLoading]);

  const calcRelevance = (data, byRelevance = true) => {
    if (byRelevance) {
      return data.reduce((acc, curr) => {
        return curr.relevance > acc.relevance ? curr : acc;
      });
    }

    return data.reduce((acc, curr) => {
      return curr.value > acc.value ? curr : acc;
    });
  };

  const getBuildBarChart = (key, data, title) => {
    const sortAge = (array) => {
      return array?.sort((a, b) => {
        return a.label.slice(0, 6) < b.label.slice(0, 6) ? -1 : 1;
      });
    };

    const sortOthers = (array) => {
      return array?.sort((a, b) => {
        return a.value < b.value ? 1 : -1;
      });
    };

    sortOthers(data);

    if (title === 'Idade da Audiência') {
      sortAge(data);
    }

    return (
      <div key={`bars-${key}`} className={`bars bars-${key} ${!data ? 'no-data' : ''}`}>
        {title && <div className="text-part">{title}</div>}

        {!data && <div className="no-data">{t('Ops! Infelizmente não temos essa informação')}</div>}

        {data && (
          <>
            <Tooltip title={t('Baixar imagem do gráfico')}>
              <i
                role="button"
                className="icon icon-download hover"
                onClick={() => {
                  setTimeout(() => {
                    exportElementToImage(
                      `bar-${key}`,
                      `${influencer.name}-${key}`.toLowerCase().replaceAll(' ', '-'),
                    );
                  }, 1000);
                }}
              />
            </Tooltip>

            <DoubleBarChart
              total={100}
              name={key}
              labels={dataset}
              fixedTooltip={true}
              formatName="percent"
              data={data}
            />
          </>
        )}
      </div>
    );
  };

  const getBuildDonutChart = (key, data, title) => {
    return (
      <div key={`donuts-${key}`} className={`donuts donuts-${key} ${!data ? 'no-data' : ''}`}>
        <div className="text-part">{title}</div>

        {!data && <div className="no-data">{t('Ops! Infelizmente não temos essa informação')}</div>}

        {data && (
          <>
            <Tooltip title={t('Baixar imagem do gráfico')}>
              <i
                role="button"
                className="icon icon-download hover"
                onClick={() => {
                  setTimeout(() => {
                    exportElementToImage(
                      `donuts-${key}`,
                      `${influencer.name}-genero-da-audiencia`.toLowerCase().replaceAll(' ', '-'),
                    );
                  }, 1000);
                }}
              />
            </Tooltip>

            <DonutsChart nameFormat="percent" data={data} />
          </>
        )}
      </div>
    );
  };

  const handleReleaseData = () => {
    dispatch(fetchDemographicsDataUpdate());
  };

  return (
    <>
      <p className="title-part">{t('Dados da audiência do influenciador')}</p>

      {!demographics.hasDemographic &&
        (['idle', 'loading'].includes(demographics.statusLoading) ||
          slice.status.fetchDemographicsData === StateStatus.loading) && (
          <div className="blocked-charts">
            <Loading />
          </div>
        )}

      {(demographics.statusLoading === 'insufficient_data' ||
        (!demographics.statusLoading && influencer.followers <= 9999)) && (
        <div className="blocked-charts">
          <p className="message message-insufficient-data">
            {t('Ops! Não conseguidos analisar os dados de audiência.')}
          </p>

          <span className="hover reasons-for-block" role="button" onClick={() => showReasons()}>
            {t('Saiba o que pode ter acontecido.')}
          </span>

          <img className="unavailable-img" src={unavailableValue} alt="diversas nuvens azuis" />
        </div>
      )}

      {!demographics.exists &&
        slice.status.fetchDemographicsData === StateStatus.idle &&
        influencer.followers > 9999 && (
          <div className="blocked-charts">
            <p>{t('Acesse os dados da audiência')}</p>

            <span>
              {t(
                'Saiba qual a principal idade, gênero, localização e mais sobre os seguidores deste perfil.',
              )}
            </span>

            <Button onClick={() => getDemographics()}>{t('Liberar dados da audiência')}</Button>
          </div>
        )}

      {demographics.hasDemographic && (
        <>
          <div className="demographics">
            <div className="segment-control">
              <Tabs
                activeKey={selectedSegmentIndex}
                defaultActiveKey={0}
                items={demographicsLabels.map((item, index) => {
                  return {
                    key: index,
                    label: item.name,
                    children: '',
                  };
                })}
                onChange={updateSelectedSegmentIndex}
              />
            </div>

            <div
              className={`current-charts current-charts-${demographicsLabels[selectedSegmentIndex].alias}`}
            >
              {demographicsLabels[selectedSegmentIndex].items.map(({ alias, type, title }) => {
                if (alias === 'persona') {
                  return (
                    <div key="box-donuts" className="box-donuts">
                      <div className="chart-items">
                        {aboutFollowersItems.map(({ type, name, alias, icon }, index) => {
                          const chartData = demographics.charts[type][alias];

                          let value;
                          let label;

                          if (chartData && Object.keys(chartData).length > 0) {
                            const greaterRelevance = calcRelevance(chartData, alias !== 'gender');

                            value = `(${(greaterRelevance.value / 100).toFixed(2)}%)`.replaceAll(
                              '.',
                              ',',
                            );
                            label = greaterRelevance.label;
                          }

                          return (
                            <div key={`chart-item-${index}`} className="chart-item">
                              <p className="value-name">{name}</p>

                              <div className="junction-value">
                                <div>
                                  <i className={`icon icon-${icon}`} />

                                  {label && <b>{t(label)}</b>}

                                  {!value && <span className="no-data">-</span>}
                                </div>

                                {!value && ['parental', 'marital'].includes(alias) && (
                                  <>
                                    {demographics.updateStatus === 'idle' && (
                                      <button
                                        className="release_data__junction_value"
                                        onClick={handleReleaseData}
                                      >
                                        Liberar novos dados
                                      </button>
                                    )}

                                    {demographics.updateStatus === 'loading' && (
                                      <span className="spinner__junction_value" />
                                    )}
                                  </>
                                )}

                                {value && <span className="value">{value}</span>}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else {
                  const chartData = demographics.charts[type][alias];

                  const existsData =
                    !chartData?.length &&
                    (alias === 'media' || alias === 'marital' || alias === 'parental');

                  if (existsData) {
                    return (
                      <div key={`chart-${alias}`} className="card_release_data">
                        {demographics.updateStatus === 'idle' && (
                          <>
                            <strong>Novos dados foram lançados</strong>
                            <p>
                              Saiba qual o estado civil, se têm filhos, mídia consumida e perfis que
                              a audiência mais seguem.
                            </p>
                            <button type="button" onClick={handleReleaseData}>
                              Liberar novos dados
                            </button>
                          </>
                        )}

                        {demographics.updateStatus === 'loading' && <Loading />}
                      </div>
                    );
                  }

                  return (
                    <div className={`box-chart box-chart-${alias}`} key={`chart-${alias}`}>
                      {type === 'bars' && getBuildBarChart(alias, chartData, title)}
                      {type === 'donuts' && getBuildDonutChart(alias, chartData, title)}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </>
      )}

      {slice.status.fetchDemographicsData === StateStatus.failed && (
        <p>{t('Erro ao gerar os dados da audiência. Tente novamente mais tarde!')}</p>
      )}
    </>
  );
};

PartDemographics.defaultProps = {
  demographicError: false,
  blockedDemographics: false,
  reachAudienceLoading: false,
};

PartDemographics.propTypes = {
  demographicError: PropTypes.bool,
  blockedDemographics: PropTypes.bool,
  reachAudienceLoading: PropTypes.bool,
};

export default PartDemographics;
