import moment from "moment"
// 
import InfluencerModel from "./InfluencerModel"
class NotificationModel {
    id
    date
    description
    link
    status
    title
    type
    userId
    isArchived
    plannerList
    influencers = []

    fromJson(data) {
        this.id = data["id"] ? `${data["id"]}` : null 
        this.date = moment(data["created_at"]).format("DD MMM. YY")
        this.description = data["description"]
        this.link = data["link"]
        this.status = data["status"]
        this.title = data["title"]
        this.type = data["type"]
        this.userId = data["user_id"]
        this.isArchived = data["status"] === "archived"
        
        return this
    }

    detailsFromJson(data) {
        this.id = data["uid"]
        this.date = moment(data["created_at"]).format("DD/MM/YYYY")
        this.description = data["description"]
        this.status = data["status"]
        this.title = data["title"]
        this.type = data["type"]
        this.userId = data["user_id"]
        this.isArchived = data["status"] === "archived"
        this.completeCount = data["complete"]
        this.notCompleteCount = data["not_complete"]
        
        if(data["planner_list"]) this.plannerList = data["planner_list"]["title"]

        return this
    }

    addInfluencers(influencers, categories) {
        if(influencers)
            this.influencers = influencers.map(influencerData => {
                let influencer = new InfluencerModel().fromJson(influencerData, null, categories)
                
                influencer.id = influencerData["id"]
                influencer.status = influencerData["status"]
                influencer.message = influencerData["message"]
                influencer.airscore = Math.ceil((influencerData["score"] || 0) * 1000)
                
                if(influencerData["counters"]) {
                    influencer.followers = influencerData["counters"]["followers"]
                    influencer.engagementRate = ((influencerData["engagement_rate"] || 0) * 100).toFixed(2)
                }

                return influencer
            })
        
        return this
    }
}

export default NotificationModel