import React from 'react';
import { Link } from 'react-router-dom';

import airaIMG from '../../../common/assets/images/aira-1.png';

import { CustomInput } from './components/CustomInput';
import { CustomSelect } from './components/CustomSelect';
import { useAiraCurationForm } from './useAiraCurationForm';

import './index.scss';

const AiraCurationFormEmail = () => {
  const { handleSubmit, register, errors, isSubmitting, isSubmitSuccessful } =
    useAiraCurationForm();

  const sectors = [
    { value: 'CEO', key: 'CEO' },
    { value: 'Comercial', key: 'Comercial' },
    { value: 'Financeiro', key: 'Financeiro' },
    { value: 'Influenciador', key: 'Influenciador' },
    { value: 'Marketing', key: 'Marketing' },
    { value: 'Marketing de Influência', key: 'Marketing de Influência' },
  ];

  return (
    <main className="container_aira_form">
      {!isSubmitSuccessful && (
        <>
          <div className="split__container">
            <div className="aira__container">
              <img src={airaIMG} />
              <div>
                <strong>Aira</strong>
                <span>Especialista em curadoria de influenciadores</span>
                <p>💪 Praticidade e agilidade</p>
                <p>🤖 Inteligência artificial</p>
                <p>🔎 Curadoria de influenciadores</p>
              </div>
            </div>
          </div>
          <div className="split__container">
            <form className="form__container" onSubmit={handleSubmit}>
              <h1>Teste gratuito</h1>
              <p>Acesse os dados dos 5 melhores influenciadores que combinam com a sua campanha.</p>
              <div className="fields__container">
                <CustomInput
                  id="name"
                  label="Nome completo*"
                  placeholder="Digite seu nome completo"
                  error={errors.name?.message}
                  {...register('name', {
                    required: 'O campo nome é obrigatório.',
                  })}
                />
                <CustomInput
                  id="email"
                  label="E-mail*"
                  placeholder="Digite seu e-mail"
                  error={errors.email?.message}
                  {...register('email', {
                    required: 'O campo e-mail é obrigatório.',
                  })}
                />
                <CustomInput
                  id="phone"
                  label="Celular*"
                  placeholder="Digite seu número"
                  error={errors.phone?.message}
                  {...register('phone', {
                    required: 'O campo celular é obrigatório.',
                  })}
                />
                <CustomSelect
                  id="sector"
                  label="Setor*"
                  placeholder="Selecione seu setor"
                  error={errors.sector?.message}
                  {...register('sector', {
                    required: 'O campo setor é obrigatório.',
                  })}
                >
                  <option value="" hidden>
                    Selecione seu setor
                  </option>
                  {sectors.map((sector) => (
                    <option key={sector.value} value={sector.value}>
                      {sector.value}
                    </option>
                  ))}
                </CustomSelect>
              </div>
              <div className="checkbox_wrapper">
                <input id="chatOnWhatsApp" type="checkbox" {...register('chatOnWhatsApp')} />
                <label htmlFor="chatOnWhatsApp">
                  <span>Conversar via Whatsapp</span>
                </label>
              </div>

              <footer className="footer__container">
                <div>
                  <div className="checkbox_wrapper">
                    <input
                      id="acceptTermsPrivacy"
                      type="checkbox"
                      {...register('acceptTermsPrivacy', {
                        required: 'Aceite os termos e políticas de privacidade para continuar.',
                      })}
                    />
                    <label htmlFor="acceptTermsPrivacy">
                      <span>
                        Aceito os{' '}
                        <a
                          href="https://airfluencers.com/politica-de-privacidade/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          termos de uso e política de privacidade
                        </a>
                      </span>
                    </label>
                  </div>
                  {errors.acceptTermsPrivacy?.message && (
                    <span className="error_message">{errors.acceptTermsPrivacy?.message}</span>
                  )}
                </div>

                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Enviando' : 'Continuar'}
                </button>
              </footer>
            </form>
          </div>
        </>
      )}
      {isSubmitSuccessful && (
        <div className="container_successful">
          <strong>Sucesso!</strong>
          <svg
            width="140"
            height="141"
            viewBox="0 0 140 141"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="container_successful__icon"
          >
            <circle cx="70" cy="70.5" r="70" fill="#50C064" className="svg-elem-1"></circle>
            <path
              d="M30 67.5L60.5 98L110.5 48"
              stroke="white"
              strokeWidth="3"
              className="svg-elem-2"
            ></path>
          </svg>
          <p>
            Pode levar alguns minutinhos para você receber o e-mail. Se não estiver encontrando
            lembre de olhar na caixa de spam ou lixo eletrônico.
          </p>
          <Link to="/aira-demonstracao/briefing/result">Voltar para o Top 5</Link>
        </div>
      )}
    </main>
  );
};

export default AiraCurationFormEmail;
