export const startMonitoring = (onSend) => {
    const enableMonitoring = process.env.REACT_APP_ENABLE_MONITORING;

    // monitoring
    const hiddenProperty = "hidden" in document ? "hidden" :
        "webkitHidden" in document ? "webkitHidden" :
            "mozHidden" in document ? "mozHidden" :
                null
    const array = new Uint32Array(1)
    const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, "visibilitychange")
    const SESSION_ID = `${Date.now()}-${window.crypto.getRandomValues(array)[0]}`
    const SCREEN_SIZE = {
        width: window.screen.width,
        height: window.screen.height
    }

    const getCSSSelector = (node) => {
        if (!(node instanceof Element)) return
    
        const path = []
    
        while (node.nodeType === Node.ELEMENT_NODE) {
            let selector = node.nodeName.toLowerCase()
    
            if (node.id) {
                selector += `#${node.id}`
                path.unshift(selector)
    
                break
            } else {
                let sibling = node
                let index = 1
    
                while (sibling.previousElementSibling) {
                    sibling = sibling.previousElementSibling
                    if (sibling.nodeName.toLowerCase() === selector) {
                        index++
                    }
                }
    
                if (index !== 1) {
                    selector += `:nth-child(${index + 1})`
                }
            }
    
            path.unshift(selector)
    
            node = node.parentNode
        }
    
        return path.join(' > ')
    }
    
    const airClickEvent = (e, onSend) => {
        const eventData = {
            timestamp: new Date().getTime(),
            type: e.type,
            screenWidth: SCREEN_SIZE.width,
            screenHeight: SCREEN_SIZE.height,
            sessionId: SESSION_ID,
            element: getCSSSelector(e.target),
            page: window.location.href,
            positionX: e.screenX,
            positionY: e.screenY
        }
    
        onSend({ ...eventData })
    }
    
    const airKeyEvent = (e, onSend) => {
        let eventData = {
            timestamp: new Date().getTime(),
            type: e.type,
            screenWidth: SCREEN_SIZE.width,
            screenHeight: SCREEN_SIZE.height,
            sessionId: SESSION_ID,
            element: e.key,
            page: window.location.href,
            position: null
        }
    
        if (e.ctrlKey) {
            if (e.keyCode === 67) {
                eventData.type = "copy"
                eventData.element = window.getSelection().anchorNode.textContent
            } else if (e.keyCode === 86) {
                eventData.type = "paste"
                eventData.element = e.srcElement.value
            }
        } else {
            onSend({ ...eventData })
        }
    }
    
    const airCopyEvent = (e, onSend) => {
        let eventData = {
            timestamp: new Date(Date.UTC()).getTime(),
            type: "copy",
            screenWidth: SCREEN_SIZE.width,
            screenHeight: SCREEN_SIZE.height,
            sessionId: SESSION_ID,
            element: e.srcElement.textContent,
            page: window.location.href,
            position: null,
        }
    
        onSend({ ...eventData })
    }
    
    const airPasteEvent = (e, onSend) => {
        const text = (e.originalEvent || e).clipboardData.getData('text/plain');
    
        let eventData = {
            timestamp: new Date().getTime(),
            type: "paste",
            screenWidth: SCREEN_SIZE.width,
            screenHeight: SCREEN_SIZE.height,
            sessionId: SESSION_ID,
            element: text,
            page: window.location.href,
            position: null
        }
    
        onSend({ ...eventData })
    }
    
    const airChangeViewEvent = (e, onSend) => {
        const eventData = {
            timestamp: new Date().getTime(),
            type: e.type,
            screenWidth: SCREEN_SIZE.width,
            screenHeight: SCREEN_SIZE.height,
            sessionId: SESSION_ID,
            element: document[hiddenProperty] ? "exit" : "enter",
            page: window.location.href,
            position: null
        }
    
        onSend({ ...eventData })
    }
    
    if(enableMonitoring) {
        document.addEventListener("mousedown", (e) => airClickEvent(e, onSend))
        document.addEventListener("keyup", (e) => airKeyEvent(e, onSend))
        document.addEventListener("copy", (e) => airCopyEvent(e, onSend))
        document.addEventListener("paste", (e) => airPasteEvent(e, onSend))
        document.addEventListener(visibilityChangeEvent, (e) => airChangeViewEvent(e, onSend))
    } else {
        document.removeEventListener("keyup", airKeyEvent, true)
        document.removeEventListener("copy", airCopyEvent, true)
        document.removeEventListener("paste", airPasteEvent, true)
        document.removeEventListener("mousedown", airClickEvent, true)
        document.removeEventListener(visibilityChangeEvent, airChangeViewEvent, true)
    }
}