import React from "react"
import { useParams } from "react-router-dom"
// 
import Home from "../Home"

const InfluencerList = () => {
  const { id } = useParams()

  return <Home listId={id} />
}

export default InfluencerList
