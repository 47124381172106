import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import { useSelector } from "react-redux"
import { selectInfluencerDetailsSlice } from "../../slice"
import { selectPlaces } from "../../../../../app/slice"
// 
import Button from "../../../../../common/components/Button"
// 
import "./index.scss"

const PartMentions = ({
    onClickBrand,
    onClickPlace,
    onClickHashtag,
    isSimple
}) => {
    const { t } = useTranslation()
    // 
    const slice = useSelector(selectInfluencerDetailsSlice)
    const placeNames = useSelector(selectPlaces).list
    const influencer = slice.influencer
    // 
    const [brands, updateBrands] = useState([])
    const [places, updatePlaces] = useState([])
    const [mentions, updateMentions] = useState([])
    const [hashtags, updateHashtags] = useState([])
    // 
    const [simpleBrands, updateSimpleBrands] = useState()
    const [simplePlaces, updateSimplePlaces] = useState()
    const [simpleMentions, updateSimpleMentions] = useState()
    const [simpleHashtags, updateSimpleHashtags] = useState()
    // 
    const [showMore, setShowMore] = useState(false)
    const [mentionExceededSize, setMentionExceededSize] = useState(false)

    useEffect(() => {
        if(!isSimple) {
            updateBrands(influencer.brands) 
            updatePlaces(influencer.places) 
            updateMentions(influencer.mentions) 
            updateHashtags(influencer.hashtags) 
        } else {
            let list = influencer.brands.map(mention => ` ${mention.name}`).toString()
            updateSimpleBrands(list.length > 210 ? `${list.slice(0, 210)}...` : list)
            
            list = influencer.places.map(mention => {
                const indexName = placeNames.findIndex(({ value }) => value === mention.id)

                return ` ${placeNames[indexName].key}`
            }).toString()
            updateSimplePlaces(list.length > 210 ? `${list.slice(0, 210)}...` : list)
            
            list = influencer.mentions.map(mention => ` ${mention.name}`).toString()
            updateSimpleMentions(list.length > 210 ? `${list.slice(0, 210)}...` : list)
            
            list = influencer.hashtags.map(mention => ` ${mention.name}`).toString()
            updateSimpleHashtags(list.length > 210 ? `${list.slice(0, 210)}...` : list)
        }
    }, [influencer])

    useEffect(() => {
        const checkHeightMentions = () => {
            const boxMentions = document.querySelectorAll(".mentions")
            // 
            const exceededSize = [...boxMentions].some((element) => element.scrollHeight > 200)
            
            setMentionExceededSize(exceededSize)
        }

        checkHeightMentions()
    }, [brands, places, mentions, hashtags])

    return <div className="influencer-mentions">
        <div className="title-part">
            {t("O que o influenciador mencionou")}  

            {!isSimple && mentionExceededSize && 
                <Button
                    className="outline-light btn-show-more"
                    onClick={() => setShowMore(!showMore)}
                >
                    {t(!showMore ? "Ver mais" : "Ver menos")}

                    <span
                        className={`arrow ${showMore ? "open" : ""}`}
                    />
                </Button>
            }
        </div>

        <div className="text-part">{t("Menções dos últimos 90 dias")}</div>
        
        <div className="junction-mentions">
            <div className="list-mentions">
                <p className="list-title">{t("Marcas")}</p>

                <div className={`mentions ${showMore ? "expanded" : "simple"}`}>
                    {((!isSimple && brands.length < 1) || (isSimple && (!simpleBrands || simpleBrands.length < 1))) && 
                        <p className="text-empty">
                            {t("Nenhuma marca mencionada pelo influenciador")}
                        </p>
                    }

                    {!isSimple && brands.map((mention, i) => 
                        <div 
                            key={i} 
                            className={`mention ${onClickBrand ? "hover" : ""}`} 
                            onClick={() => onClickBrand && onClickBrand(mention.id)}
                        >
                            {mention.name}

                            <div className="value">{mention.count}</div>
                        </div>
                    )}

                    {isSimple && <span>{simpleBrands}</span>}
                </div>
            </div>
            
            <div className="list-mentions">
                <p className="list-title">{t("Perfil")}</p>

                <div className={`mentions ${showMore ? "expanded" : "simple"}`}>
                    {((!isSimple && mentions.length < 1) || (isSimple && (!simpleMentions || simpleMentions.length < 1))) && 
                        <p className="text-empty">
                            {t("Nenhum perfil mencionada pelo influenciador")}
                        </p>
                    }

                    {!isSimple && mentions.map((mention, i) => 
                        <div 
                            key={i} 
                            className="mention" 
                        >
                            {mention.name}

                            <div className="value">{mention.value || mention.count}</div>
                        </div>
                    )}

                    {isSimple && <span>{simpleMentions}</span>}
                </div>
            </div>
            
            <div className="list-mentions">
                <p className="list-title">{t("Hashtags")}</p>

                <div className={`mentions ${showMore ? "expanded" : "simple"}`}>
                    {((!isSimple && hashtags.length < 1) || (isSimple && (!simpleHashtags || simpleHashtags.length < 1))) && 
                        <p className="text-empty">
                            {t("Nenhuma hashtag mencionada pelo influenciador")}
                        </p>
                    }

                    {!isSimple && hashtags.map((mention, i) => {
                        return <div 
                            key={i}
                            className={`mention ${onClickHashtag ? "hover" : ""}`}
                            onClick={() => onClickHashtag && onClickHashtag(mention.name)}
                        >
                            {mention.name}

                            <div className="value">{mention.value}</div>
                        </div>
                    })}

                    {isSimple && <span>{simpleHashtags}</span>}
                </div>
            </div>

            <div className="list-mentions">
                <p className="list-title">{t("Localizações")}</p>

                <div className={`mentions ${showMore ? "expanded" : "simple"}`}>
                    {((!isSimple && places.length < 1) || (isSimple && (!simplePlaces || simplePlaces.length < 1))) && 
                        <p className="text-empty">
                            {t("Nenhuma localização mencionada pelo influenciador")}
                        </p>
                    }

                    {!isSimple && places.map((mention, i) => {
                        const indexName = placeNames.findIndex(({ value }) => value === mention.id)
                        
                        return placeNames[indexName].key && 
                            <div 
                                key={i} 
                                className={`mention ${onClickPlace ? "hover" : ""}`}
                                onClick={() => onClickPlace && onClickPlace(mention.id)}
                            >
                                {placeNames[indexName].key}
                                
                                {!isSimple ? 
                                    <div className="value">{mention.count}</div>
                                    : (i < places.length - 1 && <span className="comma">,</span>)
                                }
                            </div>
                    })}

                    {isSimple && <span>{simplePlaces}</span>}
                </div>
            </div>
        </div>
    </div>
}

PartMentions.defaultProps = {
    isSimple: false,
}

PartMentions.propTypes = {
    isSimple: PropTypes.bool,
    onClickBrand: PropTypes.func,
    onClickPlace: PropTypes.func,
    onClickHashtag: PropTypes.func,
}
  
export default PartMentions