import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//
import Modal from '../../../../../../../common/components/Modal';
import Input from '../../../../../../../common/components/Input';
import CheckBox from '../../../../../../../common/components/CheckBox';
//
import emptyResult from '../../../../../../../common/assets/images/empty-result.png';
//
import { sortBySimilarity } from '../../../../../../../utils';
import './index.scss';

const CategoriesModal = ({ categories, selecteds, onChange, onClose, isVisible }) => {
    const { t } = useTranslation();
    //
    let delayTimer;
    //
    const [filteredOptions, updateFilteredOptions] = useState([]);

    const verifyValue = (key, parentValue, children) => {
        if (selecteds[key]?.rule) return selecteds[key].rule;

        if (parentValue) return selecteds[parentValue]?.rule;

        if (children) return children.every((child) => selecteds[child['key']]?.rule);

        return false;
    };

    const updSelecteds = (key, parentValue, children) => {
        let newSelecteds = selecteds;

        const keyValue = !selecteds[key]?.rule
            ? { ...selecteds[key], rule: 'include' }
            : selecteds[key]?.rule === 'include'
            ? { ...selecteds[key], rule: 'exclude' }
            : null;

        if (parentValue) {
            if (selecteds[parentValue] && !keyValue) {
                const oldValue = selecteds[parentValue];
                const parentCategory = categories.find((c) => c['key'] === parentValue);

                if (parentCategory) {
                    parentCategory['subCategories'].map((subCat) => {
                        let value = oldValue;
                        if (
                            selecteds[subCat['key']]?.rule &&
                            selecteds[subCat['key']]?.rule !== oldValue?.rule
                        ) {
                            value = selecteds[subCat['key']];
                        }

                        if (key !== subCat['key']) {
                            newSelecteds = {
                                ...newSelecteds,
                                ...{ [subCat['key']]: value },
                            };
                        } else {
                            newSelecteds = {
                                ...newSelecteds,
                                ...{ [key]: keyValue },
                            };
                        }
                    });
                }

                newSelecteds = {
                    ...newSelecteds,
                    ...{ [parentValue]: null },
                };
            } else {
                const categoryValue = !selecteds[parentValue]?.rule
                    ? { ...selecteds[key], rule: 'include' }
                    : selecteds[parentValue]?.rule === 'include'
                    ? { ...selecteds[key], rule: 'exclude' }
                    : null;

                newSelecteds = {
                    ...newSelecteds,
                    ...{ [key]: !selecteds[key]?.rule ? categoryValue : keyValue },
                };
            }
        } else if (children) {
            children.forEach((child) => {
                newSelecteds = {
                    ...newSelecteds,
                    ...{ [child['key']]: null },
                };
            });

            newSelecteds = {
                ...newSelecteds,
                ...{ [key]: keyValue },
            };
        }

        onChange(newSelecteds);
    };

    const filterCategories = (text) => {
        if (text && text.length < 1) {
            updateFilteredOptions(categories);
        } else {
            let newList = [];

            categories.forEach((cat) => {
                const categoryHasText = (cat['name'] || '')
                    .toLowerCase()
                    .includes(text.toLowerCase());

                if (categoryHasText) {
                    newList.push(cat);
                } else {
                    if (cat['subCategories']) {
                        for (let i = 0; i < cat['subCategories'].length; i++) {
                            const subCat = cat['subCategories'][i];

                            if (
                                !newList.some((item) => item.id !== cat.id) &&
                                (subCat['name'] || '').toLowerCase().includes(text.toLowerCase())
                            ) {
                                newList.push(cat);

                                i = cat['subCategories'].length - 1;
                            }
                        }
                    }
                }
            });

            newList = sortBySimilarity(text, newList);

            updateFilteredOptions(newList);
        }
    };

    useEffect(() => {
        updateFilteredOptions(categories);
    }, []);

    return (
        <Modal className="categories-modal" isVisible={isVisible} onClose={() => onClose()}>
            <>
                <Input
                    isOutlined
                    className="input-name"
                    prefix={<i className="icon icon-search" />}
                    placeholder={t('Pesquise uma categoria')}
                    onChange={(e) => {
                        clearTimeout(delayTimer);

                        delayTimer = setTimeout(() => {
                            filterCategories(e.target.value);
                        }, 500);
                    }}
                />

                <div className="list">
                    {filteredOptions.map((cat) => {
                        return (
                            <div
                                key={cat['key']}
                                className={`card-category ${
                                    verifyValue(cat['key'], null, cat['subCategories']) ===
                                        'include' || null
                                        ? 'full'
                                        : ''
                                }`}
                            >
                                <CheckBox
                                    key={cat['key']}
                                    text={cat['name']}
                                    className="category"
                                    checked={
                                        verifyValue(cat['key'], null, cat['subCategories']) ===
                                            'include' || null
                                    }
                                    indeterminate={
                                        verifyValue(cat['key'], null, cat['subCategories']) ===
                                            'exclude' || null
                                    }
                                    onChange={() => {
                                        updSelecteds(cat['key'], null, cat['subCategories']);
                                    }}
                                />

                                <div className="list-sub-categories">
                                    {cat['subCategories'] &&
                                        cat['subCategories'].map((subCat) => {
                                            return (
                                                <CheckBox
                                                    key={subCat['key']}
                                                    text={subCat['name']}
                                                    className="category"
                                                    checked={
                                                        verifyValue(
                                                            subCat['key'],
                                                            cat['key'],
                                                            null,
                                                        ) === 'include' || null
                                                    }
                                                    indeterminate={
                                                        verifyValue(
                                                            subCat['key'],
                                                            cat['key'],
                                                            null,
                                                        ) === 'exclude' || null
                                                    }
                                                    onChange={() => {
                                                        updSelecteds(
                                                            subCat['key'],
                                                            cat['key'],
                                                            null,
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {filteredOptions.length < 1 && (
                    <div className="empty-result">
                        <img src={emptyResult} alt="garoto branco do cabelo marrom pensativo" />

                        <span>{t('Ops! Não encontramos nenhum tema para sua busca')}.</span>

                        <span>
                            <b>{t('Tente novamente com outras palavras')}!</b>
                        </span>
                    </div>
                )}
            </>
        </Modal>
    );
};

CategoriesModal.defaultProps = {
    isVisible: false,
    categories: [],
    selecteds: [],
    onChange: () => {},
};

CategoriesModal.propTypes = {
    isVisible: PropTypes.bool,
    selecteds: PropTypes.any,
    categories: PropTypes.any,
    onChange: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

export default CategoriesModal;
