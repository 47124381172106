import React from "react"
import PropTypes from "prop-types"
// 
import { useTranslation } from "react-i18next"
// 
import Button from "../../../../../common/components/Button";
// 
import aira from '../../../../../common/assets/images/aira.png';

const Presentation = ({
    onFinish
}) => {
    const { t } = useTranslation()

    return <div className="aira-presentation">
        <img className="aira" src={aira} alt={t("mulher  do cabelo preto acenando")} />
        
        <p className="aira-title">{t("Olá, meu nome é Aira!")}</p>

        <p className="aira-text">{t("Sou especialista em curadoria e utilizo Inteligência Artificial para te ajudar a encontrar os melhores influenciadores para sua campanha. Vamos lá?")}</p>


        <Button
            className="submit-btn"
            onClick={() => onFinish()}
        >
            {t("Começar")}
        </Button>
    </div>
}

Presentation.propTypes = {
    onFinish: PropTypes.func.isRequired,
}
  
export default Presentation