// 
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import React from "react";
import {
    buildValue
} from "../../../../utils/formatters";
import { useTranslation } from "react-i18next";
// 
import "../Charts.scss";

const MultipleLinesChart = ({
    series,
    className,
    height,
    currencySymbol,
    formatType,
    showLegend,
    onDotOver,
    onDotOut,
    hasTooltip,
    buildTooltipElement
}) => {
    const { t } = useTranslation()

    const options = {
        chart: {
            height: height,
            className: className,
            styles: {
                width: "100%",
            },
            backgroundColor: "transparent"
        },
        title: {
            text: ""
        },
        yAxis: {
            title: {
                text: ""
            },
            type: "number",
            labels: {
                formatter: function () {
                    return buildValue("humanize", this.value);
                }
            }
        },
        xAxis: {
            type: "datetime",
            labels: {
                formatter: function () {
                    return Highcharts.dateFormat('%e/%m', this.value);
                }
            }
        },
        legend: {
            margin: 14,
            padding: 16,
            useHTML: true,
            // width: "100%",
            symbolRadius: 0,
            align: "center",
            borderRadius: 6,
            enabled: showLegend,
            backgroundColor: "#FAFAFA"
        },
        plotOptions: {
            series: {
                marker: {
                    symbol: "circle"
                },

                point: {
                    events: {
                        mouseOut: function() {
                            if(onDotOut) onDotOut(this)
                        },
                        mouseOver: function() {
                            if(onDotOver && this.series.name === t("interactions")) {
                                onDotOver({
                                    date: this.x,
                                    value: this.y,
                                })
                            }
                        }
                    }
                }
            },
            events: {
                checkboxClick: function () {
                    if(this.visible) {
                        this.hide();
                    } else {
                        this.show();
                    }
                },
                legendItemClick: function () {
                    if (this.selected) {
                      this.selected = false;
                    } else {
                      this.selected = true;
                    }
                
                    if (this.visible) {
                      this.checkbox.checked = false;
                    } else {
                      this.checkbox.checked = true;
                    }
                }
            },
        },
        tooltip: {
            snap: 1,
            shared: true,
            useHTML: true,
            crosshair: true,
            hideDelay: 2500,
            enabled: hasTooltip,
            pointerEvents: "auto",
            backgroundColor: "#FFF",
            borderWidth: buildTooltipElement ? 0 : 2,
            style: {
                pointerEvents: "auto"
            },
            formatter: function () {
                if(buildTooltipElement) {
                    return buildTooltipElement(this) 
                } else {
                    return `<div class="high-tooltip">
                        ${buildValue("dateString", this.x)}: <b>${buildValue(formatType, this.y, currencySymbol)}</b>
                    </div>`
                }
            }
        },

        series: series,
    };

    return <HighchartsReact
    highcharts = {
        Highcharts
    }
    options = {
        options
    }
    />
}

MultipleLinesChart.defaultProps = {
    series: [],
    hasTooltip: true,
    hasPointer: false,
    showLegend: true,
}

MultipleLinesChart.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    formatType: PropTypes.string,
    currencySymbol: PropTypes.string,
    onChange: PropTypes.func,
    onDotOver: PropTypes.func,
    onDotOut: PropTypes.func,
    buildTooltipElement: PropTypes.func,
    series: PropTypes.arrayOf(PropTypes.object),
    hasPointer: PropTypes.bool,
    showLegend: PropTypes.bool,
    hasTooltip: PropTypes.bool,
}

export default MultipleLinesChart