import { LevelGauge } from '../LevelGauge';

import './index.scss';

export const EngagementRateTable = () => {
  return (
    <div className="engagement_rate_table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Instagram</th>
            <th>TikTok</th>
            <th>Youtube</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div>
                Regular
                <LevelGauge value={1} range={[1, 3]} />
              </div>
            </td>
            <td>&lt;1%</td>
            <td>&lt;5%</td>
            <td>&lt;10%</td>
          </tr>
          <tr>
            <td>
              <div>
                Bom
                <LevelGauge value={2} range={[1, 3]} />
              </div>
            </td>
            <td>1-3%</td>
            <td>5-8%</td>
            <td>10-15%</td>
          </tr>
          <tr>
            <td>
              <div>
                Excelente
                <LevelGauge value={4} range={[1, 3]} />
              </div>
            </td>
            <td>3%&gt;</td>
            <td>8%&gt;</td>
            <td>15%&gt;</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
