import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
// 
import { useDispatch, useSelector } from "react-redux"
import { getInfluencerExists } from "../../../InfluencerCard/service"
import { fetchDemographicsData, selectInfluencerDetailsSlice, updateDemographicsStatus } from "../../../InfluencerCard/slice"
import StateStatus from "../../../../../utils/status"
// 
import DonutsChart from "../../../../../common/components/Charts/DonutsChart"
import DoubleBarChart from "../../../../../common/components/Charts/DoubleBarChart"
// 
import rightArrowsImg from "../../../../../common/assets/icons/right-arrows.svg"
// 
import "./index.scss"

const PartDemographics = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // 
    const dataset = {}
    const slice = useSelector(selectInfluencerDetailsSlice)
    // 
    const influencer = slice.influencer
    const demographics = slice.demographics
    // 
    const [isExists, updateIsExists] = useState(false)

    const getDemographics = () => {
        dispatch(fetchDemographicsData())
    }

    useEffect(() => {
        if (influencer.followers > 10000) {
            getInfluencerExists(influencer.id, influencer.username, influencer.network).then((response) => {
                if(response.data.exists) {
                    updateIsExists(true)
                    
                    dispatch(fetchDemographicsData())
                } else {
                    dispatch(updateDemographicsStatus(StateStatus.succeeded))
                }
            }).catch(() => {
                dispatch(updateDemographicsStatus(StateStatus.succeeded))
            })
        }
    }, [])

    useEffect(() => {
        if (isExists && slice.status.fetchDemographicsData === StateStatus.succeeded && ["idle", "loading"].includes(demographics.statusLoading)) {
            setTimeout(() => { getDemographics() }, 2000)
        }
    }, [slice.status.fetchDemographicsData, demographics.statusLoading])

    return <>
        {(demographics.statusLoading === "insufficient_data" || (!demographics.statusLoading && influencer.followers <= 9999)) && <>
            <br /><br /><br /><br />

            <p className="title-part">
                <i className="icon icon-group" />

                {t("Dados da audiência do influenciador")}
            </p>

            <div className="blocked-charts-pdf">
                <p className="message message-insufficient-data">
                    <span className="title-warning">{t("Base de seguidores insuficiente")}</span>
                    <span className="text-warning">
                        {t("O perfil precisa ter mais de 10.000 seguidores para conseguir visualizar os dados demográficos da audiência do influenciador.")}
                    </span>
                </p>
            </div>
        </>}

        {slice.status.fetchDemographicsData != StateStatus.idle && <>
            <br /><br /><br /><br />
            {slice.status.fetchDemographicsData === StateStatus.succeeded && Object.keys(demographics.charts.bars).length > 0 &&
                <>
                    <p className="title-part">
                        <i className="icon icon-group" />

                        {t("Dados da audiência do influenciador")}
                    </p>

                    <div className="demographics-pdf demographics-pdf-1">
                        {demographics.charts.donuts && Object.keys(demographics.charts.donuts).length > 0 && <>
                            {Object.keys(demographics.charts.donuts).map((key, index) => {
                                return <div key={`donuts-${index}`} className="donuts">
                                    <div className="subtitle-part">
                                        <i className="icon icon-single-user-favorite" />

                                        {t(`${key}_chart`)}
                                    </div>

                                    <DonutsChart
                                        nameFormat="percent"
                                        hasAnimation={false}
                                        data={demographics.charts.donuts[key]}
                                    />
                                </div>
                            })}
                        </>}

                        <img src={rightArrowsImg} alt={t("setas apontando para a direita")} />

                        {demographics.charts.bars && ["census"].map((key, index) => {
                            return demographics.charts.bars[key] ? <div key={`bar-${index}`} className={`bar bar-${index}`}>
                                <div className="subtitle-part">
                                    <i className="icon icon-make-up-star" />

                                    {t(`${key}_chart`)}
                                </div>

                                <DoubleBarChart
                                    total={100}
                                    name={key}
                                    labels={dataset}
                                    fixedTooltip={true}
                                    formatName="percent"
                                    hasAnimation={false}
                                    data={demographics.charts.bars[key]}
                                />
                            </div> : null
                        })}
                    </div>

                    <br /><br /><br /><br />
                    <br /><br /><br /><br />
                    <br /><br /><br /><br />
                    <br /><br />

                    <p className="title-part">
                        <i className="icon icon-group" />

                        {t("Dados da audiência do influenciador")}
                    </p>

                    <div className="demographics-pdf demographics-pdf-2">
                        {demographics.charts.bars && ["location", "likes", "brands"].map((key, index) => {
                            return demographics.charts.bars[key] ?
                                <div key={`bar-${index}`} className={`bar bar-${index}`}>
                                    <div className="subtitle-part">
                                        <i className="icon icon-make-up-star" />

                                        {t(`${key}_chart`)}
                                    </div>

                                    <DoubleBarChart
                                        total={100}
                                        name={key}
                                        labels={dataset}
                                        fixedTooltip={true}
                                        formatName="percent"
                                        data={demographics.charts.bars[key]}
                                    />
                                </div> : null
                        })}
                    </div>
                </>
            }

            {slice.status.fetchDemographicsData === StateStatus.failed &&
                <p>
                    {t("Erro ao gerar os dados da audiência. Tente novamente mais tarde!")}
                </p>
            }
        </>}

        <br />
        <br />
    </>
}

PartDemographics.defaultProps = {
    demographicError: false,
    blockedDemographics: false,
    reachAudienceLoading: false,
}

PartDemographics.propTypes = {
    demographicError: PropTypes.bool,
    blockedDemographics: PropTypes.bool,
    reachAudienceLoading: PropTypes.bool,
}

export default PartDemographics