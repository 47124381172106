import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit"
// 
import {
    getNotification,
    notificationInfluencers,
    updNotification
} from "./service"
import StateStatus from "../../../utils/status"
// 
import NotificationModel from "../../../models/NotificationModel"

const initialStateInfluencer = {
    status: StateStatus.idle,
    statusInfluencers: StateStatus.idle,
    notification: new NotificationModel(),
    //
    page: 1,
    totalPages: 0,
    // 
    errorMsg: null
}

export const fetchNotification = createAsyncThunk("notification/get", async (id) => {
    try {
        const response = await getNotification(id)

        return response.data
    } catch (err) {
        console.debug(err)

        throw err
    }
})

export const updateNotification = createAsyncThunk("notification/upd", async ({
    ids,
    status,
    newStatus,
}) => {
    try {
        const response = await updNotification({
            ids: ids,
            status: newStatus,
            current_status: status,
        })

        return response.data
    } catch (err) {
        console.debug(err)

        throw err
    }
})

export const fetchSolicitationInfluencers = createAsyncThunk("notification/influencers", async ({
    id,
    page,
    status,
}, thunkAPI) => {
    const { app } = thunkAPI.getState()

    try {
        const response = await notificationInfluencers({
            id: id,
            page: page,
            status: status,
        })

        return {
            data: response.data,
            categories: app.categories.list,
        }
    } catch (err) {
        console.debug(err)

        throw err
    }
})

export const solicitationSlice = createSlice({
    name: "solicitation",
    initialState: initialStateInfluencer,
    extraReducers: {
        [fetchNotification.pending]: (state) => {
            state.status = StateStatus.loading
        },
        [fetchNotification.fulfilled]: (state, {
            payload
        }) => {
            const notification = new NotificationModel().detailsFromJson(payload)

            state.notification = notification

            state.status = StateStatus.succeeded
        },
        [fetchNotification.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status = StateStatus.failed
        },
        // 
        [fetchSolicitationInfluencers.pending]: (state) => {
            state.statusInfluencers = StateStatus.loading
        },
        [fetchSolicitationInfluencers.fulfilled]: (state, {
            payload
        }) => {
            const { data, categories } = payload

            state.page = data.page || 1
            state.totalPages = data.pages || 0
            state.influencersTotal = data.total || 0
            // 
            const influencers = data.items.map(influencer => {
                let data = {...influencer, ...influencer.profile}
                delete data.profile

                return data
            })

            state.notification = state.notification.addInfluencers(influencers, categories)

            state.statusInfluencers = StateStatus.succeeded
        },
        [fetchSolicitationInfluencers.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.statusInfluencers = StateStatus.failed
        },
        // 
    }
})

export const selectSolicitationSlice = (state) => state.solicitation