import ChartColors from '../common/components/Charts/ChartColors';

import moment from 'moment';
import i18n from '../languages/i18n';
class InfluencerModel {
  constructor() {
    this.id = '';
    this.bio = '';
    this.bioSlice = '';
    this.name = '';
    this.avatar = '';
    this.network = '';
    this.verified = false;
    this.totalPosts = 0;
    this.followers = 0;
    this.profileUrl = '';
    this.deliveryFee = 0;
    this.quantPost = 0;
    this.username = '';
    this.hiddenLikes = false;
    this.airscore = 0;
    this.interactions = [];
    this.mean = [];
    this.maximum = [];
    this.minimum = [];
    this.prices = {};
    this.engagementRate = 0;
    this.totalInteractions = 0;
    this.gauges = [];
    this.places = [];
    this.brands = [];
    this.totalBrands = 0;
    this.themes = [];
    this.totalThemes = 0;
    this.mentions = [];
    this.hashtags = [];
    this.profilesMentioneds = [];
    this.subThemes = [];
    this.price = 0;
    this.likes = 0;
    this.views = 0;
    this.interactionsTotal = 0;
    this.followings = 0;
    this.interactionChart = [];
    this.followersChart = {};
    this.scoresChart = [];
    this.allCategories = [];
    this.allSubCategories = {};
    this.hourWithMorePosts = '';
    this.posts = [];
    this.summaryPosts = [];
    this.notes = [];
    this.email = '';
    this.emails = [];
    this.phones = [];
    this.bioPlaces = [];
    this.scoresPerPeriod = [{ 60: 0, 30: 0 }];
    this.followersPerPeriod = [{ 60: 0, 30: 0 }];
    this.insight = '';
    this.insightIsLiked = false;
    this.classification = '';
    this.deliveryFee = 0;
    this.demographics = {};
    this.postsIntervalChart = [];
    this.postsIntervalAverage = 0;
    this.postsIntervalTotal = 0;
    this.categories = [];
    this.rawCategories = {};
    this.interactionsChartTotal = {};
    this.interactionsChartAverage = {};
    this.means = {};
    this.d30_means = {};
    this.counters = {};
  }

  // HOME
  fromJson(data, brandDataset, categoriesDataSet) {
    this.airscore = data.score;
    this.followers = data.followers;
    this.hourWithMorePosts = data['hour_with_more_posts'];
    this.verified = data['is_verified'];
    this.totalPosts = data['last_posts'];
    this.name = data['name'];
    this.network = data['network'];
    this.places = data['places'];
    this.id = data['profile_id'];
    this.username = data['username'];
    this.avatar = data['picture'];
    this.profileUrl = data['permalink'] || data['url'];
    this.foundOn = data['found_on'] || [];
    this.postIds = data['posts'] || [];
    this.acceleratedGrowth = data['positive_badges']
      ? data['positive_badges'].includes('accelerated_growth')
      : false;
    this.engagementRate = ((data['engagement_rate'] || 0) / 100).toFixed(2);
    this.hiddenLikes = false;
    this.means = data.means;
    this.d30_means = data.d30_means;
    this.counters = data.counters;

    if (data['brands']) this.brands = data['brands'].map((brandId) => brandDataset[brandId]);

    if (data['categories']) {
      let sliceThemes = [];

      data['categories'].slice(0, 3).forEach((category) => {
        let theme = categoriesDataSet.find((item) => item.key === category.id);

        if (theme) {
          const newValues = {
            percent: category.relevance,
            score: category.score,
            percentScore: parseInt(category.score / 10),
          };

          sliceThemes.push({
            ...theme,
            ...newValues,
          });
        }
      });

      this.themes = sliceThemes;
    }

    return this;
  }

  // CARD DO INFLUENCIADOR
  detailsFromJson(data, categories, subCategories, brandDataset) {
    const deliveryCharges = {
      mega: 0.04,
      macro: 0.05,
      inter: 0.07,
      micro: 0.08,
      nano: 0.08,
    };

    this.id = data['id'];
    this.bio = data['bio'] || '';
    this.name = data['name'] || '';
    this.acceleratedGrowth = data['positive_badges']
      ? data['positive_badges'].includes('accelerated_growth')
      : false;
    this.verified = data['is_verified'];
    this.avatar = data['picture'] || '';
    this.gender = data['gender'];
    this.isNew = data['is_new'];
    this.network = data['network'];
    this.disabled = data.disabled;
    this.disable_reason = data.disable_reason;
    this.profileUrl = data['permalink'];
    this.username = data['username'];
    this.engagementRate = ((data['engagement_rate'] || 0) / 100).toFixed(2);
    this.hourWithMorePosts = data['hour_with_more_posts'];
    this.bioSlice = data['bio']
      ? `${data['bio'].slice(0, data['bio'].length > 280 ? 280 : data['bio'].length)}${
          data['bio'].length > 280 ? '...' : ''
        }`
      : '';

    this.minInteractions = {
      comments: data['minimum'] ? (data['minimum']['comments'] || 0).toFixed(2) : 0,
      dislikes: data['minimum'] ? (data['minimum']['dislikes'] || 0).toFixed(2) : 0,
      likes: data['minimum'] ? (data['minimum']['likes'] || 0).toFixed(2) : 0,
      retweets: data['minimum'] ? (data['minimum']['retweets'] || 0).toFixed(2) : 0,
      shares: data['minimum'] ? (data['minimum']['shares'] || 0).toFixed(2) : 0,
      views: data['minimum'] ? (data['minimum']['views'] || 0).toFixed(2) : 0,
      total: data['minimum'] ? (data['minimum']['total'] || 0).toFixed(2) : 0,
    };
    this.d30_means = data['d30_means'];
    this.meansInteractions = {
      comments: data['means'] ? (data['means']['comments'] || 0).toFixed(2) : 0,
      dislikes: data['means'] ? (data['means']['dislikes'] || 0).toFixed(2) : 0,
      likes: data['means'] ? (data['means']['likes'] || 0).toFixed(2) : 0,
      retweets: data['means'] ? (data['means']['retweets'] || 0).toFixed(2) : 0,
      shares: data['means'] ? (data['means']['shares'] || 0).toFixed(2) : 0,
      views: data['means'] ? (data['means']['views'] || 0).toFixed(2) : 0,
      total: data['means'] ? (data['means']['total'] || 0).toFixed(2) : 0,
    };
    this.maxInteractions = {
      comments: data['maximum'] ? (data['maximum']['comments'] || 0).toFixed(2) : 0,
      dislikes: data['maximum'] ? (data['maximum']['dislikes'] || 0).toFixed(2) : 0,
      likes: data['maximum'] ? (data['maximum']['likes'] || 0).toFixed(2) : 0,
      retweets: data['maximum'] ? (data['maximum']['retweets'] || 0).toFixed(2) : 0,
      shares: data['maximum'] ? (data['maximum']['shares'] || 0).toFixed(2) : 0,
      views: data['maximum'] ? (data['maximum']['views'] || 0).toFixed(2) : 0,
      total: data['maximum'] ? (data['maximum']['total'] || 0).toFixed(2) : 0,
    };
    this.airscore = Math.floor(data['score'] * 1000) || 0;
    this.comments = data['counters'] ? data['counters']['comments'] || 0 : 0;
    this.likes = data['counters'] ? data['counters']['likes'] || 0 : 0;
    this.shares = data['counters'] ? data['counters']['shares'] || 0 : 0;
    this.followers = data['counters'] ? data['counters']['followers'] || 0 : 0;
    this.followings = data['counters'] ? data['counters']['followings'] || 0 : 0;
    this.views = data['counters'] ? data['counters']['views'] || 0 : 0;
    this.interactionsTotal = data['counters'] ? data['counters']['total'] || 0 : 0;
    this.quantPost = data['counters'] ? data['counters']['last_posts'] || 0 : 0;
    this.averageInteractions = data['counters'] ? data['counters']['avarage'] || 0 : 0;
    this.rangeEstimate = data['counters'] ? data['counters']['range'] || 0 : 0;

    const classification = this._getClassification(this.followers);
    const percentage = deliveryCharges[classification];

    this.classification = classification;

    if (this.followers >= 1000 && percentage)
      this.deliveryFee = Math.ceil(this.followers * percentage);

    if (data['biography_data']) {
      const email = data['biography_data']['email'];
      const listEmails = data['biography_data']['emails'];
      const listPhones = data['biography_data']['phones'];
      const listPlaces = data['biography_data']['places'];

      if (email) this.email = email;
      if (listEmails) this.emails = listEmails && listEmails.length > 0 ? listEmails : null;

      if (listPhones) this.phones = listPhones && listPhones.length > 0 ? listPhones : null;

      if (listPlaces) this.bioPlaces = listPlaces && listPlaces.length > 0 ? listPlaces : null;
    }

    if (data['hashtags']) {
      this.hashtags = data['hashtags'].map((item) => ({
        name: item['tag'],
        value: item['count'],
      }));
    }

    if (data['mentions']) {
      this.mentions = data['mentions'].map((item) => ({
        name: item['tag'],
        value: item['count'] || 1,
      }));
    }

    if (data['brands']) {
      this.brands = data['brands']
        .map((brand) => {
          if (brand && brand['id'] && brandDataset[brand['id']]) {
            return {
              ...brand,
              name: brandDataset[brand['id']],
            };
          }
        })
        .filter((item) => item);
    }

    this.places = data['places'];

    if (categories) this.allCategories = categories;
    if (subCategories) this.allSubCategories = subCategories;

    this.hiddenLikes = data['negative_badges'] && data['negative_badges'].includes('hidden_likes');

    return this;
  }

  // AIRA DEGUSTAÇÃO - CARD DO INFLUENCIADOR
  detailsPublicFromJson(data) {
    const deliveryCharges = {
      mega: 0.04,
      macro: 0.05,
      inter: 0.07,
      micro: 0.08,
      nano: 0.08,
    };

    this.id = data['id'] || '';
    this.bio = data['bio'] || '';
    this.name = data['name'] || '';
    this.verified = data['is_verified'];
    this.avatar = data['picture'] || '';
    this.gender = data['gender'];
    this.network = data['network'];
    this.profileUrl = data['permalink'];
    this.username = data['username'];
    this.engagementRate = ((data['engagement_rate'] || 0) / 100).toFixed(2);
    this.bioSlice = data['bio']
      ? `${data['bio'].slice(0, data['bio'].length > 280 ? 280 : data['bio'].length)}${
          data['bio'].length > 280 ? '...' : ''
        }`
      : '';
    this.airscore = Math.floor(data['score'] * 1000) || 0;
    this.comments = data['counters'] ? data['counters']['comments'] || 0 : 0;
    this.likes = data['counters'] ? data['counters']['likes'] || 0 : 0;
    this.shares = data['counters'] ? data['counters']['shares'] || 0 : 0;
    this.followers = data['counters'] ? data['counters']['followers'] || 0 : 0;
    this.followings = data['counters'] ? data['counters']['followings'] || 0 : 0;
    this.views = data['counters'] ? data['counters']['views'] || 0 : 0;
    this.interactionsTotal = data['counters'] ? data['counters']['total'] || 0 : 0;
    this.quantPost = data['counters'] ? data['counters']['last_posts'] || 0 : 0;
    this.averageInteractions = data['counters'] ? data['counters']['avarage'] || 0 : 0;
    this.rangeEstimate = data['counters'] ? data['counters']['range'] || 0 : 0;

    const classification = this._getClassification(this.followers);
    const percentage = deliveryCharges[classification];

    this.classification = classification;

    this.demographics =
      {
        ...data['audience'],
      } || {};

    if (this.followers >= 1000 && percentage)
      this.deliveryFee = Math.ceil(this.followers * percentage);

    return this;
  }

  // TABELA COMPARATIVA
  comparativeFromJson(data) {
    this.id = data['profile_id'];
    this.name = data['name'];
    this.username = data['username'];
    this.avatar = data['picture'];
    this.airscore = Math.ceil(data['score'] * 1000) || 0;
    this.followers = data['followers'] || 0;
    this.quantPost = data['total_posts'] || 0;
    this.topHour = data['hour_with_more_posts'] || '00h-00h';
    this.engagementRate = data['engagement_rate'];
    this.network = data.network;
    this.price = data['price'];
    this.mean = {};
    this.maximum = {};
    this.minimum = {};
    this.interactions = {};
    this.insightIsLiked = data.assistant_client_feedback;

    if (data['assistant_description'] && data['assistant_description']['insights']) {
      this.insight = data['assistant_description']['insights'];
    }

    if (data['total']) {
      Object.keys(data['total']).forEach((key) => {
        if (this.network === 'twitch') {
          if (key === 'views') this.interactions[key] = data['total'][key];
        } else if (key === 'retweets') {
          if (this.network === 'twitter') this.interactions[key] = data['total'][key];
        } else if (key === 'views') {
          if (this.network !== 'instagram') this.interactions[key] = data['total'][key];
        } else {
          this.interactions[key] = data['total'][key];
        }
      });
    }

    if (data['mean']) {
      Object.keys(data['mean']).forEach((key) => {
        if (this.network === 'twitch') {
          if (key === 'views') this.mean[key] = data['mean'][key];
        } else if (key === 'retweets') {
          if (this.network === 'twitter') this.mean[key] = data['mean'][key];
        } else if (key === 'views') {
          if (this.network !== 'instagram') this.mean[key] = data['mean'][key];
        } else {
          this.mean[key] = data['mean'][key];
        }
      });
    }

    if (data['maximum']) {
      Object.keys(data['maximum']).forEach((key) => {
        if (this.network === 'twitch') {
          if (key === 'views') this.maximum[key] = data['maximum'][key];
        } else if (key === 'retweets') {
          if (this.network === 'twitter') this.maximum[key] = data['maximum'][key];
        } else if (key === 'views') {
          if (this.network !== 'instagram') this.maximum[key] = data['maximum'][key];
        } else {
          this.maximum[key] = data['maximum'][key];
        }
      });
    }

    if (data['minimum']) {
      Object.keys(data['minimum']).forEach((key) => {
        if (this.network === 'twitch') {
          if (key === 'views') this.minimum[key] = data['minimum'][key];
        } else if (key === 'retweets') {
          if (this.network === 'twitter') this.minimum[key] = data['minimum'][key];
        } else if (key === 'views') {
          if (this.network !== 'instagram') this.minimum[key] = data['minimum'][key];
        } else {
          this.minimum[key] = data['minimum'][key];
        }
      });
    }

    this.prices = {};
    Object.keys(this.interactions).forEach((key) => {
      if (key !== 'total') this.prices[key] = 0;
    });

    this.prices['interactions'] = 0;
    this.prices['followers'] = 0;

    this.themes = data['categories'];
    this.subThemes = data['subcategories'];
    this.brands = data['brands'];

    return this;
  }

  plCardFromJson(data) {
    this.airscore = {
      total: data['score'],
      changePercent: 0,
    };
    this.avatar = data['picture'];
    this.network = data['network'];
    this.username = data['username'];
    this.followers = data['followers'];
  }

  airaCurationResultFromJson(data) {
    this.name = data['name'] || '';
    this.avatar = data['picture'];
    this.network = data['network'] || '';
    this.username = data['username'] || '';
    this.followers = data['followers'] || 0;
    this.airscore = Math.ceil(data['score'] * 1000) || 0;
    this.engagementRate = ((data['engagement_rate'] || 0) / 100).toFixed(2);
    this.averageInteractions = (data['means'] || 0).toFixed(2);

    if (data['categories']) this.themes = data['categories'];

    return this;
  }

  addValuesByPeriod(data, period) {
    if (data && data.length > 0) {
      this.scoresPerPeriod[period === 30 ? 1 : 0] = {
        period,
        value: Math.ceil(data[0]?.score * 1000) || 0,
      };
      this.followersPerPeriod[period === 30 ? 1 : 0] = {
        period,
        value: data[0]?.counters.followers,
      };
    }

    return this;
  }

  addInteractionChart({ items, means, total }) {
    let interactions = [
      i18n.t('interactions'),
      i18n.t('comments'),
      i18n.t('retweets'),
      i18n.t('likes'),
      i18n.t('posts'),
    ];

    if (this.network === 'twitch') {
      interactions.splice(1, 3);
    }

    if (this.network !== 'instagram' && this.network !== 'twitter') {
      interactions.push(i18n.t('views'));
    }

    if (items && items.length > 0) {
      let interactionSeries = [];

      items.forEach((item) => {
        if (item) {
          Object.keys(item).forEach((counter) => {
            const counterName = i18n.t(counter);

            if (interactions.includes(counterName)) {
              // multiple lines chart
              const indexFinded = interactionSeries.findIndex(
                (serie) => serie.name === counterName,
              );

              if (indexFinded === -1) {
                interactionSeries = [
                  ...interactionSeries,
                  {
                    name: counterName,
                    color: '#f70b53',
                    data: [
                      {
                        y: item[counter],
                        x: moment(item['reference_date']).toDate(),
                      },
                    ],
                    marker: {
                      enabled: true,
                      states: {
                        hover: {
                          enabled: true,
                        },
                      },
                    },
                    lineWidth: 2,
                    type: 'scatter',
                    stickyTracking: false,
                  },
                ];
              } else {
                interactionSeries[indexFinded].data = [
                  ...interactionSeries[indexFinded].data,
                  {
                    y: item[counter],
                    x: moment(item['reference_date']).toDate(),
                  },
                ];
              }
            }
          });
        }
      });

      if (this.network === 'instagram' || this.network === 'twitter') {
        delete total['views'];
        delete means['views'];
      }

      if (this.network === 'twitch') {
        total = {
          views: total['views'],
        };
        means = {
          views: means['views'],
        };
      }

      this.interactionsChartTotal = total;
      this.interactionsChartAverage = means;

      this.interactionChart = interactionSeries;
    }

    return this;
  }

  addFollowersChart(data) {
    const cColors = new ChartColors();

    let followersSeries = {
      name: i18n.t('followers'),
      color: cColors.getColor(0),
      data: [],
    };

    data.forEach((item) => {
      if (item) {
        followersSeries.data = [
          ...followersSeries.data,
          {
            y: item['followers'],
            x: moment(item['reference_date']).toDate(),
          },
        ];
      }
    });

    this.followersChart = followersSeries;

    return this;
  }

  addScoresChart(data, type) {
    const cColors = new ChartColors();

    if (type === 'all') {
      let scoresSeries = [];

      data.forEach((item) => {
        if (item && item.interactions) {
          const category = this._getCategory(item['id']);

          const interactionData = {
            name: category['name'],
            color: category['color'],
            data: [],
          };

          item.interactions.forEach((interaction) => {
            interactionData.data.push({
              y: Math.ceil(interaction['score'] * 1000) || 0,
              x: moment(interaction['reference_date']).toDate(),
            });
          });

          scoresSeries.push(interactionData);
        }
      });

      this.scoresChart = scoresSeries;
    } else {
      let scoresSeries = {
        name: 'Scores',
        color: cColors.getColor(0),
        data: [],
      };

      data.forEach((item) => {
        if (item) {
          scoresSeries.data = [
            ...scoresSeries.data,
            {
              y: Math.ceil(item['score'] * 1000) || 0,
              x: moment(item['reference_date']).toDate(),
            },
          ];
        }
      });

      this.scoresChart = [scoresSeries];
    }

    return this;
  }

  addPostsInterval(data) {
    let intervalSeries = [];

    let totalPosts = 0;

    data.forEach((item) => {
      if (item && item['label']) {
        totalPosts += item['count'];

        intervalSeries = [
          ...intervalSeries,
          {
            label: item['label'],
            value: item['count'] || 0,
          },
        ];
      }
    });

    this.postsIntervalChart = intervalSeries;

    this.postsIntervalAverage = (totalPosts / data.length).toFixed(2);
    this.postsIntervalTotal = totalPosts;

    return this;
  }

  addCategories(data) {
    this.categories = this._buildGauges(data);
    const tempObj = {};
    data.forEach((cat) => (tempObj[cat.id] = cat));
    this.rawCategories = tempObj;

    return this;
  }

  addPosts(data) {
    this.posts = data;

    return this;
  }

  addSummaryPosts(data) {
    this.summaryPosts = [...this.summaryPosts, ...data];

    return this;
  }

  addNotes(data) {
    this.notes = data;

    return this;
  }

  _verifyArray(list) {
    if (list) {
      return list.length >= 3 ? list.slice(0, 3) : list;
    }
    return [];
  }

  _buildGauges(data) {
    if (!data) return list;

    const list = [];

    data.forEach((item) => {
      const id = item['id'];

      if (item) {
        const category = this._getCategory(id);

        if (category) {
          category.id = id;
          category.posts = item['total_posts'] || 0;
          category.relevance = Math.round(item['relevance'] || 0);
          category.score = Math.round((item['score'] || 0) * 1000);
          category.percentScore = parseInt(String((item['pre_score'] || 0) * 100), 10);

          let subCategoriesFinal = [];

          item['subcategories'].forEach((sub) => {
            const subItem = this._getSubCategory(sub['id']);

            if (subItem && subItem['labels']) {
              subCategoriesFinal = [
                ...subCategoriesFinal,
                {
                  name: subItem['labels']['1'],
                  id: sub['id'],
                  relevance: sub['relevance'],
                  score: sub['score'],
                  posts: sub['total_posts'],
                  percent: Math.round(sub['relevance'] * 100 * 10) / 10,
                },
              ];
            }
          });

          category.subCategories = subCategoriesFinal;

          list.push(category);
        }
      }
    });

    return list.sort((a, b) => {
      if (a.relevance > b.relevance) return -1;
      if (a.relevance < b.relevance) return 1;
      if (a.score > b.score) return -1;
      if (a.score < b.score) return 1;
      if (a.posts > b.posts) return -1;
      if (a.posts < b.posts) return 1;

      return 0;
    });
  }

  _getClassification(followers) {
    if (followers) {
      if (followers >= 1000000) return 'mega';
      if (followers >= 500000 && followers < 1000000) return 'macro';
      if (followers >= 50000 && followers < 500000) return 'inter';
      if (followers >= 10000 && followers < 50000) return 'micro';
      if (followers >= 1000 && followers < 10000) return 'nano';
    }
  }

  _getCategory(key) {
    let response = null;

    if (this.allCategories) {
      const category = this.allCategories.find((c) => c.key === key);

      if (category) response = Object.assign({}, category);
    }

    return response;
  }

  _getSubCategory(key) {
    let response = null;

    if (this.allSubCategories) {
      const subCategory = this.allSubCategories[key];

      if (subCategory) response = subCategory;
    }

    return response;
  }
}

export default InfluencerModel;
