import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchPostsInterval, selectInfluencerDetailsSlice, updateFilterCategory } from "../../slice"
import StateStatus from "../../../../../utils/status"
// 
import { Tooltip } from "antd"
import BarChart from "../../../../../common/components/Charts/BarChart"
import Loading from "../../../../../common/components/Loading"
import Select from "../../../../../common/components/Select"
// 
import { exportElementToImage } from "../../../../../utils"
import "./index.scss"

const PartPostsInterval = ({ influencerId }) => {
    const params = useParams()
    const id = influencerId || params.id
    const { t } = useTranslation()
    // 
    const dispatch = useDispatch()
    // 
    const slice = useSelector(selectInfluencerDetailsSlice)
    // 
    const status = slice.status
    const influencer = slice.influencer
    const filterCategory = slice.filterCategory
    const rawInfluencerCategories = influencer.rawCategories
    // 
    const filterPeriods = [
        { key: "30 Dias", value: 30 },
        { key: "60 Dias", value: 60 },
        { key: "90 Dias", value: 90 },
    ]
    const types = [
        { key: t("Data"), value: "date" },
        { key: t("Horas"), value: "hour" },
        { key: t("Quatro horas"), value: "four_hours" },
        { key: t("Dias da semana"), value: "day_of_week" },

    ]
    // 
    const [filterPeriod, updateFilterPeriod] = useState(30)
    const [filterType, updateFilterType] = useState("four_hours")

    useEffect(() => {
        dispatch(fetchPostsInterval({
            id,
            type: filterType,
            days: filterPeriod,
            categories: filterCategory ? 
                rawInfluencerCategories[filterCategory] ? rawInfluencerCategories[filterCategory].subcategories.map(item => item.id) : []
                : [],
        }))
    }, [filterType, filterPeriod, filterCategory])

    return <div className="interaction-chart chart-posts-interval" key="chart-posts-interval">
        <div className="subtitle-filter-category">
            <p className="subtitle-part">{t("Gráfico de posts")}</p>

            <div>
                {influencer.categories && influencer.categories.length > 1 && 
                    <Select
                        allowClear={true}
                        value={filterCategory}
                        placeholder={t("Selecione uma categoria")}
                        onChange={(value) => dispatch(updateFilterCategory(value))}
                        options={influencer.categories.map(category => (
                            { 
                                value: category.id, 
                                key: category.name, 
                                icon: <i 
                                    style={{ color: category.color }}
                                    className={`icon icon-${category.icon}`} 
                                /> 
                            }
                        ))}
                    />
                }

                <Select
                    allowClear={true}
                    placeholder={t("Posts por dia da semana")}
                    onChange={(value) => updateFilterType(value)}
                    options={types.map(item => (
                        { value: item.value,  key: item.key }
                    ))}
                />
            </div>
        </div>

        <div className="filter-period">
            {filterPeriods.map(filter => 
                <button    
                    key={filter.value}
                    className={`btn-filter ${filterPeriod === filter.value ? "selected" : "unselected"}`}
                    onClick={() => updateFilterPeriod(filter.value)}
                >
                    {filter.key}
                </button>
            )}
        </div>
    
        {status.fetchPostsInterval === StateStatus.loading && <Loading />}

        {status.fetchPostsInterval === StateStatus.succeeded && 
            <div className="box-chart">
                <Tooltip title={t("Baixar imagem do gráfico")}>
                    <i 
                        role="button"
                        className="icon icon-download hover" 
                        onClick={() => {
                            setTimeout(() => {
                                exportElementToImage(
                                    "chart-posts-interval", 
                                    `${influencer.name}-chart-posts-interval`.toLowerCase().replaceAll(" ", "-"),
                                    !filterCategory    
                                )
                            }, 1000);
                        }}    
                    />
                </Tooltip>

                <BarChart
                    formatType="number"
                    data={influencer.postsIntervalChart}
                />
            </div>
        }

        <div className="chart-description">
            <p className="text">
                {t("Total de posts por")} 
                <span>{types.find(type => type.value === filterType).key || "-"}</span>
            </p>

            <p className="value">
                Total do Gráfico acima: <b>{influencer.postsIntervalTotal} Posts</b> | Média: <b>{influencer.postsIntervalAverage}</b>
            </p>
        </div>
    </div> 
}

PartPostsInterval.propTypes = {
    influencerId: PropTypes.string,
}

export default PartPostsInterval