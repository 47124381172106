import moment from 'moment';
//
import { calcDeliveryCharge } from '../utils/formulas';
import InfluencerModel from './InfluencerModel';

class PlannerListModel {
  id;
  name;
  size;
  list;
  sharedBy;
  networks = [];
  isOwner;
  isActive;
  isFavorite;
  date;
  created;
  isShared;
  createdAt;
  createdByAira;
  assistantData;
  assistantBriefing;

  // modal
  influencerIds;

  // aux
  allCategories;
  allSubCategories;

  // details
  airscore;
  influencersCount;
  sumOfBases;
  baseOfNetworks;
  postsByNetworks;
  influencers;
  mentionedProfiles;
  mentionedPlaces;
  interactionsTotal;
  gauges = [];
  topInfluencersByCat = [];
  categories;
  // mentions
  hashtags;
  places;
  brands;
  mentions;

  fromJson(data, isFavorite) {
    const networkKeys = ['instagram', 'facebook', 'twitter', 'youtube', 'tiktok', 'twitch'];

    this.isFavorite = isFavorite;
    //
    this.id = this.isFavorite ? 'favorite' : data['uid'];
    this.name = data['title'];
    this.size = data['total'] || 0;
    this.list = data['list'] || [];
    this.isActive = data['activated'] || false;
    this.isShared = data['shared'] || false;
    this.isOwner = data['owner'] || false;
    this.created = data['created_by'] || '';
    this.status = data['status'] || '';
    this.date = moment(data['created_at']).format('DD/MM/YYYY');
    this.createdAt = new Date(data['created_at']);
    this.createdByAira = data['type'] === 'assistant' || false;
    this.assistantData = data['assistant_data'];
    this.assistantBriefing = data['assistant_briefing'];

    if (data['cover_image']) {
      this.avatar = `${process.env.REACT_APP_BASEURL}/${data['cover_image'].replace('/v1/', '')}`;
    }

    if (data['profiles']) {
      this.influencerIds = data['profiles'].map((profile) => profile['profile_id']);
    }

    networkKeys.forEach((network) => {
      if (data[network] && data[network] > 0) {
        this.networks = [
          ...this.networks,
          {
            name: network,
            value: data[network],
          },
        ];
      }
    });

    return this;
  }

  detailsFromJson(data, categories, subCategories) {
    const metrics = data['metrics_data'];
    //
    const colors = {
      instagram: '#A032AC',
      facebook: '#4267B2',
      twitter: '#3897F0',
      youtube: '#FF0000',
      tiktok: '#000000',
    };

    this.id = data['uid'] === 'favorite' ? 'favorite' : data['uid'];
    this.isFavorite = data['uid'] === 'favorite';
    this.name = data['title'] || '';
    this.isShared = data['shared'] || false;
    this.createdBy = data['created_by'] || '';
    this.influencersCount = data['total'] || 0;
    this.createdAt = new Date(data['created_at']);
    this.date = moment(data['created_at']).format('DD/MM/YYYY');
    this.isOwner = data['owner'];
    this.isShared = data['shared'];
    this.createdByAira = data['type'] === 'assistant' || false;
    this.status = data['status'] || '';
    this.assistantData = data['assistant_data'];
    this.assistantBriefing = data['assistant_briefing'];

    if (data['cover_image']) {
      this.avatar = `${process.env.REACT_APP_BASEURL}/${data['cover_image'].replace('/v1/', '')}`;
    }

    if (metrics) {
      const places = metrics['places'];
      const brands = metrics['brands'];
      const profiles = metrics['profiles'];
      const mentions = metrics['mentions'];
      const hashtags = metrics['hashtags'];
      const postsByNetwork = metrics['posts_by_social_media'];
      const followersByNetwork = metrics['followers_by_social_media'];
      //
      this.sumOfBases = metrics['followers_total'] || 0;
      this.interactionsTotal = metrics['interactions_total'] || 0;
      this.airscore = parseInt((metrics['score_average'] || 0) * 1000);
      this.engagementRate = metrics['engagement_rate'] || 0;

      this.rangePotencial = 0;

      if (followersByNetwork) {
        this.baseOfNetworks = Object.keys(followersByNetwork)
          .map((item) => {
            const itemValues = followersByNetwork[item];

            if (itemValues && item != 'twitch') {
              this.rangePotencial = calcDeliveryCharge(parseInt(itemValues['value']), item);

              return {
                label: item,
                value: parseInt(itemValues['value']),
                color: colors[item],
              };
            }
          })
          .filter((item) => item);
      }

      if (postsByNetwork) {
        this.postsByNetworks = Object.keys(postsByNetwork)
          .map((item) => {
            const itemValues = postsByNetwork[item];
            if (itemValues) {
              return {
                label: item,
                value: parseInt(itemValues.value),
                color: colors[item],
              };
            }
          })
          .filter((item) => item);
      }

      if (profiles) {
        this.influencers = Object.keys(profiles).map((profile) => {
          const influencer = profiles[profile];

          const model = new InfluencerModel().plCardFromJson(
            {
              profile: influencer,
            },
            true,
          );

          return model;
        });
      }

      if (hashtags) {
        const listHashtags = [];

        Object.keys(hashtags).map((hashtag) => {
          listHashtags.push({
            name: hashtag,
            count: hashtags[hashtag],
          });
        });

        this.hashtags = listHashtags || [];
      }

      if (places) {
        const listPlaces = [];

        Object.keys(places).map((place) => {
          listPlaces.push({
            name: place,
            count: places[place],
            // id: places[place]["id"],
          });
        });

        this.places = listPlaces || [];
      }

      if (brands) {
        const listBrands = [];

        Object.keys(brands).map((brand) => {
          listBrands.push({
            name: brand,
            count: brands[brand],
          });
        });

        this.brands = listBrands || [];
      }

      if (mentions) {
        const listMentions = [];

        Object.keys(mentions).map((mention) => {
          listMentions.push({
            name: mention,
            count: mentions[mention],
          });
        });

        this.mentions = listMentions || [];
      }

      if ((categories, subCategories)) {
        this.allCategories = categories;
        this.allSubCategories = subCategories;

        if (metrics['categories']) {
          const influencersData = metrics['profiles'];
          const topProfiles = metrics['top_profiles_by_categories'];
          //
          this.gauges = this._getGauges(metrics['categories']).slice(0, 8);
          this.categories = this._getCategories(metrics['categories']).slice(0, 8);

          if (topProfiles) {
            this.topInfluencersByCat = Object.keys(topProfiles)
              .map((id) => {
                const cat = topProfiles[id];
                const influencersValues = cat['profiles'];
                //
                let influencers = [];

                if (cat && influencersValues) {
                  influencersValues.map((influencer) => {
                    const influencerProfileData = influencersData[influencer['profile']];

                    if (influencerProfileData) {
                      influencers.push({
                        name: influencerProfileData['username'],
                        network: influencerProfileData['network'],
                        avatar: influencerProfileData['picture'],
                        score: Math.ceil((influencer['score'] || 0) * 1000),
                      });
                    }
                  });
                }

                let categorieData = this.categories.find((item) => item.id === id);

                if (categorieData)
                  return {
                    ...categorieData,
                    influencers: influencers,
                  };
              })
              .sort((a, b) => {
                if (a.percent > b.percent) return -1;
                if (a.percent < b.percent) return 1;
                if (a.score > b.score) return -1;
                if (a.score < b.score) return 1;
                if (a.posts > b.posts) return -1;
                if (a.posts < b.posts) return 1;
                if (a.name > b.name) return -1;
                if (a.name < b.name) return 1;

                return 0;
              })
              .slice(0, 8);
          }
        }
      }
    }

    return this;
  }

  _getGauges = (data) => {
    const response = [];

    if (!data) return response;

    for (const id in data) {
      const item = data[id];

      if (item) {
        const topic = this._getTopic(id);

        topic.id = id;
        topic.color = `${topic.color}`;
        topic.posts = item['posts'] || 0;
        topic.score = Math.ceil((item['score'] || 0) * 1000);
        topic.percent = parseFloat(`${item['percentage'] || 0}`).toFixed(0);
        topic.percentScore = parseInt(String(topic.score), 10);

        response.push(topic);
      }
    }

    return response
      .sort((a, b) => {
        if (a.percent > b.percent) return -1;
        if (a.percent < b.percent) return 1;
        if (a.score > b.score) return -1;
        if (a.score < b.score) return 1;
        if (a.posts > b.posts) return -1;
        if (a.posts < b.posts) return 1;
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;

        return 0;
      })
      .slice(0, 8);
  };

  _getTopic = (key) => {
    let response = null;

    if (this.allCategories) {
      const category = this.allCategories.find((c) => c.key === key);

      if (category) response = Object.assign({}, category);
    }

    return response;
  };

  _getTopInfluencers = (ids, categoryId, format) => {
    let influencers = [];

    if (ids) {
      ids.forEach((id) => {
        const influencer = this.influencers.find((i) => i.id === id);

        if (influencer) {
          if (format) {
            const gauge = influencer.gauges.find((g) => g.id === categoryId);

            if (gauge) influencer.categoryScore = gauge.score;
          }

          influencers.push(influencer);
        }
      });
    }

    influencers = influencers.sort((a, b) => {
      const scoreA = format ? a.categoryScore : a.score;
      const scoreB = format ? b.categoryScore : b.score;

      if (scoreA > scoreB) return -1;
      if (scoreA < scoreB) return 1;
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;

      return 0;
    });

    return influencers;
  };

  _getCategories = (data) => {
    if (!data) return [];

    const categories = this._getGauges(data).slice(0, 8);

    categories.forEach((category) => {
      if (category.subCategories) {
        let subCategories = [];

        if (data[category.id]['subcategories']) {
          category.subCategories.forEach((subCategory) => {
            const subCategoryData = data[category.id]['subcategories'].find(
              (item) => item['slug'] === subCategory.slug,
            );

            if (subCategoryData) {
              const sub = {
                name: subCategory.name,
                posts: subCategoryData['posts'] || 0,
                score: Math.ceil((subCategoryData['score'] || 0) * 1000),
                percent: Math.round(((subCategoryData['posts'] || 0) / category.posts) * 100),
              };

              subCategories.push(sub);
            }
          });

          subCategories = subCategories.sort((a, b) => {
            if (a.percent > b.percent) return -1;
            if (a.percent < b.percent) return 1;
            if (a.posts > b.posts) return -1;
            if (a.posts < b.posts) return 1;
            if (a.name > b.name) return -1;
            if (a.name < b.name) return 1;

            return 0;
          });

          category.subCategories = subCategories.slice(0, 4);
        }
      }
    });

    return categories;
  };

  _getCategory = (key) => {
    let response = {};

    if (this.allSubCategories && this.allSubCategories[key]) {
      response = this.allSubCategories[key];
    }

    return response;
  };
}

export default PlannerListModel;
