import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import Input from "../Input"
import Loading from "../Loading"
import CheckBox from "../CheckBox"
// 
import "./index.scss"
import Button from "../Button"

const MultiSelect = ({
    onChange,
    onSearch,
    isLoading,
    options,
    hasSearch,
    placeholder,
    selecteds,
    isOpenPopover,
    showAfterSearch,
}) => {
    const { t } = useTranslation()
    // 
    const [inputValue, updateInputValue] = useState("")
    const [filteredOptions, updateFilteredOptions] = useState([])

    useEffect(() => {
        if(!isOpenPopover) updateInputValue("")
    }, [isOpenPopover])

    useEffect(() => {
        if(showAfterSearch && !onSearch) {
            if(inputValue.length >= 3) {
                updateFilteredOptions(options.filter(item => item["key"].toLowerCase().includes(inputValue.toLowerCase())))
            } else {
                updateFilteredOptions([])
            }
        } else {
            updateFilteredOptions(options)
        }
    }, [options, inputValue])
    
    return <div className="multi-select">
        {hasSearch && <Input
            value={inputValue}
            placeholder={placeholder}
            onChange={(e) => {
                const text = e.target.value

                updateInputValue(text)
                
                if(onSearch) {
                    onSearch(text.normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                } 
            }}
            prefix={<i className="icon icon-search" />}
        />}

        {isLoading ?
            <Loading />
            :
            <div className="options">
                {showAfterSearch && filteredOptions.length < 1 &&
                    <p className="text-empty">
                        {onSearch && t(inputValue.length > 0 ? 
                            "Sem resultados"
                            :
                            "Informe pelo menos uma letra para as opções aparecerem"
                        )}
                        
                        {!onSearch && t(inputValue.length > 3 ? 
                            "Sem resultados"
                            :
                            "Informe pelo menos três letras para as opções aparecerem"
                        )}
                    </p>
                }

                {filteredOptions.map(option => (
                    <CheckBox
                        key={option["value"]}
                        text={option["key"]}
                        checked={selecteds[option["value"]] !== undefined && selecteds[option["value"]] !== null}
                        onChange={({ target }) => {
                            if(target.checked) {
                                onChange({ 
                                    ...selecteds, 
                                    ...{ [option["value"]]: "include" }, 
                                })
                            } else {
                                onChange({ 
                                    ...selecteds, 
                                    ...{ [option["value"]]: null }, 
                                })
                            }
                        }}
                    />
                ))}
            </div>
        }

        <Button
            className="outline-light btn-clear"
            onClick={() => {
                if(onSearch) onSearch("")
                
                updateInputValue("")
                
                onChange({})
            }}
        >
            {t("Limpar filtros")}
        </Button>
    </div>
}

MultiSelect.defaultProps = {
    options: [],
    selecteds: {},
    hasSearch: true,
    isLoading: false,
    isDisabled: false,
    isOpenPopover: false,
    showAfterSearch: false,
    onChange: () => { },
    onNewSelecteds: () => { },
}

MultiSelect.propTypes = {
    children: PropTypes.element,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    hasSearch: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isOpenPopover: PropTypes.bool,
    isLoading: PropTypes.bool,
    showAfterSearch: PropTypes.bool,
    placeholder: PropTypes.string,
    selecteds: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.object),
}

export default MultiSelect