import { useSearchParams } from 'react-router-dom';

import { QUERIES } from '../../../../../constants/queries';
import { storage } from '../../../../../utils/storage';

import './index.scss';

export const DisplayMode = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    [QUERIES.DISPLAY_MODE]: storage.getItem(QUERIES.DISPLAY_MODE) ?? 'list',
  });

  const displayMode = searchParams.get(QUERIES.DISPLAY_MODE);

  const handleToggleDisplayMode = (mode) => {
    setSearchParams((params) => {
      params.set(QUERIES.DISPLAY_MODE, mode);
      storage.setItem(QUERIES.DISPLAY_MODE, mode);

      return params;
    });
  };

  return (
    <div className="display_mode">
      <button
        type="button"
        className="button__display_mode"
        onClick={() => handleToggleDisplayMode('list')}
        data-state={displayMode === 'list'}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="8" x2="21" y1="6" y2="6" />
          <line x1="8" x2="21" y1="12" y2="12" />
          <line x1="8" x2="21" y1="18" y2="18" />
          <line x1="3" x2="3.01" y1="6" y2="6" />
          <line x1="3" x2="3.01" y1="12" y2="12" />
          <line x1="3" x2="3.01" y1="18" y2="18" />
        </svg>
      </button>

      <button
        type="button"
        className="button__display_mode"
        onClick={() => handleToggleDisplayMode('grid')}
        data-state={displayMode === 'grid'}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <rect width="7" height="7" x="3" y="3" rx="1" />
          <rect width="7" height="7" x="14" y="3" rx="1" />
          <rect width="7" height="7" x="14" y="14" rx="1" />
          <rect width="7" height="7" x="3" y="14" rx="1" />
        </svg>
      </button>
    </div>
  );
};
