import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectBrands, selectCategories } from '../../../../../../../app/slice';
import { changeObjAlert, showAlert } from '../../../../../../../common/components/Alert/slice';
import CheckBox from '../../../../../../../common/components/CheckBox';
import { EngagementRateTable } from '../../../../../../../common/components/EngagementRateTable';
import Input from '../../../../../../../common/components/Input';
import MultiSelect from '../../../../../../../common/components/MultiSelect';
import { NewFeature } from '../../../../../../../common/components/NewFeature';
import Radio from '../../../../../../../common/components/Radio';
import Slider from '../../../../../../../common/components/Slider';
import Tooltip from '../../../../../../../common/components/Tooltip';
import {
  convertEngagementRate,
  convertToEngagementRate,
} from '../../../../../../../utils/formatters';
import StateStatus from '../../../../../../../utils/status';
import { fetchAutocompletes, selectHomeSlice, updateSearchData } from '../../../../slice';
import CategoriesModal from '../CategoriesModal';
import MultiSelectBrands from '../MultiSelectBrands';
import MultiSelectCategories from '../MultiSelectCategories';
import PickedItemIncludeExclude from '../PickedItemIncludeExclude';
import Popover from '../Popover';

import './index.scss';

const SearchFilters = ({ filterAges }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brands = useSelector(selectBrands);
  const homeSlice = useSelector(selectHomeSlice);
  const categories = useSelector(selectCategories);
  const [selectedFilterType, setSelectedFilterType] = useState('influencer');
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const [bioInput, setBioInput] = useState('');

  const search = homeSlice.search;
  const status = homeSlice.status;

  const handleShowEngagementRateTable = () => {
    dispatch(
      changeObjAlert({
        show: true,
        type: 'info',
        className: 'engagement-modal',
        title: t('Taxa de engajamento'),
        subtitle: t('Confira as taxas de engajamentos comuns para cada rede social.'),
        text: <EngagementRateTable />,
        cancelText: t('Fechar'),
        onCancel: () => dispatch(showAlert(false)),
      }),
    );
  };

  const filterTypes = [
    { label: t('Filtros do influenciador'), value: 'influencer' },
    {
      label: t('Filtros da audiência'),
      value: 'audience',
      tooltip: t(
        'Utilize os filtros da audiência para encontrar perfis de acordo com interesses dos seguidores de um influenciador.',
      ),
    },
  ];

  const addStringList = (text) => {
    let list = [];
    let newMentions = text.split(';').join(',').split('.').join(',').split(',');

    newMentions.forEach((item) => {
      if (item.length > 1 && !list.includes(item)) {
        list = [...list, item];
      }
    });

    return list;
  };

  const getValue = (key, returnDefault = null) => {
    if (search[key]) {
      if (key === 'brands') {
        let obj = {};

        search[key].forEach(({ id, rule }) => {
          if (id) obj[id] = rule;
        });

        return obj;
      } else if (key === 'categories') {
        let obj = {};

        search[key].forEach(({ id, rule, relevances }) => {
          if (id) obj[id] = { rule, relevances };
        });

        return obj;
      } else if (key === 'audience_gender' || key === 'gender') {
        let obj = {};

        search[key].forEach((value) => {
          if (value) obj[value] = 'include';
        });

        return obj;
      } else if (
        key === 'audience_interests' ||
        key === 'audience_places' ||
        key === 'audience_age' ||
        key === 'estimated_age'
      ) {
        let obj = {};

        search[key].forEach((value) => {
          obj[value] = 'include';
        });

        return obj;
      } else if (key === 'bio') {
        let list = [];

        search[key].forEach((item) => {
          list.push(item.text);
        });

        return list.toString();
      }

      return search[key];
    } else {
      return returnDefault;
    }
  };

  useEffect(() => {
    if (search.bio) {
      const bio = search.bio.map((b) => b.text);

      if (bio && bio.length > 0) setBioInput(bio.join(','));
    } else {
      setBioInput('');
    }
  }, [search]);

  let delayTimer;

  return (
    <div className="search-filters">
      <div className="filter-types">
        {filterTypes.map((item) => (
          <div
            key={item.value}
            className={`filter-type-option ${
              selectedFilterType === item.value ? 'selected' : 'unselected'
            }`}
            onClick={() => setSelectedFilterType(item.value)}
          >
            {item.label}

            {item.tooltip && <Tooltip child={item.tooltip} />}
          </div>
        ))}

        <div className={`underline ${selectedFilterType}`} />
      </div>

      <div className="card-form-items">
        <div className={`influencer-filters ${selectedFilterType !== 'audience' ? 'show' : ''}`}>
          <Row className="filter-line">
            <Popover
              title="Selecione a estimativa de idade"
              label={
                <div style={{ display: 'flex', gap: 8, alignItems: 'flex-start' }}>
                  <label>Estimativa de idade</label>
                  <NewFeature />
                </div>
              }
              itemCount={
                search.estimated_age &&
                search.estimated_age.length > 0 &&
                `${search.estimated_age.length}`
              }
              content={() => (
                <MultiSelect
                  placeholder="Selecione a estimativa de idade"
                  options={filterAges}
                  selecteds={getValue('estimated_age', {})}
                  onChange={(values) => {
                    if (values && Object.keys(values).length > 0) {
                      const list = Object.keys(values)
                        .map((id) => values[id] && id)
                        .filter((item) => item);

                      dispatch(
                        updateSearchData({
                          estimated_age: list,
                        }),
                      );
                    } else {
                      dispatch(updateSearchData({ estimated_age: null }));
                    }
                  }}
                />
              )}
            />

            <div className="divider" />

            <Input
              isOutlined
              value={bioInput}
              label={
                <>
                  {t('Palavras mencionadas na bio')}

                  <Tooltip
                    child={t("Utilize vírgula ',' para poder buscar mais de uma palavra na bio.")}
                  />
                </>
              }
              onChange={(e) => {
                const value = e.target.value;

                setBioInput(value);
              }}
              onBlur={(e) => {
                const value = e.target.value;

                dispatch(
                  updateSearchData({
                    bio: addStringList(value).map((text) => ({ text: text, rule: 'include' })),
                  }),
                );
              }}
              placeholder={t('Digite as palavras-chave')}
            />

            <div className="divider" />

            <div className="label-checkbox">
              <span style={{ display: 'flex', gap: 8, alignItems: 'flex-start' }}>
                <label style={{ marginBottom: 0 }}>Tipo de perfil</label>
                <NewFeature />
              </span>

              <CheckBox
                checked={search.is_influencer}
                text="Visualizar somente influenciadores"
                onChange={(value) => {
                  dispatch(
                    updateSearchData({
                      is_influencer: value.target.checked,
                    }),
                  );
                }}
              />
            </div>
          </Row>

          <Row className="filter-line">
            <Popover
              title={t('Defina a taxa de engajamento')}
              label={t('Taxa de engajamento')}
              itemValue={
                search.engagement_rate &&
                `${search.engagement_rate.min}% - ${search.engagement_rate.max}%`
              }
              content={() => (
                <Slider
                  min={0}
                  max={100}
                  step={50}
                  range={true}
                  marks={{
                    0: '0',
                    11: '2%',
                    22: '4%',
                    33: '7%',
                    44: '9%',
                    55: '12%',
                    66: '15%',
                    80: '20%',
                    100: '100%',
                  }}
                  additionalContent={
                    <div className="additional-content">
                      <span className="hover" role="button" onClick={handleShowEngagementRateTable}>
                        {t('Qual taxa escolher?')}
                      </span>
                    </div>
                  }
                  formatter={(value) => `${convertEngagementRate(value)}%`}
                  values={
                    search.engagement_rate
                      ? [
                          convertToEngagementRate(search.engagement_rate.min),
                          convertToEngagementRate(search.engagement_rate.max),
                        ]
                      : null
                  }
                  onAfterChange={(values) => {
                    clearTimeout(delayTimer);

                    delayTimer = setTimeout(() => {
                      dispatch(
                        updateSearchData({
                          engagement_rate: !values
                            ? null
                            : {
                                min: convertEngagementRate(values[0]),
                                max: convertEngagementRate(values[1]),
                              },
                        }),
                      );
                    }, 750);
                  }}
                />
              )}
            />

            <div className="divider" />

            <Popover
              label={t('Gênero')}
              btnTitle={t('Selecione o gênero')}
              title={t('Escolha o gênero')}
              itemCount={search.gender && search.gender[0] && `${search.gender[0][0]}`}
              content={() => (
                <Radio
                  value={search.gender ? search.gender[0] : null}
                  options={[
                    { label: t('Masculino'), value: 'M' },
                    { label: t('Feminino'), value: 'F' },
                  ]}
                  onChange={(value) => {
                    dispatch(
                      updateSearchData({
                        gender: [value],
                      }),
                    );
                  }}
                />
              )}
            />

            <div className="divider" />

            <div className="label-checkbox">
              <label>
                {t('Crescimento acelerado')}

                <Tooltip
                  child={t(
                    'Aponta os perfis que tiveram destaque na rede e apresentaram um rápido crescimento.',
                  )}
                />
              </label>

              <CheckBox
                checked={
                  search.positive_badges && search.positive_badges.includes('accelerated_growth')
                }
                text={t('Somente perfis com crescimento acelerado')}
                onChange={(value) => {
                  if (
                    search.positive_badges &&
                    search.positive_badges.includes('accelerated_growth')
                  ) {
                    dispatch(
                      updateSearchData({
                        positive_badges: null,
                      }),
                    );
                  } else {
                    dispatch(
                      updateSearchData({
                        positive_badges: value ? ['accelerated_growth'] : null,
                      }),
                    );
                  }
                }}
              />
            </div>
          </Row>

          <Row className="filter-line">
            <Col>
              <Popover
                title={t('Marcas')}
                label={
                  <div style={{ display: 'flex', gap: 8, alignItems: 'flex-start' }}>
                    <label>{t('Marcas mencionadas')}</label>

                    <Tooltip
                      child={t(
                        `Ao incluir uma ou mais marcas na busca, é possível negativar uma das marcas. Ex.: quero achar perfis que fale sobre a marca "X" (clicar no "+") e não fale sobre a "Y" (clicar no "-").`,
                      )}
                    />
                  </div>
                }
                itemCount={search.brands && search.brands.length > 0 && `${search.brands.length}`}
                content={({ openPopover }) => (
                  <MultiSelectBrands
                    isOpenPopover={openPopover}
                    showAfterSearch={true}
                    selecteds={getValue('brands', {})}
                    placeholder={t('Pesquise pelo nome da marca')}
                    options={Object.keys(brands.object).map((id) => ({
                      value: id,
                      key: brands.object[id],
                    }))}
                    onChange={(values) => {
                      if (values && Object.keys(values).length > 0) {
                        const list = Object.keys(values)
                          .map((id) => ({ id: id, rule: values[id] }))
                          .filter((item) => item.rule);

                        dispatch(
                          updateSearchData({
                            brands: list,
                          }),
                        );
                      } else {
                        dispatch(updateSearchData({ brands: null }));
                      }
                    }}
                  />
                )}
              />

              {search.brands && search.brands.length > 0 && (
                <>
                  {search.brands.map(({ id }) => {
                    let brandName = brands.object[id];
                    const brand = search.brands.find((item) => item.id === id);

                    return (
                      <PickedItemIncludeExclude
                        key={id}
                        value={id}
                        include={brand?.rule === 'include'}
                        hasRelevances={false}
                        onDelete={() => {
                          const list = search.brands.filter((item) => item.id != id);

                          dispatch(
                            updateSearchData({
                              brands: list && list.length > 0 ? list : null,
                            }),
                          );
                        }}
                        onChange={(data) => {
                          const list = search.brands.map((cat) => {
                            return {
                              id: cat.id,
                              relevances: cat.id === id ? data.relevances : cat.relevances,
                              rule: cat.id === id ? data.rule : cat.rule,
                            };
                          });

                          dispatch(
                            updateSearchData({
                              brands: list && list.length > 0 ? list : null,
                            }),
                          );
                        }}
                      >
                        {brandName}
                      </PickedItemIncludeExclude>
                    );
                  })}
                </>
              )}
            </Col>

            <div className="divider" />

            <Col align="end" className="col-theme">
              <div className="popover-btn popover-btn-categories">
                <Popover
                  title={t('Selecione as categorias')}
                  label={
                    <div style={{ display: 'flex', gap: 8, alignItems: 'flex-start' }}>
                      <label>{t('Categorias')}</label>

                      <Tooltip
                        child={
                          t(
                            `É possível fazer a busca por mais de uma categoria ou subcategoria, caso deseje excluir alguma categoria selecionado, ative o sinal negativo ("-")`,
                          ) +
                          '. Ex.:' +
                          t(
                            `Buscar um perfil que fale de Jiu Jitsu, mas que não fale de política. É só negativar a categoria política.`,
                          )
                        }
                      />
                    </div>
                  }
                  itemCount={
                    search.categories &&
                    search.categories.length > 0 &&
                    `${search.categories.length}`
                  }
                  content={({ openPopover }) => (
                    <MultiSelectCategories
                      categories={categories.list}
                      isOpenPopover={openPopover}
                      selecteds={getValue('categories', {})}
                      placeholder={t('Selecione ou pesquise')}
                      onChange={(value) => {
                        if (value && Object.keys(value).length > 0) {
                          const list = Object.keys(value)
                            .map((id) => ({
                              id: id,
                              rule: value[id]?.rule,
                              relevances: value[id]?.relevances ?? { high: true },
                            }))
                            .filter((item) => item.rule);

                          dispatch(
                            updateSearchData({
                              categories: list,
                              category_relevance: search.category_relevance || 0,
                            }),
                          );
                        } else {
                          dispatch(updateSearchData({ categories: null }));
                        }
                      }}
                    />
                  )}
                />

                <i
                  className="icon icon-expand hover"
                  onClick={() => setShowCategoriesModal(true)}
                />
              </div>

              {search.categories && search.categories.length > 0 && (
                <>
                  <Row justify={'space-between'}>
                    <Col>
                      <span>Categorias selecionadas</span>
                    </Col>
                    <Col>
                      <span
                        role="button"
                        className="text-clear-all hover"
                        onClick={() =>
                          dispatch(
                            updateSearchData({
                              categories: null,
                            }),
                          )
                        }
                      >
                        {t('Limpar categorias')}
                      </span>
                    </Col>
                  </Row>

                  {search.categories.map(({ id }) => {
                    let catName = categories.list.find((category) => category.key === id)?.name;
                    const category = search.categories.find((item) => item.id === id);

                    if (!catName && categories.subCategories[id]) {
                      catName = categories.subCategories[id].labels[1] || '-';
                    }

                    return (
                      <PickedItemIncludeExclude
                        key={id}
                        value={id}
                        relevances={category?.relevances}
                        hasRelevances={true}
                        include={category?.rule === 'include'}
                        onDelete={() => {
                          const list = search.categories.filter((item) => item.id != id);

                          dispatch(
                            updateSearchData({
                              categories: list && list.length > 0 ? list : null,
                            }),
                          );
                        }}
                        onChange={(data) => {
                          const list = search.categories.map((cat) => {
                            return {
                              id: cat.id,
                              relevances: cat.id === id ? data.relevances : cat.relevances,
                              rule: cat.id === id ? data.rule : cat.rule,
                            };
                          });

                          dispatch(
                            updateSearchData({
                              categories: list && list.length > 0 ? list : null,
                            }),
                          );
                        }}
                      >
                        {catName}
                      </PickedItemIncludeExclude>
                    );
                  })}
                </>
              )}
            </Col>

            <div className="divider" />

            <div className="label-checkbox">
              <label>
                {t('Contato')}

                <Tooltip
                  child={t('Apenas perfis que adicionaram algum contato na bio aparecerão.')}
                />
              </label>

              <CheckBox
                checked={search.has_contact}
                text={t('Somente perfis com contato')}
                onChange={() => {
                  dispatch(
                    updateSearchData({
                      has_contact: !search.has_contact,
                    }),
                  );
                }}
              />
            </div>
          </Row>
        </div>

        <div className={`audience-filters ${selectedFilterType === 'audience' ? 'show' : ''}`}>
          <Row className="filter-line">
            <Popover
              title={t('Selecione a faixa de idade')}
              label={t('Idade')}
              itemCount={
                search.audience_age &&
                search.audience_age.length > 0 &&
                `${search.audience_age.length}`
              }
              content={() => (
                <MultiSelect
                  placeholder={t('Seleciona a faixa de idade')}
                  options={filterAges}
                  selecteds={getValue('audience_age', {})}
                  onChange={(values) => {
                    if (values && Object.keys(values).length > 0) {
                      const list = Object.keys(values)
                        .map((id) => values[id] && id)
                        .filter((item) => item);

                      dispatch(
                        updateSearchData({
                          audience_age: list,
                        }),
                      );
                    } else {
                      dispatch(updateSearchData({ audience_age: null }));
                    }
                  }}
                />
              )}
            />

            <div className="divider" />

            <Popover
              label={t('Gênero')}
              btnTitle={t('Selecione o gênero da audiência')}
              title={t('Escolha o gênero')}
              itemCount={search.audience_gender && search.audience_gender[0].toUpperCase()}
              content={() => (
                <Radio
                  value={search.audience_gender}
                  options={[
                    { label: t('Masculino'), value: 'male' },
                    { label: t('Feminino'), value: 'female' },
                  ]}
                  onChange={(value) => {
                    dispatch(
                      updateSearchData({
                        audience_gender: value,
                      }),
                    );
                  }}
                />
              )}
            />

            <div className="divider" />

            <Popover
              label={t('Localização')}
              title={t('Localização da audiência')}
              itemCount={
                search.audience_places &&
                search.audience_places.length > 0 &&
                `${search.audience_places.length}`
              }
              content={({ openPopover }) => (
                <MultiSelect
                  isOpenPopover={openPopover}
                  showAfterSearch={true}
                  onSearch={(text) => {
                    clearTimeout(delayTimer);

                    delayTimer = setTimeout(() => {
                      dispatch(
                        fetchAutocompletes({
                          type: 'places',
                          query: text,
                        }),
                      );
                    }, 500);
                  }}
                  placeholder={t('Pesquise por estado ou cidade')}
                  selecteds={getValue('audience_places', {})}
                  options={(homeSlice.autocompletes['places'] || []).map((item) => ({
                    value: item,
                    key: item,
                  }))}
                  isLoading={status.fetchAutocompletes === StateStatus.loading}
                  onChange={(values) => {
                    if (values && Object.keys(values).length > 0) {
                      const list = Object.keys(values)
                        .map((id) => values[id] && id)
                        .filter((item) => item);

                      dispatch(
                        updateSearchData({
                          audience_places: list,
                        }),
                      );
                    } else {
                      dispatch(updateSearchData({ audience_places: null }));
                    }
                  }}
                />
              )}
            />
          </Row>

          <Row className="filter-line">
            <Popover
              label={t('Interesses')}
              title={t('Selecione os interesses')}
              itemCount={
                search.audience_interests &&
                search.audience_interests.length > 0 &&
                `${search.audience_interests.length}`
              }
              content={({ openPopover }) => (
                <MultiSelect
                  isOpenPopover={openPopover}
                  showAfterSearch={true}
                  selecteds={getValue('audience_interests', {})}
                  placeholder={t('Pesquise pelo nome do interesse')}
                  options={(homeSlice.autocompletes['interests'] || []).map((item) => ({
                    value: item,
                    key: item,
                  }))}
                  onSearch={(text) => {
                    clearTimeout(delayTimer);

                    delayTimer = setTimeout(() => {
                      dispatch(
                        fetchAutocompletes({
                          type: 'interests',
                          query: text,
                        }),
                      );
                    }, 500);
                  }}
                  isLoading={status.fetchAutocompletes === StateStatus.loading}
                  onChange={(values) => {
                    if (values && Object.keys(values).length > 0) {
                      const list = Object.keys(values)
                        .map((id) => values[id] && id)
                        .filter((item) => item);

                      dispatch(
                        updateSearchData({
                          audience_interests: list,
                        }),
                      );
                    } else {
                      dispatch(updateSearchData({ audience_interests: null }));
                    }
                  }}
                />
              )}
            />

            <div className="divider transparent" />
          </Row>
        </div>
      </div>

      <CategoriesModal
        categories={categories.list}
        selecteds={getValue('categories', {})}
        onChange={(value) => {
          if (value && Object.keys(value).length > 0) {
            const list = Object.keys(value)
              .map((id) => ({
                id: id,
                rule: value[id]?.rule,
                relevances: value[id]?.relevances ?? { high: true },
              }))
              .filter((item) => item.rule);

            dispatch(
              updateSearchData({
                categories: list,
                category_relevance: search.category_relevance || 0,
              }),
            );
          } else {
            dispatch(updateSearchData({ categories: null }));
          }
        }}
        isVisible={showCategoriesModal}
        onClose={() => setShowCategoriesModal(false)}
      />
    </div>
  );
};

SearchFilters.defaultProps = {
  filterAges: [],
};

SearchFilters.propTypes = {
  filterAges: PropTypes.arrayOf(PropTypes.object),
};

export default SearchFilters;
