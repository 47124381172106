import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit"
import {
    getInfluencerDetails,
    getInteractionsHistory
} from "../InfluencerCard/service"
// 
import {
    changeObjAlert,
    showAlert
} from "../../../common/components/Alert/slice"
import i18n from "../../../languages/i18n"
// 
import InfluencerModel from "../../../models/InfluencerModel"
import StateStatus from "../../../utils/status"

const initialStateInfluencer = {
    status: {
        fetchPublipostData: StateStatus.idle,
    },
    influencer: new InfluencerModel()
}

export const fetchPublipostData = createAsyncThunk("influencer-card-pdf/fetch", async ({
    id,
}, thunkAPI) => {
    try {
        const {
            app
        } = thunkAPI.getState()
        const {
            categories,
            brands
        } = app

        const response = await getInfluencerDetails(id, "publi_post")

        return {
            brands: brands.object,
            categories: categories.list,
            subCategories: categories.subCategories,
            influencer: response.data,
        }
    } catch (err) {
        thunkAPI.dispatch(changeObjAlert({
            show: true,
            type: "warning",
            title: `${i18n.t("atenção")}!`,
            text: i18n.t("Falha ao pegar os dados do influenciador"),
            onCancel: () => {
                thunkAPI.dispatch(showAlert(false))
            },
        }))
        throw err
    }
})

export const fetchInteractionsHistory = createAsyncThunk("influencer-card-pdf/fetch/history", async ({
    id,
    days,
}) => {
    const data = {
        "days": days,
        "profile_id": id,
        "data_type": "publi_post"
    }

    const response = await getInteractionsHistory(data)

    return {
        days,
        ...response.data
    }
})

export const influencerDetailsPDFSlice = createSlice({
    name: "influencerDetailsPDF",
    initialState: initialStateInfluencer,
    extraReducers: {
        [fetchPublipostData.pending]: (state) => {
            state.status.fetchPublipostData = StateStatus.loading
        },
        [fetchPublipostData.fulfilled]: (state, {
            payload
        }) => {
            const data = payload.influencer
            const categories = payload.categories
            const subCategories = payload.subCategories
            const brands = payload.brands

            const influencer = new InfluencerModel().detailsFromJson(data, categories, subCategories, brands)

            state.influencer = influencer

            state.status.fetchPublipostData = StateStatus.succeeded
        },
        [fetchPublipostData.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.fetchPublipostData = StateStatus.failed
        },
        // 
        [fetchInteractionsHistory.pending]: (state) => {
            state.status.fetchInteractionsHistory = StateStatus.loading
        },
        [fetchInteractionsHistory.fulfilled]: (state, {
            payload
        }) => {
            state.influencer = state.influencer.addValuesByPeriod(payload.items, payload.days)

            if(payload.days === 60) {
                state.status.fetchInteractionsHistory = StateStatus.succeeded
            } else {
                state.status.fetchInteractionsHistory = StateStatus.idle
            }
        },
        [fetchInteractionsHistory.rejected]: (state) => {
            state.status.fetchInteractionsHistory = StateStatus.failed
        },
    }
})

export const selectInfluencerDetailsPDFSlice = (state) => state.influencerDetailsPDF
