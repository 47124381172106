import React from 'react';
import { Col, Collapse, Row } from 'antd';
import PropTypes from 'prop-types';
import './styles.scss';
import { useTranslation } from 'react-i18next';

// const replaceLast = (x, y, z) => {
//     var a = x.split('');
//     a[x.lastIndexOf(y)] = z;
//     return a.join('');
// };

const getItems = (pl) => {
    const { t } = useTranslation();

    return [
        {
            key: '1',
            label: (
                <Row justify="space-between">
                    <Col span={12}>
                        <span>
                            <strong>{t('Meu preenchimento (briefing)')}:</strong>
                        </span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <span>Mostrar mais</span>
                    </Col>
                </Row>
            ),
            children: (
                <div>
                    <span>{pl?.assistantBriefing}</span>
                </div>
            ),
        },
    ];
};

const BriefingCollapse = ({ pl }) => {
    return (
        <Row className="briefing-collapse" justify={'start'}>
            <Col span={24}>
                <Collapse ghost items={getItems()} expandIconPosition={'end'}>
                    {getItems(pl).map((item) => {
                        return (
                            <Collapse.Panel header={item.label} key={item.key}>
                                {item.children}
                            </Collapse.Panel>
                        );
                    })}
                </Collapse>
            </Col>
        </Row>
    );
};

BriefingCollapse.propTypes = {
    pl: PropTypes.object,
};

export default BriefingCollapse;
