const span = {
    "Configurar conta": "Configurar cuenta",
    "Idioma": "Idioma",
    "Sair": "Sal",

    "Pesquisar": "Buscar",
    "Buscar influenciadores": "Buscar influencers",
    "Lista de influciadores": "Lista de influencers",

    // alerta
    "sucesso": "éxito",
    "atenção": "alerta",
    "erro": "error",
    "Limite de listas atingido! Você pode ter no máximo DUAS listas.": "¡Límite de lista alcanzado! Puede tener un máximo de DOS listas.",

    // menu
    "Buscar influenciador": "Buscar influencer",
    "Lista de planejamento": "Lista de planificadores",

    // search
    "Nome ou usuário": "Nombre o nombre de usuario",
    "@nombredeusuario ou nome": "@nombredeusuario o nombre",
    "Selecione as redes que deseja pesquisar": "Seleccione las redes que desea buscar",
    "Selecione um ou mais temas": "Seleccione uno o más temas",
    "Selecione": "Seleccionar",
    "Hashtags mencionados en el pelo influenciador": "Hashtags mencionados por el influencer",
    "Número de seguidores, fãs ou inscritos": "Número de seguidores, fans o suscriptores",
    "Selecione um ou mais locais mencionadas": "Seleccione una o más ubicaciones mencionadas",
    "Airscore (métrica própria)": "Airscore (métrica propia)",
    "Limpar busca": "Borrar búsqueda",
    "Buscar": "Buscar",
    "Relevância do tema selecionadas": "Relevancia del tema seleccionado",
    "Utilice aspas duplas para busca exata do nome ou usuário que deseja encontrar": "Use comillas dobles para la búsqueda exacta del nombre o usuario que desea encontrar",
    "Selecione um ou mais temas que o influenciador abordou em suas postagens. Conseguimos definir a relevância do tema quando selecionamos apenas um tema.": "Seleccione uno o más temas que el influencer abordó en sus publicaciones. Pudimos definir la relevancia del tema cuando seleccionamos solo un tema.",
    "Insira uma ou mais hashtags mencionados en el pelo influenciador en las publicaciones. Não deixe de separar como hashtags por ',' ou ';'": "Inserta uno o más hashtags mencionados por el influencer en tus publicaciones. Asegúrate de separar los hashtags por ',' o ';'",
    "Defina o tamanho de base para os perfis que deseja encontrar.": "Establece el tamaño base para los perfiles que deseas buscar.",
    "Essa porcentagem é a relevância sobre o assunto. Ou seja, o quanto o influenciador falou sobre o assunto em suas publicações.": "Este porcentaje es la relevancia sobre el tema. Es decir, cuánto habló el influencer sobre el tema en su publicaciones. ",
    "A busca é feita através dos locais mencionados na bio e posts do influenciador": "La búsqueda se realiza a través de los lugares mencionados en la biografía y publicaciones del influencer.",
    "O Airscore é uma métrica que avalia a qualidade do engajamento de um perfil nas redes sociais - levando em consideração pontuações que variam entre 0 a 1.000.": "Airscore es una métrica que mide la calidad de la participación de un perfil en las redes sociales - toma en cuenta las puntuaciones que van de 0 a 1.000. ",

    // pl
    "Criar nova lista": "Crear nueva lista",
    "Inserir o nome da lista": "Inserta el nombre de la lista",
    "Você ainda não criou uma lista.": "Aún no has creado una lista.",
    "Editar lista": "Editar lista",
    "Lista duplicadora": "Lista duplicada",
    "Exportar lista": "Exportar lista",
    "Apagar lista": "Eliminar lista",
    "Nome atualizado com sucesso": "Nombre actualizado correctamente",
    "Falha ao atualizar nome": "No se pudo actualizar el nombre",
    "Esta ação não pode ser desfeita! Caso queira, você pode exportar a campanha antes de excluí-la.": "¡Esta acción no se puede deshacer! Si lo desea, puede exportar la campaña antes de eliminarla.",
    "Você tem certeza que deseja deletar a campanha?": "¿Está seguro de que desea eliminar la campaña?",
    "Sim, remover campanha": "Sí, eliminar campaña",
    "Não, manter campanha": "No, mantén la campaña",
    "Lista removida com sucesso": "Lista eliminada correctamente",
    "Falha ao remover a lista": "No se pudo eliminar la lista",
    "Lista duplicada com sucesso": "Lista duplicada correctamente",
    "Falha ao duplicar a lista": "No se pudo duplicar la lista",
    "Ver influenciadores": "Ver influencers",
    "Quantidade total de influenciadores na lista em todas as redes": "Número total de influencers en la lista en todas las redes",
    "Influenciadores na lista": "Influencers en la lista",
    "Comparar desempenho por rede": "Comparar rendimiento por rede",

    // seletc
    "Ordenar por": "Ordenar por",
    "Relevância": "Relevancia",
    "Nome (A-Z)": "Nombre (A-Z)",
    "Nome (Z-A)": "Nombre (A-Z)",
    "Seguidores, fãs ou inscritos (0-9)": "Seguidores, fans o suscriptores (0-9)",
    "Seguidores, fãs ou inscritos (9-0)": "Seguidores, fans o suscriptores (9-0)",
    "Airscore (0-9)": "Airscore (0-9)",
    "Airscore (9-0)": "Airscore (9-0)",

    // lang modal
    "Selecione o idioma desejado": "Seleccione el idioma deseado",
    "Português": "Portugués",
    "Inglês": "Inglés",
    "Espanhol": "Español",

    // comparative table
    "Influenciador": "Influencer",
    "Soma das bases": "Suma de bases",
    "Potencial de alcance": "Alcance potencial",
    "Airscore Médio": "Airscore medio",
    "Soma das interações": "Suma de interacciones",
    "Total de influenciadores na lista": "Total de influencers en la lista",
    "Soma das bases de todos os influenciadores da lista": "Suma de las bases de todos los influencers de la lista",
    "Soma das médias de interação de cada um dos influenciadores da lista": "Suma de los promedios de interacción para cada uno de los influencers de la lista",
    "Média do Airscore do grupo de influencidores": "Promedio Airscore del grupo Influencer",
    "Soma das interações dos influenciadores da lista nos útlimos 90 dias": "Suma de interacciones de influencers en la lista durante los últimos 90 días",
    "Temas": "Temas",
    "Marcas": "Marcas",
    "Minhas listas": "Mis listas",

    "Usuário inválido": "Usuario no válido",
    "Nenhuma marca mencionada pelo influenciador": "Ninguna marca mencionada por el influencer",
    "Nenhuma hashtag mencionadoda pelo influenciador": "Ningún hashtag mencionado por el influencer",
    "Nenhum local mencionado pelo influenciador": "Ninguna ubicación mencionada por el influencer",
    "Nenhum perfil mencionado pelo influenciador": "No hay perfiles mencionados por el influencer",
    "Nenhum tema mencionado pelo influenciador": "Ningún tema mencionado por el influencer",

    "Crie uma lista": "Buscar o crear una lista",
    "Selecionar rede": "Seleccionar red",

    "Acessar Rede Social": "Acceder a la red social",
    "Ver perfil": "Ver perfil",

    // influencer card
    "A bio do influeciador não possui texto": "La biografía del influencer no tiene texto",
    "Ver biografía": "Ver biografía",
    "Seguidores": "Seguidores",
    "Quantidade de seguidores em sua rede social e a porcentagem representa a variação de crescimento": "Número de seguidores en su red social y el porcentaje representa la variación de crecimiento",
    "Airscore": "Airscore",
    "Nota que representa a qualidade do engajamento, que varia de 0 a 1000. Un porcentajem representa a variação de crescimento.": "Tenga en cuenta que representa la calidad del compromiso, que va de 0 a 1000. El porcentaje representa la variación del crecimiento.",
    "Publicações": "Publicaciones",
    "Quantidade de publicações realizadas pelo influenciador nos últimos 90 dias.": "Número de publicaciones realizadas por el influencer en los últimos 90 días.",
    "Top 3 marcas": "Top 3 marcas",
    "Top 3 marcas citadas pelo influenciador": "Top 3 marcas citadas por el influencer",
    "Los 3 mejores hashtags": "Los 3 mejores hashtags",
    "Top 3 hashtags citadas pelo influenciador.": "Top 3 hashtags citados por el influencer.",
    "Top 3 Locais": "Top 3 Locais",
    "Top 3 locais vistas pelo influenciador.": "Top 3 lugares mencionados por el influencer.",
    "Los 3 mejores perfiles": "Los 3 mejores perfiles",
    "Los 3 mejores perfiles mencionados por el influenciador.": "Los 3 mejores perfiles mencionados por el influencer.",
    "Assuntos abordados pelo influenciador": "Temas de influenciadores",
    "Top 3 temas abordados pelo influenciador ea relavância sobre o assunto. A porcentagem representa o quanto o influenciador falou sobre o tema em suas publicações no período.": "Top 3 temas cubiertos por el influencer y la relevancia sobre el tema. cuánto habló el influencer sobre el tema en sus publicaciones de la época. ",
    "Temas detalhados": "Temas detallados",
    "Top 3 temas abordados pelo influenciador ecom o detalhamento dos subtemas abordados de cada assunto e a porcentagem de relevância.": "Top 3 temas tratados por el influencer y con el detalle de los subtemas cubiertos para cada tema y el porcentaje de relevancia.",
}

export default span;