import { configureStore } from "@reduxjs/toolkit"
// 
import { appSlice } from "./app/slice"
import { alertSlice } from "./common/components/Alert/slice"
import { menuSlice } from "./common/components/SideMenu/slice"
// 
import { plannerListSlice } from "./features/private/PlannerList/slice"
import { influencerDetailsSlice } from "./features/private/InfluencerCard/slice"
import { comparativeTableSlice } from "./features/private/ComparativeTable/slice"
import { addInfluencerSlice } from "./features/private/AddInfluencer/slice"
import { homeSlice } from "./features/private/Home/slice"
import { plannerListDetailsSlice } from "./features/private/PlannerListCard/slice"
import { notificationsSlice } from "./features/private/Notifications/slice"
import { modalPlannerListsSlice } from "./common/components/ButtonModalLists/slice"
import { solicitationSlice } from "./features/private/Solicitation/slice"
import { addBrandSlice } from "./features/private/AddBrand/slice"
import { addCategorySlice } from "./features/private/AddCategory/slice"
import { influencerDetailsPDFSlice } from "./features/private/InfluencerCardPDF/slice"
import { curationSlice } from "./features/private/Aira/slice"
import { airaTastingSlice } from "./features/public/AiraCuration/slice"
import { airaCurationResultSlice } from "./features/public/AiraCurationResult/slice"
import { influencerDetailsPublicSlice } from "./features/public/InfluencerCard/slice"

export const configStore = () => {
    return configureStore({ 
        reducer: {
            app: appSlice.reducer,
            menu: menuSlice.reducer,
            alert: alertSlice.reducer,
            // private pages
            home: homeSlice.reducer,
            plannerList: plannerListSlice.reducer,
            modalPlannerLists: modalPlannerListsSlice.reducer,
            plannerListDetails: plannerListDetailsSlice.reducer,
            comparativeTable: comparativeTableSlice.reducer,
            influencerDetails: influencerDetailsSlice.reducer,
            influencerDetailsPDF: influencerDetailsPDFSlice.reducer,
            addInfluencer: addInfluencerSlice.reducer,
            notifications: notificationsSlice.reducer,
            solicitation: solicitationSlice.reducer,
            addBrand: addBrandSlice.reducer,
            addCategory: addCategorySlice.reducer,
            curation: curationSlice.reducer,
            // public pages
            airaTasting: airaTastingSlice.reducer,
            airaCurationResult: airaCurationResultSlice.reducer,
            influencerDetailsPublic: influencerDetailsPublicSlice.reducer,
        },
        
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
        }),
    })
}