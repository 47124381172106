import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import { Tooltip } from "antd"
// 
import Input from "../../../../../../../common/components/Input"
import Button from "../../../../../../../common/components/Button"
import CheckBox from "../../../../../../../common/components/CheckBox"
// 
import { sortBySimilarity } from "../../../../../../../utils"
import "./index.scss"

const MultiSelectCategories = ({
    onChange,
    selecteds,
    placeholder,
    categories,
    isOpenPopover
}) => {
    const { t } = useTranslation()
    // 
    let delayTimer
    // 
    const [inputValue, updateInputValue] = useState("")
    const [filteredOptions, updateFilteredOptions] = useState([])

    const verifyValue = (key, parentValue, children) => {
        if (selecteds[key]?.rule) return selecteds[key].rule

        if (parentValue) return selecteds[parentValue]?.rule

        if (children) return children.every(child => selecteds[child["key"]]?.rule)

        return false
    }

    const updSelecteds = (key, parentValue, children) => {
        let newSelecteds = selecteds

        const keyValue =
            (!selecteds[key]?.rule ? {...selecteds[key], rule: "include"}
                : selecteds[key]?.rule === "include" ? {...selecteds[key], rule: "exclude"} : null
            )

        if (parentValue) {
            if (selecteds[parentValue] && !keyValue) {
                const oldValue = selecteds[parentValue]
                const parentCategory = categories.find(c => c["key"] === parentValue)

                if (parentCategory) {
                    parentCategory["subCategories"].map(subCat => {
                        let value = oldValue
                        if(selecteds[subCat["key"]]?.rule && selecteds[subCat["key"]]?.rule !== oldValue?.rule) {
                            value = selecteds[subCat["key"]]
                        } 

                        if (key !== subCat["key"]) {
                            newSelecteds = {
                                ...newSelecteds,
                                ...{ [subCat["key"]]: value}
                            }
                        } else {
                            newSelecteds = {
                                ...newSelecteds,
                                ...{ [key]: keyValue }
                            }
                        }
                    })
                }

                newSelecteds = {
                    ...newSelecteds,
                    ...{ [parentValue]: null }
                }
            } else {
                const categoryValue =
                    (!selecteds[parentValue]?.rule ? {...selecteds[key], rule: "include"}
                        : selecteds[parentValue]?.rule === "include" ? {...selecteds[key], rule: "exclude"} : null
                    )

                newSelecteds = {
                    ...newSelecteds,
                    ...{ [key]: !selecteds[key]?.rule ? categoryValue : keyValue },
                }
            }
        } else if (children) {
            children.forEach((child) => {
                newSelecteds = {
                    ...newSelecteds,
                    ...{ [child["key"]]: null }
                }
            })

            newSelecteds = {
                ...newSelecteds,
                ...{ [key]: keyValue },
            }
        }

        onChange(newSelecteds)
    }

    const filterCategories = (text) => {
        if (text && text.length < 1) {
            updateFilteredOptions(categories)
        } else {
            let newList = []

            categories.forEach(cat => {
                const categoryHasText = (cat["name"] || "").toLowerCase().includes(text.toLowerCase())

                if (categoryHasText) {
                    newList.push(cat)
                } else {
                    if (cat["subCategories"]) {
                        for (let i = 0; i < cat["subCategories"].length; i++) {
                            const subCat = cat["subCategories"][i];

                            if (!newList.some(item => item.id !== cat.id) && (subCat["name"] || "").toLowerCase().includes(text.toLowerCase())) {
                                newList.push(cat)

                                i = (cat["subCategories"].length - 1)
                            }
                        }
                    }
                }
            })

            newList = sortBySimilarity(text, newList)
            
            updateFilteredOptions(newList)
        }
    }

    useEffect(() => {
        if(!isOpenPopover) updateInputValue("")
    }, [isOpenPopover])

    useEffect(() => {
        updateFilteredOptions(categories)
    }, [categories])

    return <div className="multi-select-categories">
        <Input
            value={inputValue}
            placeholder={placeholder}
            prefix={<i className="icon icon-search" />}
            onChange={(e) => {
                const text = e.target.value

                updateInputValue(text)

                clearTimeout(delayTimer)

                delayTimer = setTimeout(() => {
                    filterCategories(e.target.value)
                }, 500);
            }}
        />

        <div className="options">
            {filteredOptions.length < 1 &&
                <p className="text-empty">
                    {t("Sem resultados")}
                </p>
            }

            {filteredOptions.map((cat, i) => {
                return <>
                    <CheckBox
                        key={`cat-${cat["key"]}-${i}`}
                        text={cat["name"]}
                        className="category"
                        checked={verifyValue(cat["key"], null, cat["subCategories"]) === "include" || null}
                        indeterminate={verifyValue(cat["key"], null, cat["subCategories"]) === "exclude" || null}
                        onChange={() => {
                            updSelecteds(cat["key"], null, cat["subCategories"])
                        }}
                    />

                    {cat["subCategories"] && cat["subCategories"].map(subCat => {
                        return <CheckBox
                            key={`subcat-${subCat["key"]}-${i}`}
                            text={<Tooltip title={subCat["name"]}>
                                {subCat["name"]}
                            </Tooltip>}
                            className="subcategory"
                            checked={verifyValue(subCat["key"], cat["key"], null) === "include" || null}
                            indeterminate={verifyValue(subCat["key"], cat["key"], null) === "exclude" || null}
                            onChange={() => {
                                updSelecteds(subCat["key"], cat["key"], null)
                            }}
                        />
                    })}
                </>
            })}
        </div>

        <Button
            className="outline-light btn-clear"
            onClick={() => {
                updateInputValue("")
            
                onChange({})
            }}
        >
            {t("Limpar filtros")}
        </Button>
    </div>
}

MultiSelectCategories.defaultProps = {
    options: [],
    categories: [],
    selecteds: {},
    hasSearch: true,
    isDisabled: false,
    isOpenPopover: false,
    onChange: () => { },
    onNewSelecteds: () => { },
}

MultiSelectCategories.propTypes = {
    children: PropTypes.element,
    onChange: PropTypes.func,
    hasSearch: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isOpenPopover: PropTypes.bool,
    categories: PropTypes.any,
    placeholder: PropTypes.string,
    newSelecteds: PropTypes.array,
    options: PropTypes.arrayOf(PropTypes.object),
    selecteds: PropTypes.object,
}

export default MultiSelectCategories