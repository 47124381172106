import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import facebookImg from '../../../common/assets/icons/facebook.svg';
import instagramImg from '../../../common/assets/icons/instagram.svg';
import tiktokImg from '../../../common/assets/icons/tiktok.svg';
import twitchImg from '../../../common/assets/icons/twitch.svg';
import xImg from '../../../common/assets/icons/x-twitter.svg';
import youtubeImg from '../../../common/assets/icons/youtube.svg';
import Avatar from '../../../common/components/Avatar';
import ButtonModalLists from '../../../common/components/ButtonModalLists';
import { PARAMS } from '../../../constants/params';
import ModalInfluencerSummary from '../../../features/private/InfluencerCard/components/ModalInfluencerSummary';
import { fetch, updateClearInfluencer } from '../../../features/private/InfluencerCard/slice';
import { humanize } from '../../../utils/formatters';
import { LevelGauge } from '../LevelGauge';

import './index.scss';

const InfluencerRow = ({ onSelect, influencer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSummary, setShowSummary] = useState(false);

  const handleOpenSummary = () => {
    window.clarity('set', 'click', 'username_in_search_result');

    dispatch(fetch({ id: influencer.id }));
    setShowSummary(true);
  };

  const networkImages = {
    instagram: instagramImg,
    facebook: facebookImg,
    tiktok: tiktokImg,
    twitter: xImg,
    youtube: youtubeImg,
    twitch: twitchImg,
  };

  return (
    <>
      {showSummary && (
        <ModalInfluencerSummary
          isVisible={showSummary}
          influencer={influencer}
          onClose={() => setShowSummary(false)}
        />
      )}

      <tr
        className={`influencer-row ${
          influencer.foundOn && influencer.foundOn.length > 0 ? 'with-footer' : ''
        }`}
      >
        <td className={`td-influencer ${influencer.acceleratedGrowth ? 'rapid-growth' : ''}`}>
          <div className="div-flex">
            <Avatar
              size={56}
              src={influencer.avatar}
              onClick={() => {
                dispatch(updateClearInfluencer());
                onSelect?.();
              }}
            />

            {influencer.acceleratedGrowth && <i className="icon icon-rocket" />}

            <div className="name-username">
              <Tooltip title={influencer.name}>
                <span className="name">
                  {influencer.name}

                  {influencer.verified && <i className="icon icon-certificate" />}
                </span>
              </Tooltip>

              <div className="username hover" onClick={handleOpenSummary}>
                <span>@{influencer.username}</span>

                <i className="icon icon-external-link" />
              </div>
            </div>
          </div>
        </td>

        <td className={`td-network color-${influencer.network}`}>
          <div>
            <img src={networkImages[influencer.network]} alt={`${influencer.network} logo`} />
          </div>
        </td>

        <td className="td-followers">{humanize(influencer.followers)}</td>

        <td className="td-airscore">
          <div>
            {influencer.airscore}{' '}
            <LevelGauge value={influencer.airscore} range={PARAMS.LEVEL_GAUGE.AIR_SCORE} />
          </div>
        </td>

        <td className="td-engagement">
          <div>
            {influencer.engagementRate.replace('.', ',')}%{' '}
            {influencer.network !== 'twitch' && influencer.network !== 'twitter' && (
              <LevelGauge
                value={influencer.engagementRate}
                range={PARAMS.LEVEL_GAUGE.ENGAGEMENT[influencer.network.toUpperCase()]}
              />
            )}
          </div>
        </td>

        <td className="td-themes">
          <div className="div-flex">
            {influencer.themes &&
              influencer.themes.map((theme) => {
                return (
                  theme && (
                    <Tooltip
                      key={theme.name}
                      title={
                        <div className="tooltip-themes">
                          <p className="theme-name">{theme.name}</p>

                          <p className="theme-score">
                            Airscore <b>{theme.score}</b>
                          </p>

                          <p className="theme-percent">
                            Relevância <b>{theme.percent}%</b>
                          </p>
                        </div>
                      }
                    >
                      <div style={{ position: 'relative', margin: '0 2px' }}>
                        <div
                          style={{
                            backgroundColor: theme.color,
                          }}
                          className="circle-theme"
                        ></div>

                        <i
                          style={{
                            color: theme.color,
                          }}
                          className={`icon icon-${theme.icon}`}
                        />
                      </div>
                    </Tooltip>
                  )
                );
              })}
          </div>
        </td>

        <td className="td-my-lists">
          <ButtonModalLists influencerId={influencer.id} influencerNetwork={influencer.network}>
            <Tooltip title={t('Minhas Listas')}>
              <i className="icon icon-plus" />
            </Tooltip>
          </ButtonModalLists>
        </td>

        {influencer.foundOn && influencer.foundOn.length > 0 && (
          <td className="td-footer">
            <b>{t('O texto que você buscou foi encontrado em')}:</b>

            {influencer.foundOn.map(
              (text, i) =>
                `${t(text)}${
                  influencer.foundOn.length > 0 && i < influencer.foundOn.length - 1
                    ? i === influencer.foundOn.length - 2
                      ? t(' e ')
                      : ', '
                    : ''
                }`,
            )}
          </td>
        )}
      </tr>
    </>
  );
};

InfluencerRow.defaultValue = {
  influencer: {},
};

InfluencerRow.propTypes = {
  onSelect: PropTypes.func,
  influencer: PropTypes.object,
};

export default InfluencerRow;
