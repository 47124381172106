import { Form, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../common/components/Button';
import Header from '../../../common/components/Header';
import StateStatus from '../../../utils/status';
import { updateTabTitle } from '../../../utils/updateTabTitle';
import BrandForm from './components/BrandForm';
import CompetitorsForm from './components/CompetitorsForm';
import StrandsForm from './components/StrandsForm';

import { selectDatasetSlice, sendBrand } from './slice';

import './index.scss';

const { useForm } = Form;

const AddBrand = () => {
    const [data, updateData] = useState({});
    const [current, updateCurrent] = useState(0);
    const [steps, updateSteps] = useState([]);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const slice = useSelector(selectDatasetSlice);
    const [form] = useForm();

    updateTabTitle('Adicionar marca - AirSearch');

    const status = slice.status.sendBrand;

    const items = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon }));

    const onFinish = (values) => {
        if (current < 2) {
            if (form.getFieldError()) {
                updateCurrent(current + 1);

                if (values['hashtags'])
                    values['hashtags'] = values['hashtags'].filter((item) => item);

                if (values['products']) {
                    const products = values['products'].filter((item) => item);
                    const subBrands = values['sub_brands'].filter((item) => item);

                    if (products.length > 0) {
                        values['sub_brands_and_products'] = products;
                    }

                    if (subBrands.length > 0) {
                        values['sub_brands_and_products'] = [...subBrands, ...products];
                    }

                    delete values['products'];
                    delete values['sub_brands'];
                }

                updateData({ ...data, ...values });
            }
        } else {
            dispatch(sendBrand({ ...data, ...values }));
        }
    };

    useEffect(() => {
        if (status === StateStatus.succeeded) {
            form.resetFields();

            updateCurrent(0);
        }
    }, [status]);

    useEffect(() => {
        updateSteps([
            {
                title: t('Marca'),
                icon: current > 0 ? null : <i className="icon icon-brand" />,
                content: <BrandForm />,
            },
            {
                title: t('Vertentes'),
                icon: current > 1 ? null : <i className="icon icon-planner-list" />,
                content: <StrandsForm />,
            },
            {
                title: t('Concorrentes'),
                icon: current > 2 ? null : <i className="icon icon-posts" />,
                content: <CompetitorsForm />,
            },
        ]);
    }, [current]);

    return (
        <div className="add-brand-page background-page">
            <div className="add-brand-content scroll-content">
                <Header
                    title={t('Solicitação para inclusão de marca')}
                    className="add-brand-header"
                ></Header>

                <div className="add-brand-forms">
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <p className="title-form first">{t('Adicione uma marca')}:</p>

                        <Steps items={items} current={current} labelPlacement="vertical" />

                        <div className="current-form">
                            {steps[current] && steps[current].content}
                        </div>

                        <div className="buttons">
                            {current > 0 && (
                                <Button
                                    className="back-btn outline-dark"
                                    onClick={() => updateCurrent(current - 1)}
                                    isDisabled={status === StateStatus.loading}
                                >
                                    {t(current > 1 ? 'Voltar para vertentes' : 'Voltar para marca')}
                                </Button>
                            )}

                            <Button
                                className="submit-btn"
                                onClick={() => form.submit()}
                                isLoading={status === StateStatus.loading}
                            >
                                {t(current < 2 ? 'Continuar' : 'Enviar')}
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AddBrand;
