import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
import { selectAiraCurationResult, updateStatus } from "./slice"
import { selectAiraTastingSlice } from "../AiraCuration/slice"
import StateStatus from "../../../utils/status"
//
import Modal from "../../../common/components/Modal"
import Button from "../../../common/components/Button"
import Table from "./components/Table"
import SkeletonComparativeTable from "./components/SkeletonComparativeTable"
import feedback from "../../../common/components/Feedback"
//
import aira from "../../../common/assets/images/aira.png"
// 
import "./index.scss"

const AiraCurationResult = () => {
    const { t } = useTranslation()
    //
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //
    const slice = useSelector(selectAiraCurationResult)
    const airaTastingSlice = useSelector(selectAiraTastingSlice)
    //
    const status = slice.status
    const profiles = airaTastingSlice.profiles
    //
    const [showFirstOpeningModal, updateShowFirstOpeningModal] = useState(false)

    useEffect(() => {
        if(profiles && profiles.length > 0) { 
            dispatch(updateStatus({ name: "fetch", status: StateStatus.succeeded }))
        } else {
            if(airaTastingSlice.plId) feedback.error(t("A lista gerada não retornou nenhum influenciador. Tente novamente"))
            
            navigate("/aira-demonstracao/briefing")
        }
    }, [profiles])

    return (
        <div className="aira-curation-result-page pattern-page">
            {status.fetch !== StateStatus.failed && 
                <h1>{t("Top 5 perfis para sua campanha")}</h1>
            }

            {status.fetch === StateStatus.succeeded && (
                <>
                    {showFirstOpeningModal && (
                        <Modal
                            title={t("Sucesso")}
                            className="aira-tasting-modal"
                            isVisible={showFirstOpeningModal}
                            onCancel={() => {
                                updateShowFirstOpeningModal(false)
                            }}
                            onClose={() => {
                                updateShowFirstOpeningModal(false)
                            }}
                        >
                            <img src={aira} alt="mulher  do cabelo preto sorrindo" />

                            <p>
                                {t("Aqui está o planejamento da nossa primeira curadoria")}
                                <br />
                                <b>{t("Boa campanha")}</b>
                            </p>

                            <Button
                                className="outline-light btn-next"
                                onClick={() => {
                                    updateShowFirstOpeningModal(false)
                                }}
                            >
                                {t("Concluir")}
                            </Button>
                        </Modal>
                    )}

                    {profiles && profiles.length > 0 &&
                        <div className="box box-aira-observation">
                            <img
                                className="aira"
                                src={aira}
                                alt={t("mulher  do cabelo preto sorrindo")}
                            />

                            <div className="observation">
                                <p className="chat-name">{t("Aira")}</p>
                                
                                <span>{t("Aqui estão os 5 melhores perfis que encontrei para sua campanha.")}</span>
                                <br/>
                                <span>{t("Nessa versão gratuita você pode receber")} <b>{t("dados aprofundados")}</b> {t("pelo seu e-mail")}.</span>
                            </div>

                            <Button
                                onClick={() => navigate(`/aira-demonstracao/briefing/${airaTastingSlice.plId}/form`)}
                            >
                                {t("Receber dados")}
                            </Button>
                        </div>
                    }

                    <Table profiles={profiles} />
                </>
            )}

            {status.fetch === StateStatus.loading && <SkeletonComparativeTable />}

            {status.fetch === StateStatus.failed && <p>Erro</p>}
        </div>
    )
}

export default AiraCurationResult
