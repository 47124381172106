import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import InfluencerRow from '../../../../../common/components/InfluencerRow';
import StateStatus from '../../../../../utils/status';
import { selectHomeSlice, updateResults } from '../../slice';

import './index.scss';

const InfluencersTable = ({ onSelect }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const homeSlice = useSelector(selectHomeSlice);
  const [sort, setSort] = useState();
  const [sorts, setSorts] = useState([]);
  const [boolBigger, setBoolBigger] = useState(false);
  const [arrowLocation, setArrowLocation] = useState(-1);

  const page = homeSlice.search.page;
  const status = homeSlice.status;
  const influencers = homeSlice.results;

  const columns = [
    { key: 'name', title: t('Dados da conta'), span: 2 },
    { key: 'network', title: t('Redes Sociais'), span: 2 },
    { key: 'followers', title: t('Seguidores'), span: 2 },
    { key: 'airscore', title: t('Airscore'), span: 2 },
    { key: 'engagementRate', title: t('Engajamento'), span: 2 },
    { key: 'themes', title: t('Categorias'), span: 6 },
    { key: 'planner-list', title: t('Lista de Planejamento'), span: 2 },
  ];

  const orderBy = (obj, index, lastSorts) => {
    if (columns[index].key !== 'planner-list' && columns[index].key !== 'themes') {
      setArrowLocation(index);

      if (!(lastSorts || sorts).includes(obj)) {
        const newOrder = [...influencers].sort((a, b) => {
          if (obj === 'name') {
            if (a[obj].toLowerCase() > b[obj].toLowerCase()) return -1;
            if (a[obj].toLowerCase() < b[obj].toLowerCase()) return 1;
            return 0;
          } else if (obj === 'engagementRate') {
            if ((parseFloat(a[obj]) || 0) > (parseFloat(b[obj]) || 0)) return -1;
            if ((parseFloat(a[obj]) || 0) < (parseFloat(b[obj]) || 0)) return 1;
            return 0;
          } else {
            if ((a[obj] || 0) > (b[obj] || 0)) return -1;
            if ((a[obj] || 0) < (b[obj] || 0)) return 1;
            return 0;
          }
        });
        setBoolBigger(true);
        dispatch(updateResults(newOrder));

        setSorts((oldArray) => [...oldArray, obj]);
      } else {
        const newOrder = [...influencers].sort((a, b) => {
          if (obj === 'name') {
            if (a[obj].toLowerCase() < b[obj].toLowerCase()) return -1;
            if (a[obj].toLowerCase() > b[obj].toLowerCase()) return 1;
            return 0;
          } else if (obj === 'engagementRate') {
            if ((parseFloat(a[obj]) || 0) < (parseFloat(b[obj]) || 0)) return -1;
            if ((parseFloat(a[obj]) || 0) > (parseFloat(b[obj]) || 0)) return 1;
            return 0;
          } else {
            if ((a[obj] || 0) < (b[obj] || 0)) return -1;
            if ((a[obj] || 0) > (b[obj] || 0)) return 1;
            return 0;
          }
        });

        setBoolBigger(false);
        dispatch(updateResults(newOrder));

        setSorts((lastSorts || sorts).filter((item) => item !== obj));
      }
    }

    setSort(obj);
  };

  useEffect(() => {
    if (status.fetch === StateStatus.succeeded && sort) {
      orderBy(sort, arrowLocation, sorts.includes(sort) ? [] : [sort]);
    }
  }, [page, status]);

  return (
    <div className="influencer-table-container">
      <div className="container-table">
        <table>
          <thead>
            <tr>
              {columns.map((item, key) => {
                return (
                  <th
                    key={key}
                    className={`${item.key} ${
                      item.key === 'planner-list' || item.key === 'themes' ? '' : 'hover'
                    }`}
                    rowSpan={item.span}
                    onClick={() => orderBy(item.key, key, item.withTotal)}
                  >
                    {item.title}

                    {key === arrowLocation && (
                      <i className={`icon icon-down ${boolBigger ? 'bigger' : 'smaller'}`} />
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {influencers.map((influencer, key) => {
              return (
                <InfluencerRow
                  key={key}
                  influencer={influencer}
                  onSelect={() => onSelect(influencer, key)}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

InfluencersTable.propTypes = {
  onSelect: PropTypes.func,
};

export default InfluencersTable;
