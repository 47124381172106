class CategoriesModel {
    list
    subCategories

    fromJson(data) {
        // this.places = Object.entries(data.tags_data["2"]["names"]).map((place) => ({ key: place[1], value: place[0] })).sort((a, b) => a.key.localeCompare(b.key))
        // this.dataset = data.tags_data["1"]["names"] || [] // brands

        this.subCategories = data["sub_categories"] || []

        this.list = Object.entries(data["categories"]).map((option) => {
            const obj = option[1]
            const keyTags = obj["sub_categories"]

            let tags = []
            
            if(keyTags && keyTags.length > 0) {
                tags = keyTags.map(tag => ({ 
                    key: tag, 
                    slug: this.subCategories[tag]["slug"] ,
                    name: this.subCategories[tag]["labels"]["1"] 
                })).sort((a, b) => a.name.localeCompare(b.name))
            }

            return {
                key: option[0],
                name: obj["labels"]["1"],
                icon: obj["icon"],
                color: obj["color"],
                subCategories: tags,
            }
        }).sort((a, b) => a.name.localeCompare(b.name))
        
        return this
    }
}

export default CategoriesModel