import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
// 
import { createBrand } from "./service"
// 
import i18n from "../../../languages/i18n"
import StateStatus from "../../../utils/status"
import { changeObjAlert, showAlert } from "../../../common/components/Alert/slice"

const initialState = {
    status: {
        sendBrand: StateStatus.idle,
    },
    errMsg: null
}

export const sendBrand = createAsyncThunk("add-brand", async (data, thunkAPI) => {
    try {
        const result = await createBrand(data)
        
        thunkAPI.dispatch(changeObjAlert({
            show: true,
            type: "success",
            title: `${i18n.t("Solicitação enviada")}!`,
            text: i18n.t("Nossa equipe avaliará sua solicitação e em breve você receberá uma notificação com a resposta."),
            cancelText: i18n.t("Fechar"),
            onCancel: () => {
                thunkAPI.dispatch(showAlert(false))
            },
        }))

        return result.data
        
    } catch (err) {
        thunkAPI.dispatch(changeObjAlert({
            show: true,
            type: "error",
            title: `${i18n.t("Erro")}!`,
            text: i18n.t("Erro ao enviar marca. Tente novamente mais tarde."),
            cancelText: i18n.t("Fechar"),
            onCancel: () => {
                thunkAPI.dispatch(showAlert(false))
            },
        }))

        throw err
    }
})

export const addBrandSlice = createSlice({
    name: "addBrand",
    initialState,
    extraReducers: {
        [sendBrand.pending]: (state) => {
            state.status.sendBrand = StateStatus.loading
        },
        [sendBrand.fulfilled]: (state) => {
            state.status.sendBrand = StateStatus.succeeded      
        },
        [sendBrand.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.sendBrand = StateStatus.failed
        },
    }
})

export const selectDatasetSlice = (state) => state.addBrand