import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import Button from "../../../../../common/components/Button"
// 
import mensureImg from "../../../../../common/assets/images/mensure.png"
import popularImg from "../../../../../common/assets/images/popular.png"
import planningImg from "../../../../../common/assets/images/planning.png"
// 
import "./index.scss"

const SliderBenefits = ({
    onBack,
    onClose,
}) => {
    const { t } = useTranslation()
    // 
    const [selectDot, updateSelectDot] = useState(0)
    // 
    const sliderOnboarding = [
        { title: t("Top 20 influenciadores"), img: popularImg, alt: t("dois homens e duas mulheres mostrando publicações de uma mulher em sua rede social"), text: t("Para começar encontrei o Top 20 influenciadores que mais combinam com a nossa curadoria.") },
        { title: t("Mensure"), img: mensureImg, alt: t("dois homens e uma mulher trabalhando em um escritório"), text: t("Também organizei e apresentarei de forma simples o potencial da nossa lista de planejamento.") },
        { title: t("Planeje"), img: planningImg, alt: t("um homem e uma mulher simulando a criação de uma ideia simbolizada por uma lampada gigante feita de quebra-cabeça"), text: t("Agora é só cruzar os dados e tirar os insights para um planejamento de sucesso. Boa campanha!") },
    ]

    useEffect(() => {
        const btnNext = document.querySelector(".aira-modal .btn-next")
        const slider = document.querySelector(".slider-benefits")
        
        if(btnNext) btnNext.style.display = selectDot === 2 ? "none" : "block"
        if(slider) slider.style.transform = `translateX(-${(selectDot) * 100}%)`
    }, [selectDot])
    
    return <div
        className="container-slider-benefits"
    >
        <div className="slider-benefits">
            {sliderOnboarding.map((item) => {
                return <div className="benefit-item" key={item.alt}>
                    <h2>{item.title}</h2>

                    <img src={item.img} alt={item.alt} />

                    <p>{item.text}</p>
                </div>
            })}
        </div>

        <div className="dots">
            {sliderOnboarding.map((_, i) => {
                return <div 
                    key={i}
                    className={`dot ${i === selectDot ? "active" : ""}`} 
                    onClick={() => updateSelectDot(i)}
                />
            })}
        </div>

        <div className="slider-actions">
            <Button 
                className="outline-dark"
                onClick={() => {
                    if(selectDot === 0) onBack()
                    else updateSelectDot(dot => dot - 1)
                }}
            >
                Voltar
            </Button>
            
            <Button
                onClick={() => {
                    if(selectDot === 2) onClose()
                    else updateSelectDot(dot => dot + 1)
                }}
            >
                {t(selectDot === 2 ? "Ver curadoria" : "Avançar")}
            </Button>
        </div>
    </div>
}

SliderBenefits.propTypes = {
    onBack: PropTypes.func,
    onClose: PropTypes.func,
}

export default SliderBenefits