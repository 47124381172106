import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
//
import { buildValue } from '../../../../utils/formatters';
// 
import './index.scss';
import '../Charts.scss';

const DoubleBarChart = ({ name, data, total, labels, formatName, fixedTooltip, hasAnimation }) => {
    const { t } = useTranslation();
    //
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);
    //
    const [biggestValue, setBiggestValue] = useState(0);

    useEffect(() => {
        let biggest = 0
        let newSeries = [];
        let newCategories = [];

        const users = [];

        data.forEach((item) => {
            const percentValue = item.value / 100;

            if (percentValue > biggest) biggest = percentValue;

            users.push({ name: item.label, value: item.total || total });
            users.push({ name: item.label, value: percentValue });

            if (labels) {
                if (name == 'likes' && Object.keys(labels).length > 5) {
                    const cat = labels[item.subCategory].label;
                    const subCat = labels[item.subCategory].categories[item.label];

                    newCategories.push(`${subCat} ${cat == subCat ? '' : ` (${cat})`}`);
                } else {
                    newCategories.push(t(item.label));
                }
            }
        });

        setBiggestValue(biggest)

        for (let index = 0; index < users.length; index++) {
            const user = users[index];

            let values = [];

            for (let i = 0; i < index; i++) {
                if (i % 2 == 1 && i > 0) {
                    values.push(0);
                }
            }

            values.push(user.value);

            newSeries.push({
                name: user.name,
                color: index % 2 == 0 ? 'rgba(255, 255, 255, 0)' : 'rgba(247, 11, 83)',
                pointPadding: 0,
                data: values,
                selected: true,
            });
        }

        setSeries(newSeries);
        setCategories(newCategories);
    }, [data]);

    const options = {
        chart: {
            type: 'column',
            height: '300px',
        },
        title: {
            text: '',
        },
        yAxis: {
            title: '',
            type: 'number',
            max: formatName === "percent" ? 100 : null,
            labels: {
                formatter: function () {
                    return `${buildValue('humanize', this.value)}${formatName === "percent" ? "%" : ""}`;
                },
            },
        },
        xAxis: {
            categories: categories,
            lineColor: '#ffffff',
            tickLength: 0,
        },
        tooltip: {
            enabled: !fixedTooltip,
            crosshairs: fixedTooltip,
            formatter: function () {
                if (this.point.y == 100 || this.point.y == 0) {
                    return false;
                } else {
                    return `<div class="high-tooltip">
          <b>
            ${buildValue(
                formatName,
                this.series.userOptions.data[this.series.userOptions.data.length - 1],
            )}
          </b>
        </div>`;
                }
            },
        },
        legend: {
            shadow: false,
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0,
                pointWidth: 60,
                borderRadius: 8,
                borderRadiusTopLeft: 50,
                borderRadiusTopRight: 50,
            },
            bar: {
                borderRadiusTopLeft: 50,
                borderRadiusTopRight: 50,
            },
            series: {
                animation: hasAnimation,
                dataLabels: {
                    enabled: true,
                    inside: false,
                    overflow: 'none',
                    crop: true,
                    shape: 'callout',
                    //
                    y: -7,
                    color: '#333',
                    borderWidth: 1,
                    borderRadius: 4,
                    borderColor: '#f70b53',
                    backgroundColor: '#FFF',
                    style: {
                        fontSize: '12px',
                    },
                    formatter: function () {
                        const val =
                            this.series.userOptions.data[
                                this.series.userOptions.data.length - 1
                            ];

                        if (this.point.y == 100 || this.point.y == 0) {
                            return null;
                        } else {
                            return `<div class="high-fixed-tooltip ${
                                val === biggestValue ? 'biggest' : ''
                            }">
                            ${buildValue(formatName, val)}
                          </div>`;
                        }
                    },
                },
            },
        },
        series: series,
    };

    return (
        series && options && (
            <div className="double-bar">
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        )
    );
};

DoubleBarChart.defaultProps = {
    total: 100,
    formatName: 'number',
    fixedTooltip: false,
    hasAnimation: true,
};

DoubleBarChart.propTypes = {
    data: PropTypes.any,
    total: PropTypes.number,
    labels: PropTypes.object,
    formatName: PropTypes.string,
    name: PropTypes.string,
    fixedTooltip: PropTypes.bool,
    hasAnimation: PropTypes.bool,
};

export default DoubleBarChart;
