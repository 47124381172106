import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../../../common/components/Button';

import './index.scss';

const SelectNetworks = ({ selecteds, onChange }) => {
  const { t } = useTranslation();

  const networks = [
    { icon: 'instagram', tooltip: null, network: 'instagram' },
    { icon: 'tiktok', tooltip: null, network: 'tiktok' },
    { icon: 'youtube', tooltip: null, network: 'youtube' },
    { icon: 'twitch', tooltip: null, network: 'twitch' },
  ];

  const update = (network) => {
    if (selecteds.includes(network)) {
      onChange(selecteds.filter((selected) => selected !== network));
    } else {
      onChange([...selecteds, network]);
    }
  };

  return (
    <div className="junction-filter">
      <div className="filter-networks">
        {networks &&
          networks.map(({ icon, tooltip, network }) => (
            <Tooltip key={network} title={tooltip}>
              <div
                className={`option-network option-${
                  selecteds.includes(network) ? 'active' : 'desactive'
                }`}
                onClick={() => update(network)}
              >
                <i className={`nw-icon icon icon-${icon}`} />
              </div>
            </Tooltip>
          ))}

        <Button className="outline-light btn-clear" onClick={() => onChange([])}>
          {t('Limpar filtros')}
        </Button>
      </div>
    </div>
  );
};

SelectNetworks.defaultProps = {
  selecteds: [],
};

SelectNetworks.propTypes = {
  onChange: PropTypes.func,
  selecteds: PropTypes.array,
};

export default SelectNetworks;
