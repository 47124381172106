import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
// 
import { useDispatch, useSelector } from "react-redux"
import { download, remove } from "../../../PlannerList/slice"
import { clearSearchValues } from "../../../Home/slice"
import { selectPlannerListDetailsSlice, share } from "../../slice"
import { selectMenuSlice } from "../../../../../common/components/SideMenu/slice"
import { changeObjAlert, showAlert } from "../../../../../common/components/Alert/slice"
// 
import Button from "../../../../../common/components/Button"
// 
import "./index.scss"

const PlannerListFloatingActions = ({
    onEdit
}) => {
    const { t } = useTranslation()
    // 
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // 
    const menu = useSelector(selectMenuSlice)
    const slice = useSelector(selectPlannerListDetailsSlice)
    // 
    const pl = slice.data
    const status = slice.status
    const collapsed = menu.collapsed
    // 
    const [dataActions, updateShowActions] = useState([])

    const runAction = (name) => {
        if(name === "influencers") {
            dispatch(clearSearchValues())

            navigate(`/list/${pl.id}/${window.btoa(pl.name)}`)
        }
        else if(name === "export") dispatch(download({
            id: pl.id,
            name: pl.name,
        }))
        else if(name === "share") {
            dispatch(share({ 
                id: pl.id, 
                title: pl.name, 
                shared: !pl.isShared,
            }))
        }
        else if(name === "edit") onEdit()
        else if(name === "delete") {
            dispatch(changeObjAlert({
                show: true,
                type: "warning",
                title: t("Atenção"),
                text: t("Você não terá a opção de recuperar a lista de planejamento!"),
                question: t("Tem certeza que deseja excluí-la?"),
                okText: t("Sim, excluir planejamento"),
                cancelText: t("Não, manter planejamento"),
                onCancel: () => dispatch(showAlert(false)),
                onOk: () => {
                    dispatch(remove(pl.id))
                    
                    setTimeout(() => {
                        dispatch(showAlert(false))
                    }, 500);
                },
            }))
        }
    }

    useEffect(() => {
        updateShowActions([
            { icon: "group", name: "influencers", label: t("Ver influenciadores") },
            pl.isOwner && !pl.isFavorite && 
                { 
                    name: "share", 
                    icon: pl.isShared ? "unshare" : "arrow-share", 
                    label: t(pl.isShared ? "Descompartilhar" : "Compartilhar") 
                },
            pl.isOwner && { icon: "pencil", name: "edit", label: t("Editar") },
            { icon: "download", name: "export", label: t("Baixar") },
            pl.isOwner && !pl.isFavorite && { icon: "", name: "divider" },
            pl.isOwner && !pl.isFavorite && { icon: "trash", name: "delete", label: t("Excluir") },
        ].filter(item => item))
    }, [status.share])
    
    return <div className={`pl-box-floating-actions ${!collapsed ? "menu-open" : ""}`}>
        <div className="floating-actions">
            {dataActions.map(({ name, icon, label }) => {
                if(name === "divider") {
                    return <div key="divider" className="popup-divider" />
                } else {
                    return <Button
                        key={name}
                        icon={icon}
                        className="outline-light"
                        onClick={() => runAction(name)} 
                    >
                        {label} 
                    </Button>
                }
            })}
        </div>
    </div>
}

PlannerListFloatingActions.propTypes = {
    onEdit: () => {},
}

PlannerListFloatingActions.propTypes = {
    onEdit: PropTypes.func.isRequired,
}

export default PlannerListFloatingActions