import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
// 
import { useDispatch } from "react-redux"
import { updateNotifications } from "../../slice"
// 
import Button from "../../../../../common/components/Button"
import CheckBox from "../../../../../common/components/CheckBox"
// 
import "./index.scss"

const CardSolicitation = ({ solicitation, selected, onSelect, onClose }) => {
    const { t } = useTranslation()
    // 
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return <div 
        className={`card-solicitation card-solicitation-${solicitation.status}`}
        onClick={() => onSelect(`${solicitation.id}`)}
    >
        <div className="solicitation-content">
            <CheckBox
                checked={selected}
                key={solicitation.id}
            />
            
            {solicitation.status === "created" && <div className="circle-unread" />}    

            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}>
                <p className="solicitation-title">
                    {t(solicitation.title)}
                </p>    

                {solicitation.description && 
                    <p className="solicitation-description">
                        {t(solicitation.description)}
                    </p>
                }    

                <div className="date-button">
                    <p className="solicitation-date">
                        {t("Data de solicitação")}: {solicitation.date}
                    </p>

                    {solicitation.link && 
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                
                                dispatch(updateNotifications({
                                    status: null,
                                    newStatus: "read",
                                    ids: [solicitation.id],
                                }))

                                navigate(solicitation.link.replace("/#/", ""))

                                onClose()
                            }}
                        >
                            {t("Mostrar detalhes")}
                        </Button>
                    }
                </div>
            </div>
        </div>

    </div>
}

CardSolicitation.defaultProps = {
    selected: false,
    onClose: () => {},
}

CardSolicitation.propTypes = {
    selected: PropTypes.bool,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    solicitation: PropTypes.object.isRequired,
}

export default CardSolicitation