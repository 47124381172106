import jwtDecode from "jwt-decode"

class UserModel {
    id
    name
    email
    company
    // 
    places
    brands
    categories
    subCategories
    rawCategories
    // 
    features = []

    fromJson(data) {
        const accessToken = window.localStorage.getItem("access_token")
        const tokenJwt = jwtDecode(accessToken)
        
        this.id = data["uid"]
        this.hash = data["user_hash"]
        this.name = data["name"].split(" ")[0] || "..."
        this.email = data["email"] || "..."
        this.company = data["company"]["name"] || "..."
        
        if(tokenJwt.sub && tokenJwt.sub.features) {
            if(Array.isArray(tokenJwt.sub.features)) {
                this.features = tokenJwt.sub.features
            }
        }
        
        // this.features = [...this.features, "unlimited-search"]

        return this
    }
}

export default UserModel