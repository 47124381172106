import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Avatar from '../../../../../common/components/Avatar';
import Button from '../../../../../common/components/Button';
import ButtonModalLists from '../../../../../common/components/ButtonModalLists';
import {
  modalPLToggle,
  selectPLFavorite,
} from '../../../../../common/components/ButtonModalLists/slice';
import Loading from '../../../../../common/components/Loading';
import Pagination from '../../../../../common/components/Pagination';
import { selectMenuSlice } from '../../../../../common/components/SideMenu/slice';
import StateStatus from '../../../../../utils/status';
import { clearResults, fetch, selectHomeSlice } from '../../../Home/slice';
import { selectInfluencerDetailsSlice, updateFavorite } from '../../slice';
import ModalRemarksAboutInfluencer from '../ModalRemarksAboutInfluencer';

import './index.scss';

const InfluencerFloatingActions = ({ onSelect, influencerId, influencerNetwork }) => {
  const { t } = useTranslation();

  const params = useParams();
  const id = influencerId || params.id;
  const dispatch = useDispatch();
  const menu = useSelector(selectMenuSlice);
  const homeSlice = useSelector(selectHomeSlice);
  const favoriteList = useSelector(selectPLFavorite);
  const modalPlSlice = useSelector((state) => state.modalPlannerLists);
  const influencerSlice = useSelector(selectInfluencerDetailsSlice);
  const [showComments, updateShowComments] = useState(false);
  const [showInfluencerOptions, updateShowInfluencerOptions] = useState(false);

  const collapsed = menu.collapsed;
  const data = homeSlice.search;
  const influencers = homeSlice.results;
  const statusFetch = homeSlice.status.fetch;
  const favorite = influencerSlice.isFavorite;
  const influencer = influencerSlice.influencer;
  const totalNotes = influencerSlice.totalNotes;
  const statusExport = influencerSlice.status.fetchInfluencerCard;
  const statusFavorite = modalPlSlice.status.modalPLToggle;

  const dataActions = [
    { icon: 'notepad', name: 'comments', label: t('Anotações sobre o influenciador') },
    statusFetch !== StateStatus.idle && {
      icon: 'group',
      name: 'open-influencers-modal',
      label: t('Mais perfis'),
    },
    { icon: 'favorite', name: 'favorite', label: t('Favoritar') },
    { icon: 'planner-list-add-influencer', name: 'planner-list', label: t('Adicionar a lista') },
    { icon: 'download', name: 'export', label: t('Baixar') },
  ].filter((item) => item);

  const runAction = (name) => {
    if (name === 'open-influencers-modal') {
      if (!showInfluencerOptions && (!influencers || influencers.length < 1)) {
        dispatch(clearResults());
        dispatch(fetch({ page: 0 }));
      }

      updateShowInfluencerOptions(!showInfluencerOptions);
    } else if (name === 'export' && statusExport !== StateStatus.loading) {
      window.open(`/#/card/${influencer.id}/pdf`);
    } else if (name === 'favorite') {
      dispatch(
        modalPLToggle({
          include: favorite,
          influencerId: id,
          id: 'favorite',
        }),
      ).then((res) => {
        if (!res.payload.include) {
          dispatch(updateFavorite(!favorite));
        }
      });
    }
  };

  useEffect(() => {
    if (favoriteList && favoriteList.influencerIds) {
      dispatch(updateFavorite(favoriteList.influencerIds.includes(id)));
    }
  }, [favoriteList]);

  return (
    <>
      <ModalRemarksAboutInfluencer
        influencer={influencer}
        isVisible={showComments}
        onClose={() => updateShowComments(false)}
      />

      <div className="container-floating-actions">
        {showInfluencerOptions && (
          <div
            role="button"
            className="bg-popup-influencers"
            onClick={() => updateShowInfluencerOptions(false)}
          />
        )}

        <div className={`box-floating-actions ${!collapsed ? 'menu-open' : ''}`}>
          <div className="floating-actions">
            {showInfluencerOptions && (
              <div className="popup-influencers-options">
                <div
                  className={`influencer-options ${
                    statusFetch === StateStatus.succeeded ? 'succeeded' : ''
                  }`}
                >
                  {influencers &&
                    influencers.map((inf) => {
                      return (
                        <div className="influencer-option" key={inf.id}>
                          <Avatar src={inf.avatar} onClick={() => onSelect(inf.id)} />

                          <p className="influencer-name">{inf.name}</p>
                        </div>
                      );
                    })}

                  {statusFetch === StateStatus.loading && <Loading />}
                </div>

                <Pagination
                  pageSize={10}
                  current={data.page + 1}
                  total={homeSlice.influencersTotal}
                  isDisabled={
                    statusFetch === StateStatus.loading || statusFetch === StateStatus.failed
                  }
                  onChange={(p) => {
                    dispatch(clearResults());
                    dispatch(fetch({ page: p - 1 }));
                  }}
                />
              </div>
            )}

            {dataActions.map(({ name, icon, label }) => {
              if (name === 'divider') {
                return <div key="divider" className="popup-divider" />;
              } else if (name === 'favorite') {
                return (
                  <Button key={name} className="outline-light" onClick={() => runAction(name)}>
                    {statusFavorite === StateStatus.loading ? (
                      <span key={name} className="spinner__influencer_bar" />
                    ) : (
                      <i className={`icon icon-${favorite ? 'pink-star' : 'favorite'}`} />
                    )}

                    {t(favorite ? 'Desfavoritar' : 'Favoritar')}
                  </Button>
                );
              } else if (name === 'planner-list') {
                return (
                  <ButtonModalLists
                    influencerId={id}
                    key="planner-list"
                    influencerNetwork={influencerNetwork}
                    onClick={() => {
                      if (showInfluencerOptions) updateShowInfluencerOptions(false);
                    }}
                  >
                    <i className={`icon icon-${icon}`} />

                    {label}
                  </ButtonModalLists>
                );
              } else if (name === 'comments') {
                return (
                  <Button
                    key={name}
                    className="outline-light"
                    onClick={() => updateShowComments(true)}
                  >
                    <div
                      className={`comments hover ${totalNotes > 0 ? 'with-comments' : ''}`}
                      role="button"
                    >
                      <i className={`icon icon-${icon}`} />

                      {totalNotes > 0 && <div className="counter">{totalNotes}</div>}
                    </div>

                    {label}
                  </Button>
                );
              } else {
                return (
                  <Button
                    key={name}
                    icon={icon}
                    className="outline-light"
                    onClick={() => runAction(name)}
                    isLoading={name === 'export' && statusExport === StateStatus.loading}
                  >
                    {label}
                  </Button>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

InfluencerFloatingActions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  influencerId: PropTypes.string.isRequired,
  influencerNetwork: PropTypes.string.isRequired,
};

export default InfluencerFloatingActions;
