import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
// 
import { useSelector } from "react-redux"
import { selectPlaces } from "../../../../../app/slice"
import { selectInfluencerDetailsPDFSlice } from "../../slice"
import { selectInfluencerDetailsSlice } from "../../../InfluencerCard/slice"
import StateStatus from "../../../../../utils/status"
// 
import "./index.scss"

const PartMentions = () => {
    const { t } = useTranslation()
    // 
    const slice = useSelector(selectInfluencerDetailsSlice)
    const slicePP = useSelector(selectInfluencerDetailsPDFSlice) // PP = publipost
    const placeNames = useSelector(selectPlaces).list
    // 
    const influencer = slice.influencer
    const influencerPP = slicePP.influencer
    // 
    const statusInfluencer = slice.status.fetch
    const statusInfluencerPP = slicePP.status.fetch
    // general
    const [brands, updateBrands] = useState([])
    const [places, updatePlaces] = useState([])
    const [mentions, updatMmentions] = useState([])
    const [hashtags, updateHashtags] = useState([])
    // publipost 
    const [brandsPP, updateBrandsPP] = useState([])
    const [placesPP, updatePlacesPP] = useState([])
    const [mentionsPP, updatMmentionsPP] = useState([])
    const [hashtagsPP, updateHashtagsPP] = useState([])

    useEffect(() => {
        if(statusInfluencer === StateStatus.succeeded) {
            updateBrands(influencer.brands.slice(0, influencer.brands.length > 10 ? 10 : influencer.brands.length))
            updatePlaces(influencer.places.slice(0, influencer.places.length > 10 ? 10 : influencer.places.length))
            updatMmentions(influencer.mentions.slice(0, influencer.mentions.length > 10 ? 10 : influencer.mentions.length))
            updateHashtags(influencer.hashtags.slice(0, influencer.hashtags.length > 10 ? 10 : influencer.hashtags.length))
        }
    }, [statusInfluencer])

    useEffect(() => {
        if(statusInfluencerPP === StateStatus.succeeded) {
            updateBrandsPP(influencerPP.brands.slice(0, influencerPP.brands.length > 10 ? 10 : influencerPP.brands.length))
            updatePlacesPP(influencerPP.places.slice(0, influencerPP.places.length > 10 ? 10 : influencerPP.places.length))
            updatMmentionsPP(influencerPP.mentions.slice(0, influencerPP.mentions.length > 10 ? 10 : influencerPP.mentions.length))
            updateHashtagsPP(influencerPP.hashtags.slice(0, influencerPP.hashtags.length > 10 ? 10 : influencerPP.hashtags.length))
        }
    }, [statusInfluencerPP])

    return <div className="influencer-mentions-pdf">
        <div className="junction-mentions junction-mentions-general">
            <p className="subtitle-part">
                <i className="icon icon-business-chart" />

                {t("Dados gerais")}
            </p>

            <div className={`list-mentions ${places.length < 1 ? "empty" : ""}`}>
                {places.length > 0 ? <>
                    <p className="list-title">{t("Localizações mencionadas")}</p>

                    <div className="mentions">
                        {places.map((mention, i) => {
                            const indexName = placeNames.findIndex(({ value }) => value === mention.id)

                            return placeNames[indexName].key &&
                                <div key={i} className="mention">
                                    {placeNames[indexName].key}

                                    <div className="value">{mention.count}</div>
                                </div>
                        })}
                    </div>
                </>
                    : <p className="text-empty">{t("O perfil não mencionou")} <b>{t("localizações")}</b></p>
                }
            </div>

            <div className={`list-mentions ${brands.length < 1 ? "empty" : ""}`}>
                {brands.length > 0 ? <>
                    <p className="list-title">{t("Marcas mencionadas")}</p>

                    <div className="mentions">
                        {brands.map((mention, i) =>
                            <div className="mention" key={i}>
                                {mention.name}

                                <div className="value">{mention.count}</div>
                            </div>
                        )}
                    </div>
                </>
                    : <p className="text-empty">{t("O perfil não mencionou")} <b>{t("marcas")}</b></p>
                }

            </div>

            <div className={`list-mentions ${mentions.length < 1 ? "empty" : ""}`}>
                {mentions.length > 0 ? <>
                    <p className="list-title">{t("Perfis mencionados")}</p>

                    <div className="mentions">
                        {mentions.map((mention, i) =>
                            <div key={i} className="mention">
                                {mention.name}

                                <div className="value">{mention.value || mention.count}</div>
                            </div>
                        )}
                    </div>
                </>
                    : <p className="text-empty">{t("O perfil não mencionou")} <b>{t("outros perfis")}</b></p>
                }
            </div>

            <div className={`list-mentions ${hashtags.length < 1 ? "empty" : ""}`}>
                {hashtags.length > 0 ? <>
                    <p className="list-title">{t("Hashtags mencionadas")}</p>

                    <div className="mentions">
                        {hashtags.map((mention, i) => {
                            return <div key={i} className="mention">
                                {mention.name}

                                <div className="value">{mention.value}</div>
                            </div>
                        })}
                    </div>
                </>
                    : <p className="text-empty">{t("O perfil não mencionou")} <b>{t("hashtags")}</b></p>
                }
            </div>
        </div>

        <div className="junction-mentions junction-mentions-publipost">
            <p className="subtitle-part">
                <i className="icon icon-business-chart-1" />

                {t("Dados publipost")}
            </p>


            <div className={`list-mentions ${placesPP.length < 1 ? "empty" : ""}`}>
                {placesPP.length > 0 ? <>
                    <p className="list-title">{t("Localizações mencionadas")}</p>

                    <div className="mentions">
                        {placesPP.map((mention, i) => {
                            const indexName = placeNames.findIndex(({ value }) => value === mention.id)

                            return placeNames[indexName].key &&
                                <div key={i} className="mention">
                                    {placeNames[indexName].key}

                                    <div className="value">{mention.count}</div>
                                </div>
                        })}
                    </div>
                </>
                    : <p className="text-empty">{t("O perfil não mencionou")} <b>{t("localizações")}</b></p>
                }
            </div>

            <div className={`list-mentions ${brandsPP.length < 1 ? "empty" : ""}`}>
                {brandsPP.length > 0 ? <>
                    <p className="list-title">{t("Marcas mencionadas")}</p>

                    <div className="mentions">
                        {brandsPP.map((mention, i) =>
                            <div className="mention" key={i}>
                                {mention.name}

                                <div className="value">{mention.count}</div>
                            </div>
                        )}
                    </div>
                </>
                    : <p className="text-empty">{t("O perfil não mencionou")} <b>{t("marcas")}</b></p>
                }

            </div>

            <div className={`list-mentions ${mentionsPP.length < 1 ? "empty" : ""}`}>
                {mentionsPP.length > 0 ? <>
                    <p className="list-title">{t("Perfis mencionados")}</p>

                    <div className="mentions">
                        {mentionsPP.map((mention, i) =>
                            <div key={i} className="mention">
                                {mention.name}

                                <div className="value">{mention.value || mention.count}</div>
                            </div>
                        )}
                    </div>
                </>
                    : <p className="text-empty">{t("O perfil não mencionou")} <b>{t("outros perfis")}</b></p>
                }
            </div>

            <div className={`list-mentions ${hashtagsPP.length < 1 ? "empty" : ""}`}>
                {hashtagsPP.length > 0 ? <>
                    <p className="list-title">{t("Hashtags mencionadas")}</p>

                    <div className="mentions">
                        {hashtagsPP.map((mention, i) => {
                            return <div key={i} className="mention">
                                {mention.name}

                                <div className="value">{mention.value}</div>
                            </div>
                        })}
                    </div>
                </>
                    : <p className="text-empty">{t("O perfil não mencionou")} <b>{t("hashtags")}</b></p>
                }
            </div>
        </div>
    </div>
}

export default PartMentions