import React from "react"
import { useTranslation } from "react-i18next"
// 
import { useSelector } from "react-redux"
import { selectPlannerListDetailsSlice } from "../../slice"
// 
import { Tooltip as AntTooltip } from "antd"
import Tooltip from "../../../../../common/components/Tooltip"
// 
import { buildValue } from "../../../../../utils/formatters"
// 
import "./index.scss"

const PartTotalizers = () => {
    const { t } = useTranslation()
    // 
    const slice = useSelector(selectPlannerListDetailsSlice)
    // 
    const pl = slice.data
    // 
    const values = [
        { icon: "airscore", text: t("Airscore médio"), fullValue: pl.airscore, value: buildValue("humanize", pl.airscore), tooltip: t("Nota atribuída ao perfil do influenciador que metrifica a qualidade do engajamento, levando em consideração sua base, suas interações, publicações e categorias.") },
        { icon: "group", text: t("Seguidores"), fullValue: pl.sumOfBases, value: buildValue("humanize", pl.sumOfBases), tooltip: t("Somatória do tamanho da base de seguidores dos perfis presentes na lista.") },
    ]
    const otherValues = [
        { text: t("Total de influenciadores"), fullValue: pl.influencersCount, value: buildValue("humanize", pl.influencersCount), tooltip: t("Quantidade total de perfis incluídos na lista.") },
        { text: t("Potencial de alcance"), fullValue: pl.rangePotencial, value: buildValue("humanize", pl.rangePotencial), tooltip: t("Estimativa de alcance que as publicações podem impactar, totalizando todos os perfis presentes na lista.") },
        { text: t("Total de interações"), fullValue: pl.interactionsTotal, value: buildValue("humanize", pl.interactionsTotal), tooltip: t("Quantidade total das interações de todos os perfis presentes na lista.") },
    ]

    return <div className="pl-interactions">
        <div className="interaction-values">
            {values.map(interaction =>
                <div className="interaction with-icons" key={interaction.icon}>
                    <i className={`icon icon-${interaction.icon}`} />

                    <p className="interaction-value">
                        <AntTooltip title={interaction.fullValue}>
                            {interaction.value}
                        </AntTooltip>
                    </p> 

                    <div className="interaction-name">
                        {interaction.text}

                        {interaction.tooltip && <Tooltip child={interaction.tooltip} />}
                    </div>

                </div>
            )}
        </div>

        <div className="interaction-values">
            {otherValues.map(interaction =>
                <div className="interaction" key={interaction.text}>
                    <p className="interaction-value">
                        <AntTooltip title={interaction.fullValue}>
                            {interaction.value}
                        </AntTooltip>
                    </p>

                    <div className="interaction-name">
                        {interaction.text}
                        
                        {interaction.tooltip && <Tooltip child={interaction.tooltip} />}
                    </div>

                </div>
            )}
        </div>
    </div>
}

export default PartTotalizers
