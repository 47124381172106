class ChartColors {
    colors = [
        "#f70b53",
        "#0d47a1",
        "#fa9000",
        "#225e2c",
        '#d50000',
        '#c51162',
        '#aa00ff',
        '#6500ea',
        '#304ffe',
        '#2962ff',
        '#0091ea',
        '#00b8d4',
        '#00bfa5',
        '#00c853',
        '#64dd17',
        '#aeea00',
        '#ffd600',
        '#ffab00',
        '#ff6d00',
        '#dd2c00',
        '#3e2723',
        '#263238',
        '#ff1744',
        '#f50057',
        '#d500f9',
        '#651fff',
        '#3d5afe',
        '#2979ff',
        '#00b0ff',
        '#00e5ff',
        '#1de9b6',
        '#00e676',
        '#76ff03',
        '#c6ff00',
        '#ffea00',
        '#ffc400',
        '#ff9100',
        '#ff3d00',
        '#4e342e',
        '#37474f',
        '#ff5252',
        '#ff4081',
        '#e040fb',
        '#7c4dff',
        '#536dfe',
        '#448aff',
        '#40c4ff',
        '#18ffff',
        '#64ffda',
        '#69f0ae',
        '#b2ff59',
        '#eef141',
        '#ffff00',
        '#ffd740',
        '#ffab40',
        '#ff6e40',
        '#5d4037',
        '#455a64',
        '#ff8a80',
        '#ff80ab',
        '#ea80fc',
        '#b388ff',
        '#8c9eff',
        '#82b1ff',
        '#80d8ff',
        '#84ffff',
        '#a7ffeb',
        '#b9f6ca',
        '#ccff90',
        '#f4ff81',
        '#ffff8d',
        '#ffe57f',
        '#ffd180',
        '#ff9e80',
        '#6d4c41',
        '#546e7a',
        '#b71c1c',
        '#880e4f',
        '#4a148c',
        '#311b92',
        '#1a237e',
        '#0d47a1',
        '#01579b',
        '#006064',
        '#004d40',
        '#1b5e20',
        '#33691e',
        '#827717',
        '#f57f17',
        '#ff6f00',
        '#e65100',
        '#bf360c',
        '#795548',
        '#607d8b',
        '#c62828',
        '#ad1457',
        '#6a1b9a',
        '#4527a0',
        '#283593',
        '#1565c0',
        '#0277bd',
        '#00838f',
        '#00695c',
        '#2e7d32',
        '#558b2f',
        '#9e9d24',
        '#f9a825',
        '#ff8f00',
        '#ef6c00',
        '#d84315',
        '#a1887f',
        '#78909c',
        '#d32f2f',
        '#c2185b',
        '#7b1fa2',
        '#512da8',
        '#303f9f',
        '#1976d2',
        '#0288d1',
        '#0097a7',
        '#00796b',
        '#388e3c',
        '#689f38',
        '#afb42b',
        '#fbc02d',
        '#ffa000',
        '#f57c00',
        '#e64a19',
        '#bcaaa4',
        '#90a4ae',
        '#e53935',
        '#d81b60',
        '#8e24aa',
        '#5e35b1',
        '#3949ab',
        '#1e88e5',
        '#039be5',
        '#00acc1',
        '#00897b',
        '#43a047',
        '#7cb342',
        '#c0ca33',
        '#fdd835',
        '#ffb300',
        '#fb8c00',
        '#f4511e',
        '#d7ccc8',
        '#b0bec5',
        '#f44336',
        '#e91e63',
        '#9c27b0',
        '#673ab7',
        '#3f51b5',
        '#2196f3',
        '#03a9f4',
        '#00bcd4',
        '#009688',
        '#4CAF50',
        '#8bc34a',
        '#cddc39',
        '#ffeb3b',
        '#ffc107',
        '#ff9800',
        '#ff5722',
        '#936b5e',
        '#cfd8dc',
        '#ef5350',
        '#ec407a',
        '#ab47bc',
        '#7e57c2',
        '#5c6bc0',
        '#42a5f5',
        '#29b6f6',
        '#26c6da',
        '#26a69a',
        '#66bb6a',
        '#9ccc65',
        '#d4e157',
        '#ffee58',
        '#ffca28',
        '#ffa726',
        '#ff7043',
        '#a27c70',
        '#7593a0',
        '#e57373',
        '#f06292',
        '#ba68c8',
        '#9575cd',
        '#7986cb',
        '#64b5f6',
        '#4fc3f7',
        '#4dd0e1',
        '#4db6ac',
        '#81c784',
        '#aed581',
        '#dce775',
        '#fff176',
        '#ffd54f',
        '#ffb74d',
        '#ff8a65',
        '#513531',
        '#86a4b3',
        '#ef9a9a',
        '#f48fb1',
        '#ce93d8',
        '#b39ddb',
        '#9fa8da',
        '#90caf9',
        '#81d4fa',
        '#80deea',
        '#80cbc4',
        '#a5d6a7',
        '#c5e1a5',
        '#e6ee9c',
        '#fff59d',
        '#ffe082',
        '#ffcc80',
        '#ffab91',
        '#6c4b46',
        '#9fbecd'
    ]

    getColor(index, alpha = 1) {
        const errorColor = '#bbff00';
		let finalColor = this.errorColor;
		
		if (this.colors[index]) {
			finalColor = this.colors[index];

            finalColor = this.rgba(finalColor, alpha);
		}

		if (typeof finalColor !== 'string') {
			finalColor = errorColor;
		}

		return finalColor;
	}

    rgba(hex, alpha) {
		const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
			.substring(1)
			.match(/.{2}/g)
			.map(x => parseInt(x, 16));

		const rgba = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
		return rgba;
	}
}

export default ChartColors