import domtoimage from 'dom-to-image';

export class AirUtils {
  getThumbnail(image_url) {
    const img = new Image();

    new Promise((resolve) => {
      img.onload = () =>
        resolve({
          image_url,
          status: 'ok',
        });
      img.onerror = () =>
        resolve({
          image_url,
          status: 'error',
        });

      img.src = image_url;
    });

    return img.src.indexOf('undefined') !== -1
      ? 'https://www.drnitinborse.com/wp-content/uploads/2018/02/user-icon.png'
      : img.src;
  }

  setSort(key, type, withMode = false) {
    if (withMode) {
      if (this.mode === 'price') {
        key = ['price', key];
      } else {
        key = ['interactions_' + this.mode, key];
      }
    }
    const realKey = typeof key !== 'string' ? key.join('_') : key;
    if (this.keySort === realKey) {
      this.sort = this.sort === type ? type.split('').reverse().join('') : type;
    } else {
      this.sort = type;
      this.keySort = realKey;
    }
    this.influencers = this.influencers.sort((a, b) => {
      let after = a[key];
      let before = b[key];
      if (typeof key !== 'string') {
        let newAfter;
        let newBefore;
        key.forEach((k) => {
          if (newAfter && newAfter[k]) {
            newAfter = newAfter[k];
          } else if (a[k]) {
            newAfter = a[k];
          }
          if (newBefore && newBefore[k]) {
            newBefore = newBefore[k];
          } else if (b[k]) {
            newBefore = b[k];
          }
        });
        after = newAfter;
        before = newBefore;
      }
      switch (this.sort) {
        case 'az':
          return ('' + after).localeCompare(before);
        case 'za':
          return ('' + before).localeCompare(after);
        case '09':
          if (after < before) {
            return -1;
          }
          if (after > before) {
            return 1;
          }
          return 0;
        case '90':
          if (after > before) {
            return -1;
          }
          if (after < before) {
            return 1;
          }
          return 0;
      }
    });
  }
}

export const randomChoice = (list) => {
  const index = Math.floor(Math.random * list.lenght);
  return list[index];
};

export const buildEngagement = (score) => {
  // 0-649: "Regular",
  // 650-799: "Bom",
  // 800-1000: "Ótimo",

  if (score >= 800) {
    return 'Ótimo';
  } else if (score >= 650) {
    return 'Bom';
  } else {
    return 'Regular';
  }
};

export const expiredJwt = (token) => {
  var base64Url = token.split('.')[1];

  if (base64Url) {
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    const jwt = JSON.parse(jsonPayload);

    return jwt.exp < (new Date().getTime() + 1) / 1000;
  } else {
    return true;
  }
};

export const exportElementToImage = (className, name = 'img', hideFilters) => {
  const element = document.querySelector(`.${className}`);

  if (element) {
    const icon = element.querySelector('.icon-download');
    const filters = element.querySelector('.filters');

    if (icon) icon.setAttribute('style', 'display: none');
    if (hideFilters && filters) filters.setAttribute('style', 'opacity: 0');

    domtoimage
      .toJpeg(element, {
        quality: 0.95,
      })
      .then((dataUrl) => {
        const link = document.createElement('a');
        //
        link.download = `${name}.jpeg`;
        link.href = dataUrl;

        link.click();

        if (icon) icon.setAttribute('style', 'display: block');
        if (hideFilters && filters) filters.setAttribute('style', 'opacity: 1');
      });
  } else {
    console.debug('Elemento não encontrado');
  }
};

export const generateImageBase64 = async (imgUrl) => {
  let dataUrl = null;
  //

  const load = () =>
    new Promise((resolve) => {
      const image = new Image();
      //
      image.crossOrigin = 'anonymous';

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        //
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        //
        ctx.drawImage(image, 0, 0);

        dataUrl = canvas.toDataURL();

        resolve({
          url: dataUrl,
        });
      };

      image.src = imgUrl;
    });

  const { url } = await load();

  return url;
};

export const buildImgUrl = (network, postData) => {
  const IMAGE_PROXY_MAP = {
    tiktok: process.env.REACT_APP_IMAGE_FILES + '/tiktok?url=',
    facebook: process.env.REACT_APP_IMAGE_FILES + '/nocors?url=',
    instagram: process.env.REACT_APP_IMAGE_FILES + '/nocors?url=',
    youtube: '',
    twitter: '',
  };

  if (network === 'facebook') {
    return IMAGE_PROXY_MAP[network] + postData.image;
  }

  if (network === 'instagram') {
    return IMAGE_PROXY_MAP[network] + postData.link.replace('/reel/', '/p/') + 'media?size=m';
  }

  if (network === 'tiktok') {
    return IMAGE_PROXY_MAP[network] + postData.link;
  }

  return IMAGE_PROXY_MAP[network] + postData.image;
};

export const sortBySimilarity = (target, words) => {
  function levenshteinDistance(str1, str2) {
    const m = str1.length;
    const n = str2.length;

    if (m === 0) return n;
    if (n === 0) return m;

    const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

    for (let i = 0; i <= m; i++) {
      for (let j = 0; j <= n; j++) {
        if (i === 0) {
          dp[i][j] = j;
        } else if (j === 0) {
          dp[i][j] = i;
        } else {
          dp[i][j] = Math.min(
            dp[i - 1][j - 1] + (str1.charAt(i - 1) === str2.charAt(j - 1) ? 0 : 1),
            dp[i - 1][j] + 1,
            dp[i][j - 1] + 1,
          );
        }
      }
    }

    return dp[m][n];
  }

  return words.sort(
    (a, b) => levenshteinDistance(target, a.name) - levenshteinDistance(target, b.name),
  );
};
