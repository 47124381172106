import React from "react"
import { Route, Routes as AntRoutes } from "react-router-dom"
import { useTranslation } from "react-i18next"
// 
import { useSelector } from "react-redux"
import { selectAppSlice } from "../../app/slice"
// private
import Aira from "./Aira"
import Home from "./Home"
import AddBrand from "./AddBrand"
import AddCategory from "./AddCategory"
import PlannerList from "./PlannerList"
import Solicitation from "./Solicitation"
import AddInfluencer from "./AddInfluencer"
import Notifications from "./Notifications"
import InfluencerCard from "./InfluencerCard"
import InfluencerList from "./InfluencerList"
import PlannerListCard from "./PlannerListCard"
import ComparativeTable from "./ComparativeTable"
import InfluencerCardPDF from "./InfluencerCardPDF"
import PlannerListCardPDF from "./PlannerListCardPDF"
import ComparativeTablePDF from "./ComparativeTablePDF"
// public
// 
import pageErrorImage from "../../common/assets/images/page-error.png"

const Routes = () => {
    const { t } = useTranslation()
    // 
    const slice = useSelector(selectAppSlice)
    // 
    const user = slice.user

    return <AntRoutes>
        <Route exact path="/" element={<Home />} />
        
        <Route path="/card/:id" element={<InfluencerCard />} />
        
        <Route path="/card/:id/pdf" element={<InfluencerCardPDF />} />
        
        <Route path="/card/:id/:notificationId" element={<InfluencerCard />} />
        
        <Route path="/planner-lists" element={<PlannerList />} />
        
        <Route path="/planner-lists/:id/card" element={<PlannerListCard />} />
        
        <Route path="/planner-lists/:id/card/pdf" element={<PlannerListCardPDF />} />
        
        <Route path="/list/:id/:name" element={<InfluencerList />} />
        
        <Route path={"/planner-lists/:id/comparative-table/:nw"} element={<ComparativeTable />} />

        <Route path={"/planner-lists/:id/comparative-table"} element={<ComparativeTable />} />

        <Route path="/planner-lists/:id/comparative-table/:nw/pdf" element={<ComparativeTablePDF />} />

        <Route path="/dataset/brand" element={<AddBrand />} />
        
        <Route path="/dataset/theme" element={<AddCategory />} />
        
        <Route path="/add-influencer" element={<AddInfluencer />} />
        
        {user.features.includes("aira") && <Route path="/aira" element={<Aira />} />}
        
        <Route path="/notification/:id" element={<Solicitation />} />
        
        <Route path="/notifications" element={<Notifications />} />
    
        <Route  
            path="*" 
            element={  
                <div className="page-not-found">
                    <div className="feedback-error">
                        <img src={pageErrorImage} alt={t("um homem e duas mulheres trabalhando com seus notebooks sentados em um notebook gigante")} />

                        <span><b>{t("Esta página não existe")}.</b></span>
                    </div>
                </div>
            } 
        />
    </AntRoutes>
}


export default Routes