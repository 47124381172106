import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
import StateStatus from "../../../utils/status"
import {
    fetch,
    selectPlannerListDetailsSlice,
    updatePlannerListAvatar,
    updatePlannerListName,
} from "./slice"
//
import Button from "../../../common/components/Button"
import Loading from "../../../common/components/Loading"
import CreateEditPlannerList from "../../../common/components/CreateEditPlannerList"
//
import { Col, Row } from "antd"
import PlannerListHeader from "./components/Header"
import PartTotalizers from "./components/PartTotalizers"
import PartDonutChart from "./components/PartDonutChart"
import BriefingCollapse from "./components/BriefingCollapse"
import PlannerListPartMentions from "./components/PartMentions"
import PlannerListPartCategories from "./components/PartCategories"
import PlannerListFloatingActions from "./components/FloatingActions"
import TopInfluencersByCategories from "./components/TopInfluencersByCategories"
//
import "./index.scss"

const PlannerListCard = () => {
    const { id } = useParams()
    const { t } = useTranslation()
    //
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //
    const slice = useSelector(selectPlannerListDetailsSlice)
    //
    const pl = slice.data
    const fetchStatus = slice.status.fetch
    //
    const [showEditModal, updateShowEditModal] = useState()

    useEffect(() => {
        if (id && !pl.name) dispatch(fetch({ id }))
    }, [id])

    return (
        <div className="background-page">
            <div className="pl-card-details scroll-content">
                {showEditModal && (
                    <CreateEditPlannerList
                        id={id}
                        name={pl.name}
                        avatar={pl.avatar}
                        isFavorite={pl.isFavorite}
                        onClose={(data) => {
                            if (data) {
                                const { name, url } = data

                                if (name) dispatch(updatePlannerListName(name))
                                if (url) dispatch(updatePlannerListAvatar(url))
                            }

                            updateShowEditModal(false)
                        }}
                    />
                )}

                {fetchStatus === StateStatus.loading && !pl?.id && <Loading />}

                {fetchStatus === StateStatus.failed && <span>ERRO</span>}

                {(fetchStatus === StateStatus.succeeded || pl?.id) && <>
                    <div className="pl-card-details-content">
                        <Button
                            icon="arrow-back"
                            className="outline-light btn-back"
                            onClick={() => {
                                if (pl.postsByNetworks && pl.postsByNetworks[0] && pl.postsByNetworks[0].label) {
                                    navigate(`/planner-lists/${pl.id}/comparative-table/${pl.postsByNetworks[0].label}`)
                                } else {
                                    navigate("/planner-lists")
                                }
                            }}
                        >
                            {t(pl.postsByNetworks && pl.postsByNetworks[0] && pl.postsByNetworks[0].label ? "Voltar a tabela de comparação" : "Voltar às listas")}
                        </Button>

                        <PlannerListHeader 
                            inComparativeTable={false}
                            onEdit={() => updateShowEditModal(true)} 
                        />
                            
                        {pl.postsByNetworks && pl.postsByNetworks[0] && pl.postsByNetworks[0].label ?
                            <Row>
                                {pl?.assistantData ? (
                                    <Col span={24}>
                                        <BriefingCollapse pl={pl} />
                                    </Col>
                                ) : null}
                                <Col span={24}>
                                    <PartTotalizers />

                                    <PlannerListPartMentions />

                                    <PartDonutChart />

                                    <PlannerListPartCategories />

                                    <TopInfluencersByCategories />

                                    <PlannerListFloatingActions
                                        onEdit={() => updateShowEditModal(true)}
                                    />
                                </Col>
                            </Row>
                            :
                            <Col span={24}>
                                <div className="empty-list">
                                    <p className="text-empty">
                                        {t("Ops! Ainda não há influenciadores nesta lista")}
                                    </p>
                                    <p className="question-empty">
                                        {t("Deseja adicionar influenciadores?")}
                                    </p>

                                    <Button onClick={() => navigate("/")}>
                                        {t("Buscar influenciadores")}
                                    </Button>
                                </div>
                            </Col>
                        }
                    </div>
                </>
                }
            </div>
        </div>
    )
}

PlannerListCard.defaultProps = {
    isModal: false,
    onClose: () => {},
}

PlannerListCard.propTypes = {
    isModal: PropTypes.any,
    modalId: PropTypes.any,
    onClose: PropTypes.func,
}

export default PlannerListCard
