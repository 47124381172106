import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import emptyImage from '../../../../../common/assets/images/empty-influencers.png';
import { changeObjAlert, showAlert } from '../../../../../common/components/Alert/slice';
import Avatar from '../../../../../common/components/Avatar';
import { EngagementRateTable } from '../../../../../common/components/EngagementRateTable';
import { LevelGauge } from '../../../../../common/components/LevelGauge';
import { humanize } from '../../../../../utils/formatters';
import { selectAiraCurationResult } from '../../slice';

import { PARAMS } from '../../../../../constants/params';
import './index.scss';

const Table = ({ profiles }) => {
  const { t } = useTranslation();
  const slice = useSelector(selectAiraCurationResult);
  const dispatch = useDispatch();

  const topValues = slice.topValues;
  const worstValues = slice.worstValues;

  const handleShowEngagementRateTable = () => {
    dispatch(
      changeObjAlert({
        show: true,
        type: 'info',
        className: 'engagement-modal',
        title: 'Taxa de engajamento',
        subtitle: 'Confira as taxas de engajamentos comuns para cada rede social.',
        text: <EngagementRateTable />,
        cancelText: 'Fechar',
        onCancel: () => dispatch(showAlert(false)),
      }),
    );
  };

  const columns = [
    { alias: 'name', title: t('Influenciador') },
    { alias: 'followers', title: t('Seguidores') },
    { alias: 'engagement_rate', title: t('Engajamento') },
    { alias: 'average_interactions', title: t('Média de interações') },
    { alias: 'airscore', title: t('Airscore') },
    { alias: 'categories', title: t('Assuntos') },
  ];

  return (
    <div className="aira-result-table">
      <h2>{t('Influenciadores')}</h2>

      {profiles.length > 0 && (
        <div className="result-table-container">
          <table>
            <thead>
              <tr>
                {columns.map((item) => {
                  return (
                    <th key={`th-${item.alias}`} className="interaction-value">
                      <span>{item.title}</span>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {profiles.map((influencer, i) => {
                const hiddenName = i !== 2;

                return (
                  <tr key={`tr-${influencer.id}`}>
                    <td className="td-influencer">
                      <div className="div-flex">
                        <span>{i + 1}º</span>

                        <Avatar size={24} src={influencer.avatar} />

                        <div className={`influencer-name ${hiddenName ? 'hidden' : ''}`}>
                          <span>{influencer.name}</span>
                        </div>
                      </div>
                    </td>

                    <td
                      className={`td-followers ${
                        influencer.followers === topValues.followers ? 'max' : ''
                      } ${influencer.followers === worstValues.followers ? 'min' : ''}`}
                    >
                      <span>{humanize(influencer.followers)}</span>
                    </td>

                    <td
                      className={`td-engagement ${
                        influencer.engagementRate === topValues.engagementRate ? 'max' : ''
                      } ${influencer.engagementRate === worstValues.engagementRate ? 'min' : ''}`}
                    >
                      <span>{`${influencer.engagementRate}`.replace('.', ',')}%</span>
                      {!['twitter', 'twitch'].includes(influencer.network) && (
                        <button
                          type="button"
                          onClick={handleShowEngagementRateTable}
                          className="level_gauge__wrapper"
                        >
                          <LevelGauge
                            value={influencer.engagementRate}
                            range={PARAMS.LEVEL_GAUGE.ENGAGEMENT[influencer.network.toUpperCase()]}
                          />
                        </button>
                      )}
                    </td>

                    <td
                      className={`td-interactions ${
                        influencer.averageInteractions === topValues.averageInteractions
                          ? 'max'
                          : ''
                      } ${
                        influencer.averageInteractions === worstValues.averageInteractions
                          ? 'min'
                          : ''
                      }`}
                    >
                      <span>{humanize(influencer.averageInteractions)}</span>
                    </td>

                    <td
                      className={`td-airscore ${
                        influencer.airscore === topValues.airscore ? 'max' : ''
                      } ${influencer.airscore === worstValues.airscore ? 'min' : ''}`}
                    >
                      <div>
                        <span>{influencer.airscore}</span>
                        <LevelGauge
                          value={influencer.airscore}
                          range={PARAMS.LEVEL_GAUGE.AIR_SCORE}
                        />
                      </div>
                    </td>

                    <td className="td-themes">
                      <div className="div-flex">
                        {influencer.themes &&
                          influencer.themes.map((theme) => {
                            return (
                              theme && (
                                <Tooltip
                                  key={theme.label}
                                  title={
                                    <div className="tooltip-themes">
                                      <p className="theme-name">{theme.label}</p>

                                      {theme.score && (
                                        <p className="theme-score">
                                          Airscore <b>{theme.score}</b>
                                        </p>
                                      )}

                                      {theme.percent && (
                                        <p className="theme-percent">
                                          Relevância <b>{theme.percent}%</b>
                                        </p>
                                      )}
                                    </div>
                                  }
                                >
                                  <div
                                    style={{
                                      position: 'relative',
                                      margin: '0 2px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: `#${theme.color}`,
                                      }}
                                      className="circle-theme"
                                    ></div>

                                    <i
                                      style={{
                                        color: `#${theme.color}`,
                                      }}
                                      className={`icon icon-${theme.icon}`}
                                    />
                                  </div>
                                </Tooltip>
                              )
                            );
                          })}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {profiles.length < 1 && (
        <div className="empty-list box">
          <img
            src={emptyImage}
            className="empty-image"
            alt={t('Imagem simbolizando que a listá está vazia')}
          />
          <span>{t('Lista vazia')}</span>
        </div>
      )}
    </div>
  );
};

Table.defaultProps = {
  profiles: [],
};

Table.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
