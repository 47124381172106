import React from "react"
import PropTypes from "prop-types"
// 
import { EllipsisOutlined } from "@ant-design/icons"
import { Pagination as AntPagination } from "antd"
// 
import "./index.scss"

const Pagination = ({ 
    pageSize, 
    total, 
    current, 
    isDisabled, 
    showArrow,
    onChange ,
}) => {
    return <AntPagination 
        className="simple-pagination"
        pageSize={pageSize}
        itemRender={( page, type ) => {
            if(type === "prev") 
                return !showArrow ? "" : <button className="ant-pagination-item-link hover">
                    <i className="icon icon-arrow-back "/>
                </button>
            
            if(type === "next") 
                return !showArrow ? "" : <button className="ant-pagination-item-link hover">
                    <i className="icon icon-arrow-next "/>
                </button>
            
            if(type === "jump-next")
                return <div className="ant-pagination-item-container">
                    <EllipsisOutlined />
                </div>
            
            
            if(type === "jump-prev")
                return <div className="ant-pagination-item-container">
                    <EllipsisOutlined />
                </div>
            
            return <a className={`number ${current === page ? "number-active" : ""}`} >
                { page }
            </a>
        }}
        total={total}
        current={current}
        onChange={onChange}
        disabled={isDisabled}
        showSizeChanger={false}
    /> 
}

Pagination.defaultProps = {
    total: 1, 
    current: 4, 
    pageSize: 10, 
    showArrow: true,
    isDisabled: false, 
    onChange: () => {}
}

Pagination.propTypes = {
    total: PropTypes.number, 
    count: PropTypes.number, 
    current: PropTypes.number, 
    pageSize: PropTypes.number, 
    onChange: PropTypes.func,
    showArrow: PropTypes.bool, 
    isDisabled: PropTypes.bool, 
}

export default Pagination