import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
// 
import { InputNumber } from "antd"
// 
import "./index.scss"

const FlattedInputNumber = ({ formatter, parser, ...props }) => {
    return (
        <InputNumber
            className="input-pattern"
            formatter={formatter}
            parser={parser}
            {...props}
        />
    )
}

const InputCurrency = (props) => {
    const { t } = useTranslation()

    const formatCurrency = useCallback((value) => {
        value = value.toString()
        if (value.length === 0) return ""
        if (value.length <= 2) return `R$ ${value}`

        const prefixValue = value.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        return `R$ ${prefixValue},${value.slice(-2)}`
    })

    return <FlattedInputNumber
        formatter={formatCurrency}
        parser={(value) => value.replace(/\D/g, "")}
        placeholder={t("Insira o valor cobrado")}
        {...props}
    />
}

FlattedInputNumber.propTypes = {
    parser: PropTypes.func.isRequired,
    formatter: PropTypes.func.isRequired,
}

InputCurrency.propTypes = {
    label: PropTypes.string,
}

export default InputCurrency