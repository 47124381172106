import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { selectAppSlice } from '../../../../../app/slice';
import emptyResult from '../../../../../common/assets/images/empty-result.png';
import Button from '../../../../../common/components/Button';
import Loading from '../../../../../common/components/Loading';
import Pagination from '../../../../../common/components/Pagination';
import Select from '../../../../../common/components/Select';
import { QUERIES } from '../../../../../constants/queries';
import { useScrollLock } from '../../../../../hooks/useScrollLock';
import StateStatus from '../../../../../utils/status';
import { storage } from '../../../../../utils/storage';
import { updateClearInfluencer, updateIndex } from '../../../InfluencerCard/slice';
import { fetch, fetchQuota, selectHomeSlice } from '../../slice';
import { DisplayMode } from '../DisplayMode';
import { InfluencerCard } from '../InfluencerCard';
import InfluencerPreview from '../InfluencerPreview';
import InfluencersTable from '../InfluencersTable';

import './index.scss';

const InfluencerList = ({ listId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appSlice = useSelector(selectAppSlice);
  const homeSlice = useSelector(selectHomeSlice);

  const { lock, unlock } = useScrollLock({
    autoLock: false,
  });

  const [searchParams, setSearchParams] = useSearchParams({
    [QUERIES.DISPLAY_MODE]: storage.getItem(QUERIES.DISPLAY_MODE) ?? 'list',
    page: 1,
    order: 'relevance',
  });

  const displayMode = searchParams.get(QUERIES.DISPLAY_MODE);
  const page = Number(searchParams.get('page') ?? 1);
  const order = searchParams.get('order');
  const desc = searchParams.get('desc');

  useEffect(() => {
    dispatch(
      fetch({
        page: page - 1,
        ...(order !== 'relevance' && {
          sort: {
            field: order ?? undefined,
            order: desc ?? undefined,
          },
        }),
        listId: listId,
      }),
    );
  }, [page, order, desc, listId]);

  const handleChangePagination = (page) => {
    setSearchParams((params) => {
      params.set('page', page);

      return params;
    });
  };

  const user = appSlice.user;
  const status = homeSlice.status;
  const lastSearch = homeSlice.lastSearch;
  const influencers = homeSlice.results;
  const percentageQuota = homeSlice.percentageQuota;

  const [selectedInfluencer, setSelectedInfluencer] = useState();

  let time = 0;
  let interval = 0;

  const orderOptions = [
    { key: t('Mais relavante'), value: 'relevance' },
    { key: t('Airscore (1-9)'), value: 'score asc' },
    { key: t('Airscore (9-1)'), value: 'score desc' },
    { key: t('Seguidores (1-9)'), value: 'followers asc' },
    { key: t('Seguidores (9-1)'), value: 'followers desc' },
    { key: t('Engajamento (1-9)'), value: 'engagement_rate asc' },
    { key: t('Engajamento (9-1)'), value: 'engagement_rate desc' },
    { key: t('Nome (A-Z)'), value: 'clean_name asc' },
    { key: t('Nome (Z-A)'), value: 'clean_name desc' },
  ];

  const closeInfluencerPreview = (clearInfluencer = true) => {
    window.clarity('set', 'PREVIEW_SPENT_TIME', time >= 3000 ? '>3s' : '<3s');

    const modal = document.querySelector('.influencer-preview-modal');

    if (modal) {
      modal.classList.add('closed');

      if (clearInfluencer) dispatch(updateClearInfluencer());

      setTimeout(() => {
        setSelectedInfluencer();
        unlock();
      }, 250);
    }
  };

  useEffect(() => {
    const hasPlId = Object.keys(lastSearch).includes('planner_list_id');

    if (listId || hasPlId || !influencers || influencers.length < 1) {
      dispatch(fetch({ page: page - 1, listId: listId }));
    }

    if (!user.features.includes('unlimited-search')) {
      dispatch(fetchQuota());
    }
  }, [listId]);

  useEffect(() => {
    if (selectedInfluencer) {
      time = 0;

      interval = setInterval(() => {
        time += 500;

        if (time >= 3000) {
          clearInterval(interval);
        }
      }, 500);
    } else {
      clearInterval(interval);
    }
  }, [selectedInfluencer]);

  const handleSelectInfluencer = (influencer, index) => {
    dispatch(updateIndex(index || 0));
    setSelectedInfluencer(influencer);
    lock();
  };

  return (
    <div className="influencer_list_container">
      {selectedInfluencer && (
        <div className="influencer-preview-modal" onClick={() => closeInfluencerPreview()}>
          <div
            className="influencer-preview-modal-content"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <InfluencerPreview
              influencer={selectedInfluencer}
              onClose={(clearInfluencer) => closeInfluencerPreview(clearInfluencer)}
            />
          </div>
        </div>
      )}

      <div className="junction-influencer-list">
        <p className="title-influencer-list">{t('Visualizar todos')}</p>

        <div className="junction-select">
          {user.features.includes('unlimited-search') ||
          (!user.features.includes('unlimited-search') && percentageQuota < 100) ? (
            <>
              <div className="text-select">{t('Ordenar por')}</div>

              <Select
                options={orderOptions}
                value={desc ? `${order} ${desc}` : order}
                placeholder={t('Selecione')}
                onChange={(value) => {
                  const [orderValue, descValue] = value.split(' ');

                  setSearchParams((params) => {
                    params.set('order', orderValue);

                    if (descValue) {
                      params.set('desc', descValue);
                    } else {
                      params.delete('desc');
                    }

                    return params;
                  });
                }}
              />
            </>
          ) : (
            <div></div>
          )}
        </div>

        <DisplayMode />
      </div>

      {status.fetch === StateStatus.loading && <Loading />}

      {status.fetch === StateStatus.failed && <p className="text-error">{t('Erro na listagem')}</p>}

      {status.fetch === StateStatus.succeeded && influencers.length > 0 && (
        <>
          {displayMode === 'list' && <InfluencersTable onSelect={handleSelectInfluencer} />}
          {displayMode === 'grid' && (
            <div className="grid_display">
              {influencers.map((influencer, index) => (
                <InfluencerCard
                  key={influencer.id}
                  influencer={influencer}
                  onInfluencerPreview={() => handleSelectInfluencer(influencer, index)}
                />
              ))}
            </div>
          )}
        </>
      )}

      {status.fetch === StateStatus.succeeded && influencers.length < 1 && (
        <div className="empty-list">
          <img src={emptyResult} alt="garoto branco do cabelo marrom pensativo" />

          <p className="empty-title">{t('Nenhum influenciador para este filtro.')}</p>

          <p className="empty-description">
            {t(
              `Tente alterar o filtro ou clique em "adicionar influenciador" para adicionar um perfil específico.`,
            )}
          </p>

          <span className="empty-warning">
            *{t('Os perfis adicionados podem levar até 24 horas para aparecerem na plataforma.')}
          </span>

          <div className="buttons">
            <Button onClick={() => navigate('/add-influencer')}>
              {t('Adicionar influenciador')}
            </Button>
          </div>
        </div>
      )}

      {status.fetch !== StateStatus.failed && homeSlice.totalPages > 1 && (
        <>
          <br />
          <Pagination
            pageSize={12}
            current={page}
            total={homeSlice.influencersTotal}
            isDisabled={status.fetch === StateStatus.loading || status.fetch === StateStatus.failed}
            onChange={handleChangePagination}
          />
        </>
      )}
    </div>
  );
};

InfluencerList.propTypes = {
  listId: PropTypes.string,
};

export default InfluencerList;
