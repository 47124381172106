import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
// 
import { useDispatch, useSelector } from "react-redux"
import { clearPlannerList, fetch, selectPlannerListDetailsSlice } from "../PlannerListCard/slice"
import StateStatus from "../../../utils/status"
// 
import Loading from "../../../common/components/Loading"
// 
import PlannerListHeader from "../PlannerListCard/components/Header"
import PartDonutChart from "../PlannerListCard/components/PartDonutChart"
import PartTotalizers from "../PlannerListCard/components/PartTotalizers"
import PlannerListPartMentions from "../PlannerListCard/components/PartMentions"
import PlannerListPartCategories from "../PlannerListCard/components/PartCategories"
import TopInfluencersByCategories from "../PlannerListCard/components/TopInfluencersByCategories"
// 
import html2pdf from "html-to-pdf-js"
// 
import "./index.scss"
import "../../../common/styles/icons.scss"

const PlannerListCardPDF = () => {
    const { id } = useParams()
    // 
    const { t } = useTranslation()
    // 
    const dispatch = useDispatch()
    // 
    const slice = useSelector(selectPlannerListDetailsSlice)
    // 
    const pl = slice.data
    const fetchStatus = slice.status.fetch
    // 
    const [avatar, updateAvatar] = useState()
    const [loadingBuildPDF, updateLoadingBuildPDF] = useState(true)

    const convertImageToBase64 = (imgUrl) => {
        const image = new Image();
        
        image.crossOrigin = "anonymous";
        
        image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // 
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            // 
            ctx.drawImage(image, 0, 0);
            const dataUrl = canvas.toDataURL();

            updateAvatar(dataUrl)
            return dataUrl
        }
        
        image.src = imgUrl;
    }

    useEffect(() => {
        if (id) dispatch(fetch({ id }))
    }, [id])

    useEffect(() => {
        const menu = document.querySelector(".ant-layout-sider")
        const menuArrow = document.querySelector(".menu-arrow")

        if(menu) {
            menu.style.display = "none"
            menuArrow.style.display = "none"
        }

        if (fetchStatus === StateStatus.succeeded) {
            convertImageToBase64(pl.avatar)
            
            updateLoadingBuildPDF(true)
            
            setTimeout(async () => {
                const elementHTML = document.querySelector(".pl-card-details-pdf");
                const opt = {
                    filename: `${pl.name}.pdf`,
                    image: {
                        type: "jpeg",
                        quality: 0.95
                    },
                    jsPDF: {
                        unit: "in",
                        width: 180, 
                        windowWidth: 1280, 
                        format: "a2",
                    },
                    html2canvas: {
                        scale: 1
                    },
                }

                await html2pdf().set(opt).from(elementHTML).save()
                
                updateLoadingBuildPDF(false)

                setTimeout(() => {
                    window.close()
                }, 1500);
            }, 2500);
        } else if (fetchStatus === StateStatus.failed) {
            window.close();

            dispatch(clearPlannerList())

            window.location.href = `/#/planner-lists/${id}/card`;

        }
    }, [fetchStatus])

    return (
        <>
            {loadingBuildPDF && 
                <div className="fixed-loading">
                    <Loading />

                    <span>{t("Aguarde enquanto estamos gerando o PDF")}</span>
                </div>
            }
            
            <div className="pl-card-details-pdf scroll-content">
                {fetchStatus === StateStatus.loading && <Loading />}

                {fetchStatus === StateStatus.failed && <span>ERRO</span>}

                {fetchStatus === StateStatus.succeeded &&
                    <div className="pl-card-details-content">
                        <PlannerListHeader
                            toPDF={true}
                            avatar={avatar}
                        />

                        {pl.influencersCount > 0 &&
                            <>
                                <PartTotalizers />

                                <PlannerListPartMentions toPDF={true} />

                                <PartDonutChart hasAnimation={false} />

                                <PlannerListPartCategories toPDF={true} />

                                <TopInfluencersByCategories />
                            </>
                        }
                    </div>
                }
            </div>
        </>
    )
}

PlannerListCardPDF.defaultProps = {
    isModal: false,
    onClose: () => { },
}

PlannerListCardPDF.propTypes = {
    isModal: PropTypes.any,
    modalId: PropTypes.any,
    onClose: PropTypes.func,
}

export default PlannerListCardPDF