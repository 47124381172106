import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
// 
import Radio from "../../../common/components/Radio"
import { Slider as AntSlider } from "antd"
// 
import "./index.scss"
import Button from "../Button"

const Slider = ({
    min,
    max,
    step,
    range,
    marks,
    values,
    value,
    formatter,
    onChange,
    showTooltip,
    onAfterChange,
    withClearBtn,
    options,
    formatterRadio,
    hasInput,
    additionalContent
}) => {
    const { t } = useTranslation()
    // 
    const [minValue, updateMinValue] = useState(min)
    const [maxValue, updateMaxValue] = useState(max)
    // 
    const [optionSelected, updateOptionSelected] = useState()

    const verifyValues = () => {
        const lower = minValue
        const greater = maxValue

        if(minValue > maxValue) {
            updateMinValue(greater)
            updateMaxValue(lower)

            onAfterChange([greater, lower])
        } else {
            onAfterChange([lower, greater])
        }
    }

    useEffect(() => {
        if (values && values.length > 0) {
            updateMinValue(values[0])
            updateMaxValue(values[1])
        } else {
            updateMinValue(value || min)
            updateMaxValue(value || max)

            updateOptionSelected()
        }
    }, [values])

    return (
        <div className={`slider-container ${range ? "slider-range" : "slider-simple"} ${marks ? "with-marks" : ""}`}>
            <AntSlider
                min={min}
                max={max}
                marks={marks}
                step={step}
                range={range}
                open={false}
                tooltip={{ open: false }}
                defaultValue={[min, max]}
                value={[minValue, maxValue]}
                onAfterChange={onAfterChange}
                onChange={(values) => {
                    if (range) {
                        updateMinValue(values[0])
                        updateMaxValue(values[1])
                    } else {
                        updateMinValue(values)
                        updateMaxValue(values)
                    }

                    if (onChange) onChange(values)
                }}
            />

            <div className={`slider-values ${range ? "" : "one-vlaue"}`}>
                <div className="slider-value">
                    <span className="span-title">{t(range ? "Minímo" : "Percentual")}</span>

                    {!hasInput && <span className="span-value">{formatter ? formatter(minValue) : minValue}</span>}


                    {hasInput &&
                        <input
                            max={max}
                            min={min}
                            type="number"
                            maxLength={4}
                            placeholder={min}
                            id="input-min"
                            value={minValue <= 0 ? "" : minValue}
                            onBlur={() => verifyValues()}
                            onChange={(e) => {
                                const value = (e.target.value || "").replace(/[^\d.-]+/g, '')

                                updateMinValue(
                                    value < min ? min : 
                                    value > max ? max : value
                                )
                            }}
                        />
                    }
                </div>

                {range &&
                    <div className="slider-value">
                        <span className="span-title">{t("Máximo")}</span>

                        {!hasInput && <span className="span-value">{formatter ? formatter(maxValue) : maxValue}</span>}

                        {hasInput &&
                            <input
                                max={max}
                                min={min}
                                type="number"
                                maxLength={4}
                                value={maxValue}
                                id="input-max"
                                onBlur={() => verifyValues()}
                                onChange={(e) => {
                                    const value = (e.target.value || "").replace(/[^\d.-]+/g, '')
    
                                    updateMaxValue(
                                        value > max ? max : 
                                        value < min ? min : value
                                    )
                                }}
                            />
                        }
                    </div>
                }
            </div>

            {showTooltip &&
                <div className="slider-indicators tooltip-indicator">
                    <div className="slider-indicator max-indicator"
                        style={{ left: `${(maxValue * 100 / max)}%` }}
                    >
                        {formatter ? formatter(maxValue) : maxValue}
                    </div>
                </div>
            }

            {options &&
                <div className="option-values">
                    <Radio
                        value={optionSelected}
                        onChange={(value) => {
                            const option = options.find(option => option.value === value)

                            if (option) {
                                updateMinValue(formatterRadio(option.min))
                                updateMaxValue(formatterRadio(option.max))
                                updateOptionSelected(value)

                                onChange([
                                    formatterRadio(option.min),
                                    formatterRadio(option.max)
                                ])
                                onAfterChange([
                                    formatterRadio(option.min),
                                    formatterRadio(option.max)
                                ])
                            }
                        }}
                        options={options}
                    />
                </div>
            }

            {additionalContent && additionalContent}

            {withClearBtn && <Button
                className="outline-light btn-clear"
                onClick={() => {
                    onChange()
                    onAfterChange()
                    updateOptionSelected()
                    // 
                    updateMinValue(min)
                    updateMaxValue(max)
                }}
            >
                {t("Limpar filtros")}
            </Button>}
        </div>
    )
}

Slider.defaultProps = {
    min: 0,
    max: 1000,
    value: 0,
    step: 1,
    range: false,
    withClearBtn: true,
    showTooltip: false,
    hasInput: false,
    onChange: () => { },
    onAfterChange: () => { },
    formatterRadio: () => { },
}

Slider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    range: PropTypes.bool,
    hasInput: PropTypes.bool,
    values: PropTypes.array,
    value: PropTypes.number,
    options: PropTypes.array,
    showTooltip: PropTypes.bool,
    withClearBtn: PropTypes.bool,
    onChange: PropTypes.func,
    formatter: PropTypes.func,
    onAfterChange: PropTypes.func,
    formatterRadio: PropTypes.func,
    marks: PropTypes.object,
    additionalContent: PropTypes.element,
}

export default Slider
