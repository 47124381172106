import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
//
import { Tooltip as AntTooltip } from 'antd';
import Button from '../../../../../../../common/components/Button';
import Tooltip from '../../../../../../../common/components/Tooltip';
//
import './index.scss';

const Popover = ({
  icon,
  title,
  label,
  content,
  btnTitle,
  itemValue,
  itemCount,
  tooltip,
  width,
}) => {
  //
  const ref = useRef(null);
  //
  const [showOptions, updateShowOptions] = useState(false);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      updateShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="popover-item" style={{ width: width }}>
      {typeof label === 'string' ? <label>{label}</label> : label}

      {showOptions && (
        <div
          className="box-to-close hover"
          onClick={() => {
            updateShowOptions(true);
          }}
        />
      )}

      <AntTooltip title={tooltip}>
        <div
          className={`popover-button hover ${showOptions ? 'opened' : 'closed'}`}
          onClick={() => {
            updateShowOptions(!showOptions);
          }}
        >
          <Button icon={icon} className="outline-light">
            {itemValue && <span className="item-value">{itemValue}</span>}

            {!itemValue && <div className="btn-title">{btnTitle || title}</div>}
          </Button>

          {itemCount ? (
            <span className="item-count">{itemCount}</span>
          ) : (
            <i className={`icon icon-down ${!showOptions ? 'down' : 'up'}`} />
          )}
        </div>
      </AntTooltip>

      <div
        ref={ref}
        style={{ width: width }}
        className={`popover-content ${label ? 'with-label' : ''} ${
          showOptions ? 'popover-opened' : ''
        }`}
      >
        <div className="title">
          {title}

          {tooltip && <Tooltip child={tooltip} />}
        </div>

        {content({ openPopover: showOptions })}
      </div>
    </div>
  );
};

Popover.propTypes = {
  width: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  btnTitle: PropTypes.string,
  itemCount: PropTypes.string,
  itemValue: PropTypes.string,
  content: PropTypes.func,
  label: PropTypes.any,
  tooltip: PropTypes.any,
};

export default Popover;
