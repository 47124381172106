import React from "react"
import { useTranslation } from "react-i18next"
// 
import { useSelector } from "react-redux"
import { selectPlannerListDetailsSlice } from "../../slice"
// 
import AvatarNetwork from "../../../../../common/components/AvatarNetwork"
// 
import "./index.scss"

const TopInfluencersByCategories = () => {
    const { t } = useTranslation()
    // 
    const slice = useSelector(selectPlannerListDetailsSlice)
    // 
    const pl = slice.data
    
    return <>
        <p className="title-part">{t("Top influenciador por categoria")}</p>

        <div className="top-influencers-cards">
            {pl.topInfluencersByCat && pl.topInfluencersByCat.map(category => {
                return <div
                    key={category.name + "-top"}
                    className="top-influencer-card"
                    style={{
                        "--influencers-category-color": `${category.color}`,
                    }}
                >
                    <div className="top-influencer-card-header">
                        <i className={`icon icon-${category.icon}`} /> 
                        
                        {category.name} 
                    </div>

                    <div className="top-influencer-card-body">
                        {category.influencers && category.influencers.map((influencer, index) => {
                            return <div
                                key={index}
                                className={`row-values ${index % 2 === 0 ? "odd" : "even"}`}
                            >
                                <div className="user">
                                    <span className="position">{index + 1}º</span>
                                    
                                    <AvatarNetwork 
                                        src={influencer.avatar}
                                        network={influencer.network}    
                                    />
                                    
                                    <span className="name">{influencer.name}</span>
                                </div>
                                
                                <div className="influencer-score">
                                    <i className="icon icon-airscore" />
                                    <span className="score">{influencer.score}</span>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            })}
        </div> 
    </>
}

export default TopInfluencersByCategories