// 
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import React from "react";
import {
    buildValue
} from "../../../../utils/formatters";
// 
import "../Charts.scss";

const LineChart = ({
    series,
    className,
    height,
    currencySymbol,
    formatType,
    yAxisText,
    // color = "#009081",
    showLegend = true,
}) => {
    const options = {
        chart: {
            height: height,
            className: className,
            styles: {
                width: "100%",
            },
            backgroundColor: "transparent"
        },
        title: {
            text: ""
        },
        yAxis: {
            title: {
                text: yAxisText
            },
            type: "number",
            labels: {
                formatter: function () {
                    return buildValue("humanize", this.value);
                }
            }
        },
        xAxis: {
            type: "datetime",
            labels: {
                formatter: function () {
                    return Highcharts.dateFormat('%e/%m', this.value);
                }
            }
        },
        legend: {
            margin: 14,
            padding: 16,
            useHTML: true,
            // width: "100%",
            symbolRadius: 0,
            // align: "center",
            borderRadius: 6,
            enabled: showLegend,
            // verticalAlign: "middle",
            backgroundColor: "#FAFAFA"
        },
        plotOptions: {
            series: {
                marker: {
                    symbol: "circle"
                }
            },
            events: {
                checkboxClick: function () {
                    if (this.visible) {
                        this.hide();
                    } else {
                        this.show();
                    }
                },
                legendItemClick: function () {
                    if (this.selected) {
                        this.selected = false;
                    } else {
                        this.selected = true;
                    }

                    if (this.visible) {
                        this.checkbox.checked = false;
                    } else {
                        this.checkbox.checked = true;
                    }
                }
            },
        },
        tooltip: {
            formatter: function () {
                return `<div class="high-tooltip">
                ${buildValue("dateString", this.x)}: <b>${buildValue(formatType, this.y, currencySymbol)}</b>
              </div>`
            }
        },

        series: [series],
    };

    return <HighchartsReact
        highcharts={
            Highcharts
        }
        options={
            options
        }
    />
}

LineChart.defaultProps = {
    series: [],
}

LineChart.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    formatType: PropTypes.string,
    currencySymbol: PropTypes.string,
    yAxisText: PropTypes.string,
    onChange: PropTypes.func,
    series: PropTypes.any,
    hasPointer: PropTypes.bool,
    showLegend: PropTypes.bool,
}

export default LineChart;