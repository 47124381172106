import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import './CustomInput.scss';

export const CustomSelect = forwardRef(({ label, error, children, id, ...props }, ref) => {
  return (
    <div className="field_wrapper">
      <select id={id} {...props} ref={ref}>
        {children}
      </select>
      <label htmlFor={id}>{label}</label>
      {error && <span className="error_message__field_wrapper">{error}</span>}
    </div>
  );
});

CustomSelect.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

CustomSelect.displayName = 'CustomSelect';
