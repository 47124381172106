export const PARAMS = {
  LEVEL_GAUGE: {
    AIR_SCORE: [499, 700],
    ENGAGEMENT: {
      INSTAGRAM: [1, 3],
      TIKTOK: [5, 8],
      YOUTUBE: [10, 15],
    },
  },
};
