import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
//
import { useDispatch, useSelector } from 'react-redux';
import { fetch, fetchDataset, selectInfluencerDetailsSlice, updateClearInfluencer } from '../InfluencerCard/slice';
import StateStatus from '../../../utils/status';
//
import Loading from '../../../common/components/Loading';
import InfluencerHeader from './components/Header';
//
import PartCategories from './components/PartCategories';
import PartDemographics from './components/PartDemographics';
import PartGeneralData from './components/PartGeneralData';
import PartMentions from './components/PartMentions';
//
import feedback from '../../../common/components/Feedback';
import airfluencersImg from '../../../common/assets/images/airfluenecers-logo.svg';
//
import { buildValue } from '../../../utils/formatters';
import html2pdf from 'html-to-pdf-js';
//
import './index.scss';

const InfluencerCardPDF = () => {
    const { t } = useTranslation();
    //
    const params = useParams();
    //
    const { id } = params;
    //
    const dispatch = useDispatch();
    const slice = useSelector(selectInfluencerDetailsSlice);
    //
    const status = slice.status;
    const influencer = slice.influencer;
    //
    const [avatar, updateAvatar] = useState();
    const [loadingBuildPDF, updateLoadingBuildPDF] = useState(false);
    // 
    const influencerCardRef = useRef(null)

    const convertImageToBase64 = (imgUrl) => {
        const image = new Image();

        image.crossOrigin = 'anonymous';

        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            //
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            //
            ctx.drawImage(image, 0, 0);
            const dataUrl = canvas.toDataURL();

            updateAvatar(dataUrl);
            return dataUrl;
        };

        image.src = imgUrl;
    };

    const redirectToCard = () => {
        const menu = document.querySelector('.ant-layout-sider');
        const menuArrow = document.querySelector('.menu-arrow');

        window.close();

        window.location.href = (status.fetch === StateStatus.failed ? "/#/" : `/#/card/${id}`);

        if (menu) {
            menu.style.display = 'flex';
            menuArrow.style.display = 'flex';
        }
    }

    useEffect(() => {
        const fetchs = () => {
            dispatch(fetch({ id }));
            dispatch(fetchDataset());
        };

        const removeMenu = () => {
            const menu = document.querySelector('.ant-layout-sider');
            const menuArrow = document.querySelector('.menu-arrow');

            if (menu) {
                menu.style.display = 'none';
                menuArrow.style.display = 'none';
            }
        };

        fetchs();
        removeMenu();
    }, []);

    useEffect(() => {
        if(avatar) {
            setTimeout(async () => {
                const opt = {
                    filename: `${influencer.username}-${influencer.network}.pdf`,
                    image: {
                        type: 'jpeg',
                        quality: 0.95,
                    },
                    jsPDF: {
                        unit: 'in',
                        width: 240,
                        windowWidth: 1280,
                        format: 'a2',
                    },
                    html2canvas: {
                        scale: 1,
                    },
                };

                await html2pdf().set(opt).from(influencerCardRef.current).save();

                updateLoadingBuildPDF(false);

                setTimeout(() => {
                    redirectToCard()
                }, 1500);
            }, 3000);
        }
    }, [avatar])

    useEffect(() => {
        const hasDemographics = influencer.followers >= 10000 ? status.fetchDemographicsData === StateStatus.succeeded : true

        if (
            !loadingBuildPDF &&
            status.fetch === StateStatus.succeeded &&
            status.fetchCategories === StateStatus.succeeded &&
            hasDemographics &&
            influencerCardRef.current
        ) {
            convertImageToBase64(influencer.avatar);

            updateLoadingBuildPDF(true);
        }
    }, [
        status.fetch,
        status.fetchDemographicsData,
        status.fetchCategories,
        status.fetchInteractionsHistory
    ], []);

    useEffect(() => {
        if (
            status.fetch === StateStatus.failed ||
            status.fetchDemographicsData === StateStatus.failed ||
            status.fetchCategories === StateStatus.failed ||
            status.fetchInteractionsHistory === StateStatus.failed
        ) {
            redirectToCard()

            dispatch(updateClearInfluencer())

            feedback.error(t("Erro ao gerar PDF! Tente novamente."))
        }
    }, [
        status.fetch,
        status.fetchDemographicsData,
        status.fetchCategories,
        status.fetchInteractionsHistory
    ]);

    return (
        <>
            {/* {loadingBuildPDF === "dasad" &&  */}
            <div className="fixed-loading">
                <Loading />

                <span>{t('Aguarde enquanto estamos gerando o PDF')}</span>
            </div>
            {/* } */}

            <div
                ref={influencerCardRef}
                className="influencer-card-pdf"
                style={{ backgroundColor: 'rgba(247, 247, 247, 0.9)' }}
            >
                <div className="influencer-card-container scroll-content">
                    <div className="influencer-card-content">
                        <header className="header">
                            <div>
                                <img src={airfluencersImg} alt="airfluencers logo" />

                                <span>
                                    {t('Dados gerados em')}: {buildValue('date', new Date())}
                                </span>
                            </div>
                        </header>

                        {influencer.username && influencer.network && (
                            <InfluencerHeader avatar={avatar} influencerId={id} />
                        )}

                        {status.fetch === StateStatus.succeeded && (
                            <>
                                <div className="content">
                                    {/* PAGE 1 */}
                                    {<PartGeneralData />}

                                    {<PartCategories />}

                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />

                                    {/* PAGE 2 */}
                                    {<PartMentions />}

                                    {/* PAGE 2 and 3 */}
                                    {<PartDemographics />}
                                </div>
                            </>
                        )}

                        {status.fetch === StateStatus.loading && <Loading />}

                        {status.fetch === StateStatus.failed && <span>ERRO</span>}
                    </div>
                </div>
            </div>
        </>
    );
};

InfluencerCardPDF.propTypes = {};

export default InfluencerCardPDF;