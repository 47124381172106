import { Checkbox, Popover, Spin, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import StateStatus from '../../../utils/status';
import Input from '../Input';
import Loading from '../Loading';

import { fetchLastLists, modalPLCreate, modalPLToggle, selectModalPlannerListSlice } from './slice';

import './index.scss';

const ButtonModalLists = ({
  influencerId,
  influencerNetwork,
  removeInfluencer,
  children,
  onClick,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const slice = useSelector(selectModalPlannerListSlice);

  const list = slice.list;
  const status = slice.status;

  const [loading, setLoading] = useState(-1);
  const [showModal, setShowModal] = useState(false);

  const [modalText, setModalText] = useState('');

  let delayTimer;

  const onChange = (id, index, include) => {
    setLoading(index);

    dispatch(
      modalPLToggle({
        include,
        influencerId,
        influencerNetwork,
        id,
      }),
    )
      .then((res) => {
        setLoading(-1);

        if (removeInfluencer != null && !res.payload.include) {
          removeInfluencer();
        }
      })
      .catch(() => {
        setLoading(-1);
      });
  };

  useEffect(() => {
    if (status.modalPLCreate === StateStatus.succeeded) {
      setModalText('');
    }
  }, [status.modalPLCreate]);

  useEffect(() => {
    if (showModal) {
      setModalText('');
      dispatch(fetchLastLists());
    }
  }, [showModal]);

  return (
    <div className="container-modal-lists">
      <Popover
        trigger="click"
        open={showModal}
        onOpenChange={() => {
          setShowModal(!showModal);
          onClick?.();
        }}
        content={() => (
          <div className={`modal-lists ${showModal ? 'open-modal-lists' : 'close-modal-lists'}`}>
            <div className="modal-lists-header">
              <span className="modal-lists-header-title">{t('Minhas listas')}</span>

              <i className="icon icon-close" onClick={() => setShowModal(false)} />
            </div>

            <div className="modal-lists-content">
              <Input
                width="100%"
                placeholder={t('Pesquise ou crie')}
                hasFocus={false}
                isOutlined={true}
                value={modalText}
                prefix={<i className="icon icon-search" />}
                suffix={
                  <Tooltip
                    title={t(
                      !modalText || modalText.length < 1
                        ? 'Informe o nome da lista de planejamento para poder criá-la'
                        : null,
                    )}
                  >
                    <i className="icon icon-plus" />
                  </Tooltip>
                }
                isDisabledSuffix={!modalText || modalText.length < 1}
                onChange={(e) => {
                  const value = e.target.value;

                  setModalText(value);

                  if (value && value.length > 0) clearTimeout(delayTimer);

                  delayTimer = setTimeout(() => {
                    dispatch(fetchLastLists(value));
                  }, 500);
                }}
                onClickSuffix={(value) => {
                  if (value && value.length > 0) {
                    dispatch(modalPLCreate(value)).then(() => {
                      dispatch(fetchLastLists(slice.title));
                    });
                  }
                }}
              />

              <div className="modal-lists-junction">
                {status.fetchLastLists === StateStatus.loading && <Loading />}

                {status.fetchLastLists === StateStatus.succeeded && (
                  <>
                    {list.length > 0 &&
                      list.map((item, key) => {
                        return (
                          !item.isFavorite && (
                            <Tooltip key={item.id} title={item.name}>
                              <div className="option-list">
                                {loading === key && (
                                  <div className="pl-loading">
                                    <Spin />
                                    <span>{item.name}</span>
                                  </div>
                                )}

                                {loading !== key && (
                                  <Checkbox
                                    checked={
                                      item.influencerIds &&
                                      item.influencerIds.includes(influencerId)
                                    }
                                    onChange={() => {
                                      onChange(
                                        item.id,
                                        key,
                                        item.influencerIds
                                          ? item.influencerIds.includes(influencerId)
                                          : false,
                                      );
                                    }}
                                  >
                                    <span
                                      onClick={() => {
                                        onChange(
                                          item.id,
                                          key,
                                          item.influencerIds
                                            ? item.influencerIds.includes(influencerId)
                                            : false,
                                        );
                                      }}
                                    >
                                      {item.name}
                                    </span>
                                  </Checkbox>
                                )}
                              </div>
                            </Tooltip>
                          )
                        );
                      })}

                    {list.length <= 0 && (
                      <p className="text-empty">
                        {t('Nenhuma lista de planejamento foi encontrada')}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      >
        {children}
      </Popover>
    </div>
  );
};

ButtonModalLists.propTypes = {
  influencerId: PropTypes.string,
  influencerNetwork: PropTypes.string,
  user: PropTypes.object,
  onChange: PropTypes.func,
  removeInfluencer: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default ButtonModalLists;
