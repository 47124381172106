import { Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectBrands, selectCategories } from '../../../../../app/slice';
import Button from '../../../../../common/components/Button';
import Input from '../../../../../common/components/Input';
import MultiSelect from '../../../../../common/components/MultiSelect';
import Slider from '../../../../../common/components/Slider';
import SliderFollowers from '../../../../../common/components/SliderFollowers';
import { humanize } from '../../../../../utils/formatters';
import StateStatus from '../../../../../utils/status';
import {
  clearSearchValues,
  fetch,
  fetchAutocompletes,
  selectHomeSlice,
  updateSearchData,
} from '../../slice';

import Popover from './components/Popover';
import SearchFilters from './components/SearchFilters';
import SelectNetworks from './components/SelectNetworks';

import './index.scss';

const SearchInfluencer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brands = useSelector(selectBrands);
  const categories = useSelector(selectCategories);
  const homeSlice = useSelector(selectHomeSlice);
  const [filterValues, setFilterValues] = useState([]);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [minFollowers, setMinFollowers] = useState(0);
  const [maxFollowers, setMaxFollowers] = useState(300000000);

  const search = homeSlice.search;
  const status = homeSlice.status;
  const [inputMain, setInputMain] = useState(search.query || '');

  let delayTimer;

  const filterAges = [
    { value: 'under-16', key: 'até 16' },
    { value: '17-19', key: '17 a 19' },
    { value: '20-24', key: '20 a 24' },
    { value: '25-29', key: '25 a 29' },
    { value: '30-34', key: '30 a 34' },
    { value: '35-39', key: '35 a 39' },
    { value: '40-49', key: '40 a 49' },
    { value: '50-59', key: '50 a 59' },
    { value: '60-over', key: 'Acima de 60' },
  ];

  const getValue = (key, returnDefault = null) => {
    if (search[key]) {
      if (key === 'bio_places') {
        let obj = {};

        search[key].forEach((value) => {
          obj[value] = value ? 'include' : null;
        });

        return obj;
      }
    } else {
      return returnDefault;
    }
  };

  const removeValue = (key, text, id) => {
    if (search[key]) {
      if (typeof search[key] === 'string') {
        dispatch(updateSearchData({ [key]: null }));
      } else if (typeof search[key] === 'object') {
        if (search[key].length) {
          if (key === 'networks') {
            let list = search[key].filter((item) => item !== text);

            dispatch(updateSearchData({ [key]: list }));
          } else if (key === 'brands' || key === 'categories') {
            let list = search[key].filter((item) => item.id != id);

            dispatch(
              updateSearchData({
                [key]: list && list.length > 0 ? list : null,
              }),
            );
          } else if (key === 'gender' || key === 'audience_gender') {
            dispatch(
              updateSearchData({
                [key]: null,
              }),
            );
          } else if (key === 'audience_age') {
            const age = filterAges.find((item) => item.key === text);

            let list = search[key].filter((item) => item !== age.value);

            dispatch(
              updateSearchData({
                [key]: list && list.length > 0 ? list : null,
              }),
            );
          } else if (key === 'estimated_age') {
            const age = filterAges.find((item) => item.key === text);

            let list = search[key].filter((item) => item !== age.value);

            dispatch(
              updateSearchData({
                [key]: list && list.length > 0 ? list : null,
              }),
            );
          } else if (key === 'audience_interests' || key === 'audience_places') {
            let list = search[key].filter((value) => value != text);

            dispatch(
              updateSearchData({
                [key]: list && list.length > 0 ? list : null,
              }),
            );
          } else if (key === 'bio') {
            let list = search[key].filter((value) => value.text != text);

            dispatch(
              updateSearchData({
                [key]: list && list.length > 0 ? list : null,
              }),
            );
          } else if (key === 'bio_places') {
            let list = search[key].filter((item) => item != id);

            dispatch(
              updateSearchData({
                [key]: list && list.length > 0 ? list : null,
              }),
            );
          } else {
            dispatch(updateSearchData({ [key]: null }));
          }
        } else {
          dispatch(updateSearchData({ [key]: null }));
        }
      } else {
        dispatch(updateSearchData({ [key]: null }));
      }
    }

    onFinish();
  };

  const setUpdateFilterValues = () => {
    let list = [];

    Object.keys(search)
      .filter((key) => key !== 'page' && key !== 'sort')
      .forEach((filter) => {
        if (search[filter]) {
          if (filter === 'brands') {
            search[filter].map((item) => {
              list.push({
                id: item.id,
                name: filter,
                type: 'array',
                value: brands.object[item.id],
              });
            });
          } else if (filter === 'audience_age') {
            search[filter].map((item) => {
              list.push({
                id: item.id,
                name: filter,
                type: 'array',
                value: 'Audiência: ' + filterAges.find((f) => f.value === item)?.key || '?',
              });
            });
          } else if (filter === 'estimated_age') {
            search[filter].map((item) => {
              list.push({
                id: item.id,
                name: filter,
                type: 'array',
                value: 'Influenciador: ' + filterAges.find((f) => f.value === item)?.key || '?',
              });
            });
          } else if (filter === 'bio_places') {
            search[filter].map((item) => {
              list.push({
                name: filter,
                id: item,
                value: item,
                type: 'array',
                withLabel: true,
              });
            });
          } else if (filter === 'categories') {
            search[filter].map(({ id }) => {
              let filterItem = categories.list.find((category) => category.key === id);

              if (!filterItem && categories.subCategories[id]) {
                filterItem = {
                  key: id,
                  name: categories.subCategories[id].labels[1],
                };
              }

              if (filterItem) {
                list.push({
                  name: filter,
                  id: filterItem.key,
                  value: filterItem.name,
                  type: 'array',
                });
              }
            });
          } else if (filter === 'gender' || filter === 'audience_gender') {
            const prefix = filter === 'gender' ? 'Influenciador: ' : 'Audiência: ';
            list.push({
              name: filter,
              value: prefix + t(search[filter]) || '?',
              type: 'list',
              withLabel: true,
            });
          } else if (filter === 'is_influencer') {
            list.push({
              name: filter,
              value: 'Somente influenciadores',
              type: 'boolean',
            });
          } else if (filter === 'has_contact') {
            list.push({
              name: filter,
              value: 'Perfis com contato',
              type: 'boolean',
            });
          } else if (typeof search[filter] === 'object') {
            // FILTER WITH ARRAY VALUE
            if (search[filter].length) {
              search[filter].forEach((item) => {
                if (typeof item === 'string') {
                  list.push({ name: filter, value: t(item), type: 'array' });
                } else if (typeof item === 'object') {
                  if (item['text'] != null) {
                    list.push({
                      name: filter,
                      type: 'object',
                      value: item['text'],
                    });
                  }
                } else {
                  list.push({
                    id: item.id,
                    name: filter,
                    type: 'array',
                    value: item.id || '?',
                  });
                }
              });
            } else {
              // FILTER WITH MIN AND MAX
              if (search[filter]['min'] != null && search[filter]['max'] != null) {
                let value = '?';

                if (filter === 'followers') {
                  value = `${humanize(search[filter]['min'])} - ${humanize(search[filter]['max'])}`;
                } else if (filter === 'engagement_rate') {
                  value = `${search[filter]['min']}% - ${search[filter]['max']}%`;
                } else {
                  value = `${search[filter]['min']} - ${search[filter]['max']}`;
                }

                list.push({
                  name: filter,
                  type: 'object',
                  value: value,
                });
              } else if (search[filter]['text'] != null) {
                list.push({
                  name: filter,
                  type: 'object',
                  value: search[filter]['text'],
                });
              }
            }

            // FILTER WITH STRING VALUE
          } else if (typeof search[filter] === 'string') {
            list.push({ name: filter, type: 'string', value: search[filter] });
          } else if (typeof search[filter] === 'number') {
            list.push({
              name: filter,
              type: 'number',
              value: `${search[filter]}%`,
            });
          }
        }
      });

    setFilterValues(list);
  };

  const onFinish = (page = 0) => {
    dispatch(fetch({ page: page }));
  };

  useEffect(() => {
    if (homeSlice.status.fetch === StateStatus.loading) {
      setUpdateFilterValues();
    }
  }, [homeSlice.status]);

  useEffect(() => {
    if (Object.keys(search).length > 2 && homeSlice.lastSearchFilter != 'page') {
      onFinish();

      window.clarity('set', 'ACTION', 'MADE_SEARCH');
    }
  }, [search]);

  return (
    <div className="search-container">
      <div className="junction-home-search">
        <div className="filters">
          <Input
            isOutlined
            value={inputMain}
            className="input-name"
            prefix={<i className="icon icon-search" />}
            placeholder={t('Pesquise por palavra-chave, hashtags, nomes dos usuários...')}
            onBlur={(e) => {
              dispatch(updateSearchData({ query: e.target.value }));
            }}
            onChange={(e) => {
              setInputMain(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.target.blur();
            }}
          />

          <Row className="filters-row row-fixed" justify="space-between">
            <Row>
              <Popover
                width="178px"
                icon="airscore"
                title={t('Airscore')}
                itemValue={search.score && `${search.score.min} - ${search.score.max}`}
                tooltip={t(
                  ' Nota atribuída ao perfil do influenciador que metrifica a qualidade do engajamento, levando em consideração sua base, suas interações, publicações e categorias.',
                )}
                content={() => (
                  <div>
                    <Slider
                      range={true}
                      minValue={0}
                      hasInput={true}
                      maxValue={1000}
                      values={search.score ? [search.score.min, search.score.max] : null}
                      onAfterChange={(values) => {
                        dispatch(
                          updateSearchData({
                            score: !values
                              ? null
                              : {
                                  min: values[0],
                                  max: values[1],
                                },
                          }),
                        );
                      }}
                    />
                  </div>
                )}
              />

              <Popover
                width="259px"
                icon="group"
                title={t('Número de seguidores')}
                itemValue={
                  search.followers && `${humanize(minFollowers)} - ${humanize(maxFollowers)}`
                }
                content={() => (
                  <SliderFollowers
                    step={1}
                    max={25}
                    range={true}
                    options={[
                      {
                        label: 'Nano',
                        min: 1000,
                        max: 10000,
                        value: 'nano',
                        description: '1K - 10K',
                      },
                      {
                        label: 'Micro',
                        min: 10000,
                        max: 50000,
                        value: 'micro',
                        description: '10K - 50k',
                      },
                      {
                        label: 'Inter',
                        min: 50000,
                        max: 500000,
                        value: 'inter',
                        description: '50K - 500K',
                      },
                      {
                        label: 'Macro',
                        min: 500000,
                        max: 1000000,
                        value: 'macro',
                        description: '500K - 1M',
                      },
                      {
                        label: 'Mega',
                        min: 1000000,
                        max: 300000000,
                        value: 'mega',
                        description: '1M+',
                      },
                    ]}
                    values={search.followers ? [search.followers.min, search.followers.max] : null}
                    formatterRadio={(value) => value}
                    onAfterChange={(values) => {
                      clearTimeout(delayTimer);

                      delayTimer = setTimeout(() => {
                        if (values) {
                          const min = values[0];
                          const max = values[1];

                          setMinFollowers(min);
                          setMaxFollowers(max);

                          dispatch(
                            updateSearchData({
                              followers: {
                                min: parseInt(`${min}`),
                                max: parseInt(`${max}`),
                              },
                            }),
                          );
                        } else {
                          dispatch(
                            updateSearchData({
                              followers: null,
                            }),
                          );
                        }
                      }, 750);
                    }}
                  />
                )}
              />

              <Popover
                width="253px"
                title={t('Localização do influenciador')}
                icon="pin-location"
                itemCount={
                  search.bio_places && search.bio_places.length > 0 && `${search.bio_places.length}`
                }
                content={({ openPopover }) => (
                  <MultiSelect
                    showAfterSearch={true}
                    onSearch={(text) => {
                      clearTimeout(delayTimer);

                      delayTimer = setTimeout(() => {
                        dispatch(
                          fetchAutocompletes({
                            type: 'bio-places',
                            query: text,
                          }),
                        );
                      }, 500);
                    }}
                    placeholder={t('Pesquise por estado ou cidade')}
                    selecteds={getValue('bio_places', {})}
                    options={(homeSlice.autocompletes['bio-places'] || []).map((item) => ({
                      value: item,
                      key: item,
                    }))}
                    isOpenPopover={openPopover}
                    isLoading={status.fetchAutocompletes === StateStatus.loading}
                    onChange={(values) => {
                      if (values && Object.keys(values).length > 0) {
                        const list = Object.keys(values)
                          .map((id) => values[id] && id)
                          .filter((item) => item);

                        dispatch(
                          updateSearchData({
                            bio_places: list,
                          }),
                        );
                      } else {
                        dispatch(updateSearchData({ bio_places: null }));
                      }
                    }}
                  />
                )}
              />

              <Popover
                width="180px"
                title={t('Redes sociais')}
                icon="mobile-device"
                itemCount={
                  search.networks && search.networks.length > 0 && `${search.networks.length}`
                }
                content={() => (
                  <SelectNetworks
                    selecteds={search.networks}
                    onChange={(networks) => {
                      dispatch(
                        updateSearchData({
                          networks: networks && networks.length > 0 ? networks : [],
                        }),
                      );
                    }}
                  />
                )}
              />
            </Row>

            <Button
              icon="filter-settings"
              className={`outline-light ${showMoreFilters ? 'active' : ''} btn-settings`}
              onClick={(e) => {
                setShowMoreFilters(!showMoreFilters);

                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {t('Filtros avançados')}
            </Button>
          </Row>

          {showMoreFilters && <SearchFilters filterAges={filterAges} />}
        </div>

        <br />

        <Row justify="end">
          <Button className="search-button" onClick={() => onFinish(0)}>
            {t('Pesquisar')} <i className="icon icon-arrow-next" />
          </Button>
        </Row>
      </div>

      {filterValues && filterValues.length > 0 && (
        <div className="filter-values">
          <div className="filter-list">
            {filterValues.map((filter, key) => {
              return !filter ? null : (
                <Tooltip key={key} title={filter.withLabel && t(filter.name)}>
                  <div className="filter-value">
                    <p>{filter.value === 'twitter' ? `x (twitter)` : filter.value}</p>

                    <i
                      className="icon icon-circle-close hover"
                      onClick={() => {
                        removeValue(filter.name, filter.value, filter.id);
                      }}
                    />
                  </div>
                </Tooltip>
              );
            })}
          </div>

          <Button
            className="clear-button outline-light"
            onClick={() => {
              dispatch(clearSearchValues());

              setInputMain('');

              dispatch(fetch({ page: 0 }));
            }}
          >
            {t('Limpar filtros')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SearchInfluencer;
