import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit"
// 
import i18n from "../../../languages/i18n"
import {
    createInfluencer
} from "./service"
// 
import { changeObjAlert, showAlert } from "../../../common/components/Alert/slice"
import StateStatus from "../../../utils/status"

const initialStateInfluencer = {
    status: {
        create: StateStatus.idle,
    },
    errorMsg: null
}

export const create = createAsyncThunk("add-influencer/create", async ({ isMultiple, data }, thunkAPI) => {
    try {
        const response = await createInfluencer(data)
    
        thunkAPI.dispatch(changeObjAlert({
            show: true,
            type: isMultiple ? "info" : "success",
            title: `${i18n.t(isMultiple ? "Adicionar influenciadores" : "Sucesso")}!`,
            text: i18n.t(isMultiple ? "Sua solicitação já está em processamento. Você será notificado quando os influenciadores forem adicionados." : "Em até 24 horas você conseguirá encontrar o influenciador que você adicionou em nossa plataforma."),
            cancelText: i18n.t("Fechar"),
            onCancel: () => {
                thunkAPI.dispatch(showAlert(false))
            },
        }))
        
        return response.data
    } catch (err) {
        thunkAPI.dispatch(changeObjAlert({
            show: true,
            type: "error",
            title: `${i18n.t("Erro")}!`,
            text: i18n.t(isMultiple ? "Erro ao adicionar os perfis" : "Erro ao adicionar perfil"),
            cancelText: i18n.t("Fechar"),
            onCancel: () => {
                thunkAPI.dispatch(showAlert(false))
            },
        }))
        
        throw err
    }
    
})

export const addInfluencerSlice = createSlice({
    name: "addInfluencer",
    initialState: initialStateInfluencer,
    extraReducers: {
        [create.pending]: (state) => {
            state.status.create = StateStatus.loading
        },
        [create.fulfilled]: (state) => {
            state.status.create = StateStatus.succeeded
        },
        [create.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.create = StateStatus.failed
        }
    }
})

export const {
    updateIndex
} = addInfluencerSlice.actions

export const selectAddInfluencerSlice = (state) => state.addInfluencer