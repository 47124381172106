import React from "react"
import PropTypes from  "prop-types"
// 
import { Checkbox as AntCheckbox, Tooltip } from "antd"
// 
import "./index.scss"

const CheckBox = ({
    text,
    description,
    className,
    checked,
    indeterminate,
    onChange,
    tooltip,
}) => 
    <Tooltip title={tooltip}>
        <div className={`checkbox-container ${className}`}>
            <AntCheckbox 
                checked={checked} 
                indeterminate={indeterminate} 
                onChange={onChange}
            >
                {text && <span className="checkbox-text">{text}</span>}
            </AntCheckbox>

            {description && 
                <p className="checkbox-description"> 
                    {description}
                </p> 
            }
        </div> 
    </Tooltip> 

CheckBox.defaultProps = {
    className: "",
    onChange: () => {},
    checked: false,
    indeterminate: false,
}

CheckBox.propTypes = {
    text: PropTypes.any,
    className: PropTypes.string,
    description: PropTypes.any,
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onChange: PropTypes.func,
    tooltip: PropTypes.any,
}

export default CheckBox