import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../common/components/Button';
import CreateEditPlannerList from '../../../common/components/CreateEditPlannerList';
import Header from '../../../common/components/Header';
import Input from '../../../common/components/Input';
import { updateTabTitle } from '../../../utils/updateTabTitle';
import { clearPlannerList } from '../PlannerListCard/slice';

import PlannerListTable from './components/PlannerListTable';
import { clearList, fetch, selectPlannerListSlice } from './slice';

import { useSearchParams } from 'react-router-dom';
import './index.scss';

const PlannerList = () => {
  const [isOwner] = useState(false);
  const [selectedPl, updateSelectedPl] = useState();
  const [showCreateModal, updateShowCreateModal] = useState(false);
  const slice = useSelector(selectPlannerListSlice);
  const [inputValue, updateInputValue] = useState(slice.title);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.has('page')) {
      setSearchParams({
        page: 1,
        order: 'updated_at',
        desc: 'desc',
      });
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(clearList());
    dispatch(clearPlannerList());
  }, [isOwner]);

  const page = Number(searchParams.get('page') ?? 1);
  const order = searchParams.get('order');
  const desc = searchParams.get('desc');

  useEffect(() => {
    dispatch(
      fetch({
        page,
        order: order ?? undefined,
        desc: desc ? desc === 'desc' : undefined,
      }),
    );
  }, [page, order, desc]);

  updateTabTitle('Lista de planejamento - AirSearch');

  let delayTimer;

  return (
    <div className="pl-page background-page">
      <div className="pl-content scroll-content">
        <Header
          title={t('Planejamentos')}
          subtitle={t(
            'Use a lista de planejamento para comparar o desempenho dos influenciadores, simular custos e tirar insights para suas campanhas.',
          )}
          className="pl-header"
        />

        {showCreateModal && (
          <CreateEditPlannerList
            id={selectedPl?.id}
            name={selectedPl?.name}
            avatar={selectedPl?.avatar}
            isFavorite={selectedPl?.isFavorite}
            onClose={() => {
              updateSelectedPl();
              updateShowCreateModal(false);
            }}
          />
        )}

        <div className="pl-list">
          <div className="pl-actions">
            <Input
              isOutlined={true}
              defaultValue={slice.title}
              placeholder={t('Buscar uma lista')}
              prefix={<i className="icon icon-search" />}
              onChange={(e) => {
                clearTimeout(delayTimer);

                delayTimer = setTimeout(() => {
                  const text = e.target.value;

                  dispatch(clearList());

                  updateInputValue(text);

                  dispatch(fetch({ page: 1, owner: isOwner, title: text }));
                }, 500);
              }}
            />

            <Button
              icon="plus"
              className="pattern-dark"
              onClick={() => updateShowCreateModal(true)}
            >
              {t('Criar lista')}
            </Button>

            <Button
              icon="arrow-next"
              className="btn-search"
              onClick={() => {
                dispatch(fetch({ page: 1, owner: isOwner, title: inputValue }));
              }}
            >
              {t('Pesquisar')}
            </Button>
          </div>

          <PlannerListTable
            onEdit={(pl) => {
              updateShowCreateModal(true);

              updateSelectedPl(pl);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PlannerList;
