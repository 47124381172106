import { Layout } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Alert from '../../../common/components/Alert';
import { fetchFavorite, fetchLastLists } from '../../../common/components/ButtonModalLists/slice';
import Loading from '../../../common/components/Loading';
import { NetPromoterScore } from '../../../common/components/NetPromoterScore';
import SideMenu from '../../../common/components/SideMenu';
import { selectMenuSlice } from '../../../common/components/SideMenu/slice';
import {
  fetchNotificationsCounters,
  selectNotificationsSlice,
} from '../../../features/private/Notifications/slice';
import Routes from '../../../features/private/Routes';
import StateStatus from '../../../utils/status';
import { startMonitoring } from '../../../utils/userActions';
import {
  authUser,
  fetchCategoriesPlacesBrands,
  fetchShowNPS,
  fetchUser,
  selectAppSlice,
  sendAction,
} from '../../slice';

import './index.scss';

const delay = 5;

const PrivateApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const slice = useSelector(selectAppSlice);
  const menu = useSelector(selectMenuSlice);
  const notification = useSelector(selectNotificationsSlice);

  const user = slice.user;
  const status = slice.status;
  const collapsed = menu.collapsed;

  const loading =
    status.authUser === StateStatus.loading ||
    status.fetchUser === StateStatus.loading ||
    status.fetchCategoriesPlacesBrands === StateStatus.loading;
  const succeeded =
    status.fetchUser === StateStatus.succeeded &&
    status.fetchCategoriesPlacesBrands === StateStatus.succeeded;

  const query = new URLSearchParams(window.location.href.split('?')[1]);
  const ssid = query.get('redirect_token');
  const accessToken = window.localStorage.getItem('access_token');

  useEffect(() => {
    const auth = async () => {
      if (ssid) {
        await dispatch(authUser(ssid));
      } else if (accessToken) {
        await dispatch(fetchUser());
      } else {
        window.location.href = process.env.REACT_APP_HUB_URL + '?product=Search';
      }

      if (!window.localStorage.getItem('lang')) window.localStorage.setItem('lang', 'pt');
    };

    auth();
  }, []);

  useEffect(() => {
    const buildUser = async () => {
      if (accessToken && status.authUser === StateStatus.succeeded) {
        const lastAccessedPage = window.localStorage.getItem('last_accessed_page');

        let currURL = window.location.href;
        let afterDomain = currURL.substring(currURL.lastIndexOf('/#/') + 1);
        let beforeQueryString = afterDomain.split('?')[0];

        window.history.pushState('', 'Home', '' + beforeQueryString);

        if (lastAccessedPage && lastAccessedPage.length > 1) {
          navigate(lastAccessedPage);
        }

        await dispatch(fetchUser());
      }
    };

    buildUser();
  }, [status.authUser]);

  useEffect(() => {
    const buildPlannerList = () => {
      dispatch(fetchFavorite());
      dispatch(fetchLastLists());
      dispatch(fetchCategoriesPlacesBrands());
      dispatch(fetchShowNPS());
    };

    if (status.fetchUser === StateStatus.succeeded) {
      buildPlannerList();

      startMonitoring((event) => dispatch(sendAction({ ...event })));
    }
  }, [status.fetchUser]);

  useEffect(() => {
    if (
      status.fetchUser === StateStatus.succeeded &&
      notification.status.fetchNotificationsCounters === StateStatus.succeeded
    ) {
      let timer1 = setInterval(() => {
        dispatch(fetchNotificationsCounters());
      }, delay * 1000);

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [status.fetchUser, notification.status]);

  useEffect(() => {
    document.body.setAttribute('style', 'overflow: auto');

    if (location && !window.location.href.includes('?')) {
      window.localStorage.setItem('last_accessed_page', location.pathname);
    }

    if (window.clarity && status.fetchUser === StateStatus.succeeded) {
      if (user.email.includes('@airfluencers.com') || user.email.includes('@airstrip.com.br')) {
        window.clarity('set', 'USER_TYPE', 'AIRFLUENCERS');
      }
      window.clarity(
        'identify',
        user.email,
        null,
        location.pathname === '/' ? 'Home' : location.pathname.replace('/', ''),
      );
    }
  }, [location]);

  return (
    <Layout
      className={`layout-pattern ${!collapsed ? 'menu-open' : ''} ${loading ? 'in-loading' : ''}`}
    >
      {loading && <Loading />}

      {succeeded && (
        <>
          <SideMenu />
          <NetPromoterScore />
          {window?.location.hostname.includes('dev') && (
            <div className="dev-env" data-collapsed-menu={collapsed}>
              <span>Ambiente de desenvolvimento.</span>
            </div>
          )}

          <Routes />
        </>
      )}

      <Alert />
    </Layout>
  );
};

export default PrivateApp;
