import InfluencerModel from "./InfluencerModel"
import {
    calcDeliveryCharge
} from "../utils/formulas"

class ComparativeTableModel {
    id
    name
    profiles = []
    networks = []
    counters = new ComparativeTableCountersModel()
    isOwner
    // top values
    topAirscore = -1
    topEngagement = -1
    topFollowers = -1
    topPosts = -1
    //  
    topInteractions = {
        mean: {
            views: -1,
            likes: -1,
            comments: -1,
            total: -1
        },
        interactions: {
            views: -1,
            likes: -1,
            comments: -1,
            total: -1
        },
        maximum: {
            views: -1,
            likes: -1,
            comments: -1,
            total: -1
        },
        minimum: {
            views: -1,
            likes: -1,
            comments: -1,
            total: -1
        },
    }
    // 
    topThemes = -1
    topSubThemes = -1
    topBrands = -1
    // worst values
    worstAirscore = 1000000000
    worstEngagement = 1000000000
    worstFollowers = 1000000000
    worstPosts = 1000000000
    //  
    worstLikes = 1000000000
    worstViews = 1000000000
    worstComments = 1000000000
    worstInteractions = {
        mean: {
            views: 1000000000,
            likes: 1000000000,
            comments: 1000000000,
            total: 1000000000
        },
        interactions: {
            views: 1000000000,
            likes: 1000000000,
            comments: 1000000000,
            total: 1000000000
        },
        maximum: {
            views: 1000000000,
            likes: 1000000000,
            comments: 1000000000,
            total: 1000000000
        },
        minimum: {
            views: 1000000000,
            likes: 1000000000,
            comments: 1000000000,
            total: 1000000000
        },
    }
    // 
    worstThemes = 1000000000
    worstSubThemes = 1000000000
    worstBrands = 1000000000

    fromJson(data) {
        this.isOwner = data["is_owner"]

        this.name = data["list_name"] || data["name"]

        if (data["profiles"]) {
            this.networks = data["networks"]
            this.profiles = data["profiles"].map((item) => {
                const profile = new InfluencerModel().comparativeFromJson(item)

                this.counters.totalProfiles += 1
                this.counters.totalFollowers += profile.followers
                this.counters.totalInteractions += profile.interactions["total"] || 0
                this.counters.meanAirscore += profile.airscore


                // CHECK VALUES

                if (this.topAirscore === 0 || profile.airscore > this.topAirscore) this.topAirscore = profile.airscore
                if (this.topEngagement === 0 || profile.engagementRate > this.topEngagement) this.topEngagement = profile.engagementRate
                if (this.topFollowers === 0 || profile.followers > this.topFollowers) this.topFollowers = profile.followers
                if (this.topPosts === 0 || profile.quantPost > this.topPosts) this.topPosts = profile.quantPost
                if (this.topThemes === 0 || profile.themes > this.topThemes) this.topThemes = profile.themes
                if (this.topSubThemes === 0 || profile.subThemes > this.topSubThemes) this.topSubThemes = profile.subThemes
                if (this.topBrands === 0 || profile.brands > this.topBrands) this.topBrands = profile.brands

                if (this.worstAirscore === 1000000000 || profile.airscore < this.worstAirscore) this.worstAirscore = profile.airscore
                if (this.worstEngagement === 1000000000 || profile.engagementRate < this.worstEngagement) this.worstEngagement = profile.engagementRate
                if (this.worstFollowers === 1000000000 || profile.followers < this.worstFollowers) this.worstFollowers = profile.followers
                if (this.worstPosts === 1000000000 || profile.quantPost < this.worstPosts) this.worstPosts = profile.quantPost
                if (this.worstThemes === 1000000000 || profile.themes < this.worstThemes) this.worstThemes = profile.themes
                if (this.worstSubThemes === 1000000000 || profile.subThemes < this.worstSubThemes) this.worstSubThemes = profile.subThemes
                if (this.worstBrands === 1000000000 || profile.brands < this.worstBrands) this.worstBrands = profile.brands


                // mean interactions
                if (this.topInteractions.mean.comments === 0 || (profile.mean.comments || 0) > this.topInteractions.mean.comments) this.topInteractions.mean.comments = (profile.mean.comments || 0)
                if (this.topInteractions.mean.views === 0 || (profile.mean.views || 0) > this.topInteractions.mean.views) this.topInteractions.mean.views = (profile.mean.views || 0)
                if (this.topInteractions.mean.likes === 0 || (profile.mean.likes || 0) > this.topInteractions.mean.likes) this.topInteractions.mean.likes = (profile.mean.likes || 0)
                if (this.topInteractions.mean.total === 0 || (profile.mean.total || 0) > this.topInteractions.mean.total) this.topInteractions.mean.total = (profile.mean.total || 0)

                if (this.worstInteractions.mean.comments === 1000000000 || (profile.mean.comments || 0) < this.worstInteractions.mean.comments) this.worstInteractions.mean.comments = (profile.mean.comments || 0)
                if (this.worstInteractions.mean.views === 1000000000 || (profile.mean.views || 0) < this.worstInteractions.mean.views) this.worstInteractions.mean.views = (profile.mean.views || 0)
                if (this.worstInteractions.mean.likes === 1000000000 || (profile.mean.likes || 0) < this.worstInteractions.mean.likes) this.worstInteractions.mean.likes = (profile.mean.likes || 0)
                if (this.worstInteractions.mean.total === 1000000000 || (profile.mean.total || 0) < this.worstInteractions.mean.total) this.worstInteractions.mean.total = (profile.mean.total || 0)

                // // total interactions
                if (this.topInteractions.interactions.comments === 0 || profile.interactions.comments > this.topInteractions.interactions.comments) this.topInteractions.interactions.comments = profile.interactions.comments
                if (this.topInteractions.interactions.views === 0 || profile.interactions.views > this.topInteractions.interactions.views) this.topInteractions.interactions.views = profile.interactions.views
                if (this.topInteractions.interactions.likes === 0 || profile.interactions.likes > this.topInteractions.interactions.likes) this.topInteractions.interactions.likes = profile.interactions.likes
                if (this.topInteractions.interactions.total === 0 || profile.interactions.total > this.topInteractions.interactions.total) this.topInteractions.interactions.total = profile.interactions.total

                if (this.worstInteractions.interactions.comments === 1000000000 || profile.interactions.comments < this.worstInteractions.interactions.comments) this.worstInteractions.interactions.comments = profile.interactions.comments
                if (this.worstInteractions.interactions.views === 1000000000 || profile.interactions.views < this.worstInteractions.interactions.views) this.worstInteractions.interactions.views = profile.interactions.views
                if (this.worstInteractions.interactions.likes === 1000000000 || profile.interactions.likes < this.worstInteractions.interactions.likes) this.worstInteractions.interactions.likes = profile.interactions.likes
                if (this.worstInteractions.interactions.total === 1000000000 || profile.interactions.total < this.worstInteractions.interactions.total) this.worstInteractions.interactions.total = profile.interactions.total

                // minimum interactions
                if (this.topInteractions.minimum.comments === 0 || profile.minimum.comments > this.topInteractions.minimum.comments) this.topInteractions.minimum.comments = profile.minimum.comments
                if (this.topInteractions.minimum.views === 0 || profile.minimum.views > this.topInteractions.minimum.views) this.topInteractions.minimum.views = profile.minimum.views
                if (this.topInteractions.minimum.likes === 0 || profile.minimum.likes > this.topInteractions.minimum.likes) this.topInteractions.minimum.likes = profile.minimum.likes
                if (this.topInteractions.minimum.total === 0 || profile.minimum.total > this.topInteractions.minimum.total) this.topInteractions.minimum.total = profile.minimum.total

                if (this.worstInteractions.minimum.comments === 1000000000 || profile.minimum.comments < this.worstInteractions.minimum.comments) this.worstInteractions.minimum.comments = profile.minimum.comments
                if (this.worstInteractions.minimum.views === 1000000000 || profile.minimum.views < this.worstInteractions.minimum.views) this.worstInteractions.minimum.views = profile.minimum.views
                if (this.worstInteractions.minimum.likes === 1000000000 || profile.minimum.likes < this.worstInteractions.minimum.likes) this.worstInteractions.minimum.likes = profile.minimum.likes
                if (this.worstInteractions.minimum.total === 1000000000 || profile.minimum.total < this.worstInteractions.minimum.total) this.worstInteractions.minimum.total = profile.minimum.total


                // maximum interactions
                if (this.topInteractions.maximum.comments === 0 || profile.maximum.comments > this.topInteractions.maximum.comments) this.topInteractions.maximum.comments = profile.maximum.comments
                if (this.topInteractions.maximum.views === 0 || profile.maximum.views > this.topInteractions.maximum.views) this.topInteractions.maximum.views = profile.maximum.views
                if (this.topInteractions.maximum.likes === 0 || profile.maximum.likes > this.topInteractions.maximum.likes) this.topInteractions.maximum.likes = profile.maximum.likes
                if (this.topInteractions.maximum.total === 0 || profile.maximum.total > this.topInteractions.maximum.total) this.topInteractions.maximum.total = profile.maximum.total

                if (this.worstInteractions.maximum.comments === 1000000000 || profile.maximum.comments < this.worstInteractions.maximum.comments) this.worstInteractions.maximum.comments = profile.maximum.comments
                if (this.worstInteractions.maximum.views === 1000000000 || profile.maximum.views < this.worstInteractions.maximum.views) this.worstInteractions.maximum.views = profile.maximum.views
                if (this.worstInteractions.maximum.shares === 1000000000 || profile.maximum.shares < this.worstInteractions.maximum.shares) this.worstInteractions.maximum.shares = profile.maximum.shares
                if (this.worstInteractions.maximum.total === 1000000000 || profile.maximum.total < this.worstInteractions.maximum.total) this.worstInteractions.maximum.total = profile.maximum.total

                return profile
            })

            this.counters.meanAirscore /= this.counters.totalProfiles

            // PROFILES
            const profiles = data["profiles"]

            if(profiles && profiles[0]) {
                const network = profiles[0]["network"]

                this.counters.reachPotential = calcDeliveryCharge(this.counters.totalFollowers, network)
            }
        }

        return this
    }
}

class ComparativeTableCountersModel {
    totalProfiles = 0
    totalFollowers = 0
    meanAirscore = 0
    totalInteractions = 0
    reachPotential = 0
}

export default ComparativeTableModel