import { Modal as AntModal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TextArea from 'antd/lib/input/TextArea';
import iconSend from '../../../../../common/assets/icons/arrow-send.svg';
import iconMoreBlack from '../../../../../common/assets/icons/more-black.svg';
import iconMore from '../../../../../common/assets/icons/more-white.svg';
import unavailableValue from '../../../../../common/assets/images/empty.svg';
import AvatarNetwork from '../../../../../common/components/AvatarNetwork';
import Button from '../../../../../common/components/Button';
import Dropdown from '../../../../../common/components/DropDown';
import Loading from '../../../../../common/components/Loading';
import StateStatus from '../../../../../utils/status';
import {
  deleteNote,
  notes,
  postNote,
  selectInfluencerDetailsSlice,
  setNote,
  updateNote,
} from '../../slice';

import './index.scss';

const ModalRemarksAboutInfluencer = ({ onClose, isVisible, influencer }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const slice = useSelector(selectInfluencerDetailsSlice);

  const inputRef = useRef(null);
  const inputEditRef = useRef(null);

  const page = slice.notesPage;
  const pages = slice.notesPages;
  const status = slice.status;
  const list = slice.influencer.notes;

  const [comment, updateComment] = useState();
  const [showEdit, updateShowEdit] = useState(-1);
  const [showActions, updateShowActions] = useState(-1);
  const [commentHeight, updateCommentHeight] = useState(40);

  const getActions = ({ isFixed, isOwner }) =>
    [
      !isFixed && {
        key: 'fixed',
        label: t('Fixar no top'),
      },
      isOwner && {
        key: 'edit',
        label: t('Editar'),
      },
      isOwner && {
        key: 'delete',
        label: t('Remover'),
      },
    ].filter((item) => item);

  const sendMessage = () => {
    if (comment && comment.length > 0) {
      const replaceComment = comment.replace(/\r?\n/g, `\n`);

      dispatch(
        postNote({
          text: replaceComment,
          influencerId: influencer.id,
        }),
      );

      inputRef.current.resizableTextArea.textArea.setAttribute('style', `height: "40px"}`);

      updateComment();
    }
  };

  const sendEditedMessage = (key, id) => {
    let value = inputEditRef.current?.resizableTextArea
      ? inputEditRef.current.resizableTextArea.textArea?.value
      : null;

    if (value && value.length > 0) {
      dispatch(
        updateNote({
          id: id,
          index: key,
          text: value,
        }),
      );

      inputEditRef.current.resizableTextArea.textArea.setAttribute('style', `height: "40px"}`);

      updateShowEdit(-1);
    }
  };

  useEffect(() => {
    if (status.postNote === StateStatus.succeeded) {
      const list = document.querySelector('.comment-list');

      if (list) list.scrollTo(0, list.scrollHeight);
    }
  }, [status.postNote]);

  useEffect(() => {
    if (influencer && influencer.id)
      dispatch(
        notes({
          page: 1,
          influencerId: influencer.id,
        }),
      );
  }, [influencer]);

  return (
    <AntModal className="modal-comments" footer={false} open={isVisible} onCancel={onClose}>
      <div className="influencer">
        <AvatarNetwork src={influencer.avatar} network={influencer.network} />

        <div className="name-username">
          <span className="name">{influencer.name}</span>

          <span
            className="username hover"
            role="button"
            onClick={() => window.open(influencer.profileUrl)}
          >
            @{influencer.username}
          </span>
        </div>
      </div>

      <div className="box-comments">
        <p className="title">{t('Observações')}</p>

        <p className="subtitle">
          <i className="icon icon-lock" />
          {t('Compartilhe apenas com o seu time informações sobre este perfil.')}
        </p>

        <div className="fixed-comment"></div>

        <div className="comment-list">
          {list &&
            list.map((comment, key) => {
              if (showEdit === key) {
                return (
                  <div className="edit-comment" key={comment.id}>
                    <TextArea
                      ref={inputEditRef}
                      defaultValue={comment.text}
                      style={{ height: `${commentHeight}px` }}
                      placeholder={t('Digite uma nova observação')}
                      className="ant-input ant-input-lg input input-full"
                      onInput={({ target }) => {
                        target.setAttribute('style', `height: "5px"}`);
                        target.setAttribute('style', `height: ${target.scrollHeight + 'px'}`);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enterdasdas') sendEditedMessage(key, comment.id);
                      }}
                    />

                    <div className="buttons">
                      <Button className="outline-dark" onClick={() => updateShowEdit(-1)}>
                        {t('Cancelar')}
                      </Button>

                      <Button
                        isLoading={status.updateNote === StateStatus.loading}
                        onClick={() => sendEditedMessage(key, comment.id)}
                      >
                        {t('Salvar')}
                      </Button>
                    </div>
                  </div>
                );
              } else {
                const msg = `${comment.text}`;

                return (
                  <div className={`comment ${comment.isFixed ? 'fixed' : ''}`} key={key}>
                    <p className="text">{msg}</p>

                    <div className="about">
                      {comment.isEdited && <span className="is-edited">{t('Editado')}</span>}

                      <li className="author">{comment.author}</li>

                      <li className="date">{comment.updatedAt}</li>
                    </div>

                    {comment.isFixed && (
                      <span className="text-fixed">{t('Observação fixada')}</span>
                    )}

                    <Dropdown
                      options={getActions({
                        isFixed: comment.isFixed,
                        isOwner: comment.isOwner,
                      })}
                      optionsWithIcons={true}
                      overlayStyle={{ zIndex: 99999 }}
                      onSelect={(value) => {
                        if (value === 'delete') {
                          dispatch(deleteNote({ id: comment.id }));
                        } else if (value === 'edit') {
                          const elementList = document.querySelector('.comment-list');
                          const elementTexts = elementList?.querySelectorAll('.text');

                          if (elementTexts) {
                            updateCommentHeight(elementTexts[key].clientHeight);
                          }

                          updateShowEdit(key);
                        } else if (value === 'fixed') {
                          dispatch(
                            setNote({
                              index: key,
                              id: comment.id,
                              text: comment.text,
                              influencerId: influencer.id,
                            }),
                          );
                        }

                        updateShowActions(-1);
                      }}
                      onChange={(value) => {
                        updateShowActions(value ? key : value);
                      }}
                    >
                      <img
                        src={key === 0 ? iconMoreBlack : iconMore}
                        className={`hover icon-more icon-more-${
                          showActions === key ? 'open' : 'close'
                        }`}
                        alt="ícone de reticências"
                      />
                    </Dropdown>
                  </div>
                );
              }
            })}

          {status.notes === StateStatus.succeeded && (!list || list.length < 1) && (
            <div className="empty">
              <p>{t('Ainda não há nada por aqui...')}</p>

              <img className="unavailable-img" src={unavailableValue} alt="diversas nuvens azuis" />
            </div>
          )}

          {status.notes === StateStatus.loading && <Loading />}

          {status.notes == StateStatus.succeeded && page < pages && (
            <div
              className="show-more hover"
              onClick={() => {
                dispatch(
                  notes({
                    page: page + 1,
                    influencerId: influencer.id,
                  }),
                );
              }}
            >
              <span>{t('Ver mais')}</span>
              <i className="icon icon-down" />
            </div>
          )}
        </div>
      </div>

      <div className="input-box">
        <TextArea
          ref={inputRef}
          value={comment}
          placeholder={t('Digite uma nova observação')}
          className="ant-input ant-input-lg input input-full"
          onChange={({ target }) => updateComment(target.value)}
          onInput={({ target }) => {
            target.setAttribute('style', `height: "5px"}`);
            target.setAttribute('style', `height: ${target.scrollHeight + 'px'}`);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enterasdas') sendMessage();
          }}
        />

        <Button isLoading={status.postNote === StateStatus.loading} onClick={() => sendMessage()}>
          <img src={iconSend} className="icon-send" alt="ícone de seta" />
        </Button>
      </div>
    </AntModal>
  );
};

ModalRemarksAboutInfluencer.defaultProps = {
  isVisible: false,
};

ModalRemarksAboutInfluencer.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  influencer: PropTypes.object,
};

export default ModalRemarksAboutInfluencer;
