import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { selectAppSlice } from '../../../app/slice';
import aira from '../../../common/assets/images/aira.png';
import { changeObjAlert, showAlert } from '../../../common/components/Alert/slice';
import Button from '../../../common/components/Button';
import CreateEditPlannerList from '../../../common/components/CreateEditPlannerList';
import Header from '../../../common/components/Header';
import Loading from '../../../common/components/Loading';
import Modal from '../../../common/components/Modal';
import Radio from '../../../common/components/Radio';
import Select from '../../../common/components/Select';
import Tooltip from '../../../common/components/Tooltip';
import { humanize } from '../../../utils/formatters';
import StateStatus from '../../../utils/status';
import InfluencerPreview from '../Home/components/InfluencerPreview';
import { updateClearInfluencer } from '../InfluencerCard/slice';
import { download as exportPlannerList, selectPlannerListSlice } from '../PlannerList/slice';
import BriefingCollapse from '../PlannerListCard/components/BriefingCollapse';
import PlannerListFloatingActions from '../PlannerListCard/components/FloatingActions';
import PlannerListHeader from '../PlannerListCard/components/Header';
import {
  fetch as fetchPlDetails,
  selectPlannerListDetailsSlice,
  updatePlannerListAvatar,
  updatePlannerListName,
} from '../PlannerListCard/slice';

import SimulationTable from './components/SimulationTable';
import SkeletonComparativeTable from './components/SkeletonComparativeTable';
import SliderBenefits from './components/SliderBenefits';
import Table from './components/Table';
import { changePrice, fetch, selectComparativeTableSlice } from './slice';

import './index.scss';

const ComparativeTable = () => {
  const { t } = useTranslation();
  let { id, nw } = useParams();
  const query = new URLSearchParams(window.location.href.split('?')[1]);
  const wasRedirected = query.get('redirect');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appSlice = useSelector(selectAppSlice);
  const slice = useSelector(selectComparativeTableSlice);
  const plSlice = useSelector(selectPlannerListSlice);
  const plDetailsSlice = useSelector(selectPlannerListDetailsSlice);
  const [selectedInfluencer, updateSelectedInfluencer] = useState();
  const [selectedVariation, updateSelectedVariation] = useState('interactions');
  const [selectedFilterType, updateSelectedFilterType] = useState('performace');
  const [showEditModal, updateShowEditModal] = useState();
  const [showDetailing, updateShowDetailing] = useState(false);

  const [showFirstOpeningModal, updateShowFirstOpeningModal] = useState(false);
  const [showSliderBenefits, updateShowSliderBenefits] = useState(false);

  const data = slice.data;
  const status = slice.status;
  const counters = data.tableData.counters;
  const user = appSlice.user;
  const plDetails = plDetailsSlice.data;
  const plStatus = plSlice.status;
  const plDetailsStatus = plDetailsSlice.status.fetch;
  const airaUsedBefore = plDetailsSlice.airaUsedBefore;

  const filterTypes = [
    { label: t('Desempenho'), value: 'performace' },
    { label: t('Simulação de custos'), value: 'simulation' },
  ];
  const dataCards = [
    {
      alias: 'totalProfiles',
      text: t('Influenciadores na lista'),
      value: counters?.totalProfiles,
      tooltip: t('Quantidade total de perfis incluídos na lista.'),
    },
    {
      alias: 'meanAirscore',
      text: t('Airscore médio'),
      value: counters?.meanAirscore,
      tooltip: t('Média de Airscore da lista, levando em consideração todos os perfis incluídos.'),
    },
    {
      alias: 'totalFollowers',
      text: t('Total de seguidores'),
      value: counters?.totalFollowers,
      tooltip: t('Somatória do tamanho da base de seguidores dos perfis presentes na lista.'),
    },
    nw !== 'twitch' && {
      alias: 'reachPotential',
      text: t('Potencial de alcance'),
      value: counters?.reachPotential,
      tooltip: t(
        'Estimativa de alcance que as publicações podem impactar, totalizando todos os perfis presentes na lista.',
      ),
    },
    {
      alias: 'totalInteractions',
      text: t('Total de interações'),
      value: counters?.totalInteractions,
      tooltip: t('Quantidade total das interações de todos os perfis presentes na lista.'),
    },
  ].filter((item) => item);
  const variations = [
    { value: 'interactions', key: t('Total') },
    { value: 'mean', key: t('Média') },
    { value: 'maximum', key: t('Máxima') },
    { value: 'minimum', key: t('Mínima') },
  ];

  const closeInfluencerPreview = () => {
    const modal = document.querySelector('.influencer-preview-modal');

    if (modal) {
      modal.classList.add('closed');
      dispatch(updateClearInfluencer());

      setTimeout(() => {
        updateSelectedInfluencer();
      }, 250);
    }
  };

  useEffect(() => {
    if (plDetailsStatus === StateStatus.succeeded) {
      let currURL = window.location.href;
      let afterDomain = currURL.substring(currURL.lastIndexOf('/#/') + 1);
      let beforeQueryString = afterDomain.split('?')[0];

      if (plDetails.status === 'finished' && plDetails.createdByAira && wasRedirected) {
        if (plDetails.influencersCount && plDetails.influencersCount > 0) {
          if (!airaUsedBefore) {
            updateShowFirstOpeningModal(true);
          }
        } else {
          dispatch(
            changeObjAlert({
              show: true,
              type: 'error',
              title: t('Não encontramos combinações.'),
              text: t(
                'Infelizmente não encontramos influenciadores que combinam com as informações que você preencheu.',
              ),
              question: t(
                'Você pode adicionar manualmente clicando no ícone “+” nos resultados da sua busca de influenciadores. Ou tentar novamente.',
              ),
              //
              cancelText: t('Fechar'),
              onCancel: () => {
                dispatch(showAlert(false));
              },
              okText: t('Buscar influenciadores'),
              onOk: () => {
                navigate('/#/');
                dispatch(showAlert(false));
              },
            }),
          );
        }
      }

      if (plDetails.status !== 'finished') {
        setTimeout(() => dispatch(fetchPlDetails({ id })), 5000);
      } else {
        window.history.pushState('', t('Lista de planejamento'), '' + beforeQueryString);
        window.scrollTo(0, 0);
      }
    }
  }, [plDetailsStatus]);

  useEffect(() => {
    dispatch(fetchPlDetails({ id }));
  }, []);

  useEffect(() => {
    document.body.setAttribute('style', `overflow: ${selectedInfluencer ? 'hidden' : 'auto'}`);
  }, [selectedInfluencer]);

  useEffect(() => {
    if (plDetailsStatus === StateStatus.succeeded && plDetails.status === 'finished') {
      if (nw) {
        dispatch(fetch({ id, network: nw }));
      } else {
        if (plDetails.postsByNetworks[0] && plDetails.postsByNetworks[0].label) {
          const network = plDetails.postsByNetworks[0].label;

          if (network) {
            navigate(`/planner-lists/${id}/comparative-table/${network}`, {
              replace: true,
            });
          }
        }
      }
    }
  }, [nw, plStatus.list, plDetailsStatus]);

  return (
    <div className="ct-page background-page">
      {showEditModal && (
        <CreateEditPlannerList
          id={id}
          name={plDetails.name}
          avatar={plDetails.avatar}
          isFavorite={plDetails.isFavorite}
          onClose={(values) => {
            if (values) {
              const { name, url } = values;

              if (name) dispatch(updatePlannerListName(name));
              if (url) dispatch(updatePlannerListAvatar(url));
            }

            updateShowEditModal(false);
          }}
        />
      )}

      {selectedInfluencer && (
        <div className="influencer-preview-modal" onClick={() => closeInfluencerPreview()}>
          <div
            className="influencer-preview-modal-content"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <InfluencerPreview
              inTable={true}
              influencer={selectedInfluencer}
              onClose={() => closeInfluencerPreview()}
            />
          </div>
        </div>
      )}

      <div className="ct-content-container scroll-content">
        <div className="ct-content">
          <Button
            icon="arrow-back"
            className="outline-light btn-back"
            onClick={() => navigate('/planner-lists')}
          >
            {t('Voltar aos planejamentos')}
          </Button>

          {plDetails.id && plDetails.name && (
            <PlannerListHeader inComparativeTable={true} onEdit={() => updateShowEditModal(true)} />
          )}

          {['assistant_creating', 'assistant_capturing', 'assistant_scoring', 'started'].includes(
            plDetails.status,
          ) ||
          (plDetails.createdByAira && plDetails.status === 'waiting') ? (
            <Row>
              {plDetails?.assistantData ? (
                <Col span={24}>
                  <BriefingCollapse pl={plDetails} />
                </Col>
              ) : null}
              <Col span={24}>
                <div className="empty-list">
                  <div>
                    <Loading />
                  </div>
                  <p className="text-empty">{t('Aguarde...')}</p>
                  <p className="question-empty">{t('Aira está buscando os influenciadores!')}</p>
                </div>
              </Col>
            </Row>
          ) : plDetails.influencersCount <= 0 ? (
            <Row>
              <Col span={24}>
                <div className="empty-list">
                  <p className="text-empty">{t('Ops! Ainda não há influenciadores nesta lista')}</p>
                  <p className="question-empty">{t('Deseja adicionar influenciadores?')}</p>

                  <Button onClick={() => navigate('/')}>{t('Buscar influenciadores')}</Button>
                </div>
              </Col>
              {plDetails?.assistantData ? (
                <Col span={24}>
                  <BriefingCollapse pl={plDetails} />
                </Col>
              ) : null}
            </Row>
          ) : (
            <>
              {status.tableData === StateStatus.succeeded &&
                plDetails.status === 'finished' &&
                plDetails.influencersCount > 0 && (
                  <>
                    {showFirstOpeningModal && (
                      <Modal
                        title={!showSliderBenefits ? t('Aira') : ''}
                        className="aira-modal"
                        isVisible={showFirstOpeningModal}
                        onCancel={() => {
                          updateShowFirstOpeningModal(false);
                        }}
                        onClose={() => {
                          updateShowFirstOpeningModal(false);
                        }}
                      >
                        {showSliderBenefits && (
                          <SliderBenefits
                            onBack={() => updateShowSliderBenefits(false)}
                            onClose={() => updateShowFirstOpeningModal(false)}
                          />
                        )}

                        {!showSliderBenefits && (
                          <>
                            <img src={aira} alt="mulher  do cabelo preto sorrindo" />

                            <p>
                              Olá, {user.name}!
                              <br />
                              Aqui esta nossa primeira curadoria.
                              <br />
                              Confira a seguir algumas vantagens que teremos com o nosso
                              planejamento.
                            </p>

                            <Button onClick={() => updateShowSliderBenefits(true)}>
                              {t('Avançar')}
                            </Button>
                          </>
                        )}

                        <Button
                          className="outline-light btn-next"
                          onClick={() => {
                            updateShowSliderBenefits(false);
                            updateShowFirstOpeningModal(false);
                          }}
                        >
                          {t('Pular')}
                        </Button>
                      </Modal>
                    )}

                    <Header
                      title={`${t('Tabela de comparação')}${data.name ? ` - ${data.name}` : ''}`}
                      className="home-header"
                    ></Header>

                    {plDetails.assistantData && (
                      <Col span={24}>
                        <BriefingCollapse pl={plDetails} />
                      </Col>
                    )}

                    <div className="ct-actions">
                      <div className="filter-networks">
                        {status.tableData === StateStatus.succeeded && (
                          <Radio
                            value={nw}
                            options={data.tableData.networks.map((item) => ({
                              value: item,
                              label: item,
                              icon: item,
                            }))}
                            onChange={(value) => {
                              navigate(`/planner-lists/${id}/comparative-table/${value}`);
                            }}
                          />
                        )}
                      </div>

                      <div className="buttons">
                        <Button
                          icon="download"
                          className="outline-dark"
                          loading={plStatus.download === StateStatus.loading}
                          onClick={() =>
                            dispatch(
                              exportPlannerList({
                                id: id,
                                name: plDetails.name,
                              }),
                            )
                          }
                        >
                          {t('Exportar influenciadores')}
                        </Button>

                        <Button
                          icon="download"
                          className="outline-dark"
                          onClick={() =>
                            window.open(`/#/planner-lists/${id}/comparative-table/${nw}/pdf`)
                          }
                        >
                          {t('Baixar tabela')}
                        </Button>
                      </div>
                    </div>

                    {plSlice.createdByAira && status.tableData === StateStatus.succeeded && (
                      <div className="detailing">
                        <div className="detailing-title">
                          <span className="title">{t('Meu detalhamento')} </span>

                          {
                            <Button
                              className="outline-light"
                              onClick={() => updateShowDetailing(!showDetailing)}
                            >
                              {t(showDetailing ? 'Ver mais' : 'Ver menos')}

                              <span className={`arrow ${showDetailing ? '' : 'open'}`} />
                            </Button>
                          }
                        </div>

                        <div className={`detailing-text ${showDetailing ? 'expanded' : 'simple'}`}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus odio
                          mauris, pretium id molestie id, vehicula sit amet mi. Suspendisse id odio
                          sollicitudin, vehicula ante a, commodo mauris. Sed euismod mi egestas,
                          fermentum justo a, tincidunt nisl. Quisque finibus quam ac pretium porta.
                          Maecenas vel ornare purus. Sed lacinia, turpis quis lobortis dictum, nulla
                          nunc tristique nibh, sed finibus ipsum sem non risus. Donec in ultrices
                          libero. Nunc eleifend eros metus, vitae pellentesque elit hendrerit eget.
                          Proin non mi pulvinar, consequat ex vel, fermentum ligula. Sed commodo ex
                          a varius elementum. Nulla facilisi. Interdum et malesuada fames ac ante
                          ipsum primis in faucibus. Phasellus eu felis elit. Fusce eget libero
                          tincidunt, pellentesque turpis ac, volutpat sapien. Donec rutrum blandit
                          leo, at auctor magna imperdiet a. Donec accumsan elementum mi malesuada
                          auctor. Vivamus dictum tortor sapien, sed sollicitudin lectus viverra ac.
                          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
                          cubilia curae Donec ullamcorper pretium nisl, et cursus justo pharetra sit
                          amet.
                        </div>
                      </div>
                    )}

                    <div className="part-table">
                      <div className={`ct-cards ct-cards-${dataCards.length}`}>
                        {status.tableData === StateStatus.succeeded &&
                          dataCards.map((item, key) => {
                            return (
                              <div key={key} className="ct-card">
                                <span className="ct-card-title">{item.text}</span>

                                <span className="ct-card-value">{humanize(item.value)}</span>

                                {item.tooltip && <Tooltip child={item.tooltip} />}
                              </div>
                            );
                          })}
                      </div>

                      <div className="ct-table">
                        {status.tableData === StateStatus.succeeded && (
                          <>
                            <div className="filter-types">
                              {filterTypes.map((item) => (
                                <div
                                  key={item.value}
                                  className={`filter-type-option ${
                                    selectedFilterType === item.value ? 'selected' : 'unselected'
                                  }`}
                                  onClick={() => updateSelectedFilterType(item.value)}
                                >
                                  {item.label}
                                </div>
                              ))}

                              <div className={`underline ${selectedFilterType}`} />
                            </div>

                            <p className="select-label">{t('Variação')}</p>

                            <Select
                              options={variations}
                              defaultValue={selectedVariation}
                              placeholder={t('Selecione uma variação')}
                              onChange={(value) => updateSelectedVariation(value)}
                            />

                            {selectedFilterType === 'performace' && (
                              <Table
                                id={id}
                                variation={selectedVariation}
                                influencers={data.tableData.profiles}
                                openModal={(influencer) => {
                                  updateSelectedInfluencer(influencer);
                                }}
                              />
                            )}

                            {selectedFilterType === 'simulation' && (
                              <SimulationTable
                                key="simulation"
                                variation={selectedVariation}
                                influencers={data.tableData.profiles}
                                openModal={(influencer) => {
                                  updateSelectedInfluencer(influencer);
                                }}
                                onChangePrice={(price, profileId) => {
                                  if (price != null && price >= 0 && data.tableData.isOwner) {
                                    dispatch(
                                      changePrice({
                                        id,
                                        price,
                                        profileId,
                                      }),
                                    );
                                  }
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <PlannerListFloatingActions onEdit={() => updateShowEditModal(true)} />
                  </>
                )}

              {status.tableData === StateStatus.loading && plDetails.status === 'finished' && (
                <SkeletonComparativeTable />
              )}

              {status.tableData === StateStatus.failed && t('ERRO')}

              {plDetailsStatus === StateStatus.loading && <Loading />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComparativeTable;
