import React from "react"
import PropTypes from "prop-types"
// 
import { Tooltip as AntTooltip } from "antd"
// 
import tooltip from "../../assets/icons/tooltip.svg"
import "./index.scss"

const Tooltip = ({
    child
}) => (
    <AntTooltip title={child}>
        <div className="tooltip">
            <img className="tooltip-icon" src={tooltip} />
        </div>
    </AntTooltip>
)

Tooltip.propTypes = {
    child: PropTypes.string
}
export default Tooltip
