import React from "react"
import PropTypes from "prop-types"
// 
import { Avatar as AntdAvatar } from "antd"
import { AirUtils } from "../../../utils/"
// 
import "./index.scss"

const Avatar = ({
    size,
    src,
    network,
    onClick,
}) => {
    const utils = new AirUtils()

    return <AntdAvatar 
        src={utils.getThumbnail(src)} 
        style={{ width: size, height: size }}
        className={`avatar avatar-${network} ${onClick ? "hover" : ""}`} 
        onClick={() => onClick && onClick()} 
    />
}

Avatar.defaultProps = {
    size: 35,
    src: "",
    network: "",
}

Avatar.propTypes = {
    size: PropTypes.number,
    network: PropTypes.string,
    src: PropTypes.string,
    onClick: PropTypes.func
}

export default Avatar