import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
// 
import { Modal } from "antd"
import Button from "../Button"
// 
import "./index.scss"
  
const TranslateModal = ({ 
    show, 
    hideModal 
}) => {
    const { t } = useTranslation()

    const langs = [
        { label: t("Português"), key: "pt" },
        { label: t("Inglês"), key: "en" },
        { label: t("Espanhol"), key: "span" },
    ]

    const changeLanguage = (lang) => {
        if(window.localStorage.getItem("lang") != lang) {
            window.localStorage.setItem("lang", lang)
            window.location.reload()
        }

        hideModal()
    }

    return (
        <Modal
            open={show}
            onCancel={hideModal}
            className="translate-modal"
        >
            <p className="title-translate-modal">{t("Selecione o idioma desejado")}</p>

            <div className="buttons">
                {langs.map((lang, key) => (
                    <Button 
                        key={key}
                        className={"button-" + lang.key}
                        outline={window.localStorage.getItem("lang") != lang.key}
                        onClick={() => changeLanguage(lang.key)}
                    >
                        {lang.label}
                    </Button>
                ))}
            </div>
        </Modal>
    )  
}

TranslateModal.defaultProps = {
    show: false,
    hideModal: () => {},
}

TranslateModal.propTypes = {
    show: PropTypes.bool,
    hideModal: PropTypes.func,
}
  
export default TranslateModal