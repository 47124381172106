import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import aira from '../../../../../common/assets/images/aira.png';
import noAvatar from '../../../../../common/assets/images/no-picture-pl.svg';
import Button from '../../../../../common/components/Button';
import StateStatus from '../../../../../utils/status';
import { updateTabTitle } from '../../../../../utils/updateTabTitle';

import { selectPlannerListSlice } from '../../../PlannerList/slice';
import { selectPlannerListDetailsSlice } from '../../slice';

import './index.scss';

const PlannerListHeader = ({ onEdit, avatar, toPDF, inComparativeTable }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const slice = useSelector(selectPlannerListDetailsSlice);
    const plannerListsSlice = useSelector(selectPlannerListSlice);

    const pl = slice.data;
    const statusExport = plannerListsSlice.status.download;

    updateTabTitle(`${pl.name} - AirSearch`);

    return (
        <div className="pl-profile">
            {pl.avatar ? (
                <div className="pl-avatar">
                    <div style={{ backgroundImage: `url(${avatar ? avatar : pl.avatar})` }} />
                </div>
            ) : (
                <div className="empty-avatar">
                    <img src={noAvatar} />
                </div>
            )}

            <div className="pl-data">
                <div className="name-edit-values">
                    <p className="name">{pl.name}</p>

                    {pl.createdByAira && (
                        <div className="created-by-aira">
                            <img src={aira} alt="mulher  do cabelo preto sorrindo" />

                            <span>{t('Criado com inteligência artificial')}</span>
                        </div>
                    )}

                    {!toPDF && pl.isOwner && (
                        <div role="button" className="btn-edit hover" onClick={() => onEdit()}>
                            {t('Editar')}
                        </div>
                    )}

                    <div className="data-values">
                        <div className="data-value">
                            <i className="icon icon-folder-shared-line" />

                            <p>
                                {t('Criado por')}: {pl.createdBy}
                            </p>
                        </div>

                        <div className="data-value">
                            <i className="icon icon-calendar-month" />

                            <p>
                                {t('Criada em')}: {pl.date}
                            </p>
                        </div>
                    </div>
                </div>

                {!toPDF && pl.influencersCount > 0 && (
                    <div className="pl-actions">
                        <Button
                            icon="download"
                            isLoading={statusExport === StateStatus.loading}
                            isDisabled={statusExport === StateStatus.loading}
                            className="outline-dark"
                            onClick={() => window.open(`/#/planner-lists/${pl.id}/card/pdf`)}
                        >
                            {t('Baixar card')}
                        </Button>

                        <Button
                            onClick={() => {
                                if (inComparativeTable) {
                                    if (
                                        pl.postsByNetworks &&
                                        pl.postsByNetworks[0] &&
                                        pl.postsByNetworks[0].label
                                    ) {
                                        navigate(`/planner-lists/${pl.id}/card`);
                                    }
                                } else {
                                    if (
                                        pl.postsByNetworks &&
                                        pl.postsByNetworks[0] &&
                                        pl.postsByNetworks[0].label
                                    ) {
                                        navigate(
                                            `/planner-lists/${pl.id}/comparative-table/${pl.postsByNetworks[0].label}`,
                                        );
                                    }
                                }
                            }}
                        >
                            {t(inComparativeTable ? 'Card da lista' : 'Tabela de comparação')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

PlannerListHeader.defaultProps = {
    toPDF: false,
    inComparativeTable: true,
    onEdit: () => {},
};

PlannerListHeader.propTypes = {
    onEdit: PropTypes.func,
    toPDF: PropTypes.bool,
    inComparativeTable: PropTypes.bool,
    avatar: PropTypes.string,
};

export default PlannerListHeader;
