import React from "react";
import PropTypes from "prop-types";
// 
import "./index.scss";

const Tabs = ({
    tabs,
    selected,
    onSelect, 
    tabWidth
}) => {
    // tabs example: { value: 'bla', 'label': 'Blá' }

    return <div className="tabs">
        {tabs.map(item => 
            <div 
                key={item.value}
                className={`tab-option ${selected === item.value ? "selected"  : "unselected"}`}
                style={{ width: tabWidth }}
                onClick={() => onSelect(item.value)}
            >
                {item.label}

                {item.count != null && item.count > 0 && 
                    <div className="count">
                        {item.count}
                    </div>
                }
            </div>
        )}

        <div    
            className="underline"
            style={{
                width: tabWidth,
                left: tabs.findIndex(item => selected === item.value) > -1 ? (tabs.findIndex(item => selected === item.value) * tabWidth) : 0,
            }} 
        />
    </div>
}

Tabs.defaultValue = {
    tabs: [],
    tabWidth: 153,
}

Tabs.propTypes = {
    tabWidth: PropTypes.number,
    selected: PropTypes.string,
    onSelect: PropTypes.func,
    tabs: PropTypes.arrayOf(PropTypes.object),
}

export default Tabs