import React from 'react';
import { useTranslation } from 'react-i18next';
import { Routes as AntRoutes, Route } from 'react-router-dom';
import AiraCuration from './AiraCuration';
import AiraCurationFormEmail from './AiraCurationFormEmail';
import AiraCurationResult from './AiraCurationResult';
import AiraTasting from './AiraTasting';
import InfluencerCard from './InfluencerCard';

import pageErrorImage from '../../common/assets/images/page-error.png';

const Routes = () => {
  const { t } = useTranslation();

  return (
    <AntRoutes>
      <Route exact path="/aira-demonstracao" element={<AiraTasting />} />
      <Route path="/aira-demonstracao/briefing" element={<AiraCuration />} />
      <Route path="/aira-demonstracao/briefing/result" element={<AiraCurationResult />} />
      <Route path="/aira-demonstracao/briefing/:id/form" element={<AiraCurationFormEmail />} />
      <Route
        path="/aira-demonstracao/briefing/:briefingId/:influencerId"
        element={<InfluencerCard />}
      />

      <Route
        path="*"
        element={
          <div className="page-not-found">
            <div className="feedback-error">
              <img
                src={pageErrorImage}
                alt={t(
                  'um homem e duas mulheres trabalhando com seus notebooks sentados em um notebook gigante',
                )}
              />

              <span>
                <b>{t('Esta página não existe')}.</b>
              </span>
            </div>
          </div>
        }
      />
    </AntRoutes>
  );
};

export default Routes;
