import React from 'react';
import { useLocation } from 'react-router-dom';
//
import PrivateApp from './components/private';
import PublicApp from './components/public';
//
import './index.scss';

const App = () => {
  const location = useLocation();
  //
  const publicRoute = '/aira-demonstracao';

  return (
    <>
      {!location.pathname.includes(publicRoute) && <PrivateApp />}

      {location.pathname.includes(publicRoute) && <PublicApp />}
    </>
  );
};

export default App;
