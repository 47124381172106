import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// 
import { addInfluencerInPL, createPlannerList, deleteInfluencerInPL, getPlannerListsDetailed, getPlannerListSimple } from "../../../features/private/PlannerList/service"
import StateStatus from "../../../utils/status"
import PlannerListModel from "../../../models/PlannerListModel"
import feedback from "../Feedback"
import i18n from "../../../languages/i18n"

const initialState = {
    status: {
        modalPLCreate: StateStatus.idle,
        modalPLToggle: StateStatus.idle,
        fetchLastLists: StateStatus.idle,
        fetchFavorite: StateStatus.idle,
    },
    list: [],
    title: null,
    favorite: new PlannerListModel(),
}

export const fetchFavorite = createAsyncThunk("modal-list/planner-list/favorite", async (_, thunkAPI) => {
    try {
        const { app } = thunkAPI.getState()

        const result = await getPlannerListSimple("favorite")

        return {
            data:result.data,
            owner: app.user.name,
        }
    } catch (err) {
        console.debug(err)
        
        throw err
    }
})

export const fetchLastLists = createAsyncThunk("modal-list/planner-list/fetch-list", async (title) => {
    try {
        const data = {
            page: 1,
            owner: true,
            desc: true,
            title: title,
        }

        const result = await getPlannerListsDetailed(data)

        return {
            title: title,
            data: result.data
        }
    } catch (err) {
        console.debug(err)
        
        throw err
    }
})

export const modalPLCreate = createAsyncThunk("modal-list/planner-list/create", async (title) => {
    const result = await createPlannerList(title)

    return result.data
})

export const modalPLToggle = createAsyncThunk("modal-list/planner-list/toggle-influencer", async ({
    id,
    include,
    influencerId,
}) => {
    try {
        const result = !include ? 
            await addInfluencerInPL(id, {
                "price": 1,
                "profile_id": influencerId,
            })
            :
            await deleteInfluencerInPL(id, {
                "price": 1,
                "profile_id": influencerId,
            })

        return {
            isInclude: !include,
            isFavorite: influencerId === "favorite",
            influencerId: influencerId,
            ...result.data,
        }

    } catch (err) {
        feedback.error("Erro ao incluir/excluir influenciador da lista")

        throw err
    }
})

export const modalPlannerListsSlice = createSlice({
    name: "modalPlannerLists",
    initialState,
    extraReducers: {
        [fetchFavorite.pending]: (state) => {
            state.status.fetchFavorite = StateStatus.loading
        },
        [fetchFavorite.fulfilled]: (state, { payload }) => {
            let favorite = new PlannerListModel().fromJson(payload.data, true)

            state.favorite = favorite
            
            state.status.fetchFavorite = StateStatus.succeeded
        },
        [fetchFavorite.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.fetchFavorite = StateStatus.failed
        },
        // 
        [fetchLastLists.pending]: (state) => {
            state.status.fetchLastLists = StateStatus.loading
        },
        [fetchLastLists.fulfilled]: (state, { payload }) => {
            let filteredList = []
            // 
            const list = payload["data"]["items"]
            
            if(list && list.length > 0) {
                list.map(item => {
                    const model = new PlannerListModel()
                    filteredList = [...filteredList, model.fromJson(item)]
                })
            }
            
            state.list = filteredList
            state.title = payload.title
            state.status.fetchLastLists = StateStatus.succeeded
        },
        [fetchLastLists.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.fetchLastLists = StateStatus.failed
        },
        // 
        // create
        [modalPLCreate.pending]: (state) => {
            state.status.modalPLCreate = StateStatus.pending
        },
        [modalPLCreate.fulfilled]: (state, {
            payload
        }) => {
            state.list.push(new PlannerListModel().fromJson(payload))

            feedback.success(i18n.t("Lista criada com sucesso"))
            state.status.modalPLCreate = StateStatus.succeeded
        },
        [modalPLCreate.rejected]: (state, action) => {
            feedback.error(i18n.t("Erro ao criar lista"))

            state.errorMsg = action.error.message
            state.status.modalPLCreate = StateStatus.failed
        },
        // toggle list
        [modalPLToggle.pending]: (state) => {
            state.status.modalPLToggle = StateStatus.loading
        },
        [modalPLToggle.fulfilled]: (state, {
            payload
        }) => {
            const index = state.list.findIndex(item => item.id === payload.uid)

            if(state.list && state.list.length > 0 && state.list[index] && state.list[index].influencerIds) {
                if(payload.isInclude) {
                    state.list[index].influencerIds = [...state.list[index].influencerIds, payload.influencerId]
                } else {
                    state.list[index].influencerIds = state.list[index].influencerIds.filter(id => id != payload.influencerId)
                }
            }
            
            state.status.modalPLToggle = StateStatus.succeeded
        },
        [modalPLToggle.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.modalPLToggle = StateStatus.failed
        },
    }
})

export const selectModalPlannerListSlice = (state) => state.modalPlannerLists

export const selectLastLists = (state) => state.modalPlannerLists.list

export const selectPLFavorite = (state) => state.modalPlannerLists.favorite
