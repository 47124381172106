import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
// 
import { useDispatch, useSelector } from "react-redux"
import { fetchLastLists, selectModalPlannerListSlice } from "../../../../../common/components/ButtonModalLists/slice"
import { create, selectAddInfluencerSlice } from "../../slice"
import StateStatus from "../../../../../utils/status"
// 
import { Form } from "antd"
import Button from "../../../../../common/components/Button"
import SearchSelect from "../../../../../common/components/SearchSelect"

const { useForm } = Form

const FormAddInfluecers = () => {
    const { t } = useTranslation()
    // 
    const [form] = useForm()
    const inputFile = useRef()
    const dispatch = useDispatch()
    // 
    const slice = useSelector(selectAddInfluencerSlice)
    const modalPlSlice = useSelector(selectModalPlannerListSlice)
    // 
    const status = slice.status.create
    // 
    const plList = modalPlSlice.list
    const title = modalPlSlice.title
    const favorite = modalPlSlice.favorite
    // 
    let delayTimer
    // 
    const [fileName, updateFileName] = useState()
    const [fileInBox, updateFileInBox] = useState(false)

    const downloadModel = () => {
        const fileText = `https://www.facebook.com/Airfluencers\r\nhttps://www.instagram.com/Airfluencers\r\nhttps://www.youtube.com/channel/UCq0vGACTQ5MCRaF1hYzu9lA\r\nhttps://www.tiktok.com/@airfluencers\r\nhttps://www.twitter.com/Airfluencers`
        const fileName = "modelo.txt"

        const blob = new Blob([fileText], { type: "text/plain" }),
            e = document.createEvent("MouseEvents"),
            a = document.createElement("a")

        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        a.dataset.downloadurl = ["text/plain", a.download, a.href].join(":")
        e.initEvent("click", true, false)
        a.dispatchEvent(e)
    }

    const setFile = (files) => {
        if (files[0] && files[0].name) {
            updateFileName(files[0].name)

            const reader = new FileReader()

            reader.onload = () => {
                //eslint-disable-next-line
                const urls = reader.result.replace(/\r/g, "").split(/\n/).filter(item => (item && item.length > 0))

                form.setFieldValue("url", urls)
            }
            
            reader.readAsText(files[0])
        }
    }

    const onFinish = ({ url, planner_list_id }) => {
        if(url) {
            dispatch(create({
                isMultiple: true,
                data: { profiles: url, planner_list_id }
            }))
        }
    }

    useEffect(() => {
        if(status === StateStatus.succeeded) {
            inputFile.current.value = ""
            
            updateFileName()
            
            form.resetFields()
        }
    }, [status])

    return <div className="form-add-influencer">
        <h2>{t("Adicione vários influenciadores de uma vez")}:</h2>

        <p>{t("Os campos com (*) são obrigatórios")}</p>

        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="multiple-influencers"
        >
            <Form.Item
                name="url"
                className="form-items"
                label={`${t("Anexar arquivo")}:`}
                rules={[
                    { required: true, message: null },
                    () => ({
                        validator(_, value) {
                            if (value) {
                                return Promise.resolve()
                            } else {
                                return Promise.reject(new Error("Informe uma arquivo válido"))
                            }
                        },
                    }),
                ]}
            >
                <div
                    onDrop={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        setFile(e.dataTransfer.files)
                    }}
                    onDragOver={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    onDragEnter={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        updateFileInBox(true)
                    }}
                    onDragLeave={() => {
                        updateFileInBox(false)
                    }}
                    className={`box-file-name ${fileName ? "selected" : ""} ${(!fileName && fileInBox) ? "hover-file" : ""}`}
                >
                    {fileName &&
                        <>
                            <i className="icon icon-file" />
                            <span>{fileName}</span>
                        </>
                    }

                    {!fileName && (
                        fileInBox ?
                            <>
                                <i className="icon icon-file" />
                                <p className="input-file-title">{t("Solte o arquivo")}</p>
                            </>
                            :
                            <>
                                <p className="input-file-title">{t("Arraste ou solte")}</p>

                                <p className="input-file-text">
                                    {t("o arquivo aqui, ou")}

                                    <span
                                        className="hover"
                                        onClick={() => {
                                            inputFile.current.click()
                                        }}
                                    >
                                        {t("busque no seu computador")}
                                    </span>
                                </p>
                            </>
                    )}
                </div>

                <input
                    readOnly
                    ref={inputFile}
                    type="file"
                    className="input-file"
                    accept="txt"
                    onChange={(e) => setFile(e.target.files)}
                />

                {fileName &&
                    <ipossui
                        className="icon icon-close hover"
                        onClick={() => {
                            inputFile.current.value = ""
                            updateFileName()
                            updateFileInBox(false)
                        }}
                    />
                }
            </Form.Item>

            <p className="text-download-model">
                {t("Não possui um arquivo de modelo")}? <span className="hover" onClick={() => downloadModel()}>{t("Baixar arquivo modelo")}</span>
            </p>

            <Form.Item
                name="planner_list_id"
                className="form-items"
                label={`${t("Adicionar em uma lista")}: `}
            > 
                <SearchSelect
                    isLoading={modalPlSlice.status.fetchLastLists === StateStatus.loading}
                    options={[
                        (!title || title === "") && { key: favorite.name, value: favorite.id },
                        ...plList.map(list => ({ key: list.name, value: list.id }))
                    ]}
                    placeholder={t("Busque e selecione uma lista")}
                    onChange={(item) => form.setFieldValue("planner_list_id", item.value)}
                    onSearch={(text) => {
                        clearTimeout(delayTimer)

                        delayTimer = setTimeout(() => {
                            dispatch(fetchLastLists(text))
                        }, 500);
                    }}
                />
            </Form.Item>

            <div className="description">
                <p><b>{t("Atenção")}</b></p>

                <p>{t("Os perfis adicionados podem levar até 24 horas para aparecer na plataforma")}.</p>
            </div>

            <Button
                isLoading={status === StateStatus.loading}
                onClick={() => form.submit()}
            >
                {t("Concluir")}
            </Button>
        </Form>
    </div>
}

export default FormAddInfluecers