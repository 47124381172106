import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
// 
import { useDispatch, useSelector } from "react-redux"
import { fetch, selectInfluencerDetailsPublicSlice } from "./slice"
import StateStatus from "../../../utils/status"
// 
import Loading from "../../../common/components/Loading"
// 
import InfluencerHeader from "./components/Header"
import PartInteractions from "./components/PartInteractions"
import PartPersona from "./components/PartPersona"
// 
import pageErrorImage from "../../../common/assets/images/page-error.png"
// 
import "./index.scss"

const InfluencerCard = () => {
    const { t } = useTranslation()
    // 
    const { briefingId, influencerId } = useParams()
    // 
    const dispatch = useDispatch()
    const slice = useSelector(selectInfluencerDetailsPublicSlice)
    // 
    const status = slice.status
    const influencer = slice.influencer
    
    useEffect(() => {
        dispatch(fetch({ 
            briefingId: briefingId, 
            influencerId: influencerId
        }))
    }, [])

    return <div
        className={`influencer-public-card background-page ${status.fetch === StateStatus.loading ? "page-loading" : ""}`}
    >   
        <div className="influencer-card-container scroll-content">
            <div className="influencer-card-content">
                {influencer.username && influencer.network &&
                    <InfluencerHeader
                        influencerId={influencerId}
                    />
                }

                {status.fetch === StateStatus.succeeded &&
                    <>
                        <div className="content">
                            <PartInteractions />

                            <PartPersona />
                        </div>
                    </>
                }

                {status.fetch === StateStatus.loading && <Loading />}

                {status.fetch === StateStatus.failed && 
                    <div className="feedback-error">
                        <img src={pageErrorImage} alt={t("um homem e duas mulheres trabalhando com seus notebooks sentados em um notebook gigante")} />

                        <span>{t("Poxa, não conseguimos carregar esta página")}.</span>

                        <span><b>{t("Por favor, tente novamente mais tarde")}.</b></span>
                    </div>
            }
            </div>
        </div>
    </div>
}

export default InfluencerCard