class BrandsModel {
    object

    fromJson(data) {
        const obj = {}

        Object.keys(data["brands"]).forEach(brand => {
            const label = data["brands"][brand]["labels"]["1"] || data["brands"][brand]["labels"]["2"]

            if(label) {
                obj[brand] = label
            }
        })
        
        this.object = obj

        return this
    }
}

export default BrandsModel