import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
// 
import { useDispatch, useSelector } from "react-redux"
import StateStatus from "../../../utils/status"
import { fetch, selectComparativeTableSlice } from "../ComparativeTable/slice"
// 
import SimulationTable from "../ComparativeTable/components/SimulationTable"
import Table from "../ComparativeTable/components/Table"
// 
import Header from "../../../common/components/Header"
import Loading from "../../../common/components/Loading"
// 
import { humanize } from "../../../utils/formatters"
import html2pdf from "html-to-pdf-js"
import "./index.scss"

const ComparativeTablePDF = () => {
    const { t } = useTranslation()
    const { id, nw } = useParams()
    // 
    const dispatch = useDispatch()
    // 
    const slice = useSelector(selectComparativeTableSlice)
    // 
    const data = slice.data
    const status = slice.status.tableData
    const counters = data.tableData.counters
    // 
    const [go, updateGo] = useState(false)
    // 
    const dataCards = [
        {
            "alias": "totalProfiles",
            "text": t("Influenciadores na lista"),
            "value": counters?.totalProfiles,
            "tooltip": t("Quantidade total de perfis incluídos na lista.")
        },
        {
            "alias": "meanAirscore",
            "text": t("Airscore médio"),
            "value": counters?.meanAirscore,
            "tooltip": t("Média de Airscore da lista, levando em consideração todos os perfis incluídos.")
        },
        {
            "alias": "totalFollowers",
            "text": t("Total de seguidores"),
            "value": counters?.totalFollowers,
            "tooltip": t("Somatória do tamanho da base de seguidores dos perfis presentes na lista.")
        },
        nw !== "twitch" && {
            "alias": "reachPotential",
            "text": t("Potencial de alcance"),
            "value": counters?.reachPotential,
            "tooltip": t("Estimativa de alcance que as publicações podem impactar, totalizando todos os perfis presentes na lista.")
        },
        {
            "alias": "totalInteractions",
            "text": t("Total de interações"),
            "value": counters?.totalInteractions,
            "tooltip": t("Quantidade total das interações de todos os perfis presentes na lista.")
        },
    ]

    const generatePDF = async () => {
        const elementHTML = document.querySelector(".ct-page-pdf");

        const opt = {
            filename: `${data.name || t("tabela-comparativa")}.pdf`,
            image: {
                type: "jpeg",
                quality: 0.95
            },
            jsPDF: {
                unit: "in",
                width: 180,
                windowWidth: 1280,
                format: "a2",
            },
            html2canvas: {
                scale: 1
            },
        }

        await html2pdf().set(opt).from(elementHTML).save()

        setTimeout(() => {
            window.close()
        }, 1500);
    }

    useEffect(() => {
        dispatch(fetch({ id, network: nw, toPdf: true }))
    }, [])

    useEffect(() => {
        if (status === StateStatus.succeeded) {
            const menu = document.querySelector(".ant-layout-sider")
            const menuArrow = document.querySelector(".menu-arrow")

            if (menu) {
                menu.style.display = "none"
                menuArrow.style.display = "none"
            }

            setTimeout(() => {
                updateGo(true)
            }, 11000);
        }
    }, [status])

    useEffect(() => {
        if(go) generatePDF()
    }, [go])

    return <>
        <div className="fixed-loading">
            <Loading />

            <span>{t("Aguarde enquanto estamos gerando o PDF")}</span>
        </div>

        <div className="ct-page-pdf background-page">
            <div className="ct-content-container scroll-content">
                <div className="ct-content">
                    <Header
                        title={`${t("Tabela de comparação")}${data.name ? ` - ${data.name}` : ""}`}
                        className="home-header">
                    </Header>

                    <div className="part-table">
                        <div className={`ct-cards ct-cards-${dataCards.length}`}>
                            {status === StateStatus.succeeded && dataCards.map((item, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="ct-card"
                                    >
                                        <span className="ct-card-title">
                                            {item.text}
                                        </span>

                                        <span className="ct-card-value">
                                            {humanize(item.value)}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="ct-table">
                            {status === StateStatus.succeeded && <>
                                <p className="title-table">{t("Desempenho")}</p>

                                <div className="box-performance-table">
                                    <Table
                                        toPdf={true}
                                        key="performace"
                                        variation="interactions"
                                        influencers={data.tableData.profiles}
                                    />
                                </div>

                                <p className="title-table">{t("Simulação de custos")}</p>

                                <div className="box-simulation-table">
                                    <SimulationTable
                                        toPdf={true}
                                        key="simulation"
                                        variation="interactions"
                                        influencers={data.tableData.profiles}
                                    />
                                </div>
                            </>}
                        </div>
                    </div>

                    {status === StateStatus.loading && <Loading />}

                    {status === StateStatus.failed && t("ERRO")}
                </div>
            </div>
        </div>
    </>
}


export default ComparativeTablePDF