import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { changeObjAlert, showAlert } from '../../../common/components/Alert/slice';
import i18n from '../../../languages/i18n';
import ComparativeTableModel from '../../../models/ComparativeTableModel';

import feedback from '../../../common/components/Feedback';
import api from '../../../service/api';
import { generateImageBase64 } from '../../../utils';
import StateStatus from '../../../utils/status';

import { changePriceInfluencers, getInfluencers } from './service';

const initialState = {
  status: {
    fetch: StateStatus.idle,
    changePrice: StateStatus.idle,
    tableData: StateStatus.idle,
    sendFeedback: StateStatus.idle,
  },
  data: {
    tableData: {
      name: null,
      profiles: [],
    },
    counters: {
      totalProfiles: 0,
      totalFollowers: 0,
      reachPotential: 0,
      meanAirscore: 0,
      totalInteractions: 0,
    },
  },
  networks: [],
  errMsg: null,
};

export const fetch = createAsyncThunk(
  'comparative-table/fetch',
  async ({ id, network, toPdf = false }, thunkAPI) => {
    try {
      const result = await getInfluencers(id, network);

      return {
        toPdf: toPdf,
        data: result.data,
      };
    } catch (err) {
      thunkAPI.dispatch(
        changeObjAlert({
          show: true,
          type: 'error',
          title: `${i18n.t('atenção')}!`,
          text: i18n.t('Falha ao listar influenciadores'),
          onCancel: () => {
            thunkAPI.dispatch(showAlert(false));
          },
        }),
      );
      throw err;
    }
  },
);

export const sendFeedback = createAsyncThunk(
  'comparative-table/send-feedback',
  async ({ id, data }) => {
    try {
      const response = await api.post(`/planner-list/${id}/profile/assistant/feedback`, data);

      const { profile_id, assistant_client_feedback } = response.data;

      return { profile_id, assistant_client_feedback };
    } catch (error) {
      feedback.error('Falha ao enviar feedback.');
      throw error;
    }
  },
);

export const changePrice = createAsyncThunk(
  'comparative-table/change-price',
  async ({ id, price, profileId }) => {
    try {
      await changePriceInfluencers(id, {
        price,
        profile_id: profileId,
      });

      feedback.success(i18n.t('Valores inseridos com sucesso!'));

      return {
        profileId,
        price,
      };
    } catch (err) {
      feedback.error(i18n.t('Erro ao salvar os valores inseridos!'));

      throw err;
    }
  },
);

export const comparativeTableSlice = createSlice({
  name: 'comparativeTable',
  initialState,
  extraReducers: {
    [fetch.pending]: (state) => {
      state.status.tableData = StateStatus.loading;
    },
    [fetch.fulfilled]: (state, { payload }) => {
      const { data, toPdf } = payload;

      const tableData = new ComparativeTableModel().fromJson(data, toPdf);

      if (toPdf) {
        tableData.profiles.map(async (profile) => {
          profile.avatar = await generateImageBase64(profile.avatar);
        });
      }

      state.data.tableData = tableData;
      state.status.tableData = StateStatus.succeeded;
    },
    [fetch.rejected]: (state, action) => {
      state.status.tableData = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
    [changePrice.pending]: (state) => {
      state.status.changePrice = StateStatus.loading;
    },
    [changePrice.fulfilled]: (state, { payload }) => {
      if (state.data.tableData.profiles && payload.profileId) {
        const influencerIndex = state.data.tableData.profiles.findIndex(
          (inf) => inf.id === payload.profileId,
        );

        if (influencerIndex >= 0 && state.data.tableData.profiles[influencerIndex]) {
          state.data.tableData.profiles[influencerIndex].price = payload.price;
        }
      }
      state.status.changePrice = StateStatus.succeeded;
    },
    [changePrice.rejected]: (state, action) => {
      state.status.changePrice = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
    [sendFeedback.pending]: (state) => {
      state.status.sendFeedback = StateStatus.loading;
    },
    [sendFeedback.rejected]: (state) => {
      state.status.sendFeedback = StateStatus.failed;
    },
    [sendFeedback.fulfilled]: (state, action) => {
      const { profile_id, assistant_client_feedback } = action.payload;

      const profiles = state.data.tableData.profiles.map((profile) => {
        if (profile.id === profile_id) {
          return {
            ...profile,
            insightIsLiked: assistant_client_feedback,
          };
        }
        return profile;
      });

      state.data.tableData.profiles = profiles;
    },
  },
});

export const selectComparativeTableSlice = (state) => state.comparativeTable;
