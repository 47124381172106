import React from 'react';
import PropTypes from "prop-types"
// 
import { useTranslation } from 'react-i18next';
//
import Button from "../../../common/components/Button"
//
import star from '../../assets/icons/star.svg';
//
import './index.scss';

const ExtensionRecommendation = ({
    onClick,
    enableOnClick
}) => {
    const { t } = useTranslation();

    const click = () => {
        if(onClick && enableOnClick) onClick()
        else window.open("https://chrome.google.com/webstore/detail/hmacaaocopciajocilmdjgalalafobho")
    }

    return (
        <div
            role="button" 
            className="extension-recommendation hover"
            onClick={() => click()}
        >
            <img src={star} alt="entrela amarela" />

            <div className="presentation-text">
                <span className="text-1">{t("Avalie influenciadores enquanto navega nas redes sociais")}.</span>

                <span className="text-2">
                    {t("Acesse dados e adicione facilmente perfis nas plataformas com a Extensão AirSearch.")}
                </span>
            </div>

            <Button
                className="outline-dark"
            >
                {t("Começar")}
            </Button>
        </div>
    );
};

ExtensionRecommendation.defaultProps = {
    enableOnClick: false
}

ExtensionRecommendation.propTypes = {
    onClick: PropTypes.func,
    enableOnClick: PropTypes.bool,
}

export default ExtensionRecommendation;
