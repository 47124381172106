import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeObjAlert, showAlert } from '../common/components/Alert/slice';
import i18n from '../languages/i18n';
import BrandsModel from '../models/BrandsModel';
import CategoriesModel from '../models/CategoriesModel';
import PlacesModel from '../models/PlacesModel';
import UserModel from '../models/UserModel';
import StateStatus from '../utils/status';

import {
  auth,
  getBrands,
  getCategories,
  getClient,
  getPlaces,
  getUser,
  postAction,
} from './service';

const initialState = {
  status: {
    authUser: StateStatus.idle,
    fetchCategoriesPlacesBrands: StateStatus.idle,
    fetchUser: StateStatus.idle,
    fetchShowNPS: StateStatus.idle,
  },
  user: {},
  show: { nps: false },
  categories: {},
};

export const authUser = createAsyncThunk('user/auth', async (token, thunkAPI) => {
  try {
    //
    const response = await auth(token);

    window.localStorage.setItem('access_token', response.data.access_token);
    window.localStorage.setItem('refresh_token', response.data.refresh_token);

    return response.data;
  } catch (err) {
    thunkAPI.dispatch(
      changeObjAlert({
        show: true,
        type: 'error',
        title: `${i18n.t('erro')}!`,
        text: i18n.t('Usuário inválido'),
        onCancel: () => {
          thunkAPI.dispatch(showAlert(false));
        },
      }),
    );

    throw err;
  }
});

export const fetchUser = createAsyncThunk('user/me', async () => {
  const response = await getUser();

  return response.data.profile;
});

export const fetchShowNPS = createAsyncThunk('client/me', async () => {
  const response = await getClient();

  return response.data;
});

export const fetchCategoriesPlacesBrands = createAsyncThunk(
  'user/categories-places-brands',
  async (thunkAPI) => {
    try {
      const responsePlaces = await getPlaces();
      const responseBrands = await getBrands();
      const responseCategories = await getCategories();

      return {
        places: responsePlaces.data,
        brands: responseBrands.data,
        categories: responseCategories.data,
      };
    } catch (err) {
      thunkAPI.dispatch(
        changeObjAlert({
          show: true,
          type: 'error',
          title: `${i18n.t('erro')}!`,
          text: i18n.t('Erro ao listar as categorias'),
          onCancel: () => {
            thunkAPI.dispatch(showAlert(false));
          },
        }),
      );
      throw err;
    }
  },
);

export const sendAction = createAsyncThunk(
  'user/action',
  async ({
    page,
    type,
    element,
    sessionId,
    positionX,
    positionY,
    screenWidth,
    screenHeight,
    timestamp,
  }) => {
    const data = {
      page: page,
      type: type,
      element: element,
      session_id: sessionId,
      position_x: positionX,
      position_y: positionY,
      screen_width: screenWidth,
      screen_height: screenHeight,
      timestamp: timestamp,
    };

    const response = await postAction(data);

    return response.data;
  },
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status.authUser = StateStatus.idle;
      state.status.fetchCategoriesPlacesBrands = StateStatus.idle;
    },
    toggleShowNPS: (state, action) => {
      state.show.nps = action.payload;
    },
  },
  extraReducers: {
    [authUser.pending]: (state) => {
      state.status.authUser = StateStatus.loading;
    },
    [authUser.fulfilled]: (state) => {
      state.status.authUser = StateStatus.succeeded;
    },
    [authUser.rejected]: (state, { error }) => {
      state.err = error.message || 'Não foi possível achar o usuário.';

      state.status.authUser = StateStatus.failed;

      window.localStorage.removeItem('access_token');
      window.localStorage.removeItem('refresh_token');

      setTimeout(() => {
        window.location.href = process.env.REACT_APP_HUB_URL;
      }, 2000);
    },
    [fetchUser.pending]: (state) => {
      state.status.fetchUser = StateStatus.loading;
    },
    [fetchUser.fulfilled]: (state, { payload }) => {
      const user = new UserModel().fromJson(payload);

      state.user = user;

      if (!process.env.REACT_APP_INTERCOM_ICON || process.env.REACT_APP_INTERCOM_ICON != 'hidden') {
        window.intercomSettings = {
          app_id: 'ej8x457w',
          email: user.email,
          name: user.name,
          user_id: user.id,
          user_hash: user.hash,
        };
      }

      state.status.fetchUser = StateStatus.succeeded;
    },
    [fetchUser.rejected]: (state, { error }) => {
      state.err = error.message || 'Não foi possível achar o usuário.';

      state.status.fetchUser = StateStatus.failed;
    },
    [fetchShowNPS.fulfilled]: (state, { payload }) => {
      state.show = payload.show;
    },
    [fetchCategoriesPlacesBrands.pending]: (state) => {
      state.status.fetchCategoriesPlacesBrands = StateStatus.loading;
    },
    [fetchCategoriesPlacesBrands.fulfilled]: (state, { payload }) => {
      if (payload.categories) {
        const categories = new CategoriesModel().fromJson(payload.categories);
        state.categories = categories;
      }
      if (payload.places) {
        const places = new PlacesModel().fromJson(payload.places);
        state.places = places;
      }
      if (payload.brands) {
        const brands = new BrandsModel().fromJson(payload.brands);
        state.brands = brands;
      }

      state.status.fetchCategoriesPlacesBrands = StateStatus.succeeded;
    },
    [fetchCategoriesPlacesBrands.rejected]: (state, { error }) => {
      state.err = error.message || 'Não foi possível achar o usuário.';

      state.status.fetchCategoriesPlacesBrands = StateStatus.failed;
    },
  },
});

export const { resetStatus, toggleShowNPS } = appSlice.actions;

export const selectAppSlice = (state) => state.app;

export const selectUser = (state) => state.app.user;

export const selectCategories = (state) => state.app.categories;

export const selectPlaces = (state) => state.app.places;

export const selectBrands = (state) => state.app.brands;
