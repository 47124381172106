import React from "react"
// 
import { useTranslation } from "react-i18next"
// 
import { Form } from "antd"
import Input from "../../../../../common/components/Input"
import Button from "../../../../../common/components/Button"

const CompetitorsForm = () => {
    const { t } = useTranslation()

    return <>
        <p className="title-form">{t("Concorrentes")}</p>

        <Form.List name={["competitors"]}>
            {(fields, { add, remove }) => {
                if (fields.length < 1) add()

                return (
                    <div>
                        {fields.map((url, index) => (
                            <div className="junction-input-icon" key={index}>
                                <Form.Item
                                    name={[index]}
                                    label={`${t("Marcas concorrentes")}:`}
                                    rules={[
                                        {
                                            required: true,
                                            message: <span className="normal-12">{t("Informe o nome da concorrência")}</span>,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Digite o nome da marca concorrente"
                                    />

                                </Form.Item>

                                {index == 0 &&
                                    <Button
                                        onClick={() => add()}
                                        className="outline-dark add-btn"
                                    >
                                        {t("Adicionar")}
                                    </Button>
                                }

                                {index > 0 &&
                                    <i
                                        onClick={() => remove(url.name)}
                                        className="icon icon-close hover"
                                    />
                                }
                            </div>
                        ))}
                    </div>
                )
            }}
        </Form.List>
    </>
}

export default CompetitorsForm