import { Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import aira from '../../../../../common/assets/images/aira.png';

import briefing from './briefing-aira.docx';
import { examples } from './data';

import './index.scss';

const CurationForm = ({ onFinish }) => {
  const { t } = useTranslation();
  const airaMsgs = useSelector((state) => state.curation.airaMsgs);
  const [text, setText] = useState('');

  return (
    <div className="curation-form">
      <ul className="observations__curation-form">
        <li className="observation__curation-form">
          <img className="aira" src={aira} alt={t('mulher  do cabelo preto sorrindo')} />
          <div>
            <b>Aira</b>
            <p>
              Para encontrarmos até os 20 melhores influenciadores é simples, basta me contar um
              pouco sobre os seus planos.
            </p>
          </div>
        </li>
        {airaMsgs.map((message) => {
          return (
            <li className="observation__curation-form" key={message.text}>
              <p>{message.text}</p>
            </li>
          );
        })}
      </ul>

      <div className="form__curation-form">
        <label>Conte sobre seus planos</label>
        <p>
          <b>Dica:</b> Copie e cole seu planejamento completo ou digite livremente.
        </p>
        <Form.Item
          name="briefing_text"
          rules={[
            {
              required: true,
              message: 'O campo é obrigatório.',
            },
            {
              min: 150,
              message: 'O texto precisa ter ao menos 150 caracteres.',
            },
          ]}
        >
          <textarea
            placeholder="Digite aqui ou copie e cole um texto."
            value={text}
            onChange={(event) => setText(event.target.value)}
          />
        </Form.Item>
        <footer>
          <a href={briefing} download="briefing-aira.docx">
            Baixar modelo
          </a>
          <button type="button" onClick={onFinish}>
            Continuar
          </button>
        </footer>
      </div>
      <div className="examples-container__curation-form">
        <b>Exemplos do que citar:</b>

        <ul className="examples__curation-form">
          {examples.map((example) => (
            <li className="example__examples" key={example.title}>
              <div>
                <i className={`icon icon-${example.icon}`} />
                <b>{example.title}</b>
              </div>
              <p>{example.text}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* <div className="box box-aira-observation">
        <img className="aira" src={aira} alt={t('mulher  do cabelo preto sorrindo')} />
        <img className="chat-triangle" src={chatTriangle} />
        <div className="observations">
          {messages.map((item, idx) => {
            const newMsg = idx === messages.length - 1 && idx !== 0;

            return (
              <div key={idx} className={`observation ${newMsg ? 'animate-new-msg' : ''}`}>
                <p className="chat-name">{t('Aira')}</p>
                <span>{t(item.text)}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="box">
        <p className="title-form">{t('Conte sobre a sua curadoria')}</p>

        <p className="subtitle-form">
          <b>{t('Dica')}</b>: {t('Copie e cole seu briefing completo ou digite livremente.')}
        </p>

        <Form.Item
          name="briefing_text"
          rules={[
            {
              required: true,
              message: t(`O texto precisa ter ao menos ${MIN_BRIEFING_LENGTH} caracteres`),
            },
          ]}
        >
          <textarea
            className="ant-input ant-input-lg input input-full"
            placeholder={t('Digite aqui ou copie e cole um texto.')}
            onKeyUp={(event) => {
              setText(event.target.value);
            }}
          />
        </Form.Item>

        {text && text.length > 0 && text.length < MIN_BRIEFING_LENGTH && (
          <span className="briefing-count">
            Seu texto precisa ter pelo menos {text?.length}/{MIN_BRIEFING_LENGTH} caracteres
          </span>
        )}

        <Button
          className="submit-btn"
          isLoading={status === StateStatus.loading}
          onClick={() => onFinish()}
          isDisabled={!text || text?.length < MIN_BRIEFING_LENGTH || status === StateStatus.loading}
        >
          {t('Continuar')}
        </Button>

        <Button className="outline-primary submit-btn" onClick={() => downloadBriefingModel()}>
          {t('Baixar modelo de briefing')}
        </Button>
      </div>
      <div className="curation-text-examples">
        <p>{t('Exemplos do que citar')}:</p>

        <div className="examples">
          {examples.map((example) => {
            return (
              <div className="example" key={example.title}>
                <i className={`icon icon-${example.icon}`} />

                <span>
                  <b>{example.title}</b>: {example.text}.
                </span>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

CurationForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

export default CurationForm;
