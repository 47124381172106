const pt = {
    "likes": "Curtidas",
    "dislikes": "Dislikes",
    "views": "Visualizações",
    "comments": "Comentários",
    "interactions": "Interações",
    "followers": "Seguidores",
    "follower": "Seguidor",
    "retweets": "Retweets",

    // DEMOGRAPHICS PODIUM
    "gender_podium": "Sexo",
    "has_child_podium": "Tem filhos?",
    "language_podium": "Língua nativa",
    "marital_status_podium": "Estado civil",

    // AIRA TASTING - INFLUENCER CARD - PERSONA
    "married": "Casado(a)",
    "single": "Solteiro(a)",
    "parents": "Sim",

    "census_podium": "Faixa etária",
    "location_podium": "Localização",
    "likes_podium": "Curtidas",
    "brands_podium": "Marcas",

    // DEMOGRAPHICS
    "Female": "Femenino",
    "Male": "Masculino",
    "gender_chart": "Gênero da audiência",
    "census_chart": "Faixa etária da audiência",
    "location_chart": "Localização da audiência (cidades)",
    "likes_chart": "Curtidas e interesses da audiência",
    "brands_chart": "Marcas que se conectam com a audiência",
	"Age 16 and under": "até 16",
	"Age 17 to 19": "17 a 19",
	"Age 20 to 24": "20 a 24",
	"Age 25 to 29": "25 a 29",
	"Age 30 to 34": "30 a 34",
	"Age 35 to 39": "35 a 39",
	"Age 40 to 49": "40 a 49",
	"Age 50 to 59": "50 a 59",
	"Age 60 and over": "Acima de 60",

    // SEARCH FILTER LABEL
    "gender": "Gênero",
    "audience_gender": "Gênero da audiência",
    "places": "Locais",
    "audience_places": "Locais da audiência",
    "F": "Feminino",
    "M": "Masculino",
    "female": "Feminino",
    "male": "Masculino",
    "accelerated_growth": "Crescimento acelerado",
    "Portuguese": "Português",
    "English": "Inglês",
    "Spanish": "Espanhol",
    "German": "Alemão",
    "French": "Francês",

    // INFLUENCER PREVIEW
    "username": "nome de usuário",
    "name": "nome",
    "posts": "publicações",
    "bio": "bio",

    // NOTIFICATION
    "idle": "Retentando",
    "error": "Erro",
}

export default pt;
