import React from "react"
import PropTypes from "prop-types"
// 
import { Progress } from "antd"
// 
import "./index.scss"

const CardCategory = ({ category, showSubcategories, showOnlyCategories }) => {
    return <div
        key={category.id}
        className="category-card"
        style={{
            "--category-color": `${category.color}`,
        }}
    >
        <div className="category-card-header">
            <p className="category-name">{category.name}</p>

            <i className={`icon icon-${category.icon}`} />

            <span className="category-airscore">
                <i className="icon icon-airscore" />

                <span className="score">{category.score}</span>
            </span>
        </div>

        {!showOnlyCategories && 
            <div className={`category-card-body ${showSubcategories ? "opened" : "closed"}`}>
                {category.subCategories && category.subCategories.map(subCategory => {
                    return <div key={subCategory.name} className="row-values">
                        <p className="name-percent">
                            <span className="name">{subCategory.name}</span>
                            <span className="percent"><b>{subCategory.percent}%</b></span>
                        </p>

                        <Progress
                            strokeColor={{
                                '0%': "#000",
                                '100%': "#000",
                            }}
                            showInfo={false}
                            success={{ percent: 0, strokeColor: "#000" }}
                            percent={subCategory.percent}
                        />
                    </div>
                })}
            </div>
        }
    </div>
}

CardCategory.defaultProps = {
    showSubcategories: false,
    showOnlyCategories: false
}

CardCategory.propTypes = {
    category: PropTypes.object,
    showSubcategories: PropTypes.bool,
    showOnlyCategories: PropTypes.bool
}

export default CardCategory