import moment from 'moment';

class PostModel {
  constructor() {
    this.id;
    this.externalId;
    this.url;
    this.link;
    this.date;
    this.image;
    this.posted;
    this.network;
    this.description;
    this.descriptionSlice;
    this.type;
    this.likes;
    this.views;
    this.shares;
    this.retweets;
    this.dislikes;
    this.comments;
    this.interactionsTotal = 0;
    this.engagementRate = 0;
  }

  fromJson(data) {
    this.id = data['post_id'];
    this.externalId = data['external_id'];
    this.date = moment(data['posted_at']).format('DD/MM');
    this.link = data['permalink'] || '';
    this.posted = data['posted_at'];
    this.description = data['caption'];

    if (data['network'] === 'instagram') {
      this.type = this.link.includes('/reel/') ? 'reels' : 'post';
    }

    if (data['network'] === 'twitter') {
      this.descriptionSlice = data['caption']
        ? `${data['caption'].slice(
            0,
            data['caption'].length > 150 ? 150 : data['caption'].length,
          )}${data['caption'].length > 170 ? '...' : ''}`
        : '';
    } else {
      this.descriptionSlice = data['caption']
        ? `${data['caption'].slice(0, data['caption'].length > 65 ? 65 : data['caption'].length)}${
            data['caption'].length > 75 ? '...' : ''
          }`
        : '';
    }

    if (data['network'] === 'youtube' && this.externalId) {
      this.image = `https://img.youtube.com/vi/${this.externalId}/sddefault.jpg`;
    } else {
      this.image = data['thumbnail'] || data['image'];
    }

    if (data['counters']) {
      this.likes = data['counters']['likes'] || 0;
      this.views = data['counters']['views'] || 0;
      this.shares = data['counters']['shares'] || 0;
      this.comments = data['counters']['comments'] || 0;
      this.retweets = data['counters']['retweets'] || 0;
      this.dislikes = data['counters']['dislikes'] || 0;
      this.interactions = data['counters']['interactions'] || 0;

      this.engagementRate = ((data['engagement_rate'] || 0) * 100).toFixed(2);
    }

    this.interactionsTotal = data['counters']['interactions'] || 0;

    return this;
  }
}

export default PostModel;
