import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit"
// 
import {
    updNotification,
    getNotifications,
    getNotificationsCounters
} from "./service"
// 
import StateStatus from "../../../utils/status"
import NotificationModel from "../../../models/NotificationModel"

const initialStateInfluencer = {
    status: {
        fetchNotifications: StateStatus.idle,
        updateNotifications: StateStatus.idle,
        fetchNotificationsCounters: StateStatus.idle,
    },
    notifications: [],
    // pagination
    page: 1,
    total: 1,
    pages: 0,
    // counter
    counters: {},
    totalCounters: 0,
    // 
    errorMsg: null
}

export const fetchNotifications = createAsyncThunk("notifications/fetch", async ({
    page,
    status
}) => {
    try {
        const data = {
            "page": page,
            "status": status,
        }

        const response = await getNotifications(data)
    
        return response.data
    } catch (err) {
        console.debug(err)

        throw err
    }
})

export const fetchNotificationsCounters = createAsyncThunk("notifications-counters/fetch", async () => {
    try {
        const response = await getNotificationsCounters()
    
        return response.data
    } catch (err) {
        console.debug(err)

        throw err
    }
})

export const updateNotifications = createAsyncThunk("notifications/update", async ({ status, ids, newStatus}) => {
    try {
        const response = await updNotification(
            { 
                ids: ids, 
                status: newStatus, 
                current_status: status, 
            }
        )
    
        return {
            data: response.data,
            ids: ids
        }
    } catch (err) {
        console.debug(err)

        throw err
    }
})

// export const 

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: initialStateInfluencer,
    extraReducers: {
        [fetchNotifications.pending]: (state) => {
            state.status.fetchNotifications = StateStatus.loading
        },
        [fetchNotifications.fulfilled]: (state, {
            payload
        }) => {
            let list = []

            state.page = payload["page"] || 0
            state.pages = payload["pages"] || 0
            state.total = payload["total"] || 0

            if(payload.items && payload.items.length > 0) {
                list = payload.items.map(item => {
                    const notification = new NotificationModel()
                    
                    return notification.fromJson(item)
                })
            }

            state.notifications = list

            state.status.fetchNotifications = StateStatus.succeeded
        },
        [fetchNotifications.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.fetchNotifications = StateStatus.failed
        },
        // 
        [fetchNotificationsCounters.pending]: (state) => {
            state.status.fetchNotificationsCounters = StateStatus.loading
        },
        [fetchNotificationsCounters.fulfilled]: (state, {
            payload
        }) => {
            state.totalCounters = payload.created || 0
            
            state.counters = {
                ...payload
            }

            state.status.fetchNotificationsCounters = StateStatus.succeeded
        },
        [fetchNotificationsCounters.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.fetchNotificationsCounters = StateStatus.failed
        },
        // 
        [updateNotifications.pending]: (state) => {
            state.status.updateNotifications = StateStatus.loading
        },
        [updateNotifications.fulfilled]: (state, {
            payload
        }) => {
            const notifications = state.notifications.filter(notification => {
                return !payload.ids.includes(notification.id)
            })

            state.notifications = notifications

            state.status.updateNotifications = StateStatus.succeeded
        },
        [updateNotifications.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.updateNotifications = StateStatus.failed
        }
        // 
    }
})

export const {
    updateIndex
} = notificationsSlice.actions

export const selectNotificationsSlice = (state) => state.notifications