import React from "react"
// 
import loading from "../../assets/gifs/loading.gif"

const Loading = () => (
    <div className="container-loading" style={{ margin: "50px auto 10px", textAlign: "center" }}>
        <img src={loading} alt="ícone de carregamento" style={{ width: "60px" }} />
    </div>
)

export default Loading