import moment from 'moment';

export const humanize = (val) => {
  const ret = String(parseInt(val, 10) || 0);

  switch (ret.length) {
    case 4:
      return (
        ret.slice(0, 1) + (parseInt(ret.slice(1, 2), 10) === 0 ? '' : '.' + ret.slice(1, 2)) + 'k'
      );
    case 5:
      return ret.slice(0, 2) + 'k';
    case 6:
      return ret.slice(0, 3) + 'k';
    case 7:
      return (
        ret.slice(0, 1) + (parseInt(ret.slice(1, 2), 10) === 0 ? '' : '.' + ret.slice(1, 2)) + 'M'
      );
    case 8:
      return ret.slice(0, 2) + 'M';
    case 9:
      return ret.slice(0, 3) + 'M';
    case 10:
      return (
        ret.slice(0, 1) + (parseInt(ret.slice(1, 2), 10) === 0 ? '' : '.' + ret.slice(1, 2)) + 'B'
      );
    case 11:
      return ret.slice(0, 2) + 'B';
    case 12:
      return ret.slice(0, 3) + 'B';
  }
  return ret;
};

export const convertToFollowers = (value) => {
  let converted = 0;

  if (value === 1) converted = 1000;
  else if (value === 2) converted = 5000;
  else if (value === 3) converted = 10000;
  else if (value === 4) converted = 20000;
  else if (value === 5) converted = 30000;
  else if (value === 6) converted = 50000;
  else if (value === 7) converted = 100000;
  else if (value === 8) converted = 200000;
  else if (value === 9) converted = 300000;
  else if (value === 10) converted = 400000;
  else if (value === 11) converted = 500000;
  else if (value === 12) converted = 1000000;
  else if (value === 13) converted = 2000000;
  else if (value === 14) converted = 3000000;
  else if (value === 15) converted = 4000000;
  else if (value === 16) converted = 5000000;
  else if (value === 17) converted = 10000000;
  else if (value === 18) converted = 20000000;
  else if (value === 19) converted = 30000000;
  else if (value === 20) converted = 40000000;
  else if (value === 21) converted = 50000000;
  else if (value === 22) converted = 100000000;
  else if (value === 23) converted = 200000000;
  else if (value === 24) converted = 250000000;
  else if (value === 25) converted = 300000000;

  return converted;
};

export const convertFollowersToPercent = (value) => {
  let converted = 0;

  if (value <= 1000) converted = 1;
  else if (value <= 5000) converted = 2;
  else if (value <= 10000) converted = 3;
  else if (value <= 20000) converted = 4;
  else if (value <= 30000) converted = 5;
  else if (value <= 50000) converted = 6;
  else if (value <= 100000) converted = 7;
  else if (value <= 200000) converted = 8;
  else if (value <= 300000) converted = 9;
  else if (value <= 400000) converted = 10;
  else if (value <= 500000) converted = 11;
  else if (value <= 1000000) converted = 12;
  else if (value <= 2000000) converted = 13;
  else if (value <= 3000000) converted = 14;
  else if (value <= 4000000) converted = 15;
  else if (value <= 5000000) converted = 16;
  else if (value <= 10000000) converted = 17;
  else if (value <= 20000000) converted = 18;
  else if (value <= 30000000) converted = 19;
  else if (value <= 40000000) converted = 20;
  else if (value <= 50000000) converted = 21;
  else if (value <= 100000000) converted = 22;
  else if (value <= 200000000) converted = 23;
  else if (value <= 250000000) converted = 24;
  else if (value <= 300000000) converted = 25;
  else converted = 25;

  return converted;
};

export const convertEngagementRate = (value) => {
  let converted = 0;

  if (value < 11) return 0;
  if (value <= 11) return 2;
  if (value <= 22) return 4;
  if (value <= 33) return 7;
  if (value <= 44) return 9;
  if (value <= 55) return 12;
  if (value <= 66) return 15;
  if (value <= 80) return 20;
  if (value <= 100) return 100;

  return converted;
};
export const convertToEngagementRate = (value) => {
  let converted = 0;

  if (value < 2) return 0;
  if (value <= 2) return 11;
  if (value <= 4) return 22;
  if (value <= 7) return 33;
  if (value <= 9) return 44;
  if (value <= 12) return 55;
  if (value <= 15) return 66;
  if (value <= 20) return 80;
  if (value <= 100) return 100;

  return converted;
};

export const buildValue = (name, value) => {
  if (name == 'date') {
    const lang = localStorage.getItem('lang') || 'pt';

    return moment(value).format(lang === 'en' ? 'MM/DD/yyyy' : 'DD/MM/yyyy');
  } else if (name == 'date2') {
    return moment(value).format('DD MMM. YY');
  } else if (name == 'dateString') {
    const lang = localStorage.getItem('lang') || 'pt';

    return moment(value).format(lang === 'en' ? 'MM/DD' : 'DD/MM');
  } else if (name == 'number') {
    return `${value || 0}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  } else if (name == 'percent') {
    return `${parseFloat(`${value}`)}%`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  } else if (name == 'humanize') {
    return humanize(value);
  } else if (name == 'currency') {
    return parseFloat(value).toLocaleString('pt-RF', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  return value;
};

export const hexToRgba = (hex, alpha = 1) => {
  var c;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');

    if (c.length == 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]];

    c = '0x' + c.join('');

    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`;
  }

  return '#FFF';
};

export const formatPercentage = (value) => {
  return new Intl.NumberFormat('pt-br', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(value);
};

export default {
  humanize,
};

export const formatPhone = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
};
