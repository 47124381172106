import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import aira from '../../../common/assets/images/aira.png';
import Button from '../../../common/components/Button';

import './index.scss';

const AiraTasting = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="aira-tasting-page pattern-page">
      <h1>{t('Olá, meu nome é Aira!')}</h1>

      <img
        className="aira-img"
        src={aira}
        alt="Aira é uma mulher brancae tem o cabelo curto e liso. Está vestindo uma jaqueta rosa e sorrindo "
      />

      <p>
        {t(
          'Sou especialista em curadoria e com o auxílio de Inteligência Artificial te ajudo a encontrar',
        )}{' '}
        <b>{t('os melhores influenciadores para sua campanha!')}</b>
      </p>

      <span>{t('Vamos lá?')}</span>

      <Button onClick={() => navigate('/aira-demonstracao/briefing')}>Começar</Button>
    </div>
  );
};

export default AiraTasting;
