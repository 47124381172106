import PropTypes from "prop-types";
import React, {
    useEffect,
    useState
} from "react";
// 
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
// 
import {
    buildValue
} from "../../../../utils/formatters"
import "../Charts.scss";

const SimpleDonutsChart = ({
    data,
    hasAnimation,
    isPercentage,
    className
}) => {
    const [series, setSeries] = useState([]);
    // 
    const colors = [
        "#FEE6EE",
        "#F70B53",
    ]

    useEffect(() => {
        const newSeries = [];

        data.forEach((item, i) => {
            if (isPercentage) {
                newSeries.push({
                    name: item.label,
                    y: item.value / 100,
                    color: colors[i]
                })
            } else {
                newSeries.push({
                    y: item.value,
                    name: item.label,
                    color: item.color
                })
            }
        })

        setSeries(newSeries);
    }, [data])

    const options = {
        chart: {
            type: "pie",
        },
        title: {
          text: ""
        },
        yAxis: {
          title: ""
        },
        xAxis: {
          visible: false
        },
        legend: {
            align: "right",
            layout: "vertical",
            verticalAlign: "middle",
            itemMarginTop: 10,
            itemMarginBottom: 10,
            labelFormatter: function () {
                return `<div class="donut-legend">
                    ${this.options.name}: ${buildValue("number", this.y)}
                </div>`
            }
        },
        plotOptions: {
            pie: {
                animation: hasAnimation,
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        return `<div style="color: ${this.color}; font-size: 20px">
                            ${this.percentage.toFixed(1)}%
                        </div>`
                    }
                },
                showInLegend: true
            }
        },
        tooltip: {
            formatter: function () {
                return `<div class="high-tooltip">
                    ${this.key}: <b>${buildValue("number", this.y)}</b>
                </div>` 
            }
        },

        series: 
            series.length < 1 ? [] :
            [{
                name: "Donut Chart",
                colorByPoint: true,
                innerSize: "55%",
                data: series
            }]
    }

    return series && <div className={className}>
        <HighchartsReact
            options={options}
            highcharts={Highcharts}
            className="simple-donuts-chart"
        />
    </div>
    
}

SimpleDonutsChart.defaultProps = {
    data: [],
    isPercentage: true,
    hasAnimation: true,
    formatName: "percent",
}

SimpleDonutsChart.propTypes = {
    data: PropTypes.any,
    formatter: PropTypes.func,
    height: PropTypes.string,
    className: PropTypes.string,
    formatName: PropTypes.string,
    isPercentage: PropTypes.bool,
    hasAnimation: PropTypes.bool,
}

export default SimpleDonutsChart;