import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
// 
import { Slider as AntSlider } from "antd"
import Radio from "../Radio"
import Button from "../Button"
// 
import { convertFollowersToPercent, convertToFollowers } from "../../../utils/formatters"
// 
import "./index.scss"

const SliderFollowers = ({
    min,
    max,
    values,
    onAfterChange,
    options,
}) => {
    const { t } = useTranslation()
    // 
    const [minValue, updateMinValue] = useState(min)
    const [maxValue, updateMaxValue] = useState(max)
    // 
    const [inputMaxValue, updateInputMaxValue] = useState(max)
    const [inputMinValue, updateInputMinValue] = useState(min)
    // 
    const [optionSelected, updateOptionSelected] = useState()

    const updateValues = (values) => {
        const convertedMin = convertToFollowers(values[0])
        const convertedMax = convertToFollowers(values[1])

        updateInputMinValue(convertedMin)
        updateInputMaxValue(convertedMax)

        if (onAfterChange) onAfterChange([convertedMin, convertedMax])
    }

    const verifyInputValues = () => {
        const lower = inputMinValue
        const greater = inputMaxValue

        if(minValue > maxValue) {
            updateInputMinValue(greater)
            updateInputMaxValue(lower)

            onAfterChange([greater, lower])
        } else {
            onAfterChange([lower, greater])
        }
    }

    useEffect(() => {
        if (values && values.length > 0) {
            updateMinValue(convertFollowersToPercent(values[0]))
            updateMaxValue(convertFollowersToPercent(values[1]))

            updateInputMinValue(values[0])
            updateInputMaxValue(values[1])
        } else {
            updateInputMinValue(convertToFollowers(min))
            updateInputMaxValue(convertToFollowers(max))
        }
    }, [values])

    return (
        <div className="slider-container range">
            <AntSlider
                min={min}
                max={max}
                range={true}
                open={false}
                tooltip={{ open: false }}
                defaultValue={[min, max]}
                value={[minValue, maxValue]}
                // onAfterChange={(values) => }
                onChange={(values) => {
                    updateMinValue(values[0])
                    updateMaxValue(values[1])
                    
                    updateValues(values)
                }}
            />

            <div className="slider-values range">
                <div className="slider-value">
                    <span className="span-title">{t("Minímo")}</span>

                    <input
                        max={convertToFollowers(max)}
                        min={convertToFollowers(min)}
                        type="number"
                        maxLength={4}
                        placeholder={min}
                        id="input-min"
                        value={inputMinValue <= 0 ? "" : inputMinValue}
                        onBlur={() => verifyInputValues()}
                        onChange={(e) => {
                            const value = (e.target.value || "").replace(/[^\d.-]+/g, '')
                            
                            if(value) {
                                const convertedValue = convertFollowersToPercent(value)
                                const finalValue = convertedValue < min ? min : convertedValue > max ? max : convertedValue
                                
                                updateInputMinValue(value)
                                
                                updateMinValue(finalValue)
                            } else {
                                updateInputMinValue("")
                            }
                            
                            if(optionSelected) updateOptionSelected()
                        }}
                    />
                </div>

                <div className="slider-value">
                    <span className="span-title">{t("Máximo")}</span>

                        <input
                            max={convertToFollowers(max)}
                            min={convertToFollowers(min)}
                            type="number"
                            maxLength={4}
                            value={inputMaxValue}
                            id="input-max"
                            onBlur={() => verifyInputValues()}
                            onChange={(e) => {
                                const value = (e.target.value || "").replace(/[^\d.-]+/g, '')

                                if(value) {
                                    const convertedValue = convertFollowersToPercent(value)
                                    const finalValue = convertedValue > max ? max : convertedValue < min ? min : convertedValue
                                    
                                    updateInputMaxValue(value)
                                    
                                    updateMaxValue(finalValue)
                                } else {
                                    updateInputMaxValue("")
                                }
                                
                                if(optionSelected) updateOptionSelected()
                            }}
                        />
                </div>
            </div>

            {options &&
                <div className="option-values">
                    <Radio
                        value={optionSelected}
                        onChange={(value) => {
                            const option = options.find(option => option.value === value)

                            if (option) {
                                updateOptionSelected(value)

                                updateMinValue(convertFollowersToPercent(option.min))
                                updateMaxValue(convertFollowersToPercent(option.max))

                                updateInputMinValue(option.min)
                                updateInputMaxValue(option.max)

                                onAfterChange([option.min, option.max])
                            }
                        }}
                        options={options}
                    />
                </div>
            }

            <Button
                className="outline-light btn-clear"
                onClick={() => {
                    onAfterChange()
                    updateOptionSelected()

                    updateMinValue(min)
                    updateMaxValue(max)

                    updateInputMinValue(convertToFollowers(min))
                    updateInputMaxValue(convertToFollowers(max))
                }}
            >
                {t("Limpar filtros")}
            </Button>
        </div>
    )
}

SliderFollowers.defaultProps = {
    min: 0,
    max: 1000,
    step: 1,
    value: 0,
    onChange: () => { },
    onAfterChange: () => { },
    formatterRadio: () => { },
}

SliderFollowers.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    values: PropTypes.array,
    options: PropTypes.array,
    showTooltip: PropTypes.bool,
    onChange: PropTypes.func,
    formatter: PropTypes.func,
    convertFollowersToPercent: PropTypes.func,
    convertToFollowers: PropTypes.func,
    onAfterChange: PropTypes.func,
    formatterRadio: PropTypes.func,
}

export default SliderFollowers
