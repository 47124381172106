import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import emptyImage from '../../../../../common/assets/images/empty-influencers.png';
import Avatar from '../../../../../common/components/Avatar';
import Button from '../../../../../common/components/Button';
import ButtonModalLists from '../../../../../common/components/ButtonModalLists';
import { humanize } from '../../../../../utils/formatters';
import { selectPlannerListDetailsSlice } from '../../../PlannerListCard/slice';
import { selectComparativeTableSlice, sendFeedback } from '../../slice';

import './index.scss';

const Table = ({ id, openModal, variation, influencers: data, toPdf }) => {
  const { t } = useTranslation();
  const slice = useSelector(selectComparativeTableSlice);
  const plDetailsSlice = useSelector(selectPlannerListDetailsSlice);
  const plDetails = plDetailsSlice.data;
  const tableData = slice.data.tableData;
  const [sorts, setSorts] = useState(['name']);
  const [boolBigger, setBoolBigger] = useState(false);
  const [arrowLocation, setArrowLocation] = useState(-1);
  const [influencers, setInfluencers] = useState([]);
  const [selectedInsight, setSelectedInsight] = useState([]);
  const dispatch = useDispatch();

  const columns = [
    { alias: 'name', title: t('Influenciador'), span: 2 },
    { alias: 'airscore', title: t('Airscore'), span: 2, withTotal: true },
    { alias: 'engagement_rate', title: t('Engajamento'), span: 2, withTotal: true },
    { alias: 'followers', title: t('Seguidores'), span: 2, withTotal: true },
    { alias: 'interactions', title: t('Interações'), span: 2, withTotal: true },
    !toPdf && { alias: '', title: t('Listas'), span: 2 },
    plDetails.createdByAira && !toPdf && { alias: '', title: t('Insights'), span: 2 },
  ].filter((column) => column);

  const orderBy = (obj = 'perNumber', index, withTotal = false) => {
    if (columns[index].title !== t('Minhas listas')) {
      setArrowLocation(index);
    }

    if (!sorts.includes(obj)) {
      const newOrder = [...influencers].sort((a, b) => {
        if (obj === 'name') {
          if (a[obj].toLowerCase() > b[obj].toLowerCase()) {
            return -1;
          }
          if (a[obj].toLowerCase() < b[obj].toLowerCase()) {
            return 1;
          }
          return 0;
        } else {
          if ((withTotal ? a[obj] ?? 0 : a[obj]) > (withTotal ? b[obj] ?? 0 : b[obj])) {
            return -1;
          }
          if ((withTotal ? a[obj] ?? 0 : a[obj]) < (withTotal ? b[obj] ?? 0 : b[obj])) {
            return 1;
          }
          return 0;
        }
      });
      setBoolBigger(true);
      setInfluencers(newOrder);
      setSorts((oldArray) => [...oldArray, obj]);
    } else {
      const newOrder = [...influencers].sort((a, b) => {
        if (obj === 'name') {
          if (a[obj].toLowerCase() < b[obj].toLowerCase()) {
            return -1;
          }
          if (a[obj].toLowerCase() > b[obj].toLowerCase()) {
            return 1;
          }
          return 0;
        } else {
          if ((withTotal ? a[obj] ?? 0 : a[obj]) < (withTotal ? b[obj] ?? 0 : b[obj])) {
            return -1;
          }
          if ((withTotal ? a[obj] ?? 0 : a[obj]) > (withTotal ? b[obj] ?? 0 : b[obj])) {
            return 1;
          }
          return 0;
        }
      });
      setBoolBigger(false);
      setInfluencers(newOrder);
      setSorts(sorts.filter((item) => item !== obj));
    }
  };

  useEffect(() => {
    if (influencers.length < 1) {
      setInfluencers(data ?? []);
    }
  }, []);

  const handleSendFeedback = async (influencerId, prevState, feedbackStatus) => {
    const feedback = prevState === feedbackStatus ? 0 : feedbackStatus;

    const formattedData = {
      profile_id: influencerId,
      feedback,
    };

    await dispatch(sendFeedback({ id, data: formattedData }));

    setInfluencers((prev) =>
      prev.map((influencer) => {
        if (influencer.id === influencerId) {
          return { ...influencer, insightIsLiked: feedback };
        }
        return influencer;
      }),
    );
  };

  const handleRemoveInfluencer = (influencerId) => {
    setInfluencers((prev) => prev.filter((influencer) => influencer.id !== influencerId));
  };

  return (
    <div className="table-container performace-table">
      {influencers.length > 0 && (
        <table>
          <thead>
            <tr>
              {columns.map((item, index) => {
                if (item.alias === 'interactions' && data.length > 0 && data[0].interactions) {
                  return (
                    <Fragment key={item.title}>
                      {Object.keys(data[0][variation] ?? {}).map((interaction) => (
                        <th
                          key={interaction}
                          className={`interaction-value interaction-value-${interaction}`}
                        >
                          {interaction === 'total' ? t('Interações') : t(interaction)}
                        </th>
                      ))}
                    </Fragment>
                  );
                } else {
                  return (
                    <th
                      key={item.title}
                      rowSpan={item.span}
                      onClick={() =>
                        item.alias === 'TODO' && orderBy(item.alias, index, item.withTotal)
                      }
                    >
                      {item.title}
                      {index === arrowLocation && (
                        <i className={`icon icon-down ${boolBigger ? 'bigger' : 'smaller'}`} />
                      )}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>

          <tbody>
            {influencers.map((influencer) => {
              return (
                <Fragment key={influencer.id}>
                  <tr
                    key={`tr-${influencer.id}`}
                    className={`${selectedInsight.includes(influencer.id) ? 'selected' : ''}`}
                  >
                    <td className="td-influencer" onClick={() => openModal(influencer)}>
                      <div className="div-flex">
                        <Avatar
                          size={24}
                          onClick={() => openModal(influencer)}
                          src={influencer.avatar}
                        />

                        <div className="influencer-name">
                          <span>
                            {influencer.name}

                            {influencer.verified && <i className="icon icon-certificate" />}
                          </span>

                          {toPdf && <span>@{influencer.username}</span>}
                        </div>
                      </div>
                    </td>

                    <td
                      className={`td-airscore ${
                        influencer.airscore === tableData.topAirscore ? 'max' : ''
                      } ${influencer.airscore === tableData.worstAirscore ? 'min' : ''}`}
                    >
                      <span>{influencer.airscore}</span>
                    </td>

                    <td
                      className={`td-engagement ${
                        influencer.engagementRate === tableData.topEngagement ? 'max' : ''
                      } ${influencer.engagementRate === tableData.worstEngagement ? 'min' : ''}`}
                    >
                      <span>{`${influencer.engagementRate}`.replace('.', ',')}%</span>
                    </td>

                    <td
                      className={`td-followers ${
                        influencer.followers === tableData.topFollowers ? 'max' : ''
                      } ${influencer.followers === tableData.worstFollowers ? 'min' : ''}`}
                    >
                      <span>{humanize(influencer.followers)}</span>
                    </td>

                    {Object.keys(influencer[variation] ?? {}).map((interaction, key) => {
                      return (
                        <td
                          key={`td-${variation}-${key}`}
                          className={`interaction-value interaction-value-${interaction} ${
                            influencer[variation][interaction] ===
                            tableData.topInteractions[variation][interaction]
                              ? 'max'
                              : ''
                          } ${
                            influencer[variation][interaction] ===
                            tableData.worstInteractions[variation][interaction]
                              ? 'min'
                              : ''
                          }`}
                        >
                          <span>{humanize(influencer[variation][interaction])}</span>
                        </td>
                      );
                    })}

                    {!toPdf && (
                      <td className="td-my-lists">
                        <ButtonModalLists
                          influencerId={influencer.id}
                          influencerNetwork={influencer.network}
                          removeInfluencer={() => handleRemoveInfluencer(influencer.id)}
                        >
                          <Tooltip title={t('Minhas Listas')}>
                            <i className="icon icon-plus" />
                          </Tooltip>
                        </ButtonModalLists>
                      </td>
                    )}

                    {plDetails.createdByAira && !toPdf && (
                      <td className="td-insights">
                        <Button
                          title={t(!influencer.insight ? 'O influenciador não possui insight' : '')}
                          isDisabled={!influencer.insight}
                          className={`${
                            selectedInsight.includes(influencer.id) ? 'outline-primary' : ''
                          }`}
                          onClick={() => {
                            const includeId = selectedInsight.includes(influencer.id);

                            const trSelected = document.querySelector(
                              '.tr-footer-selected-' + influencer.id,
                            );

                            if (includeId) {
                              if (trSelected) trSelected.classList.add('hidden');

                              setTimeout(() => {
                                if (trSelected) trSelected.classList.remove('hidden');

                                setSelectedInsight((s) =>
                                  includeId
                                    ? s.filter((item) => item != influencer.id)
                                    : [...s, influencer.id],
                                );
                              }, 300);
                            } else {
                              setSelectedInsight((s) =>
                                includeId
                                  ? s.filter((item) => item != influencer.id)
                                  : [...s, influencer.id],
                              );
                            }
                          }}
                        >
                          {t('Insights')}
                        </Button>
                      </td>
                    )}
                  </tr>

                  {plDetails.createdByAira && selectedInsight.includes(influencer.id) && (
                    <>
                      <tr
                        key={`${influencer.id}-tr-footer`}
                        className={`tr-footer tr-footer-${
                          selectedInsight.includes(influencer.id) ? 'selected-' + influencer.id : ''
                        }`}
                      >
                        <td className="td-insights-text" colSpan={11}>
                          <p>{t('Insights')}</p>
                          <pre>{influencer.insight}</pre>
                          <p className="td-insights-advise">
                            * Este insight é baseado nas informações fornecidas no seu briefing,
                            quanto mais detalhes, melhor será o seu resultado!
                          </p>
                        </td>

                        <td className="td-classification" colSpan={11}>
                          <i
                            role="button"
                            className="icon icon-like"
                            data-active={influencer.insightIsLiked === 1}
                            onClick={() =>
                              handleSendFeedback(influencer.id, influencer.insightIsLiked, 1)
                            }
                          />

                          <i
                            role="button"
                            className="icon icon-dislike"
                            data-active={influencer.insightIsLiked === -1}
                            onClick={() =>
                              handleSendFeedback(influencer.id, influencer.insightIsLiked, -1)
                            }
                          />
                        </td>
                      </tr>
                    </>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      )}

      {influencers.length < 1 && (
        <img
          src={emptyImage}
          className="empty-image"
          alt={t('Imagem simbolizando que a listá está vazia')}
        />
      )}
    </div>
  );
};

Table.defaultProps = {
  toPdf: false,
  isOwner: true,
};

Table.propTypes = {
  id: PropTypes.string,
  toPdf: PropTypes.bool,
  isOwner: PropTypes.bool,
  network: PropTypes.string,
  variation: PropTypes.string,
  openModal: PropTypes.func,
  onChangePrice: PropTypes.func,
  influencers: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
