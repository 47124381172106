import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import facebookImg from '../../../../../common/assets/icons/facebook–circle.svg';
import instagramImg from '../../../../../common/assets/icons/instagram-circle.svg';
import tiktokImg from '../../../../../common/assets/icons/tiktok-circle.svg';
import twitchImg from '../../../../../common/assets/icons/twitch-circle.svg';
import TwitterImg from '../../../../../common/assets/icons/x-twitter-circle.svg';
import youtubeImg from '../../../../../common/assets/icons/youtube-circle.svg';
import ButtonModalLists from '../../../../../common/components/ButtonModalLists';
import { LevelGauge } from '../../../../../common/components/LevelGauge';
import { PARAMS } from '../../../../../constants/params';
import { useImageLoadingStatus } from '../../../../../hooks/useImageLoadingStatus';
import { formatPercentage, humanize } from '../../../../../utils/formatters';
import { getInitials } from '../../../../../utils/getInitials';
import ModalInfluencerSummary from '../../../InfluencerCard/components/ModalInfluencerSummary';
import { fetch } from '../../../InfluencerCard/slice';

import { useTranslation } from 'react-i18next';
import './index.scss';

const networkImages = {
  instagram: instagramImg,
  facebook: facebookImg,
  tiktok: tiktokImg,
  twitter: TwitterImg,
  youtube: youtubeImg,
  twitch: twitchImg,
};

export const InfluencerCard = memo(({ influencer, onInfluencerPreview }) => {
  const [showSummary, setShowSummary] = useState(false);
  const dispatch = useDispatch();
  const imageLoadingStatus = useImageLoadingStatus(influencer.avatar);
  const { t } = useTranslation();

  const handleOpenSummary = () => {
    window.clarity('set', 'click', 'username_in_search_result');

    dispatch(fetch({ id: influencer.id }));

    setShowSummary(true);
  };

  return (
    <>
      <div className="influencer_card">
        <header className="header__influencer_card">
          <div className="avatar_wrapper__influencer_card">
            <div className="avatar__influencer_card">
              {imageLoadingStatus === 'loaded' && <img src={influencer.avatar} loading="lazy" />}
              {imageLoadingStatus !== 'loaded' && getInitials(influencer.name)}
            </div>

            <img
              src={networkImages[influencer.network]}
              alt={`${influencer.network} logo`}
              loading="lazy"
            />
          </div>

          <div className="influencer_data__influencer_card">
            <Tooltip title={influencer.name}>
              <strong>{influencer.name}</strong>
            </Tooltip>
            <small>@{influencer.username}</small>
            <p>
              {humanize(influencer.followers)} seguidores
              {influencer.acceleratedGrowth && (
                <Tooltip title="Crescimento acelerado">
                  <i className="icon icon-rocket" />
                </Tooltip>
              )}
            </p>
          </div>

          <ButtonModalLists influencerId={influencer.id} influencerNetwork={influencer.network}>
            <Tooltip title="Minhas Listas">
              <button type="button" className="my_lists_button__influencer_card">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 12H17"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 16H17"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 12H8"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 16H8"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 8H17"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 8H8"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect x="14.5" y="14.5" width="9" height="9" rx="4.5" fill="#F2F2F2" />
                  <rect x="14.5" y="14.5" width="9" height="9" rx="4.5" stroke="currentColor" />
                  <path d="M17 19L21 19" stroke="currentColor" strokeLinecap="round" />
                  <path d="M19 17L19 21" stroke="currentColor" strokeLinecap="round" />
                </svg>
              </button>
            </Tooltip>
          </ButtonModalLists>
        </header>

        <div className="social_media_data_wrapper__influencer_card">
          <div className="social_media_data__influencer_card">
            <p>Airscore</p>

            <div>
              <span>{influencer.airscore}</span>
              <LevelGauge value={influencer.airscore} range={PARAMS.LEVEL_GAUGE.AIR_SCORE} />
            </div>
          </div>

          <div className="social_media_data__influencer_card">
            <p>Engajamento</p>

            <div>
              <span>{formatPercentage(influencer.engagementRate / 100)}</span>

              {influencer.network !== 'twitch' && influencer.network !== 'twitter' && (
                <LevelGauge
                  value={influencer.engagementRate}
                  range={PARAMS.LEVEL_GAUGE.ENGAGEMENT[influencer.network.toUpperCase()]}
                />
              )}
            </div>
          </div>

          <div className="social_media_data__influencer_card">
            <Tooltip
              title={
                influencer.network === 'twitch' ? 'Média de visualizações' : 'Média de interações'
              }
            >
              <p>{influencer.network === 'twitch' ? 'Visualizações' : 'Interações'}</p>
            </Tooltip>

            <div>
              <span>
                {influencer.d30_means?.total ? humanize(influencer.d30_means?.total) : '-'}
              </span>
            </div>
          </div>

          <ul className="topics_wrapper__influencer_card">
            {influencer.themes.map((theme) => (
              <li key={theme.key} className="topics__influencer_card">
                {theme.name}
              </li>
            ))}
          </ul>
        </div>

        <footer className="footer__influencer_card">
          <button type="button" onClick={handleOpenSummary}>
            Abrir posts
          </button>
          <button type="button" onClick={onInfluencerPreview}>
            Analisar perfil
          </button>
        </footer>

        {influencer.foundOn && influencer.foundOn.length > 0 && (
          <div className="search-found">
            <b>O texto que você buscou foi encontrado em: </b>

            {influencer.foundOn.map(
              (text, i) =>
                `${t(text)}${
                  influencer.foundOn.length > 0 && i < influencer.foundOn.length - 1
                    ? i === influencer.foundOn.length - 2
                      ? ' e '
                      : ', '
                    : ''
                }`,
            )}
          </div>
        )}
      </div>

      <ModalInfluencerSummary
        isVisible={showSummary}
        influencer={influencer}
        onClose={() => setShowSummary(false)}
      />
    </>
  );
});

InfluencerCard.propTypes = {
  influencer: PropTypes.object.isRequired,
  onInfluencerPreview: PropTypes.func.isRequired,
};

InfluencerCard.displayName = 'InfluencerCard';
