import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
// 
import { Input as AntInput } from "antd"
// 
import "./index.scss"

const Input = ({
    width,
    height,
    label,
    placeholder,
    value,
    defaultValue,
    prefix,
    suffix,
    suffixStyle,
    onBlur,
    onChange,
    onClickSuffix,
    onKeyDown,
    hasError,
    hasFocus,
    isOutlined,
    isDisabled,
    isDisabledSuffix,
    clearAfterOnKeyDown,
    className,
}) => {
    const inputRef = useRef(null)
    
    useEffect(() => {
        if(hasFocus) inputRef.current.focus({ cursor: "start" })
    }, [])

    return (
        <div style={{ width: width }}  className={`input-junction ${className}`}>
            {label && 
                <label>
                    {label}
                </label>
            }
            
            <AntInput
                ref={inputRef}
                size="large" 
                value={value}
                prefix={prefix}
                disabled={isDisabled}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onBlur} 
                onKeyDown={(e) => {
                    if(onKeyDown) {
                        onKeyDown(e)
                    }

                    if (clearAfterOnKeyDown && e.key === "Enter") {
                        value = ""
                    }
                }}
                style={{ width, height }}
                className={`input ${hasError ? "input-error" : ""} input-${isOutlined ? "outline" : "full"}`} 
            />

            {suffix &&
                <div 
                    style={suffixStyle} 
                    className={`suffix ${isDisabledSuffix ? "disabled" : ""}`} 
                    onClick={() => { 
                        if (onClickSuffix) onClickSuffix(inputRef.current.input.value) 
                    }}
                >
                    {suffix}
                </div>
            }
        </div>
    )  
}

Input.propTypes = {
    width: 240,
    height: 40,
    hasError: false,
    hasClearAfterBlur: false,
    hasFocus: false,
    isOutlined: false,
    isDisabled: false,
    isDisabledSuffix: false,
    clearAfterOnKeyDown: false,
}

Input.propTypes = {
    width: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    prefix: PropTypes.element,
    suffix: PropTypes.element,
    suffixStyle: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClickSuffix: PropTypes.func,
    hasError: PropTypes.bool,
    hasclearAfterBlur: PropTypes.bool,
    hasFocus: PropTypes.bool,
    isOutlined: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isDisabledSuffix: PropTypes.bool,
    clearAfterOnKeyDown: PropTypes.bool,
}

export default Input
  