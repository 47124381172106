import { mergeRefs } from '../../../utils/mergeRefs';
import { useNetPromoterScore } from './useNetPromoterScore';

import './styles.scss';

export const NetPromoterScore = () => {
  const {
    showNPS,
    collapsed,
    handleCloseBar,
    handleSubmit,
    register,
    showDescription,
    textAreaRef,
    ref,
    rest,
    isSubmitting,
    handleTextAreaChange,
  } = useNetPromoterScore();

  return (
    showNPS && (
      <>
        <div className="container_nps" data-collapsed-menu={collapsed}>
          <strong>
            Em uma escala de 0 a 10, o quanto você recomendaria a plataforma AirSearch para um amigo
            ou familiar?
          </strong>
          <button type="button" aria-label="Fechar" onClick={handleCloseBar}>
            <i className="icon icon-close" />
          </button>
          <form onSubmit={handleSubmit} className="container__form">
            <div className="container__options">
              {Array.from({ length: 11 }).map((_, index) => {
                return (
                  <input
                    key={index}
                    type="radio"
                    value={index}
                    {...register('score', {
                      required: true,
                    })}
                  />
                );
              })}
            </div>

            <div className={`container__wrapper ${showDescription ? 'visible' : ''}`}>
              <div>
                <label htmlFor="description">
                  Em poucas palavras descreva o que motivou sua nota.
                </label>
                <textarea
                  id="description"
                  placeholder="Digite aqui..."
                  rows={1}
                  cols={1}
                  maxLength={300}
                  ref={mergeRefs(textAreaRef, ref)}
                  {...rest}
                  onChange={(event) => handleTextAreaChange(event, rest)}
                />
              </div>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Enviando' : 'Enviar'}
              </button>
            </div>
          </form>
        </div>
      </>
    )
  );
};
