import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import aira from '../../../common/assets/images/aira.png';
import Button from '../../../common/components/Button';
import Loading from '../../../common/components/Loading';
import StateStatus from '../../../utils/status';
import briefing from './briefing-aira.docx';
import {
  clearCuration,
  fetchTokens,
  selectAiraTastingSlice,
  sendBriefing,
  updateStatus,
} from './slice';

import './index.scss';

const MIN_BRIEFING_LENGTH = 150;

const { useForm } = Form;

const AiraCuration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const slice = useSelector(selectAiraTastingSlice);
  const [text, setText] = useState();
  const sendStatus = slice.status.sendBriefing;
  const fetchStatus = slice.status.fetchTokens;

  const [form] = useForm();

  const examples = [
    {
      icon: 'outline-brand',
      title: t('Sobre a marca'),
      text: t('história, posicionamentos, produto, outros detalhes'),
    },
    {
      icon: 'analytics',
      title: t('Campanha'),
      text: t('objetivo da campanha (alcance, consideração ou venda), assuntos abordados, região'),
    },
    {
      icon: 'group',
      title: t('Audiência'),
      text: t('idade, sexo, interesse'),
    },
    {
      icon: 'profile',
      title: t('Influenciador'),
      text: t('perfil em alta, número de seguidores'),
    },
    {
      icon: 'mobile-device',
      title: t('Rede social'),
      text: t('digite quais redes sociais deseja trabalhar'),
    },
  ];

  let interval;

  const messages = [
    {
      text: 'Para encontrarmos os melhores influenciadores para sua campanha é simples, basta me contar sobre a sua curadoria.',
    },
    ...slice.airaMsgs,
  ];

  const onFinish = (data) => {
    dispatch(sendBriefing(data));
  };

  const downloadBriefingModel = () => {
    const fileName = 'briefing-aira.docx';
    const link = document.createElement('a');

    link.href = briefing;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  useEffect(() => {
    dispatch(clearCuration());

    const accessToken = window.localStorage.getItem('aira_tasting_access_token');

    if (accessToken) {
      dispatch(updateStatus({ name: 'fetchTokens', status: StateStatus.succeeded }));
    } else {
      dispatch(fetchTokens());
    }
  }, []);

  useEffect(() => {
    if (fetchStatus === StateStatus.failed) {
      interval = setInterval(() => {
        dispatch(fetchTokens());
      }, 3500);

      return () => clearInterval(interval);
    } else {
      clearInterval(interval);
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (sendStatus === StateStatus.succeeded) {
      dispatch(updateStatus({ name: 'sendBriefing', status: StateStatus.idle }));

      navigate('/aira-demonstracao/briefing/result');
    }
  }, [sendStatus]);

  return (
    <div className="aira-curation-page pattern-page">
      {fetchStatus === StateStatus.loading && <Loading />}

      {fetchStatus === StateStatus.succeeded && (
        <>
          <h1>{t('Assistente de curadoria')}</h1>

          <div className="box box-aira-observation">
            <img className="aira" src={aira} alt={t('mulher  do cabelo preto sorrindo')} />

            <div className="observations">
              {messages.map((item, idx) => {
                const newMsg = idx === messages.length - 1 && idx !== 0;

                return (
                  <div key={idx} className={`observation ${newMsg ? 'animate-new-msg' : ''}`}>
                    <p className="chat-name">{t('Aira')}</p>
                    <span>{t(item.text)}</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="part-form">
            <div className="box">
              <p className="title-form">{t('Conte sobre a sua curadoria')}</p>

              <p className="subtitle-form">
                <b>{t('Dica')}</b>: {t('Copie e cole seu briefing completo ou digite livremente.')}
              </p>

              <Form form={form} onFinish={onFinish}>
                <Form.Item
                  name="briefing"
                  rules={[
                    {
                      required: true,
                      message: t(`O texto precisa ter ao menos ${MIN_BRIEFING_LENGTH} caracteres`),
                    },
                  ]}
                >
                  <textarea
                    className="ant-input ant-input-lg input input-full"
                    placeholder={t('Digite aqui ou copie e cole um texto.')}
                    onKeyUp={(event) => {
                      setText(event.target.value);
                    }}
                  />
                </Form.Item>
              </Form>

              {text && text.length > 0 && text.length < MIN_BRIEFING_LENGTH && (
                <span className="briefing-count">
                  Seu texto precisa ter pelo menos {text?.length}/{MIN_BRIEFING_LENGTH} caracteres
                </span>
              )}

              <div className="form-actions">
                <Button
                  className="submit-btn"
                  isLoading={sendStatus === StateStatus.loading}
                  onClick={() => form.submit()}
                  isDisabled={!text || text?.length < MIN_BRIEFING_LENGTH}
                >
                  {t('Continuar')}
                </Button>

                <Button
                  className="outline-primary submit-btn"
                  onClick={() => downloadBriefingModel()}
                >
                  {t('Baixar modelo de briefing')}
                </Button>
              </div>
            </div>

            <div className="curation-text-examples">
              <p>{t('Exemplos do que citar')}:</p>

              <div className="examples">
                {examples.map((example) => {
                  return (
                    <div className="example" key={example.title}>
                      <i className={`icon icon-${example.icon}`} />

                      <span>
                        <b>{example.title}</b>: {example.text}.
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AiraCuration;
