import React from "react"
import PropTypes from "prop-types"
//
import "./index.scss"

const Header = ({
    title,
    subtitle,
    subHeader,
    onBack
}) => { 
    return <header className="header">
        <h2 className="title">
            {onBack && <i 
                className="icon icon-back" 
                onClick={() => onBack()}
            />}
            
            {title}
        </h2>

        {subtitle && <p className="subtitle">{subtitle}</p>}
        
        {subHeader}
    </header>
}

Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onBack: PropTypes.func,
    subHeader: PropTypes.element
}

export default Header