import React from "react"
import PropTypes from "prop-types"
// 
import { Modal as AntModal } from "antd"
// 
import "./index.scss"

const Modal = ({
  title,
  subtitle,
  width,
  className,
  children,
  isVisible,
  isClosable,
  onClose,
}) => (
  <AntModal
    className={`base-modal ${className}`}
    width={width}
    open={isVisible}
    closable={isClosable}
    onCancel={onClose}
    footer={false}
  >
    <p className="modal-title">{title}</p>
    
    <p className="modal-subtitle">{subtitle}</p>

    <div className="modal-content">{children}</div>
  </AntModal>
  )

Modal.defaultProps = {
  with: 520,
  className: "",
  isClosable: true,
  isVisible: false,
}

Modal.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  isClosable: PropTypes.bool,
  width: PropTypes.any,
  onClose: PropTypes.func,
  children: PropTypes.element,
}

export default Modal
