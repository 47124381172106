import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import unavailableValue from '../../../../../common/assets/images/empty.svg';
import Button from '../../../../../common/components/Button';
import CardPost from '../../../../../common/components/CardPost';
import CheckBox from '../../../../../common/components/CheckBox';
import Input from '../../../../../common/components/Input';
import Loading from '../../../../../common/components/Loading';
import Pagination from '../../../../../common/components/Pagination';
import Select from '../../../../../common/components/Select';
import StateStatus from '../../../../../utils/status';
import {
  clearPostStatus,
  fetchPosts,
  selectInfluencerDetailsSlice,
  updateFilterCategory,
} from '../../slice';

import './index.scss';

const PartPosts = ({ ids, filterId, filterName, foundInPosts, influencerId, isSimple }) => {
  const params = useParams();
  const id = influencerId || params.id;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const slice = useSelector(selectInfluencerDetailsSlice);
  const [postFilterPerText, updatePostFilterPerText] = useState('');
  const [keyword, updateKeyword] = useState();
  const [checkedSize, updateCheckedSize] = useState(false);
  const [searchByText, updateSearchByText] = useState(!isSimple ? false : foundInPosts);
  const [orderBy, updateOrderBy] = useState('posted_at');
  const [postsPerPage, updatePostsPerPage] = useState({
    twitch: 3,
    youtube: 3,
    tiktok: 5,
    twitter: 4,
    facebook: 4,
    instagram: 4,
  });

  const status = slice.status;
  const influencer = slice.influencer;
  const categories = influencer.categories;
  const filterCategory = slice.filterCategory;
  const rawInfluencerCategories = influencer.rawCategories;
  const page = slice.postPage;
  const totalPosts = slice.totalPosts;

  const filterLastOrBest = [
    { key: 'Últimos posts', value: 'posted_at' },
    { key: 'Melhores posts', value: 'score' },
  ];

  const getPosts = (p) => {
    const data = {
      id: id,
      page: p,
      orderBy: orderBy,
      orderByDesc: true,
      ids: searchByText ? ids : null,
      text: !isSimple ? keyword : null,
      categories: filterCategory
        ? rawInfluencerCategories[filterCategory]
          ? rawInfluencerCategories[filterCategory].subcategories.map((item) => item.id)
          : []
        : [],
      itemsPerPage: postsPerPage[influencer.network] || 3,
    };

    if (filterName && filterId) data[filterName] = filterId;

    dispatch(fetchPosts(data));
  };

  useEffect(() => {
    dispatch(clearPostStatus());
  }, []);

  useEffect(() => {
    if (filterId && influencer.network === 'tiktok') {
      const page = document.querySelector(
        filterId ? '.mention-posts-card-content' : '.influencer-card-container',
      );

      if (page) {
        if (filterId) {
          if (page.clientWidth < 1090) {
            updatePostsPerPage({ ...postsPerPage, tiktok: 3 });
          } else if (page.clientWidth < 1240) {
            updatePostsPerPage({ ...postsPerPage, tiktok: 4 });
          }
        } else {
          if (page.clientWidth < 1240) {
            updatePostsPerPage({ ...postsPerPage, tiktok: 3 });
          } else if (page.clientWidth < 1450) {
            updatePostsPerPage({ ...postsPerPage, tiktok: 4 });
          }
        }
      }
    }

    updateCheckedSize(true);
  }, [filterId]);

  useEffect(() => {
    if (checkedSize) getPosts(0);
  }, [orderBy, filterId, filterCategory, checkedSize, keyword, searchByText]);

  useEffect(() => {
    if (status.fetchPosts === StateStatus.succeeded && isSimple) {
      const box = document.querySelector('.influencer-posts');

      if (box) {
        const slider = box.querySelector('.posts');

        if (slider) {
          let isDown = false;
          let startX;
          let scrollLeft;

          slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          });
          slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
          });
          slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
          });
          slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;

            e.preventDefault();

            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3;

            slider.scrollLeft = scrollLeft - walk;
          });
        }
      }
    }
  }, [status.fetchPosts, isSimple]);

  return (
    <div className="influencer-posts">
      <div className="title-part">
        {t('Publicações do influenciador nos últimos 90 dias')}

        {!isSimple && totalPosts > postsPerPage[influencer.network] && (
          <Pagination
            pageSize={postsPerPage[influencer.network]}
            current={page + 1}
            total={totalPosts}
            isDisabled={
              status.fetchPosts === StateStatus.loading || status.fetchPosts === StateStatus.failed
            }
            onChange={(p) => {
              getPosts(p - 1);
            }}
          />
        )}
      </div>

      {status.fetchPosts === StateStatus.failed && (
        <p className="text-error">{t('Erro ao pegar os posts')}</p>
      )}

      <div className={`posts-card ${isSimple ? 'posts-card-simple' : ''}`}>
        {influencer.posts && !filterId && (
          <div className="post-filters">
            {!isSimple && (
              <div className="box-search-keywords">
                <Input
                  isOutlined
                  value={postFilterPerText}
                  className="input-post-filter"
                  prefix={<i className="icon icon-search" />}
                  placeholder={t('Pesquise por palavras-chaves')}
                  onChange={(e) => {
                    updatePostFilterPerText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    updatePostFilterPerText(e.target.value);

                    if (e.key === 'Enter') {
                      let value = e.target.value;

                      if (value) updateKeyword(value);
                    }
                  }}
                />

                {keyword && (
                  <i
                    role="button"
                    onClick={() => {
                      updateKeyword();
                      updatePostFilterPerText();
                    }}
                    className="icon icon-close hover"
                  />
                )}

                <Button
                  className="submit-post-keyword"
                  onClick={() => {
                    updateKeyword(postFilterPerText);
                  }}
                >
                  {t('Buscar')}
                </Button>
              </div>
            )}

            <div className="selects">
              <Select
                value={orderBy}
                placeholder={t('Selecione')}
                onChange={(value) => updateOrderBy(value)}
                options={filterLastOrBest}
              />

              {categories && categories.length > 0 ? (
                <Select
                  allowClear={true}
                  value={filterCategory}
                  placeholder={t('Selecione uma categoria')}
                  onChange={(value) => dispatch(updateFilterCategory(value))}
                  options={categories.map((category) => ({
                    value: category.id,
                    key: category.name,
                    icon: (
                      <i
                        style={{ color: category.color }}
                        className={`icon icon-${category.icon}`}
                      />
                    ),
                  }))}
                />
              ) : (
                <span />
              )}
            </div>

            {isSimple && foundInPosts && (
              <div
                className="junction-checkbox hover"
                key={`checkbox-${influencer.id}-preview`}
                onClick={() => {
                  updateSearchByText(!searchByText);
                }}
              >
                <CheckBox
                  checked={searchByText}
                  text={t('Minha busca por texto')}
                  key={`check-${influencer.id}-preview`}
                />
              </div>
            )}
          </div>
        )}

        {(status.fetchPosts === StateStatus.loading || status.fetchPosts === StateStatus.idle) && (
          <Loading />
        )}

        {status.fetchPosts === StateStatus.succeeded && (
          <>
            {influencer.posts && influencer.posts.length > 0 && (
              <div
                className={`posts ${influencer.network}-posts posts-${
                  postsPerPage[influencer.network] || 3
                }`}
              >
                {influencer.posts.map((post) => {
                  return (
                    <CardPost
                      post={post}
                      key={post.id}
                      name={influencer.name}
                      avatar={influencer.avatar}
                      network={influencer.network}
                    />
                  );
                })}
              </div>
            )}

            {!influencer.posts ||
              (influencer.posts.length < 1 && (
                <div className="empty">
                  <p>{t('Nenhuma publicação feita')}</p>

                  <img
                    className="unavailable-img"
                    src={unavailableValue}
                    alt="diversas nuvens azuis"
                  />
                </div>
              ))}

            {isSimple && totalPosts > postsPerPage[influencer.network] && (
              <Pagination
                pageSize={postsPerPage[influencer.network]}
                current={page + 1}
                total={totalPosts}
                isDisabled={
                  status.fetchPosts === StateStatus.loading ||
                  status.fetchPosts === StateStatus.failed
                }
                onChange={(p) => {
                  getPosts(p - 1);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

PartPosts.defaultProps = {
  ids: [],
  isSimple: false,
  foundInPosts: false,
};

PartPosts.propTypes = {
  ids: PropTypes.array,
  isSimple: PropTypes.bool,
  foundInPosts: PropTypes.bool,
  filterId: PropTypes.string,
  filterName: PropTypes.string,
  influencerId: PropTypes.string,
};

export default PartPosts;
