import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
// 
import { useTranslation } from "react-i18next"
// 
import InputCurrency from "../InputCurrency"
// 
import "./index.scss"

const PriceSimulator = ({
    value,
    onApplication,
}) => {
    const { t } = useTranslation()
    // 
    let delayTimer
    // 
    const [newValue, setNewValue] = useState()

    useEffect(() => {
        setNewValue(value)
    }, [value])

    return (
        <InputCurrency 
            placeholder={t("Valor")}
            value={newValue ? parseInt(newValue * 100) : 0}
            onChange={(value) => {
                clearTimeout(delayTimer)
                            
                delayTimer = setTimeout(() => {
                    if(value) {
                        onApplication(value / 100)
                    } else {
                        onApplication(null)
                    }
                }, 500)
            }}
        />
    )
}
PriceSimulator.defaultProps = {
    hasFavorite: true
}

PriceSimulator.propTypes = {
    value: PropTypes.any,
    onApplication: PropTypes.func.isRequired,
}

export default PriceSimulator