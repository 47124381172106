const en = {
    // "Configurar conta": "Set up account",
    // "Idioma": "Language",
    // "Sair": "Go out",
    
    // "Pesquisar": "Search",
    // "Buscar influenciadores": "Search influencers",
    // "Lista de influciadores": "List of influencers",

    // // alert
    // "sucesso": "success", 
    // "atenção": "alert", 
    // "erro": "error", 
    // "Limite de listas atingido! Você pode ter no máximo DUAS listas.": "List limit reached! You can have a maximum of TWO lists.",

    // // menu
    // "Buscar influenciador": "Search for influencer",
    // "Lista de planejamento": "Planner list",

    // // search
    // "Nome ou usuário": "Name or username",
    // "@username ou nome": "@username or name",
    // "Selecione as redes que deseja pesquisar": "Select the networks you want to search for",
    // "Selecione um ou mais temas": "Select one or more themes",
    // "Selecione": "Select",
    // "Engajamento": "Engagement",
    // "Regular": "Regular",
    // "Bom": "Good",
    // "Ótimo": "Excellent",
    // "separados por virgula": "separated by comma",
    // "Locais contidos na bio do influenciador": "Locations contained in the influencer's bio",
    // "Buscar por palavra-chave contida na bio do influenciador. Não deixe de separar as menções por '-', ',' ou ';'": "Search for keyword contained in the influencer's bio. Be sure to separate the mentions by '-', ',' or ';'",
    // "Hashtags mencionadas pelo influenciador": "Hashtags mentioned by the influencer",
    // "Número de seguidores, fãs ou inscritos": "Number of followers, fans or subscribers",
    // "Selecione um ou mais locais mencionados": "Select one or more locations mentioned",
    // "Airscore (métrica própria)": "Airscore (own metric)",
    // "Limpar busca": "Clear search",
    // "Buscar": "Search",
    // "Relevância do tema selecionadas": "Relevance of the selected theme",
    // "Utilize aspas duplas para busca exata do nome ou usuário que deseja encontrar": "Use double quotes for exact search for the name or user you want to find",
    // "Selecione um ou mais temas que o influenciador abordou em suas postagens. Conseguimos definir a relevância do tema quando selecionamos apenas um tema.": "Select one or more topics that the influencer addressed in your posts. We were able to define the relevance of the theme when we selected only one theme.",
    // "Insira uma ou mais hashtags mencionadas pelo influenciador em seus posts. Não deixe de separar as hashtags por ',' ou ';'": "Insert one or more hashtags mentioned by the influencer in your posts. Be sure to separate the hashtags by ',' or ';'",
    // "Defina o tamanho de base para os perfis que deseja encontrar.": "Set the base size for the profiles you want to find.",
    // "Essa porcentagem é a relevância sobre o assunto. Ou seja, o quanto o influenciador falou sobre o assunto em suas publicações.": "This percentage is the relevance on the subject. That is, how much the influencer talked about the subject in his publications.",
    // "A busca é feita através dos locais mencionados na bio e posts do influenciador": "The search is done through the places mentioned in the bio and posts of the influencer",
    // "O Airscore é uma métrica que avalia a qualidade do engajamento de um perfil nas redes sociais — levando em consideração pontuações que variam entre 0 a 1.000.": "Airscore is a metric that measures the quality of a profile’s engagement on social media - taking into account scores ranging from 0 to 1,000.",
    "gender": "Gender",
    "audience_gender": "Audience gender",
    "F": "Female",
    "M": "Male",
    "female": "Female",
    "male": "Male",

    // // pl
    // "Criar nova lista": "Create new list",
    // "Inserir o nome da lista": "Insert the list name",
    // "Você ainda não criou uma lista.": "You haven't created a list yet.",
    // "Editar lista": "Edit list",
    // "Duplicar lista": "Duplicate list",
    // "Exportar lista": "Export list",
    // "Apagar lista": "Delete list", 
    // "Nome atualizado com sucesso": "Name updated successfully",
    // "Falha ao atualizar nome": "Failed to update name",
    // "Esta ação não pode ser desfeita! Caso queira, você pode exportar a campanha antes de excluí-la.": "This action cannot be undone! If you like, you can export the campaign before deleting it.",
    // "Você tem certeza que deseja deletar a campanha?": "Are you sure you want to delete the campaign?",
    // "Sim, remover campanha": "Yes, remove campaign",
    // "Não, manter campanha": "No, keep campaign",
    // "Lista removida com sucesso": "List removed successfully",
    // "Falha ao remover a lista": "Failed to remove list",
    // "Lista duplicada com sucesso": "List successfully duplicated",
    // "Falha ao duplicar a lista": "Failed to duplicate list",
    // "Ver influenciadores": "See influencers",
    // "Quantidade total de influenciadores na lista em todas as redes": "Total number of influencers on the list across all networks",
    // "Influenciadores na lista": "Influencers in the list",
    // "Comparar desempenho por rede": "Compare performance by network",

    // // select
    // "Ordenar por": "Order by",
    // "Relevância": "Relevance",
    // "Nome (A-Z)": "Name (A-Z)",
    // "Nome (Z-A)": "Name (A-Z)",
    // "Seguidores, fãs ou inscritos (0-9)": "Followers, fans or subscribers (0-9)",
    // "Seguidores, fãs ou inscritos (9-0)": "Followers, fans or subscribers (9-0)",
    // "Airscore (0-9)": "Airscore (0-9)",
    // "Airscore (9-0)": "Airscore (9-0)",

    // // lang modal
    // "Selecione o idioma desejado": "Select the desired language",
    // "Português": "Portuguese",
    // "Inglês": "English",
    // "Espanhol": "Spanish",

    // // comparative table
    // "Influenciador": "Influencer",
    // "Soma das bases": "Sum of bases",
    // "Potencial de alcance": "Reach potential",
    // "Airscore Médio": "Medium Airscore",
    // "Soma das interações": "Sum of interactions",
    // "Total de influenciadores na lista": "Total influencers in the list",
    // "Soma das bases de todos os influenciadores da lista": "Sum of the bases of all influencers on the list",
    // "Soma das médias de interação de cada um dos influenciadores da lista": "Sum of the interaction averages for each of the influencers on the list",
    // "Média do Airscore do grupo de influencidores": "Influencer group Airscore average",
    // "Soma das interações dos influenciadores da lista nos útlimos 90 dias": "Sum of interactions by influencers on the list over the past 90 days",
    // "Temas": "Themes",
    // "Marcas": "Marks",
    // "Minhas listas": "My Lists",

    // "Usuário inválido": "Invalid user",
    // "Nenhuma marca mencionada pelo influenciador": "No brand mentioned by the influencer",
    // "Nenhuma hashtag mencionada pelo influenciador": "No hashtag mentioned by the influencer",
    // "Nenhum local mencionado pelo influenciador": "No location mentioned by the influencer",
    // "Nenhum perfil mencionado pelo influenciador": "No profiles mentioned by the influencer",
    // "Nenhum tema mencionado pelo influenciador": "No topic mentioned by the influencer",
    
    
    // "Crie uma lista": "Search or create a list",
    // "Selecionar rede": "Select network",

    // "Acessar Rede Social": "Access Social Network",
    // "Ver perfil": "View profile",
    
    // // influencer card
    // "A bio do influeciador não possui texto": "The influencer's bio has no text",
    // "Ver bio": "View bio",
    // "Seguidores": "Followers",
    // "Quantidade de seguidores em sua rede social e a porcentagem representa a variação de crescimento": "Number of followers on your social network and the percentage represents the growth variation",
    // "Airscore": "Airscore",
    // "Nota que representa a qualidade do  engajamento, que varia de 0 a 1000. A porcentagem representa a variação de crescimento.": "Note that represents the quality of engagement, which ranges from 0 to 1000. The percentage represents the growth variation.",
    // "Publicações": "Publications",
    // "Quantidade de publicações realizadas pelo influenciador nos últimos 90 dias.": "Number of publications made by the influencer in the last 90 days.",
    // "Top 3 Marcas": "Top 3 Brands",
    // "Top 3 marcas citadas pelo influenciador": "Top 3 brands cited by the influencer",
    // "Top 3 Hashtags": "Top 3 Hashtags",
    // "Top 3 hashtags citadas pelo influenciador.": "Top 3 hashtags cited by the influencer.",
    // "Top 3 Locais": "Top 3 Locations",
    // "Top 3 locais mencionados pelo influenciador.": "Top 3 places mentioned by the influencer.",
    // "Top 3 Perfis": "Top 3 Profiles",
    // "Top 3 perfis mencionados pelo influenciador.": "Top 3 profiles mentioned by the influencer.",
    // "Assuntos abordados pelo influenciador": "Influencer themes",
    // "Top 3 temas abordados pelo influenciador e a relavância sobre o assunto. A porcentagem representa o quanto o influenciador falou sobre o tema em suas publicações no período.": "Top 3 topics covered by the influencer and the relevance on the subject. The percentage represents how much the influencer talked about the topic in his publications in the period.",
    // "Temas detalhados": "Detailed themes",
    // "Top 3 temas abordados pelo influenciador ecom o detalhamento dos subtemas abordados de cada assunto e a porcentagem de relevância.": "Top 3 topics covered by the influencer and with the details of the subthemes covered for each subject and the percentage of relevance.",

    // //general error
    // "Erro no sistema. Por favor, tente mais tarde.": "System error. Please, try it later."
}

export default en;