import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../../common/components/Header';
import { updateTabTitle } from '../../../utils/updateTabTitle';
import FormAddInfluecer from './components/FormAddInfluecer';
import FormAddInfluecers from './components/FormAddInfluecers';

import './index.scss';

const AddInfluencer = () => {
    const [filterType, setFilterType] = useState('single');
    const { t } = useTranslation();

    updateTabTitle('Adicionar influenciador - AirSearch');

    const filterTypes = [
        { value: 'single', icon: 'add-influencer', label: t('Um influenciador') },
        { value: 'multiple', icon: 'add-multiple', label: t('Vários influenciadores') },
    ];

    return (
        <div className="add-influencer-page background-page">
            <div className="add-influencer-content scroll-content">
                <Header title={t('Adicionar influenciador')} className="add-influencer-header" />

                <div className="add-influencer-container">
                    <div className="filter-types">
                        {filterTypes.map(({ value, label }) => (
                            <div
                                key={value}
                                className={`filter-type-option ${
                                    filterType === value ? 'selected' : 'unselected'
                                }`}
                                onClick={() => setFilterType(value)}
                            >
                                {label}
                            </div>
                        ))}

                        <div className={`underline ${filterType}`} />
                    </div>

                    <div className="">
                        {filterType === 'single' && <FormAddInfluecer />}
                        {filterType === 'multiple' && <FormAddInfluecers />}
                    </div>
                </div>
            </div>
        </div>
    );
};

AddInfluencer.propTypes = {
    listId: PropTypes.string,
    listName: PropTypes.string,
};

export default AddInfluencer;
