import moment from "moment"
// 
import "moment/locale/pt-br"

class NoteModel {
    text
    author
    createdAt
    updatedAt
    isFixed
    isEdited
    isOwner

    fromJson(data) {
        this.id = data["uid"]
        this.text = data["text"]
        this.isFixed = data["pinned"]
        this.isEdited = data["edited"]
        this.isOwner = data["owner"]
        this.author = data["created_by"]
        this.createdAt = moment(data["created_at"]).locale("pt-br").fromNow()
        this.updatedAt = moment(data["updated_at"]).locale("pt-br").fromNow()

        return this
    }
}

export default NoteModel