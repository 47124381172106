import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import { useDispatch, useSelector } from "react-redux"
import { fetchCategories, selectInfluencerDetailsSlice, updateFilterCategory } from "../../slice"
import StateStatus from "../../../../../utils/status"
// 
import Select from "../../../../../common/components/Select"
import Button from "../../../../../common/components/Button"
import Loading from "../../../../../common/components/Loading"
import CardCategory from "../../../../../common/components/CardCategory"
// 
import unavailableValue from "../../../../../common/assets/images/empty.svg"
// 
import "./index.scss"

const PartCategories = ({ influencerId }) => {
    const params = useParams()
    const id = influencerId || params.id
    const { t } = useTranslation()
    // 
    const dispatch = useDispatch()
    // 
    const slice = useSelector(selectInfluencerDetailsSlice)
    // 
    const influencer = slice.influencer
    const filterCategory = slice.filterCategory
    const status = slice.status.fetchCategories
    // 
    const [categories, updateCategories] = useState()
    // 
    const [showSubcategories, updateShowSubcategories] = useState(false)
    const [showAllCategories, updateShowAllCategories] = useState(false)

    const filterCategories = () => {
        let newList = []
            
        if(filterCategory) {
            newList = influencer.categories.filter(category => category.id === filterCategory)
        } else {
            newList = influencer.categories
        }
        
        newList = newList.slice(0, (showAllCategories ? influencer.categories.length : 5))
        
        updateCategories(newList)
    }

    useEffect(() => {
        dispatch(fetchCategories({ id }))
    }, [])

    useEffect(() => {
        if(influencer.categories) filterCategories()
    }, [influencer.categories])

    useEffect(() => {
        if(categories) filterCategories()
    }, [filterCategory, showAllCategories])

    return <div className="influencer-categories">
        <div className="title-part">
            {t("Assuntos abordados pelo influenciador")}

            <span className="observation-text">{t("*Métricas relacionadas aos últimos 90 dias")}</span>
        </div>

        {status === StateStatus.loading && <Loading />}
        
        {status === StateStatus.failed && 
            <p className="text-error">{t("Erro ao pegar as categorias")}</p>
        }

        {status === StateStatus.succeeded && 
            <div className="categories-card">
                {categories && categories.length > 0 && <>
                    <div className="filters">
                        <div className="btns">
                            {influencer.categories && influencer.categories.length > 0 && <Button 
                                className="pattern-grey"
                                onClick={() => updateShowSubcategories(!showSubcategories)}
                            >
                                {t(showSubcategories ? "Ver simplificado" : "Abrir subcategorias")}
                            </Button>}

                            {influencer.categories && influencer.categories.length > 5 && <Button 
                                className="pattern-grey"
                                onClick={() => updateShowAllCategories(!showAllCategories)}
                            >
                                {t(showAllCategories ? "Ver top 5 categorias" : "Ver todas as categorias")}
                            </Button>}
                        </div>

                        {influencer.categories && influencer.categories.length > 1 && 
                            <Select
                                allowClear={true}
                                value={filterCategory}
                                placeholder={t("Selecione uma categoria")}
                                onChange={(value) => dispatch(updateFilterCategory(value))}
                                options={influencer.categories.map(category => (
                                    { 
                                        value: category.id, 
                                        key: category.name, 
                                        icon: <i 
                                            style={{ color: category.color }}
                                            className={`icon icon-${category.icon}`} 
                                        /> 
                                    }
                                ))}
                            />
                        }
                    </div>

                    <div className="categories">
                        {categories.map(category =>
                            <CardCategory 
                                key={category.id} 
                                category={category}
                                showSubcategories={showSubcategories} 
                            />
                        )}
                    </div>
                </>}

                {(!influencer.categories || influencer.categories.length < 1) && 
                    <div className="empty">
                        <p>{t("Nenhum assunto mencionado")}</p>

                        <img className="unavailable-img" src={unavailableValue} alt="diversas nuvens azuis" />
                    </div>
                }
            </div>
        }
    </div>
}

PartCategories.propTypes = {
    influencerId: PropTypes.string,
}

export default PartCategories