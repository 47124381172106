import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import iconMore from '../../../../../common/assets/icons/more.svg';
import aira from '../../../../../common/assets/images/aira.png';
import noAvatar from '../../../../../common/assets/images/no-picture-pl.svg';
import { changeObjAlert, showAlert } from '../../../../../common/components/Alert/slice';
import BlankItem from '../../../../../common/components/BlankItem';
import Dropdown from '../../../../../common/components/DropDown';
import Loading from '../../../../../common/components/Loading';
import Pagination from '../../../../../common/components/Pagination';
import Select from '../../../../../common/components/Select';
import StateStatus from '../../../../../utils/status';
import { clearSearchValues } from '../../../Home/slice';
import { clearPlannerList } from '../../../PlannerListCard/slice';
import { download, duplicate, edit, fetch, remove, selectPlannerListSlice } from '../../slice';

import { columns, optionsSortBy, socialMediaImages } from './data';

import './index.scss';

const PlannerListTable = ({ onEdit }) => {
  const [sorts, setSorts] = useState(['name']);
  const [boolBigger, setBoolBigger] = useState(false);
  const [orderedLists, setOrderedPlannerList] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const [arrowLocation, setArrowLocation] = useState(-1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slice = useSelector(selectPlannerListSlice);
  const [searchParams, setSearchParams] = useSearchParams();

  const list = slice.list;
  const filters = slice.order;
  const status = slice.status.fetch;

  const handleChangePagination = (page) => {
    setSearchParams((params) => {
      params.set('page', page);

      return params;
    });
  };

  const page = Number(searchParams.get('page') ?? 1);
  const order = searchParams.get('order');
  const desc = searchParams.get('desc');
  const query = slice.title;

  const getActions = ({ isOwner, isFavorite, isShared, hasInfluencers }) =>
    [
      hasInfluencers && {
        icon: <i className="icon icon-group" />,
        key: 'influencers',
        label: t('Influenciadores da lista'),
      },
      {
        icon: <i className="icon icon-download" />,
        key: 'download',
        label: t('Baixar'),
        type: 'submenu',
        children: [
          {
            icon: null,
            key: 'export-planner-list',
            label: t('Baixar card da lista'),
          },
          {
            icon: null,
            key: 'export-comparative-table',
            label: t('Baixar tabela de comparação'),
          },
        ],
      },
      (isOwner || isFavorite) && {
        icon: <i className="icon icon-pencil" />,
        key: 'edit-list',
        label: t('Editar lista'),
      },
      {
        icon: <i className="icon icon-duplicate" />,
        key: 'duplicate',
        label: t('Duplicar'),
      },
      isOwner && {
        icon: <i className={`icon icon-${isShared ? 'unshare' : 'arrow-share'}`} />,
        key: 'share',
        label: t(isShared ? 'Descompartilhar' : 'Compartilhar'),
      },
      isOwner &&
        !isFavorite && {
          icon: <i className="icon icon-trash" />,
          key: 'remove',
          label: t('Excluir'),
        },
    ].filter((item) => item);

  const orderBy = (obj = 'perNumber', index) => {
    if (columns[index].key !== 'check' && columns[index].key !== 'options') {
      setArrowLocation(index);

      if (!sorts.includes(obj)) {
        const newOrder = [...orderedLists].sort((a, b) => {
          if (obj === 'name' || obj === 'created') {
            if (a[obj]?.toLowerCase() > b[obj]?.toLowerCase()) return -1;
            if (a[obj]?.toLowerCase() < b[obj]?.toLowerCase()) return 1;
            return 0;
          } else if (obj === 'createdAt') {
            if (a[obj] > b[obj]) return -1;
            if (a[obj] < b[obj]) return 1;

            return 0;
          } else {
            if ((a[obj] || 0) > (b[obj] || 0)) return -1;
            if ((a[obj] || 0) < (b[obj] || 0)) return 1;
            return 0;
          }
        });
        setBoolBigger(true);
        setOrderedPlannerList(newOrder);

        setSorts((oldArray) => [...oldArray, obj]);
      } else {
        const newOrder = [...orderedLists].sort((a, b) => {
          if (obj === 'name' || obj === 'created') {
            if (a[obj]?.toLowerCase() < b[obj]?.toLowerCase()) return -1;
            if (a[obj]?.toLowerCase() > b[obj]?.toLowerCase()) return 1;
            return 0;
          } else if (obj === 'createdAt') {
            if (a[obj] < b[obj]) return -1;
            if (a[obj] > b[obj]) return 1;

            return 0;
          } else {
            if ((a[obj] || 0) < (b[obj] || 0)) return -1;
            if ((a[obj] || 0) > (b[obj] || 0)) return 1;
            return 0;
          }
        });

        setBoolBigger(false);
        setOrderedPlannerList(newOrder);

        setSorts(sorts.filter((item) => item !== obj));
      }
    }
  };

  useEffect(() => {
    setOrderedPlannerList(list);
  }, [list]);

  return (
    <div className="pl-table-container">
      {status === StateStatus.succeeded && (
        <>
          <div className="junction-pl-list">
            <div className="selected-items">
              {selecteds.length > 0 && (
                <>
                  <span>
                    {selecteds.length}{' '}
                    {selecteds.length === 1 ? t('lista selecionada') : t('listas selecionadas')}
                  </span>

                  <i
                    className="icon icon-trash hover"
                    onClick={() => {
                      if (selecteds && selecteds.length > 0) {
                        dispatch(
                          changeObjAlert({
                            show: true,
                            type: 'warning',
                            title: t('Atenção'),
                            text: t('Você não terá a opção de recuperar a lista de planejamento!'),
                            question: t('Tem certeza que deseja excluí-la?'),
                            okText: t('Sim, excluir planejamento'),
                            cancelText: t('Não, manter planejamento'),
                            onCancel: () => dispatch(showAlert(false)),
                            onOk: async () => {
                              await selecteds.forEach((id) => {
                                dispatch(remove(id));
                              });

                              dispatch(showAlert(false));
                              dispatch(fetch({ page }));
                              setSelecteds([]);
                            },
                          }),
                        );
                      }
                    }}
                  />
                </>
              )}
            </div>

            <div className="junction-select">
              <div className="text-select">{t('Ordenar por')}</div>

              <Select
                options={optionsSortBy}
                defaultValue={filters}
                placeholder={t('Selecione')}
                value={desc ? `${order} ${desc}` : order}
                onChange={(value) => {
                  const [orderValue, descValue] = value.split(' ');

                  setSearchParams((params) => {
                    params.set('order', orderValue);

                    if (descValue) {
                      params.set('desc', descValue);
                    } else {
                      params.delete('desc');
                    }

                    return params;
                  });
                }}
              />
            </div>
          </div>

          {orderedLists.length < 1 && (
            <div className="no-planner-lists">
              <BlankItem height="187px" type="no-planner-lists" />

              <span style={{ marginTop: '16px' }}>
                {t('Nenhuma lista de planejamento foi encontrada')}
              </span>
            </div>
          )}
          {orderedLists.length > 0 && (
            <>
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      {columns.map((item, key) => {
                        return (
                          <th
                            key={item.key}
                            className={
                              !item || item.key === 'check' || item.key === 'options' ? '' : 'hover'
                            }
                            colSpan={item.span}
                            onClick={() => {
                              if (item && item.key !== 'check' && item.key !== 'options') {
                                orderBy(item.key, key, item.withTotal);
                              }
                            }}
                          >
                            {item.title}

                            {key === arrowLocation && (
                              <i
                                className={`icon icon-down ${boolBigger ? 'bigger' : 'smaller'}`}
                              />
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {orderedLists.map((pl) => {
                      return (
                        <tr key={pl.id}>
                          <td className="td-name" colSpan={7}>
                            <div
                              className="div-flex hover name-space"
                              onClick={() => {
                                dispatch(clearPlannerList());

                                if (pl.networks && pl.networks[0] && pl.networks[0].name) {
                                  navigate(
                                    `/planner-lists/${pl.id}/comparative-table/${pl.networks[0].name}`,
                                  );
                                } else {
                                  navigate(`/planner-lists/${pl.id}/card`);
                                }
                              }}
                            >
                              {!pl.avatar ? (
                                <div className="empty-avatar">
                                  <img src={noAvatar} />
                                </div>
                              ) : (
                                <div
                                  className="pl-avatar"
                                  alt="avatar da planner list"
                                  style={{
                                    backgroundImage: `url(${pl.avatar})`,
                                  }}
                                />
                              )}

                              <div className="influencer-name">{pl.name}</div>
                            </div>
                          </td>

                          <td className="td-network" colSpan={2}>
                            <div className="div-flex">
                              {pl.networks.map((network) => (
                                <div className={`box-nw ${network.name}`} key={network.name}>
                                  <Tooltip
                                    title={`${network.value} ${t(
                                      network.value === 1 ? 'influenciador' : 'influenciadores',
                                    )}`}
                                  >
                                    <img
                                      src={socialMediaImages[network.name]}
                                      alt={`${network.name} logo`}
                                    />
                                  </Tooltip>
                                </div>
                              ))}
                            </div>
                          </td>

                          <td className="td-date" colSpan={2}>
                            {pl.date}
                          </td>

                          <td className="td-total" colSpan={1}>
                            {pl.size}
                          </td>

                          <td className="td-created" colSpan={2}>
                            {pl.created}
                          </td>

                          <td className="td-aira" colSpan={1}>
                            {pl.createdByAira && (
                              <img src={aira} alt="mulher  do cabelo preto sorrindo" />
                            )}
                          </td>

                          <td className="td-options" colSpan={1}>
                            <Dropdown
                              options={getActions({
                                isOwner: pl.isOwner,
                                isShared: pl.isShared,
                                isFavorite: pl.isFavorite,
                                hasInfluencers: pl.networks.length > 0,
                              })}
                              optionsWithIcons={true}
                              onSelect={(key) => {
                                if (key === 'influencers') {
                                  dispatch(clearSearchValues());
                                  navigate(`/list/${pl.id}/${pl.name}`);
                                } else if (key === 'edit-list') {
                                  onEdit({
                                    id: pl.id,
                                    name: pl.name,
                                    avatar: pl.avatar,
                                    isFavorite: pl.isFavorite,
                                  });
                                } else if (key === 'remove') {
                                  dispatch(
                                    changeObjAlert({
                                      show: true,
                                      type: 'warning',
                                      title: t('Atenção'),
                                      text: t(
                                        'Você não terá a opção de recuperar a lista de planejamento!',
                                      ),
                                      question: t('Tem certeza que deseja excluí-la?'),
                                      okText: t('Sim, excluir planejamento'),
                                      cancelText: t('Não, manter planejamento'),
                                      onCancel: () => dispatch(showAlert(false)),
                                      onOk: async () => {
                                        await dispatch(remove(pl.id));
                                        dispatch(showAlert(false));

                                        dispatch(
                                          fetch({
                                            page,
                                            title: query,
                                            order: order,
                                            desc: desc ? desc === 'desc' : undefined,
                                          }),
                                        );

                                        setSelecteds([]);
                                      },
                                    }),
                                  );
                                } else if (key === 'duplicate') {
                                  dispatch(duplicate(pl.id));
                                } else if (key === 'share') {
                                  dispatch(
                                    edit({
                                      id: pl.id,
                                      title: pl.name,
                                      shared: !pl.isShared,
                                    }),
                                  );
                                } else if (key === 'export-comparative-table') {
                                  dispatch(
                                    download({
                                      id: pl.id,
                                      name: pl.name,
                                    }),
                                  );
                                } else if (key === 'export-planner-list') {
                                  window.open(`/#/planner-lists/${pl.id}/card/pdf`);
                                }
                              }}
                            >
                              <img src={iconMore} className="icon-more" />
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}

      {status === StateStatus.loading && <Loading />}

      {status === StateStatus.failed && <p className="text-error">{t('Erro na listagem')}</p>}

      {status !== StateStatus.failed && slice.total > 10 && (
        <Pagination
          pageSize={10}
          current={page}
          total={slice.total}
          isDisabled={status === StateStatus.loading || status === StateStatus.failed}
          onChange={handleChangePagination}
        />
      )}
    </div>
  );
};

PlannerListTable.propTypes = {
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
};

export default PlannerListTable;
