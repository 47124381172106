import api from '../../../service/api';
import routes from '../../../service/apiRoutes';

export const getPlannerListsDetailed = (data) => api.post(`${routes.plannerList}/search`, data);

export const getPlannerListSimple = (id) => api.get(`${routes.plannerList}/${id}/simple`);

export const deletePlannerList = (id) => api.delete(`${routes.plannerList}/${id}`);

export const createPlannerList = (title) =>
    api.post(`${routes.plannerList}/create`, {
        title,
        shared: false,
    });

export const uploadImage = (id, data) =>
    api.post(`${routes.plannerList}/image/${id}`, data, {
        headers: { 'Content-Type': 'mulipart/form-data' },
    });

// CARD ACTIONS
export const duplicatePlannerList = (id) => api.post(`${routes.plannerList}/${id}/duplicate`);
//
export const editPlannerList = (title, id, shared) =>
    api.post(`${routes.plannerList}/${id}`, { title, shared });
//
export const addInfluencerInPL = (id, data) =>
    api.post(`${routes.plannerList}/${id}/profile`, data);

export const deleteInfluencerInPL = (id, data) =>
    api.post(`${routes.plannerList}/${id}/profile/delete`, data);
//
export const exportPlanner = (id) =>
    api.get(`${routes.plannerList}/${id}/xlsx`, {
        responseType: 'arraybuffer',
    });
