import i18n from '../languages/i18n';

class DemographicsModel {
  constructor() {
    this.bars = {};
    this.pies = {};
    this.donuts = {};
  }

  fromJson(data, dataset) {
    const demographics = Object.keys(data).filter((r) => {
      if (Array.isArray(data[r]) && data[r].length > 0) {
        return data[r];
      }
    });

    demographics.forEach((item) => {
      if (item == 'gender' || item === 'parental' || item === 'marital') {
        let list = [];
        //
        let labels = {
          gender: {
            Male: i18n.t('Masculino'),
            Female: i18n.t('Feminino'),
          },
          parental: {
            Yes: i18n.t('Sim'),
            No: i18n.t('Não'),
          },
          marital: {
            Married: i18n.t('Casado(a)'),
            Single: i18n.t('Solteiro(a)'),
          },
        };

        if (item === 'parental' && data[item][0]) {
          const firstItem = data[item][0];
          //
          let newChartData = [
            {
              ...firstItem,
              value: firstItem.value * 10,
              label: {
                code_name: 'Yes',
              },
            },
          ];
          const newItem = {
            label: { code_name: 'No' },
            value: 10000 - firstItem.value * 10,
          };

          if (firstItem.value >= 500) {
            newChartData = [...newChartData, newItem];
          } else {
            newChartData = [newItem, ...newChartData];
          }

          list = newChartData.map((chatData) =>
            new _DemographicModel().fromJson(chatData, labels[item]),
          );
        } else {
          list = data[item].map((chatData) =>
            new _DemographicModel().fromJson(chatData, labels[item]),
          );
        }

        this.donuts[item] = [...list];
      } else {
        const list = data[item].map((chatData) =>
          new _DemographicModel().fromJson(chatData, dataset),
        );

        if (list && list.length > 0) {
          this.bars[item] = [...list];
        }
      }
    });

    return this;
  }
}

class _DemographicModel {
  constructor() {
    this.label;
    this.value;
    this.code;
    this.subCategory;
    this.relevance;
  }

  fromJson(data, dataset) {
    let label = data['label'] ? data['label']['code_name'] : '-';

    if (dataset && data['label'] && data['label']['code_name']) {
      if (dataset[label]) label = dataset[label];
    }

    this.label = label;
    this.relevance = data['relevance'];
    this.value = data['value'] || 0;
    this.code = data['label'] ? data['label']['code'] : '-';
    this.subCategory = data['label'] && data['label']['subcategory'];

    return this;
  }
}

export default DemographicsModel;
