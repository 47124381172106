import { Copy } from 'lucide-react';
import PropTypes from 'prop-types';

import feedback from '../../../../../common/components/Feedback';

export const Email = ({ email }) => {
  const handleCopyEmail = async () => {
    await navigator.clipboard
      .writeText(email)
      .then(() => {
        feedback.success('E-mail copiado com sucesso.');
      })
      .catch(() => {
        feedback.error('Falha ao copiar o e-mail.');
      });
  };

  return (
    <div className="email_header">
      <span>{email}</span>

      <button type="button" onClick={handleCopyEmail}>
        <Copy size={16} />
      </button>
    </div>
  );
};

Email.propTypes = {
  email: PropTypes.string,
};
