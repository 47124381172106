import PropTypes from 'prop-types';

import './index.scss';

export const LevelGauge = ({ value, range }) => {
  const [minimum, medium] = range;

  const formattedValue = parseFloat(value);

  const getLevel = () => {
    if (isNaN(formattedValue)) return '0';
    if (formattedValue <= minimum) return '1';
    if (formattedValue <= medium) return '2';
    return '3';
  };

  return (
    <div className="level_gauge">
      <span className="level_marker__level_gauge" data-level={getLevel()} />
      <span className="level_marker__level_gauge" data-level={getLevel()} />
      <span className="level_marker__level_gauge" data-level={getLevel()} />
    </div>
  );
};

LevelGauge.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  range: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};
