import React from "react"
// 
import Routes from "../../../features/public/Routes"
// 
import { Layout } from "antd"
import { useNavigate } from "react-router-dom"
// 
import Alert from "../../../common/components/Alert"
// 
import airLogo from "../../../common/assets/images/airsearch-logo.svg" 
// 
import "./index.scss"

const PublicApp = () => {
    const navigate = useNavigate()

    return <Layout className="layout-pattern layout-pattern-public">
        <header className="container-header">
            <div className="content-header">
                <img 
                    src={airLogo} 
                    alt="air search logo" 
                    role="button"
                    className="hover"
                    onClick={() => navigate("/aira-demonstracao")}
                />
            </div>
        </header>
        
        <Alert />
        
        <Routes />
    </Layout>
}

export default PublicApp