import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import feedback from '../../../common/components/Feedback';
import { airaCurationForm } from './services';

export const useAiraCurationForm = () => {
  const { id } = useParams();

  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm();

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      const formattedData = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        job_title: data.sector,
        can_talk_whatsapp: data.chatOnWhatsApp,
      };

      await airaCurationForm(id, formattedData);
      feedback.success('Formulário enviado com sucesso.');
    } catch (error) {
      feedback.error('Falha ao enviar formulário.');
    }
  });

  return {
    register,
    handleSubmit,
    isSubmitting,
    isSubmitSuccessful,
    errors,
  };
};
