import { Tooltip as AntTooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import hideLikesIcons from '../../../../../common/assets/icons/hide-likes.png';
import { changeObjAlert, showAlert } from '../../../../../common/components/Alert/slice';
import Button from '../../../../../common/components/Button';
import { EngagementRateTable } from '../../../../../common/components/EngagementRateTable';
import Input from '../../../../../common/components/Input';
import InputCurrency from '../../../../../common/components/InputCurrency';
import { LevelGauge } from '../../../../../common/components/LevelGauge';
import Tooltip from '../../../../../common/components/Tooltip';
import { buildValue } from '../../../../../utils/formatters';
import { selectInfluencerDetailsSlice } from '../../slice';

import { PARAMS } from '../../../../../constants/params';
import './index.scss';

const PartInteractions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const slice = useSelector(selectInfluencerDetailsSlice);
  const [value, updateValue] = useState();

  const influencer = slice.influencer;

  const handleShowEngagementRateTable = () => {
    dispatch(
      changeObjAlert({
        show: true,
        type: 'info',
        className: 'engagement-modal',
        title: 'Taxa de engajamento',
        subtitle: 'Confira as taxas de engajamentos comuns para cada rede social.',
        text: <EngagementRateTable />,
        cancelText: 'Fechar',
        onCancel: () => dispatch(showAlert(false)),
      }),
    );
  };

  const networkMapper = {
    instagram: t('Posts'),
    tiktok: t('Vídeos'),
    youtube: t('Vídeos'),
    twitter: t('Tweets'),
    facebook: t('Posts'),
    twitch: t('Vídeos'),
  };

  const values = [
    {
      icon: 'airscore',
      text: t('Airscore'),
      fullValue: influencer.airscore,
      value: influencer.airscore,
      tooltip: t(
        'Nota atribuída ao perfil do influenciador que metrifica a qualidade do engajamento, levando em consideração sua base, suas interações, publicações e categorias.',
      ),
    },
    {
      icon: 'group',
      text: t('Seguidores'),
      fullValue: buildValue('number', influencer.followers),
      value: buildValue('humanize', influencer.followers),
      tooltip: `${t('Tamanho da base')}: ${buildValue('number', influencer.followers)}`,
    },
    {
      icon: 'engagement',
      text: `${t('Taxa de engajamento')}`,
      fullValue: null,
      value: `${influencer.engagementRate.replace('.', ',')}%`,
      tooltip: t(
        'Avalia o engajamento de todos os post no geral relacionando com a quantidade de publicações.',
      ),
    },
    influencer.network !== 'twitch' && {
      icon: 'comments',
      text: t('Total de interações'),
      fullValue: buildValue('number', influencer.interactionsTotal),
      value: buildValue('humanize', influencer.interactionsTotal),
      tooltip: `${t('Total de interações nos últimos 90 dias.')}`,
    },
  ].filter((item) => item);

  const otherValues = [
    {
      text: networkMapper[influencer.network],
      fullValue: null,
      value: buildValue('number', influencer.quantPost),
      tooltip: t('Quantidades de publicações realizada pelo perfil nos últimos 90 dias.'),
    },

    influencer.network !== 'instagram' && {
      text: t('Média de visualizações'),
      fullValue: buildValue('number', influencer.meansInteractions.views),
      value: buildValue('humanize', influencer.meansInteractions.views),
      tooltip: t('Média de visualizações nos últimos 90 dias'),
    },

    {
      text: t('Estimativa de alcance'),
      fullValue: buildValue('number', influencer.deliveryFee),
      value: buildValue('humanize', influencer.deliveryFee),
      tooltip: t(
        'Estima o número de pessoas que serão impactadas ou alcançadas com as publicações do perfil.',
      ),
    },

    influencer.network !== 'twitch' && {
      text: t('Total de curtidas'),
      fullValue: buildValue('number', influencer.likes),
      value: buildValue('humanize', influencer.likes),
      tooltip: t('Quantidade total de curtidas nas publicações do perfil nos últimos 90 dias.'),
    },
  ].filter((item) => item);

  return (
    <div className="influencer-interactions">
      <div className={`interaction-values interaction-values-${values.length}`}>
        {values.map((interaction) => (
          <div className="interaction with-icons" key={interaction.icon}>
            <div className="part-top">
              <i className={`icon icon-${interaction.icon}`} />

              {(interaction.icon === 'engagement' || interaction.icon === 'airscore') &&
                influencer.hiddenLikes && (
                  <AntTooltip
                    title={t('Este cálculo pode não ser preciso pois esse perfil oculta curtidas')}
                  >
                    <div className="profile-hide-like">
                      <img
                        src={hideLikesIcons}
                        alt={t('mão fechada com o polegar levantado e um olho com um risco')}
                      />

                      {t('Oculta curtidas')}
                    </div>
                  </AntTooltip>
                )}
            </div>

            <div className="interaction-value">
              <AntTooltip title={interaction.fullValue}>{interaction.value}</AntTooltip>
            </div>

            <div className="interaction-name">
              <div>
                {interaction.text}

                {interaction.tooltip && <Tooltip child={interaction.tooltip} />}

                {interaction.icon === 'airscore' && (
                  <div className="level_gauge__wrapper">
                    <LevelGauge value={influencer.airscore} range={PARAMS.LEVEL_GAUGE.AIR_SCORE} />
                  </div>
                )}

                {!['twitter', 'twitch'].includes(influencer.network) &&
                  interaction.icon === 'engagement' && (
                    <button
                      type="button"
                      onClick={handleShowEngagementRateTable}
                      className="level_gauge__wrapper"
                    >
                      <LevelGauge
                        value={influencer.engagementRate}
                        range={PARAMS.LEVEL_GAUGE.ENGAGEMENT[influencer.network.toUpperCase()]}
                      />
                    </button>
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={`interaction-values interaction-values-${otherValues.length}`}>
        {otherValues.map((interaction) => (
          <div className="interaction" key={interaction.text}>
            <div className="interaction-value">
              <AntTooltip title={interaction.fullValue}>{interaction.value}</AntTooltip>
            </div>

            <div className="interaction-name">
              {interaction.text}

              {interaction.tooltip && <Tooltip child={interaction.tooltip} />}
            </div>
          </div>
        ))}
      </div>

      {influencer.network != 'twitch' && (
        <div className="estimated-costs-interaction">
          <div className="title-part" style={{ justifyContent: 'flex-start', gap: '16px' }}>
            {t('Custos estimados por interação')}

            <Tooltip
              child={t(
                'Estimativa realizada através do valor de investimento por post pela média de interações.',
              )}
            />
          </div>

          <div className="simulation">
            <div className="input-btn">
              <div className="input-junction">
                <label>{t('Valor cobrado por post')}</label>

                <InputCurrency
                  label={t('Valor cobrado por post')}
                  onBlur={(e) => {
                    const val = e.target.value.replace(/\D/g, '');

                    updateValue(val);
                  }}
                />
              </div>

              <Button>
                <i className="icon icon-arrow-next" />
              </Button>
            </div>

            <Input
              label={t('Curtidas')}
              placeholder="R$ 0,00"
              value={
                value
                  ? `${buildValue(
                      'currency',
                      influencer.meansInteractions.likes > 0
                        ? value / 100 / influencer.meansInteractions.likes
                        : 0,
                    )}`
                  : ''
              }
            />
            <Input
              label={t('Comentários')}
              placeholder="R$ 0,00"
              value={
                value
                  ? `${buildValue(
                      'currency',
                      influencer.meansInteractions.comments > 0
                        ? value / 100 / influencer.meansInteractions.comments
                        : 0,
                    )}`
                  : ''
              }
            />

            {influencer.network !== 'instagram' && (
              <Input
                label={t('Compartilhamentos')}
                placeholder="R$ 0,00"
                value={
                  value
                    ? `${buildValue(
                        'currency',
                        influencer.meansInteractions.shares > 0
                          ? value / 100 / influencer.meansInteractions.shares
                          : 0,
                      )}`
                    : ''
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PartInteractions;
