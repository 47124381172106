import React from "react"
import { useTranslation } from "react-i18next"
// 
import { useSelector } from "react-redux"
import { selectInfluencerDetailsPublicSlice } from "../../slice"
// 
import "./index.scss"

const PartPersona = () => {
    const { t } = useTranslation()
    // 
    const slice = useSelector(selectInfluencerDetailsPublicSlice)
    // 
    const influencer = slice.influencer
    // 
    const aboutFollowersItems = [
        { name: t("Sexo"), icon: "user", alias: "gender" },
        { name: t("Tem filhos?"), icon: "group", alias: "parental" },
        { name: t("Idade"), icon: "baby", alias: "age" },
        { name: t("Local"), icon: "location", alias: "places" },
        { name: t("Estado civil"), icon: "rings", alias: "marital" },
    ]

    return <>
        <p className="title-part">
            {t("Sobre os seguidores")}
        </p>

        {influencer.demographics &&
            <div className="part-persona">
                <div className="persona chart-items">
                    {aboutFollowersItems.map(({ name, alias, icon } ,index) => {
                        const data = influencer.demographics[alias]
                        // 
                        let value 
                        let label
                        
                        if(data && Object.keys(data).length > 0) {
                            label = influencer.demographics[alias].label
                            value = `(${(data.value / 100).toFixed(2)}%)`.replaceAll(".", ",")
                        }

                        return <div key={`chart-item-${index}`} className="chart-item">
                            <p className="value-name">{name}</p>

                            <div className="junction-value">
                                <div>
                                    <i className={`icon icon-${icon}`} />
                                    
                                    {label && <b>{t(label)}</b>}

                                    {!value && <span className="no-data">-</span>}
                                </div>
                                
                                {value && 
                                    <span className="value">{value}</span>
                                }
                            </div>
                        </div>
                    })}
                </div>
            </div>
        }
    </>
}

export default PartPersona