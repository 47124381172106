import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import { useSelector } from "react-redux"
import { selectInfluencerDetailsSlice } from "../../../InfluencerCard/slice"
// 
import AvatarNetwork from "../../../../../common/components/AvatarNetwork"
// 
import "./index.scss"

const InfluencerHeader = ({ avatar }) => {
    const { t } = useTranslation()
    // 
    const influencerSlice = useSelector(selectInfluencerDetailsSlice)
    // 
    const influencer = influencerSlice.influencer
    
    return <div className="influencer-profile-pdf">
        <AvatarNetwork
            size={240}
            networkSize={80}
            src={avatar || influencer.avatar}
            network={influencer.network}
            onClick={() => { window.open(influencer.profileUrl) }}
        />

        <div className="influencer-data">
            <div className="name-verified-classification">
                <div className="name">{influencer.name}</div>

                {influencer.verified && <i className="icon icon-certificate" />}

                <div className="classification">
                    {influencer.classification}
                </div>

                {influencer.acceleratedGrowth &&
                    <div className="accelerated-growth">
                        <i className="icon icon-rocket" />

                        <span>{t("Crescimento acelerado")}</span> 
                    </div> 
                }
            </div>

            <b className="bio-title">{t("Biografia")}</b>

            <p className="bio">
                {influencer.bioSlice || "..."}
            </p>
        </div>
    </div>
}

InfluencerHeader.propTypes = {
    avatar: PropTypes.string,
}

export default InfluencerHeader