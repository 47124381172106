import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AvatarNetwork from '../../../../../common/components/AvatarNetwork';
import { selectMenuSlice } from '../../../../../common/components/SideMenu/slice';
import { humanize } from '../../../../../utils/formatters';
import { selectInfluencerDetailsSlice } from '../../slice';

import './index.scss';

const InfluencerHeaderFixed = () => {
  const { t } = useTranslation();
  const menuSlice = useSelector(selectMenuSlice);
  const influencerSlice = useSelector(selectInfluencerDetailsSlice);
  const [show, setShow] = useState(false);

  const influencer = influencerSlice.influencer;

  const values = [
    { icon: 'air-symbol', value: influencer.airscore },
    { icon: 'group', value: influencer.followers },
    influencer.network !== 'twitch' && { icon: 'like-network', value: influencer.likes },
    { icon: 'comments', value: influencer.interactionsTotal },
  ].filter((item) => item);

  const handleScroll = () => {
    const body = window;

    if (body.scrollY > 200 && !show) {
      setShow(true);
    } else if (body.scrollY <= 200 && show) {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [show]);

  return (
    <div
      className={`influencer-card-fixed-header ${menuSlice.collapsed ? 'collapsed' : ''} ${
        show ? 'show' : 'hidden'
      }`}
    >
      <div className="influencer-profile">
        <div className="profile">
          <AvatarNetwork
            size={48}
            isSmall={true}
            src={influencer.avatar}
            network={influencer.network}
            onClick={() => {
              window.open(influencer.profileUrl);
            }}
          />

          <span className="name">{influencer.name}</span>

          {influencer.verified && <i className="icon icon-certificate" />}

          {influencer.classification && (
            <Tooltip
              overlayClassName="tooltip-influencers-classification"
              placement="bottom"
              title={
                <div className="classification_tooltip">
                  <p className="classification-title">{t('Classificação dos influenciadores')}</p>

                  <div className="junction">
                    Mega <span></span> <b>1M+</b>
                  </div>
                  <div className="junction">
                    Macro <span></span> <b>500K - 1M</b>
                  </div>
                  <div className="junction">
                    Inter <span></span> <b>50K - 500K</b>
                  </div>
                  <div className="junction">
                    Micro <span></span> <b>10K - 50K</b>
                  </div>
                  <div className="junction">
                    Nano <span></span> <b>1K - 10K</b>
                  </div>
                </div>
              }
            >
              <div className="classification">{influencer.classification}</div>
            </Tooltip>
          )}
        </div>

        <div className="values">
          {values.map((item) => {
            return (
              <div className="data" key={item.icon}>
                <i className={`icon icon-${item.icon}`} />
                <span className="value">{humanize(item.value)}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div className={`border-network-color border-${influencer.network}-color`} />
    </div>
  );
};

export default InfluencerHeaderFixed;
