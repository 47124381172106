import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit"
import { getAiraHasBeenUsed, getPlannerListDetails, updPlannerListShared } from "./service"
// 
import i18n from "../../../languages/i18n"
// 
import feedback from "../../../common/components/Feedback"
import PlannerListModel from "../../../models/PlannerListModel"
// 
import StateStatus from "../../../utils/status"

const initialState = {
    status: {
        share: StateStatus.idle,
        fetch: StateStatus.idle,
    },
    data: {},
    airaUsedBefore: false,
    errorMsg: null
}

export const fetch = createAsyncThunk("planner-list/card/fetch", async ({ id }, thunkAPI) => {
    const {
        app
    } = thunkAPI.getState()
    const {
        categories,
    } = app
    
    const response = await getPlannerListDetails(id)

    return {
        categories: categories.list,
        subCategories: categories.subCategories,
        plannerList: {
            ...response.data,
            uid: id,
        },
    }
})

export const share = createAsyncThunk("planner-list/card/edit", async ({ id, title, shared }) => {
    try {
        const result = await updPlannerListShared(id, title, shared)

        feedback.success(i18n.t(!shared ? "Lista descompartilhada com sucesso" : "Lista atualizada com sucesso"))

        return result.data
    } catch (err) {
        feedback.error(i18n.t("Erro ao editar lista"))

        throw err
    }
})

export const checkAiraHasBeenUsed = createAsyncThunk("planner-list/aira/usage", async () => {
    const result = await getAiraHasBeenUsed()

    return result.data
})

export const plannerListDetailsSlice = createSlice({
    name: "plannerListDetails",
    initialState: initialState,
    reducers: {
        updatePlannerListName: (state, { payload }) => {
            state.data.name = payload
        },
        updatePlannerListAvatar: (state, { payload }) => {
            state.data.avatar = payload
        },
        clearPlannerList: (state) => {
            state.status.share = StateStatus.idle
            state.status.fetch = StateStatus.idle

            state.data = {}
            state.errorMsg = null
        }
    },
    extraReducers: {
        [fetch.pending]: (state) => {
            state.status.fetch = StateStatus.loading
        },
        [fetch.fulfilled]: (state, { payload }) => {
            const data = payload.plannerList
            const categories = payload.categories
            const subCategories = payload.subCategories
            const plannerList = new PlannerListModel().detailsFromJson(data, categories, subCategories)

            state.data = plannerList

            state.status.fetch = StateStatus.succeeded
        },
        [fetch.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.fetch = StateStatus.failed
        },
        // 
        [share.pending]: (state) => {
            state.status.share = StateStatus.loading
        },
        [share.fulfilled]: (state, {
            payload
        }) => {
            state.data.isShared = payload.shared

            state.status.share = StateStatus.succeeded
        },
        [share.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.share = StateStatus.succeeded
        },
        // 
        [checkAiraHasBeenUsed.pending]: (state) => {
            state.status.checkAiraHasBeenUsed = StateStatus.loading
        },
        [checkAiraHasBeenUsed.fulfilled]: (state, {
            payload
        }) => {
            state.airaUsedBefore = payload["used_before"]

            state.status.checkAiraHasBeenUsed = StateStatus.succeeded
        },
        [checkAiraHasBeenUsed.rejected]: (state, action) => {
            state.errorMsg = action.error.message
            state.status.checkAiraHasBeenUsed = StateStatus.succeeded
        },
    }
})

export const {
    updatePlannerListName,
    updatePlannerListAvatar,
    clearPlannerList
} = plannerListDetailsSlice.actions

export const selectPlannerListDetailsSlice = (state) => state.plannerListDetails