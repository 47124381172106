import React from "react"
import PropTypes from "prop-types"
// 
import Avatar from "../Avatar"
// 
import instagramImg from "../../assets/icons/instagram-circle.svg"
import facebookImg from "../../assets/icons/facebook–circle.svg"
import tiktokImg from "../../assets/icons/tiktok-circle.svg"
import twitterImg from "../../assets/icons/x-twitter-circle.svg"
import youtubeImg from "../../assets/icons/youtube-circle.svg"
import twitchImg from "../../assets/icons/twitch-circle.svg"
// 
import "./index.scss"

const AvatarNetwork = ({
    src,
    size,
    networkSize,
    network,
    onClick,
    isSmall
}) => {
    return <div className={`avatar-network ${isSmall ? "is-small" : ""}`}>
        <Avatar 
            size={size} 
            src={src} 
            onClick={onClick} 
        />

        {network === "instagram" && <img style={{ widht: networkSize }} className="network-logo instagram-logo" src={instagramImg} />}

        {network === "facebook" && <img style={{ widht: networkSize }} className="network-logo facebook-logo" src={facebookImg} />}

        {network === "tiktok" && <img style={{ widht: networkSize }} className="network-logo tiktok-logo" src={tiktokImg} />}

        {network === "twitter" && <img style={{ widht: networkSize }} className="network-logo twitter-logo" src={twitterImg} />}

        {network === "twitch" && <img style={{ widht: networkSize }} className="network-logo twitch-logo" src={twitchImg} />}
        
        {network === "youtube" && <img style={{ widht: networkSize }} className="network-logo youtube-logo" src={youtubeImg} />}
    </div>
}

AvatarNetwork.defaultProps = {
    src: "",
    size: 227,
    network: "",
    isSmall: false,
    networkSize: 88,
}

AvatarNetwork.propTypes = {
    src: PropTypes.string,
    network: PropTypes.string,
    onClick: PropTypes.func,
    isSmall: PropTypes.bool,
    size: PropTypes.any,
    networkSize: PropTypes.any,
}

export default AvatarNetwork