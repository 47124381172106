import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
//
import { useDispatch, useSelector } from 'react-redux';
import { fetchSimilarInfluencers, selectInfluencerDetailsSlice } from '../../slice';
import StateStatus from '../../../../../utils/status';
//
import { Tooltip } from 'antd';
import Avatar from '../../../../../common/components/Avatar';
import Loading from '../../../../../common/components/Loading';
//
import instagramImg from '../../../../../common/assets/icons/instagram.svg';
import facebookImg from '../../../../../common/assets/icons/facebook.svg';
import tiktokImg from '../../../../../common/assets/icons/tiktok.svg';
import twitterImg from '../../../../../common/assets/icons/x-twitter.svg';
import twitchImg from '../../../../../common/assets/icons/twitch.svg';
import youtubeImg from '../../../../../common/assets/icons/youtube.svg';
import unavailableValue from '../../../../../common/assets/images/empty.svg';
//
import { humanize } from '../../../../../utils/formatters';
import './index.scss';

const PartSimilarInfluencers = ({ influencerId }) => {
  const { t } = useTranslation();
  //
  const dispatch = useDispatch();
  //
  const slice = useSelector(selectInfluencerDetailsSlice);
  //
  const status = slice.status;
  const similarInfluencers = slice.similarInfluencers;
  //
  const networkImages = {
    instagram: instagramImg,
    facebook: facebookImg,
    tiktok: tiktokImg,
    twitter: twitterImg,
    youtube: youtubeImg,
    twitch: twitchImg,
  };

  useEffect(() => {
    dispatch(fetchSimilarInfluencers(influencerId));
  }, []);

  return (
    <div className="similar-profiles">
      <div className="title-part">{t('Perfis semelhantes')}</div>

      {status.fetchSimilarInfluencers === StateStatus.loading && <Loading />}

      {status.fetchSimilarInfluencers === StateStatus.succeeded && (
        <div className="influencers">
          {similarInfluencers &&
            similarInfluencers.length > 0 &&
            similarInfluencers.map((influencer) => {
              return (
                <div className="similar-influencer-card" key={influencer.id}>
                  <div className="influencer-card-header">
                    <div
                      className={`box-avatar ${influencer.acceleratedGrowth ? 'rapid-growth' : ''}`}
                    >
                      <div className="div-flex">
                        <Avatar
                          src={influencer.avatar}
                          onClick={() => {
                            window.open(`/#/card/${influencer.id}`);
                          }}
                        />

                        {influencer.acceleratedGrowth && <i className="icon icon-rocket" />}
                      </div>
                    </div>

                    <div className="name-network">
                      <Tooltip title={influencer.name}>
                        <div className="name">{influencer.name}</div>
                      </Tooltip>

                      <div className="network">
                        <img
                          src={networkImages[influencer.network]}
                          alt={`${influencer.network} logo`}
                        />

                        <span className="network-name">
                          {influencer.network === 'twitter' ? `X` : influencer.network}
                        </span>

                        <i
                          className="icon icon-external-link hover"
                          role="button"
                          onClick={() => window.open(influencer.profileUrl)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="influencer-card-body">
                    <div className="sample-data">
                      <span className="data-name">{t('Seguidores')}</span>
                      <div className="data-value">{humanize(influencer.followers)}</div>
                    </div>

                    <div className="sample-data">
                      <span className="data-name">{t('Airscore')}</span>
                      <div className="data-value">{influencer.airscore}</div>
                    </div>

                    <div className="sample-data">
                      <span className="data-name">{t('Engajamento')}</span>
                      <div className="data-value">
                        {influencer.engagementRate.replace('.', ',')}%
                      </div>
                    </div>

                    <div className="sample-data">
                      <span className="data-name">{t('Categorias')}</span>
                      <div className="data-value themes">
                        {influencer.themes &&
                          influencer.themes.map((theme) => {
                            return (
                              <div
                                key={theme.color}
                                style={{ position: 'relative', margin: '0 2px' }}
                              >
                                <div
                                  className="circle-theme"
                                  style={{ backgroundColor: theme.color }}
                                />

                                <i
                                  style={{ color: theme.color }}
                                  className={`icon icon-${theme.icon}`}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {!similarInfluencers ||
            (similarInfluencers.length < 1 && (
              <div className="empty">
                <p>{t('Não encontramos ninguém parecido')}.</p>

                <img
                  className="unavailable-img"
                  src={unavailableValue}
                  alt="diversas nuvens azuis"
                />
              </div>
            ))}
        </div>
      )}

      {status.fetchSimilarInfluencers === StateStatus.failed && (
        <p className="text-error">{t('Erro ao buscar os influenciadores similares')}</p>
      )}
    </div>
  );
};

PartSimilarInfluencers.propTypes = {
  influencerId: PropTypes.string,
};

export default PartSimilarInfluencers;
