import { Tooltip as AntTooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import hideLikesIcons from '../../../../../common/assets/icons/hide-likes.png';
import { changeObjAlert, showAlert } from '../../../../../common/components/Alert/slice';
import { EngagementRateTable } from '../../../../../common/components/EngagementRateTable';
import { LevelGauge } from '../../../../../common/components/LevelGauge';
import Tooltip from '../../../../../common/components/Tooltip';
import { buildValue } from '../../../../../utils/formatters';
import { selectInfluencerDetailsPublicSlice } from '../../slice';

import { PARAMS } from '../../../../../constants/params';
import './index.scss';

const PartInteractions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const slice = useSelector(selectInfluencerDetailsPublicSlice);

  const handleShowEngagementRateTable = () => {
    dispatch(
      changeObjAlert({
        show: true,
        type: 'info',
        className: 'engagement-modal',
        title: 'Taxa de engajamento',
        subtitle: 'Confira as taxas de engajamentos comuns para cada rede social.',
        text: <EngagementRateTable />,
        cancelText: 'Fechar',
        onCancel: () => dispatch(showAlert(false)),
      }),
    );
  };

  const influencer = slice.influencer;

  const networkMapper = {
    instagram: t('Posts'),
    tiktok: t('Vídeos'),
    youtube: t('Vídeos'),
    twitter: t('Tweets'),
    facebook: t('Posts'),
    twitch: t('Vídeos'),
  };

  const values = [
    {
      icon: 'airscore',
      text: t('Airscore'),
      fullValue: influencer.airscore,
      value: influencer.airscore,
      tooltip: t(
        'Nota atribuída ao perfil do influenciador que metrifica a qualidade do engajamento, levando em consideração sua base, suas interações, publicações e categorias.',
      ),
    },
    {
      icon: 'group',
      text: t('Seguidores'),
      fullValue: buildValue('number', influencer.followers),
      value: buildValue('humanize', influencer.followers),
      tooltip: `${t('Tamanho da base')}: ${buildValue('number', influencer.followers)}`,
    },
    {
      icon: 'engagement',
      text: `${t('Taxa de engajamento')}`,
      fullValue: null,
      value: `${influencer.engagementRate.replace('.', ',')}%`,
      tooltip: t(
        'Avalia o engajamento de todos os post no geral relacionando com a quantidade de publicações.',
      ),
    },
    influencer.network !== 'twitch' && {
      icon: 'comments',
      text: t('Total de interações'),
      fullValue: buildValue('number', influencer.interactionsTotal),
      value: buildValue('humanize', influencer.interactionsTotal),
      tooltip: `${t('Total de interações nos últimos 90 dias.')}`,
    },
  ].filter((item) => item);

  const otherValues = [
    {
      text: networkMapper[influencer.network],
      fullValue: null,
      value: buildValue('number', influencer.quantPost),
      tooltip: t('Quantidades de publicações realizada pelo perfil nos últimos 90 dias.'),
    },

    influencer.network !== 'instagram' && {
      text: t('Média de visualizações'),
      fullValue: buildValue('number', influencer.meansInteractions.views),
      value: buildValue('humanize', influencer.meansInteractions.views),
      tooltip: t('Média de visualizações nos últimos 90 dias'),
    },

    {
      text: t('Estimativa de alcance'),
      fullValue: buildValue('number', influencer.deliveryFee),
      value: buildValue('humanize', influencer.deliveryFee),
      tooltip: t(
        'Estima o número de pessoas que serão impactadas ou alcançadas com as publicações do perfil.',
      ),
    },

    influencer.network !== 'twitch' && {
      text: t('Total de curtidas'),
      fullValue: buildValue('number', influencer.likes),
      value: buildValue('humanize', influencer.likes),
      tooltip: t('Quantidade total de curtidas nas publicações do perfil nos últimos 90 dias.'),
    },
  ].filter((item) => item);

  return (
    <div className="public-influencer-interactions">
      <div className={`interaction-values interaction-values-${values.length}`}>
        {values.map((interaction) => (
          <div className="interaction with-icons" key={interaction.icon}>
            <div className="part-top">
              <i className={`icon icon-${interaction.icon}`} />

              {(interaction.icon === 'engagement' || interaction.icon === 'airscore') &&
                influencer.hiddenLikes && (
                  <AntTooltip
                    title={t('Este cálculo pode não ser preciso pois esse perfil oculta curtidas')}
                  >
                    <div className="profile-hide-like">
                      <img
                        src={hideLikesIcons}
                        alt={t('mão fechada com o polegar levantado e um olho com um risco')}
                      />

                      {t('Oculta curtidas')}
                    </div>
                  </AntTooltip>
                )}
            </div>

            <div className="interaction-value">
              <AntTooltip title={interaction.fullValue}>{interaction.value}</AntTooltip>
            </div>

            <div className="interaction-name">
              <div>
                {interaction.text}

                {interaction.tooltip && <Tooltip child={interaction.tooltip} />}

                {interaction.icon === 'airscore' && (
                  <div className="level_gauge__wrapper">
                    <LevelGauge value={influencer.airscore} range={PARAMS.LEVEL_GAUGE.AIR_SCORE} />
                  </div>
                )}

                {!['twitter', 'twitch'].includes(influencer.network) &&
                  interaction.icon === 'engagement' && (
                    <button
                      type="button"
                      onClick={handleShowEngagementRateTable}
                      className="level_gauge__wrapper"
                    >
                      <LevelGauge
                        value={influencer.engagementRate}
                        range={PARAMS.LEVEL_GAUGE.ENGAGEMENT[influencer.network.toUpperCase()]}
                      />
                    </button>
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={`interaction-values interaction-values-${otherValues.length}`}>
        {otherValues.map((interaction) => (
          <div className="interaction" key={interaction.text}>
            <div className="interaction-value">
              <AntTooltip title={interaction.fullValue}>{interaction.value}</AntTooltip>
            </div>

            <div className="interaction-name">
              {interaction.text}

              {interaction.tooltip && <Tooltip child={interaction.tooltip} />}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartInteractions;
