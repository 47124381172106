import axios from 'axios';
import jwtDecode from 'jwt-decode';
//
import routes from './apiRoutes';

const apiUrl = process.env.REACT_APP_BASEURL;

const api = axios.create({
    baseURL: apiUrl,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        let accessToken = localStorage.getItem('access_token');

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        } else {
            window.location = process.env.REACT_APP_HUB_URL + '?product=Search';
        }

        return config;
    },
    (error) => {
        return Promise.error(error);
    },
);

api.interceptors.response.use(undefined, async (err) => {
    const customError = {
        name: 'Api Error',
        message:
            err.response && err.response.status && err.response.status < 500
                ? err.response.data.message ||
                  (err.response.data.errors ? err.response.data.errors.message : 'Erro')
                : 'Erro no sistema, tente mais tarde!',
        data: err.response ? err.response.data : null,
    };

    const originalRequest = err.config;

    if (err.response.status === 401 && !err.config.url.includes('user-action')) {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');

        if (accessToken && refreshToken) {
            try {
                const tokenJwt = jwtDecode(accessToken);
                const currentDate = new Date();

                if (tokenJwt.exp <= currentDate.getTime()) {
                    const response = await axios.post(
                        `${apiUrl}${routes.refreshToken}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${refreshToken}`,
                            },
                        },
                    );
                    localStorage.setItem('access_token', response.data.access_token);

                    if (response.data.refresh_token) {
                        localStorage.setItem('refresh_token', response.data.refresh_token);
    
                        axios.defaults.headers[
                            'Authorization'
                        ] = `Bearer ${response.data.access_token}`;
    
                        originalRequest.headers[
                            'Authorization'
                        ] = `Bearer ${response.data.access_token}`;
    
                        return axios(originalRequest);
                    } else {
                        window.localStorage.removeItem('access_token');
                        window.localStorage.removeItem('refresh_token');

                        window.location.href = process.env.REACT_APP_HUB_URL + '?product=Search';
                    }
                } else {
                    window.localStorage.removeItem('access_token');
                    window.localStorage.removeItem('refresh_token');

                    window.location.href = process.env.REACT_APP_HUB_URL + '?product=Search';
                }
            } catch (err) {
                window.localStorage.removeItem('access_token');
                window.localStorage.removeItem('refresh_token');

                window.location.href = process.env.REACT_APP_HUB_URL + '?product=Search';
            }
        } else {
            window.localStorage.removeItem('access_token');
            window.localStorage.removeItem('refresh_token');

            window.location.href = process.env.REACT_APP_HUB_URL + '?product=Search';
        }
    }

    return Promise.reject(customError);
});

export default api;
