export const examples = [
  {
    icon: 'outline-brand',
    title: 'Sobre a marca',
    text: 'História, posicionamentos, produto, outros detalhes.',
  },
  {
    icon: 'analytics',
    title: 'Campanha',
    text: 'Objetivo da campanha (alcance, consideração ou venda), assuntos abordados, região.',
  },
  {
    icon: 'group',
    title: 'Audiência',
    text: 'Idade, sexo, interesse.',
  },
  {
    icon: 'profile',
    title: 'Influenciador',
    text: 'Perfil em alta, número de seguidores.',
  },
  {
    icon: 'mobile-device',
    title: 'Rede social',
    text: 'Digite quais redes sociais deseja trabalhar.',
  },
];
