import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
// 
import { useDispatch, useSelector } from "react-redux"
import { create, selectAddInfluencerSlice } from "../../slice"
// 
import { Form } from "antd"
import Button from "../../../../../common/components/Button"
import Input from "../../../../../common/components/Input"
import SearchSelect from "../../../../../common/components/SearchSelect"
// 
import StateStatus from "../../../../../utils/status"
import { fetchLastLists, selectModalPlannerListSlice } from "../../../../../common/components/ButtonModalLists/slice"

const { useForm } = Form

const FormAddInfluecer = () => {
    const dispatch = useDispatch()
    // 
    const slice = useSelector(selectAddInfluencerSlice)  
    const modalPlSlice = useSelector(selectModalPlannerListSlice)
    // 
    const status = slice.status.create
    const plList = modalPlSlice.list
    const title = modalPlSlice.title
    const favorite = modalPlSlice.favorite
    // 
    let delayTimer
    const [form] = useForm()
    // 
    const { t } = useTranslation()

    const onFinish = ({ url, planner_list_id }) => {
        dispatch(create({
            isMultiple: false,
            data: { planner_list_id, profiles: [url] }
        }))
    }

    useEffect(() => {
        if(status !== StateStatus.idle) form.resetFields()
    }, [status])

    return <div className="form-add-influencer">
        <h2>{t("Adicione um influenciador por vez")}:</h2>
        
        <p>{t("Os campos com (*) são obrigatórios")}</p>

        <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
                name="url"
                label={`${t("Link do influenciador")}:`}
                rules={[
                    {
                        required: true,
                        message: null,
                    },
                    () => ({
                        validator(_, value) {
                            const urlRegex = new RegExp("^(https?:\\/\\/)?" + // validate protocol
                                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                                "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                                "(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*" + // validate port and path
                                "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                                "(\\#[-a-z\\d_]*)?$", "i"); // validate fragment locator

                            if (value && urlRegex.test(value)) {
                                return Promise.resolve()
                            } else {
                                return Promise.reject(new Error(t("Link incorreto. Tente novamente")))
                            }
                        },
                    }),
                ]}
            >
                <Input
                    placeholder="https://"
                />
            </Form.Item>

            <Form.Item
                name="planner_list_id"
                className="form-items"
                label={`${t("Adicionar em uma lista")}: `}
            >   
                <SearchSelect
                    isLoading={modalPlSlice.status.fetchLastLists === StateStatus.loading}
                    options={[
                        (!title || title === "") && { key: favorite.name, value: favorite.id },
                        ...plList.map(list => ({ key: list.name, value: list.id }))
                    ]}
                    placeholder={t("Busque e selecione uma lista")}
                    onChange={(item) => form.setFieldValue("planner_list_id", item.value)}
                    onSearch={(text) => {
                        clearTimeout(delayTimer)
                        
                        delayTimer = setTimeout(() => {
                            dispatch(fetchLastLists(text))
                        }, 500);
                    }}
                />
            </Form.Item>

            <div className="description">
                <p><b>{t("Atenção")}</b></p>

                <p>{t("Os perfis adicionados podem levar até 24 horas para aparecer na plataforma")}.</p>
            </div>

            <Button 
                isLoading={status === StateStatus.loading}
                onClick={() => form.submit()}
            >
                {t("Concluir")}
            </Button>
        </Form>
    </div>
}

export default FormAddInfluecer