import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
// 
import { useSelector } from "react-redux"
import { selectPlannerListDetailsSlice } from "../../slice"
// 
import PropTypes from "prop-types"
import Button from "../../../../../common/components/Button"
import CardCategory from "../../../../../common/components/CardCategory"
// 
import "./index.scss"

const PlannerListPartCategories = ({
    toPDF,
}) => {
    const { t } = useTranslation()
    // 
    const slice = useSelector(selectPlannerListDetailsSlice)
    // 
    const pl = slice.data
    // 
    const [categories, updateCategories] = useState()
    // 
    const [showSubcategories, updateShowSubcategories] = useState(false)
    const [showAllCategories, updateShowAllCategories] = useState(false)

    const filterCategories = () => {
        let newList = []
            
        newList = pl.categories
        newList = newList.slice(0, (showAllCategories ? pl.categories.length : 5))
        
        updateCategories(newList)
    }

    useEffect(() => {
        if(pl.categories) filterCategories()
    }, [pl.categories])

    useEffect(() => {
        if(categories) filterCategories()
    }, [showAllCategories])

    useEffect(() => {
        if(toPDF) {
            updateShowSubcategories(true)
            updateShowAllCategories(true)
        }
    }, [])

    return <div className="pl-categories">
        <div className="title-part">
            {t("Categorias da lista")}
        </div>

        <div className="categories-card">
            {!toPDF &&
                <div className="filters">
                    <div className="btns">
                        {pl.categories && pl.categories.length > 0 && <Button 
                            className="pattern-grey"
                            onClick={() => updateShowSubcategories(!showSubcategories)}
                        >
                            {t(showSubcategories ? "Ver simplificado" : "Abrir subcategorias")}
                        </Button>}

                        {pl.categories && pl.categories.length > 5 && <Button 
                            className="pattern-grey"
                            onClick={() => updateShowAllCategories(!showAllCategories)}
                        >
                            {t(showAllCategories ? "Ver top 5 categorias" : "Ver todas as categorias")}
                        </Button>}
                    </div>
                </div>
            }

            <div className="categories">
                {categories && categories.map(category =>
                    <CardCategory 
                        key={category.id} 
                        category={category}
                        showOnlyCategories={toPDF}
                        showSubcategories={showSubcategories} 
                    />
                )}
            </div>

            {(!pl.categories || pl.categories.length < 1) && 
                <p className="text-empty">{t("Nenhuma categoria mencionada")}</p>
            }
        </div>
    </div>
}

PlannerListPartCategories.defaultProps = {
    toPDF: false,
}

PlannerListPartCategories.propTypes = {
    toPDF: PropTypes.bool,
}
  

export default PlannerListPartCategories