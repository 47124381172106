import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
// 
import { useDispatch, useSelector } from "react-redux"
import { fetchCategories, selectInfluencerDetailsSlice } from "../../../InfluencerCard/slice"
import StateStatus from "../../../../../utils/status"
// 
import CardCategory from "../../../../../common/components/CardCategory"
import Loading from "../../../../../common/components/Loading"
// 
import "./index.scss"

const PartCategories = () => {
    const { t } = useTranslation()
    // 
    const params = useParams()
    const dispatch = useDispatch()
    // 
    const slice = useSelector(selectInfluencerDetailsSlice)
    // 
    const id = params.id
    const influencer = slice.influencer
    const status = slice.status.fetchCategories
    // 
    const [categories, updateCategories] = useState()

    useEffect(() => {
        dispatch(fetchCategories({ id }))
    }, [])

    useEffect(() => {
        if(influencer.categories) updateCategories(influencer.categories.slice(0, influencer.categories.length > 5 ? 5 : influencer.categories.length))
    }, [influencer.categories])


    return <div className="influencer-categories-pdf">
        <div className="subtitle-part">
            <i className="icon icon-friction-talk-user" />
            {t("Assuntos abordados pelo influenciador")}
        </div>

        {status === StateStatus.loading && <Loading />}
        
        {status === StateStatus.failed && 
            <p className="text-error">{t("Erro ao pegar as categorias")}</p>
        }

        {status === StateStatus.succeeded && 
            <div className="categories-card">
                {categories && categories.length > 0 && 
                    <div className="categories">
                        {categories.map(category =>
                            <CardCategory 
                                key={category.id} 
                                category={category}
                                showSubcategories={false} 
                                showOnlyCategories={true}
                            />
                        )}
                    </div>
                }
                
                {!categories || categories.length < 1 && 
                    <p className="text-empty">{t("O influencor não mencionou nenhuma")} <b>{t("categoria")}</b></p>
                }
            </div>
        }
    </div>
}

export default PartCategories