import PropTypes from "prop-types";
import React, {
    useEffect,
    useState
} from "react";
// 
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
// 
import {
    buildValue
} from "../../../../utils/formatters"
import "../Charts.scss";

const DonutsChart = ({
    data,
    isPercentage,
    hasAnimation
}) => {
    const [series, setSeries] = useState([]);
    // 
    const colors = [
        "#FEE6EE",
        "#F70B53",
    ]

    useEffect(() => {
        const newSeries = [];

        data.forEach((item, i) => {
            if (isPercentage) {
                newSeries.push({
                    name: item.label,
                    y: item.value / 100,
                    color: colors[i]
                })
            } else {
                newSeries.push({
                    y: item.value,
                    name: item.label,
                    color: item.color
                })
            }
        })

        setSeries(newSeries);
    }, [data])

    const options = {
        chart: {
            type: "pie",
            height: "300px",
        },
        title: {
            text: ""
        },
        yAxis: {
            title: ""
        },
        xAxis: {
            visible: false
        },
        plotOptions: {
            series: {
                animation: hasAnimation
            },
            pie: {
                innerSize: "50%",
                allowPointSelect: true,

                dataLabels: {
                    distance: -1,
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        return `<div class="label-circle-chart label-circle-chart-${this.colorIndex}">
                            ${buildValue("humanize", this.y)}%
                        </div>`
                    }
                },
                showInLegend: true
            }
        },
        tooltip: {
            enabled: false
        },
        series: series.length < 1 ? [] : [{
            name: "Gender",
            colorByPoint: true,
            innerSize: "55%",
            data: series
        }]
    };

    return series && <HighchartsReact
        options={options}
        highcharts={Highcharts}
        className="donuts-chart" 
    />
}

DonutsChart.defaultProps = {
    data: [],
    isPercentage: true,
    hasAnimation: true,
    formatName: "percent",
}

DonutsChart.propTypes = {
    data: PropTypes.any,
    formatter: PropTypes.func,
    height: PropTypes.string,
    className: PropTypes.string,
    formatName: PropTypes.string,
    isPercentage: PropTypes.bool,
    hasAnimation: PropTypes.bool,
}

export default DonutsChart;