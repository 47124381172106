
import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
// 
import { useSelector } from "react-redux"
import { selectPlannerListDetailsSlice } from "../../slice"
// 
import { Tooltip } from "antd"
import SimpleDonutsChart from "../../../../../common/components/Charts/SimpleDonutsChart"
// 
import { exportElementToImage } from "../../../../../utils"
import "./index.scss"

const PartDonutChart = ({
    hasAnimation
}) => {
    const { t } = useTranslation()
    // 
    const slice = useSelector(selectPlannerListDetailsSlice)
    // 
    const pl = slice.data

    return <>
        <div className="titles">
            <div className="title-part">{t("Seguidores")}</div>

            <div className="title-part">{t("Posts")}</div>
        </div>
        
        <div className="part-donuts">
            <div className="card-donut-chart donut-chart-followers">
                <p className="chart-title">{t("Total de seguidores")}</p>

                <Tooltip title={t("Baixar imagem do gráfico")}>
                    <i 
                        role="button"
                        className="icon icon-download hover" 
                        onClick={() => {
                            setTimeout(() => {
                                exportElementToImage("donut-chart-followers", `${pl.name}-seguidores`.toLowerCase().replaceAll(" ", "-"))
                            }, 1000);
                        }}    
                    />
                </Tooltip>

                <SimpleDonutsChart
                    formatName="number"
                    isPercentage={false}
                    data={pl.baseOfNetworks}
                    hasAnimation={hasAnimation}
                />
            </div>

            <div className="card-donut-chart card-donut-chart-posts donut-chart-posts">
                <p className="chart-title">{t("Total de posts")}</p>

                <Tooltip title={t("Baixar imagem do gráfico")}>
                    <i 
                        role="button"
                        className="icon icon-download hover" 
                        onClick={() => {
                            setTimeout(() => {
                                exportElementToImage("donut-chart-posts", `${pl.name}-posts`.toLowerCase().replaceAll(" ", "-"))
                            }, 1000);
                        }}    
                    />
                </Tooltip>

                <SimpleDonutsChart
                    formatName="number"
                    isPercentage={false}
                    data={pl.postsByNetworks}
                    hasAnimation={hasAnimation}
                />
            </div>
        </div>
    </>
}

PartDonutChart.defaultProps = {
    hasAnimation: true,
}

PartDonutChart.propTypes = {
    hasAnimation: PropTypes.bool,
}

export default PartDonutChart