import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
// 
import { useDispatch, useSelector } from "react-redux"
import { changeObjAlert, showAlert } from "../../../common/components/Alert/slice"
import { fetchNotifications, fetchNotificationsCounters, selectNotificationsSlice, updateNotifications } from "./slice"
import StateStatus from "../../../utils/status"
// 
import { Row } from "antd"
import Button from "../../../common/components/Button"
import Loading from "../../../common/components/Loading"
import Pagination from "../../../common/components/Pagination"
import CardSolicitation from "./components/CardSolicitation"
// 
import "./index.scss"

const Notifications = ({
  onClose
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  //
  const slice = useSelector(selectNotificationsSlice)
  // 
  const status = slice.status
  const counters = slice.counters
  // 
  const [selectedFilter, updateSelectedFilter] = useState(null)
  const [selectedNotifications, updateSelectedNotification] = useState([])
  // 
  // const notificationTypes = [
  //   { label: t("Notificações"), value: "notifications" },
  //   { label: t("Solicitações"), value: "solicitations" },
  // ]
  const filters = [
    { label: t("Todas"), key: null },
    { label: t("Não lidas"), counter: counters["created"], key: "created" },
    { label: t("Lidas"), key: "read" },
    // { label: t("Deletados"), key: "deleted" },

    // all = "all"
    // read = "read"
    // created = "created"
    // archived = "archived"
  ]

  const filterNotifications = (filter) => {
    updateSelectedFilter(filter)
  }

  const getPosts = (p) => {
    let data = { page: p }

    if(selectedFilter) data["status"] = selectedFilter 
    
    dispatch(fetchNotifications(data))
  }

  useEffect(() => {
    getPosts(1)
  }, [selectedFilter, location])

  return (
    <div className="notifications-page">
      <i 
        className="icon icon-close hover" 
        onClick={() => onClose()}
      />
        
      <div className="notifications-content">
        <div className="notifications-container">
            <div className="scroll-content">
              <div className="notification-title">
                {t("Notificações")}
              </div>

              <div className="btn-filters">
                {filters.map(filter => (
                  <Button
                    key={filter.key}
                    className={`btn-filter-${filter.key} outline-dark ${selectedFilter === filter.key ? "active" : ""}`}
                    onClick={() => filterNotifications(filter.key)}
                  >
                    {filter.label}

                    {filter.counter != null && filter.counter > 0 && 
                      <div className="counter">{filter.counter}</div>
                    }
                  </Button>
                ))}
              </div>

              {status.fetchNotifications === StateStatus.loading &&
                <Loading />
              }
              
              {status.fetchNotifications === StateStatus.failed &&
                <p className="text-empty">{t("Erro")}</p>
              }

              {status.fetchNotifications === StateStatus.succeeded &&
                <div className="list-notifications-content">
                  {slice.notifications.length > 0 && 
                    <Row justify="start">
                      <Button
                        icon="trash"
                        className="outline-light btn-clear"
                        onClick={() => {
                          dispatch(changeObjAlert({
                            show: true,
                            type: "warning",
                            title: `${t("Alerta")}`,
                            text: t("Tem certeza que deseja apagar todas as notificações?"),
                            okText: t("Sim, apagar"),
                            cancelText: t("Não, manter"),
                            onCancel: () => {
                              dispatch(showAlert(false))
                            },
                            onOk: () => {
                              dispatch(updateNotifications({
                                status: selectedFilter,
                                // newStatus: "read",
                                // newStatus: "created",
                                newStatus: "deleted",
                                ids: selectedNotifications.length > 0 ?     
                                selectedNotifications
                                : 
                                [],
                              }))

                              updateSelectedNotification([])
                              
                              dispatch(showAlert(false))
                              
                              dispatch(fetchNotificationsCounters())
                              
                              setTimeout(() => {
                                getPosts(1)
                              }, 300);
                            },
                          }))
                        }}
                      >
                        {t(selectedNotifications.length > 0 ? 
                          selectedNotifications.length === 1 ? "Limpar notificação" : `${("Limpar as")} ${selectedNotifications.length} ${t("notificações selecionadas")}` 
                          : 
                          "Limpar todas"
                        )}
                      </Button>
                    </Row>
                  }

                  {slice.notifications.length < 1 &&
                    <p className="text-empty">
                      {t("Sem notificações")}
                    </p>
                  }

                  <div className="list-notifications">
                    {slice.notifications && slice.notifications.map(notification => <CardSolicitation 
                        key={notification.id}
                        solicitation={notification}
                        selected={selectedNotifications.includes(`${notification.id}`)}
                        isLoading={(selectedNotifications === notification.id) && (status.updateNotifications === StateStatus.loading)}
                        onClose={() => onClose()}
                        onSelect={(id) => {
                          if(selectedNotifications.includes(id)) {
                            updateSelectedNotification(selectedNotifications.filter(item => item !== id))
                          } else {
                            updateSelectedNotification([...selectedNotifications, id])
                          }
                        }}
                      />
                    )}
                    
                    {slice.pages > 1 && 
                      <Pagination
                        pageSize={10}
                        current={(slice.page)}
                        total={slice.total}
                        isDisabled={status.fetchNotifications === StateStatus.loading || status.fetchNotifications === StateStatus.failed}
                        onChange={(p) => {
                          getPosts(p)
                        }}
                      />
                    }
                  </div>
                </div>
              }
          </div>
        </div>
      </div>
    </div>
  )
}

Notifications.defaultProps = {
  onClose: () => window.location.reload()
}

Notifications.propTypes = {
  onClose: PropTypes.func
}

export default Notifications
