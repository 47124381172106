import { Modal as AntModal, Tooltip } from 'antd';
import { differenceInDays } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AvatarNetwork from '../../../../../common/components/AvatarNetwork';
import CardPost from '../../../../../common/components/CardPost';
import Loading from '../../../../../common/components/Loading';
import { buildImgUrl } from '../../../../../utils';
import { buildValue, humanize } from '../../../../../utils/formatters';
import StateStatus from '../../../../../utils/status';
import { fetchSummaryPosts, selectInfluencerDetailsSlice } from '../../slice';

import './index.scss';

const ModalInfluencerSummary = ({ isVisible, influencer, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const slice = useSelector(selectInfluencerDetailsSlice);
  const [values, setValues] = useState([]);

  const id = influencer.id;
  const status = slice.status;
  const influencerData = slice.influencer;

  const getPosts = (p) => {
    dispatch(fetchSummaryPosts({ id, page: p }));
  };

  const handleScroll = () => {
    const body = document.querySelector('.scroll-posts');

    if (
      body.scrollHeight - 40 < body.offsetHeight + body.scrollTop &&
      slice.sPages >= slice.sPostPage &&
      status.fetchSummaryPosts === StateStatus.succeeded
    ) {
      getPosts(slice.sPostPage + 1);
    }
  };

  useEffect(() => {
    let body = document.querySelector('.scroll-posts');

    if (isVisible && status.fetch == StateStatus.succeeded && body) {
      body.addEventListener('scroll', handleScroll);

      return () => body.removeEventListener('scroll', handleScroll);
    }
  }, [isVisible, status.fetchSummaryPosts]);

  useEffect(() => {
    if (isVisible && status.fetch === StateStatus.succeeded) {
      getPosts(0);

      setValues([
        {
          icon: 'likes',
          text: t('Média de curtidas'),
          fullValue: buildValue('number', influencerData.meansInteractions.likes),
          value: buildValue('humanize', influencerData.meansInteractions.likes),
        },
        {
          icon: 'comments',
          text: t('Média de comentários'),
          fullValue: buildValue('number', influencerData.meansInteractions.comments),
          value: buildValue('humanize', influencerData.meansInteractions.comments),
        },
        {
          icon: 'interactions',
          text: t('Média de interações'),
          fullValue: buildValue('number', influencerData.d30_means.total),
          value: buildValue('humanize', influencerData.d30_means.total),
        },
        {
          icon: 'hour',
          text: t('Horário comum de postagem'),
          fullValue: null,
          value: `${influencerData.hourWithMorePosts}`.replace('-', '~'),
        },
      ]);
    }
  }, [isVisible, status.fetch]);

  return (
    <AntModal
      className="modal-influencer-summary"
      footer={false}
      open={isVisible}
      onCancel={onClose}
    >
      <div className="influencer">
        <AvatarNetwork src={influencer.avatar} network={influencer.network} />

        <div className="name-username">
          <span className="name">{influencer.name}</span>

          <span
            className="username hover"
            role="button"
            onClick={() => window.open(influencer.profileUrl)}
          >
            @{influencer.username}
          </span>
        </div>
      </div>

      {status.fetch === StateStatus.failed && <p className="text-error">{t('Erro')}</p>}

      {status.fetch === StateStatus.loading && <Loading />}

      {status.fetch == StateStatus.succeeded && (
        <div className={`box-summary ${influencer.network !== 'tiktok' ? '' : 'extend'}`}>
          {influencerData.bio && (
            <>
              <p className="title">{t('Bio')}</p>
              <p className="subtitle">{influencerData.bio}</p>
            </>
          )}

          {status.fetchSummaryPosts === StateStatus.failed && (
            <p className="text-error">{t('Erro ao pegar os posts')}</p>
          )}

          <div className={`interaction-values interaction-values-${values.length}`}>
            {values.map((interaction) => (
              <div className="interaction" key={interaction.text}>
                <div className="interaction-value">
                  <Tooltip title={interaction.fullValue}>{interaction.value}</Tooltip>
                </div>

                <div className="interaction-name">{interaction.text}</div>
              </div>
            ))}
          </div>
          <div>
            <p className="subtitle">{t('Métricas referentes aos últimos 90 dias')}</p>
          </div>

          <div className="posts scroll-posts">
            {influencerData.summaryPosts && influencerData.summaryPosts.length > 0 && (
              <>
                {influencerData.summaryPosts.map((post, i) => {
                  const igPostValues = [
                    {
                      icon: 'outline-like-heart',
                      tooltip: t('Curtidas'),
                      value:
                        post.likes === 0 && post.comments !== 0
                          ? t('Ocultas')
                          : humanize(post.likes),
                    },
                    {
                      icon: 'outline-comment',
                      tooltip: t('Comentários'),
                      value: humanize(post.comments),
                    },
                    {
                      icon: 'clock-outline',
                      tooltip: t('Data da postagem'),
                      value: post.date,
                    },
                  ];

                  if (influencer.network === 'twitter') {
                    return (
                      <CardPost
                        post={post}
                        key={post.id}
                        name={influencer.name}
                        avatar={influencer.avatar}
                        network={influencer.network}
                        type={post.image ? t('Mídia') : t('Tweet')}
                      />
                    );
                  } else {
                    const isMoreThan30Days = differenceInDays(new Date(), post.posted) > 30;

                    return (
                      <div
                        key={`${post.id}${i}`}
                        className="post"
                        style={{
                          backgroundImage: `url(${
                            isMoreThan30Days
                              ? buildImgUrl(influencer.network, post).replace(
                                  'tiktok.com/',
                                  'www.tiktok.com/',
                                )
                              : post.image
                          })`,
                        }}
                      >
                        <i
                          className="icon icon-clip hover"
                          onClick={() => window.open(post.link)}
                        />

                        <div className="gradient" />

                        <div className="post-interactions">
                          {igPostValues.map((item, i) => (
                            <Tooltip
                              key={i}
                              title={
                                item.value === t('Ocultas')
                                  ? t('Este perfil ocultou as curtidas desse post.')
                                  : null
                              }
                            >
                              <p className="interaction-data">
                                <i className={`icon icon-${item.icon}`} />
                                <span className="interaction-value">{item.value}</span>
                              </p>
                            </Tooltip>
                          ))}
                        </div>
                      </div>
                    );
                  }
                })}
              </>
            )}

            {status.fetchSummaryPosts === StateStatus.loading && <Loading />}

            {status.fetchSummaryPosts === StateStatus.succeeded &&
              influencerData.summaryPosts.length < 1 && (
                <p className="text-empty">{t('Nenhuma publicação')}</p>
              )}
          </div>
        </div>
      )}
    </AntModal>
  );
};

ModalInfluencerSummary.defaultProps = {
  isVisible: false,
};

ModalInfluencerSummary.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  influencer: PropTypes.object,
};

export default ModalInfluencerSummary;
