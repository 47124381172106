class PlacesModel {
    list

    fromJson(data) {
        this.list = Object.entries(data["places"]).map((place) => {
            const label = place[1]["labels"]["1"] || place[1]["labels"]["2"]

            if (label && place[0])
                return {
                    key: label,
                    slug: place[1]["slug"],
                    value: place[0],
                }
        }).sort((a, b) => a.key.localeCompare(b.key))

        return this
    }
}

export default PlacesModel