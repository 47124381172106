import { CloseOutlined } from '@ant-design/icons';
import { Tooltip as AntTooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { changeObjAlert, showAlert } from '../../../../../common/components/Alert/slice';
import Button from '../../../../../common/components/Button';
import { modalPLToggle } from '../../../../../common/components/ButtonModalLists/slice';
import { EngagementRateTable } from '../../../../../common/components/EngagementRateTable';
import { LevelGauge } from '../../../../../common/components/LevelGauge';
import Loading from '../../../../../common/components/Loading';
import Tooltip from '../../../../../common/components/Tooltip';
import { buildValue, humanize } from '../../../../../utils/formatters';
import StateStatus from '../../../../../utils/status';
import InfluencerHeader from '../../../InfluencerCard/components/Header';
import ModalRemarksAboutInfluencer from '../../../InfluencerCard/components/ModalRemarksAboutInfluencer';
import PartMentions from '../../../InfluencerCard/components/PartMentions';
import PartPosts from '../../../InfluencerCard/components/PartPosts';
import { getInfluencerExists } from '../../../InfluencerCard/service';
import {
  fetch,
  fetchDemographicsData,
  fetchInteractions,
  updateClearInfluencer,
  updateFavorite,
  updateIndex,
} from '../../../InfluencerCard/slice';

import { PARAMS } from '../../../../../constants/params';
import './index.scss';

const InfluencerPreview = ({ inTable, influencer, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slice = useSelector((state) => state.influencerDetails);
  const modalPlSlice = useSelector((state) => state.modalPlannerLists);
  const [showComments, setShowComments] = useState(false);
  const [biggestCensus, setBiggestCensus] = useState();
  const [biggestGender, setBiggestGender] = useState();
  const [existsDemographics, setExistsDemographics] = useState(false);

  const status = slice.status;
  const favorite = slice.isFavorite;
  const demographics = slice.demographics;
  const remarksAboutInfluencer = slice.influencer.notes;

  const handleShowEngagementRateTable = () => {
    dispatch(
      changeObjAlert({
        show: true,
        type: 'info',
        className: 'engagement-modal',
        title: 'Taxa de engajamento',
        subtitle: 'Confira as taxas de engajamentos comuns para cada rede social.',
        text: <EngagementRateTable />,
        cancelText: 'Fechar',
        onCancel: () => dispatch(showAlert(false)),
      }),
    );
  };

  const aboutFollowersItems = [
    { name: t('Sexo'), icon: 'user', alias: 'gender', type: 'donuts' },
    { name: t('Tem filhos?'), icon: 'group', alias: 'parental', type: 'donuts' },
    { name: t('Idade'), icon: 'baby', alias: 'census', type: 'bars' },
    { name: t('Local'), icon: 'location', alias: 'location', type: 'bars' },
    { name: t('Estado civil'), icon: 'rings', alias: 'marital', type: 'donuts' },
  ];

  const buildEngagement = (value) => {
    if (!value) return '0%';

    if (`${value}`.length > 6) {
      return `${buildValue('humanize', value.replace('.', ','))}%`;
    } else {
      return `${value}`.replace('.', ',') + '%';
    }
  };

  const [interactions, updateInteractions] = useState([
    { label: t('Airscore'), value: influencer.airscore ? influencer.airscore : 0 },
    { label: t('Seguidores'), value: influencer.followers ? humanize(influencer.followers) : 0 },
    { label: t('Engajamento'), value: buildEngagement(influencer.engagementRate) },
  ]);

  useEffect(() => {
    if (influencer.id) {
      dispatch(fetch({ id: influencer.id, inPreview: true }));
    }
  }, [influencer]);

  useEffect(() => {
    if (status.fetchInteractions === StateStatus.succeeded) {
      const meanValue =
        slice.influencer.network === 'twitch'
          ? slice.influencer.interactionsChartAverage?.views || 0
          : influencer.d30_means.total || 0;

      updateInteractions([
        ...interactions.slice(0, 2),
        {
          label: t('Engajamento'),
          value: buildEngagement(slice.influencer.engagementRate || 0),
        },
        {
          label: t(
            `Média de ${slice.influencer.network === 'twitch' ? 'visualizações' : 'interações'}`,
          ),
          value: slice.influencer.interactionsChartAverage ? humanize(meanValue) : 0,
          fullValue: slice.influencer.interactionsChartAverage
            ? buildValue('number', meanValue.toFixed(0))
            : 0,
          tooltip: t(
            `Média de ${
              slice.influencer.network === 'twitch' ? '' : 'interações'
            } por post dos últimos 30 dias`,
          ),
        },
      ]);
    }
  }, [status.fetchInteractions]);

  useEffect(() => {
    if (existsDemographics) dispatch(fetchDemographicsData());
  }, [existsDemographics]);

  useEffect(() => {
    if (status.fetch === StateStatus.succeeded) {
      dispatch(
        fetchInteractions({
          id: influencer.id,
          days: 30,
        }),
      );

      if (influencer.followers > 10000) {
        getInfluencerExists(influencer.id, influencer.username, influencer.network)
          .then((response) => {
            setExistsDemographics(response.data.exists);
          })
          .catch(() => {
            setExistsDemographics(false);
          });
      }
    }
  }, [status.fetch, influencer]);

  useEffect(() => {
    if (slice.status.fetchDemographicsData === StateStatus.succeeded) {
      const census = demographics.charts.bars.census;
      if (!census?.length) return;

      let maxValue = 0;
      let maxValueIndex = 0;

      census.forEach(({ value }, index) => {
        if (value > maxValue) {
          maxValue = value;
          maxValueIndex = index;
        }
      });

      setBiggestCensus({
        label: census[maxValueIndex].label,
        value: `(${(census[maxValueIndex].value / 100).toFixed(2)}%)`.replace('.', ','),
      });
    }
  }, [slice.status.fetchDemographicsData]);

  useEffect(() => {
    if (slice.status.fetchDemographicsData === StateStatus.succeeded) {
      const gender = demographics.charts.donuts.gender;
      if (!gender?.length) return;

      let maxValue = 0;
      let maxValueIndex = 0;

      gender.forEach(({ value }, index) => {
        if (value > maxValue) {
          maxValue = value;
          maxValueIndex = index;
        }
      });

      setBiggestGender({
        label: gender[maxValueIndex].label,
        value: `(${(gender[maxValueIndex].value / 100).toFixed(2)}%)`.replace('.', ','),
      });
    }
  }, [slice.status.fetchDemographicsData]);

  return (
    <>
      {status.fetch === StateStatus.succeeded && (
        <ModalRemarksAboutInfluencer
          influencer={influencer}
          isVisible={showComments}
          onClose={() => setShowComments(false)}
        />
      )}

      <div className="influencer-preview">
        {status.fetch === StateStatus.loading && <Loading />}

        {status.fetch === StateStatus.failed && (
          <p className="text-error">{t('Erro ao detalhar influenciador')}</p>
        )}

        {status.fetch === StateStatus.succeeded && (
          <>
            <CloseOutlined role="button" onClick={() => onClose()} />

            <div className="data-scroll">
              <InfluencerHeader isSimple={true} influencerId={influencer.id} />
              <div className="comments">
                {slice.totalNotes > 0 && remarksAboutInfluencer.length > 0 && (
                  <>
                    <p className="title-part">{t('Observações')}</p>

                    <div className="comment">
                      <span className="text">{remarksAboutInfluencer[0].text}</span>

                      <div className="about">
                        {remarksAboutInfluencer[0].isEdited && (
                          <span className="is-edited">{t('Editado')}</span>
                        )}

                        <span className="author">{remarksAboutInfluencer[0].author}</span>

                        <span className="date">{remarksAboutInfluencer[0].updatedAt}</span>
                      </div>
                    </div>
                  </>
                )}

                <div
                  className="other-comments hover"
                  role="button"
                  onClick={() => setShowComments(true)}
                >
                  <span className="text-other-comments">{t('Outras observações')}: </span>

                  <div className="count">{slice.totalNotes}</div>
                </div>
              </div>
              <div className="data-values">
                {status.fetchInteractions === StateStatus.loading && <Loading />}

                {status.fetchInteractions === StateStatus.succeeded &&
                  interactions.map((interaction) => (
                    <div className="data-value" key={interaction.label}>
                      <div className="value">
                        <AntTooltip title={interaction.fullValue}>{interaction.value}</AntTooltip>
                      </div>

                      <div className="name">
                        {interaction.label}

                        {interaction.label === 'Airscore' && (
                          <div className="level_gauge__wrapper">
                            <LevelGauge
                              value={influencer.airscore}
                              range={PARAMS.LEVEL_GAUGE.AIR_SCORE}
                            />
                          </div>
                        )}

                        {!['twitter', 'twitch'].includes(influencer.network) &&
                          interaction.label === 'Engajamento' && (
                            <button
                              type="button"
                              onClick={handleShowEngagementRateTable}
                              className="level_gauge__wrapper"
                            >
                              <LevelGauge
                                value={influencer.engagementRate}
                                range={
                                  PARAMS.LEVEL_GAUGE.ENGAGEMENT[influencer.network.toUpperCase()]
                                }
                              />
                            </button>
                          )}

                        {interaction.tooltip && <Tooltip child={interaction.tooltip} />}
                      </div>
                    </div>
                  ))}
              </div>
              {existsDemographics && (
                <>
                  <p className="title-part">{t('Dados dos seguidores')}</p>
                  <div className="follower-data">
                    {aboutFollowersItems.map(({ type, name, alias, icon }, index) => {
                      const chartData = demographics.charts[type][alias];

                      let value;
                      let label;

                      if (alias === 'census' && biggestCensus) {
                        value = biggestCensus.value;
                        label = biggestCensus.label;
                      } else if (alias === 'gender' && biggestGender) {
                        value = biggestGender.value;
                        label = biggestGender.label;
                      } else if (chartData && Object.keys(chartData).length > 0) {
                        const biggestItem = chartData[0];

                        label = biggestItem.label;
                        value = `(${(biggestItem.value / 100).toFixed(2)}%)`.replaceAll('.', ',');
                      }

                      return !value ? (
                        <></>
                      ) : (
                        <div key={`item-${index}`} className="item">
                          <p className="value-name">{name}</p>

                          <div className="junction-value">
                            <div>
                              <i className={`icon icon-${icon}`} />

                              {label && <b>{t(label)}</b>}

                              {!value && <span className="no-data">-</span>}
                            </div>

                            {value && <span className="value">{value}</span>}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              <PartPosts
                isSimple={true}
                influencerId={influencer.id}
                ids={influencer.postIds}
                foundInPosts={influencer.foundOn && influencer.foundOn.includes('posts')}
              />
              <PartMentions isSimple={true} />
            </div>

            <div className="influencer-preview-actions">
              <Button
                className="outline-primary"
                isLoading={modalPlSlice.status.modalPLToggle === StateStatus.loading}
                onClick={() => {
                  dispatch(
                    modalPLToggle({
                      include: favorite,
                      influencerId: influencer.id,
                      id: 'favorite',
                    }),
                  ).then((res) => {
                    if (!res.payload.include) {
                      dispatch(updateFavorite(!favorite));
                    }
                  });
                }}
              >
                {t(favorite ? 'Remover dos favoritos' : 'Salvar nos favoritos')}
              </Button>

              <Button
                onClick={() => {
                  if (inTable) dispatch(updateIndex(-1));

                  dispatch(updateClearInfluencer());

                  setTimeout(() => {
                    onClose(false);

                    window.clarity('set', 'ACTION', 'OPENED_CARD');

                    navigate(`/card/${influencer.id}`);
                  }, 300);
                }}
              >
                {t('Visualizar influenciador')}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

InfluencerPreview.defaultProps = {
  inTable: false,
};

InfluencerPreview.propTypes = {
  inTable: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  influencer: PropTypes.object.isRequired,
};

export default InfluencerPreview;
