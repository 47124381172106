import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getDataset,
  getDemographicData,
  getDemographicDataUpdate,
  getInfluencerAirscore,
  getInfluencerCard,
  getInfluencerCategories,
  getInfluencerDetails,
  getInfluencerExists,
  getInfluencerFollowers,
  getInfluencerInteractions,
  getInfluencerPosts,
  getInfluencerPostsInterval,
  getInteractionsHistory,
  getSimilarInfluencers,
  noteList,
  reactiveProfileService,
  removeNote,
  sendNote,
  sendPinNote,
  sendPostForReclassification,
  updNote,
} from './service';
//
import feedback from '../../../common/components/Feedback';
import DemographicsModel from '../../../models/DemographicsModel';
import InfluencerModel from '../../../models/InfluencerModel';
import NoteModel from '../../../models/NoteModel';
import PostModel from '../../../models/PostModel';
import StateStatus from '../../../utils/status';

const allStates = {
  fetch: StateStatus.idle,
  demographic: StateStatus.idle,
  themes: StateStatus.idle,
  base: StateStatus.idle,
  fetchPosts: StateStatus.idle,
  interactionsPosts: StateStatus.idle,
  fetchScores: StateStatus.idle,
  fetchFollowers: StateStatus.idle,
  fetchCategories: StateStatus.idle,
  fetchInteractions: StateStatus.idle,
  fetchPostsInterval: StateStatus.idle,
  fetchSimilarInfluencers: StateStatus.idle,
  fetchInteractionsHistory: StateStatus.idle,

  // demographics
  fetchDataset: StateStatus.idle,
  fetchDemographicsData: StateStatus.idle,
  fetchDemographicsDataUpdate: null,
  fetchVerifyExists: StateStatus.idle,

  // influencer card
  fetchInfluencerCard: StateStatus.idle,

  // notes
  postNote: StateStatus.idle,
  setNote: StateStatus.idle,
  updateNote: StateStatus.idle,
  notes: StateStatus.idle,

  // summary
  fetchSummaryPosts: StateStatus.idle,

  // reclassification
  sendPostReclassification: StateStatus.idle,

  reactiveProfile: StateStatus.idle,
};

const initialStateInfluencer = {
  status: { ...allStates },

  // similar
  similarInfluencers: [],

  // demographics
  demographicsDataset: {},
  demographics: {
    isExists: false,
    hasError: false,
    charts: new DemographicsModel(),
    statusLoading: null,
    updateStatus: null,
    hasDemographic: false,
  },

  // posts
  postPage: 0,
  totalPosts: 0,

  // summary posts
  sPostPage: 0,
  sTotalPosts: 0,
  sPages: 0,

  // interactions popup
  postsByDate: [],
  interactionsTotal: 0,

  // aux
  index: -1,
  influencer: new InfluencerModel(),
  errorMsg: null,
  isFavorite: false,

  // filters
  filterCategory: null,
  filterDataType: 'general', // general || publi_post
  selectedInteractionDay: null,

  // notes
  notesPage: 1,
  notesPages: 1,
  totalNotes: 0,
  notes: [],
};

export const fetch = createAsyncThunk(
  'influencer-card/fetch',
  async ({ id, index, inPreview }, thunkAPI) => {
    try {
      const { app } = thunkAPI.getState();
      const { categories, brands } = app;
      const { influencerDetails } = thunkAPI.getState();

      let params = {
        data_type: influencerDetails.filterDataType,
      };

      if (inPreview) params['preview'] = 1;

      const response = await getInfluencerDetails(id, params);

      return {
        index,
        brands: brands.object,
        categories: categories.list,
        subCategories: categories.subCategories,
        influencer: response.data,
      };
    } catch (err) {
      console.debug(err);

      throw err;
    }
  },
);

export const fetchSimilarInfluencers = createAsyncThunk(
  'influencer-card/similar',
  async (id, thunkAPI) => {
    const { app } = thunkAPI.getState();

    const response = await getSimilarInfluencers(id);

    return {
      data: response.data,
      brands: app.brands.object,
      categories: app.categories.list,
    };
  },
);

export const fetchInteractionsHistory = createAsyncThunk(
  'influencer-card/fetch/history',
  async ({ id, days }, thunkAPI) => {
    const { influencerDetails } = thunkAPI.getState();

    const data = {
      days: days,
      profile_id: id,
      data_type: influencerDetails.filterDataType,
    };

    const response = await getInteractionsHistory(data);

    return {
      days,
      ...response.data,
    };
  },
);

export const fetchPosts = createAsyncThunk(
  'influencer-card/fetch/posts',
  async (
    {
      id,
      page,
      categories,
      orderBy,
      text,
      ids,
      brandId,
      placeId,
      hashtag,
      orderByDesc,
      itemsPerPage,
    },
    thunkAPI,
  ) => {
    const { influencerDetails } = thunkAPI.getState();

    let data = {
      page: page,
      profile_id: id,
      order_by: orderBy,
      order_by_desc: orderByDesc,
      items_per_page: itemsPerPage,
      data_type: influencerDetails.filterDataType,
    };

    if (text) data['text'] = text;
    if (brandId) data['brand_id'] = brandId;
    if (placeId) data['place_id'] = placeId; // TODO: ajustar para a key certa
    if (hashtag) data['hashtag'] = hashtag; // TODO: ajustar para a key certa
    if (categories && categories.length > 0) data['categories'] = categories;
    if (ids) data['ids'] = ids;

    const response = await getInfluencerPosts(data);

    return {
      page,
      ...response.data,
    };
  },
);

export const fetchPostsByDate = createAsyncThunk(
  'influencer-card/fetch/posts-by-date',
  async ({ id, date, itemsPerPage }) => {
    const data = {
      page: 0,
      profile_id: id,
      posted_at: date,
      items_per_page: itemsPerPage || 10,
    };

    const response = await getInfluencerPosts(data);

    return response.data;
  },
);

export const fetchCategories = createAsyncThunk(
  'influencer-card/fetch/categories',
  async ({ id }, thunkAPI) => {
    const { influencerDetails } = thunkAPI.getState();

    const data = {
      profile_id: id,
      data_type: influencerDetails.filterDataType,
    };

    const response = await getInfluencerCategories(data);

    return response.data;
  },
);

export const fetchInteractions = createAsyncThunk(
  'influencer-card/fetch/interactions',
  async ({ id, days, categories }, thunkAPI) => {
    const { influencerDetails } = thunkAPI.getState();

    const data = {
      days: days,
      profile_id: id,
      data_type: influencerDetails.filterDataType,
    };

    if (categories) data['categories'] = categories;

    const response = await getInfluencerInteractions(data);

    return response.data;
  },
);

export const fetchFollowers = createAsyncThunk(
  'influencer-card/fetch/followers',
  async ({ id, days }, thunkAPI) => {
    const { influencerDetails } = thunkAPI.getState();

    const data = {
      days: days,
      profile_id: id,
      data_type: influencerDetails.filterDataType,
    };

    const response = await getInfluencerFollowers(data);

    return response.data;
  },
);

export const fetchScores = createAsyncThunk(
  'influencer-card/fetch/score',
  async ({ id, days, category }, thunkAPI) => {
    const { influencerDetails } = thunkAPI.getState();

    const data = {
      days: days,
      profile_id: id,
      data_type: influencerDetails.filterDataType,
    };

    if (category) data['category'] = category;

    const response = await getInfluencerAirscore(data);

    return {
      category: category,
      ...response.data,
    };
  },
);

export const fetchPostsInterval = createAsyncThunk(
  'influencer-card/fetch/posts-interval',
  async ({ id, days, type, categories }, thunkAPI) => {
    const { influencerDetails } = thunkAPI.getState();

    const data = {
      days: days,
      type: type,
      profile_id: id,
      data_type: influencerDetails.filterDataType,
    };

    if (categories && categories.length > 0) data['categories'] = categories;

    const response = await getInfluencerPostsInterval(data);

    return response.data;
  },
);

// -------------------------
// -- INFLUENCER CARD PDF --
// -------------------------
export const fetchInfluencerCard = createAsyncThunk('influencer-card/pdf', async (_, thunkAPI) => {
  const { influencerDetails } = thunkAPI.getState();
  const { influencer } = influencerDetails;

  try {
    const response = await getInfluencerCard(influencer.id);

    const url = URL.createObjectURL(response.data);

    const a = document.createElement('a');

    a.download = `${influencer.username}-${influencer.network}.pdf`;
    a.href = url;
    a.target = '_self';

    a.click();

    setTimeout(() => {
      a.remove();
      URL.revokeObjectURL(url);
    }, 300);

    return true;
  } catch (err) {
    feedback.error('Erro ao exportar card. Tente novamente mais tarde');

    throw err;
  }
});

// --------------------
// --- DEMOGRAPHICS ---
// --------------------
export const fetchDataset = createAsyncThunk('influencer-card/demographics/dataset', async () => {
  const response = await getDataset();

  let object = {};

  if (response.data && Object.keys(response.data).length > 0) {
    Object.keys(response.data).forEach((key) => {
      object[key] = response.data[key].br;

      object = {
        ...object,
        ...response.data[key].categories,
      };
    });
  }

  return {
    object: object,
  };
});

export const fetchVerifyExists = createAsyncThunk(
  'influencer-card/demographics/exists',
  async (_, thunkAPI) => {
    try {
      const { influencerDetails } = thunkAPI.getState();
      const { influencer } = influencerDetails;

      const response = await getInfluencerExists(
        influencer.id,
        influencer.networkUser,
        influencer.network,
      );

      return response.data;
    } catch (err) {
      console.debug(err);
      throw err;
    }
  },
);

export const fetchDemographicsData = createAsyncThunk(
  'influencer-card/demographics/data',
  async (_, thunkAPI) => {
    try {
      const { influencerDetails } = thunkAPI.getState();
      const { influencer } = influencerDetails;

      const response = await getDemographicData(
        influencer.id,
        influencer.username,
        influencer.network,
      );

      return {
        data: response.data,
        dataset: influencerDetails.demographicsDataset,
      };
    } catch (err) {
      console.debug(err);
      throw err;
    }
  },
);

export const fetchDemographicsDataUpdate = createAsyncThunk(
  'influencer-card/demographics/data/update',
  async (_, thunkAPI) => {
    try {
      const { influencerDetails } = thunkAPI.getState();
      const { influencer } = influencerDetails;

      const response = await getDemographicDataUpdate(
        influencer.id,
        influencer.username,
        influencer.network,
      );

      return {
        data: response.data,
        dataset: influencerDetails.demographicsDataset,
      };
    } catch (err) {
      console.debug(err);
      throw err;
    }
  },
);

// -------------------------
// --------- NOTES ---------
// -------------------------
export const postNote = createAsyncThunk(
  'influencer-card/note/create',
  async ({ influencerId, text }) => {
    const data = {
      profile_id: influencerId,
      text: text,
    };

    const response = await sendNote(data);

    return response.data;
  },
);

export const setNote = createAsyncThunk(
  'influencer-card/note/pin',
  async ({ id, text, index, influencerId }) => {
    const data = {
      text: text,
      profile_id: influencerId,
    };

    const response = await sendPinNote(id, data);

    return {
      index: index,
      data: response.data,
    };
  },
);

export const updateNote = createAsyncThunk(
  'influencer-card/note/update',
  async ({ id, text, index }) => {
    const data = {
      text: text,
    };

    const response = await updNote(id, data);

    return {
      index: index,
      data: response.data,
    };
  },
);

export const deleteNote = createAsyncThunk('influencer-card/note/delete', async ({ id }) => {
  await removeNote(id);

  return {
    id,
  };
});

export const notes = createAsyncThunk(
  'influencer-card/note/list',
  async ({ influencerId, page }) => {
    const data = {
      page: page,
      profile_id: influencerId,
    };

    const response = await noteList(data);

    return response.data;
  },
);

// -------------------------
// ----- SUMMARY POSTS -----
// -------------------------
export const fetchSummaryPosts = createAsyncThunk(
  'influencer-card/fetch/summary/posts',
  async ({ id, page }) => {
    let data = {
      page: page,
      profile_id: id,
      items_per_page: 12,
      data_type: 'general',
    };

    const response = await getInfluencerPosts(data);

    return {
      page,
      ...response.data,
    };
  },
);

// ---------------------------------
// ----- POST RECLASSIFICATION -----
// ---------------------------------
export const sendPostReclassification = createAsyncThunk(
  'influencer-card/post/reclassification',
  async (data) => {
    const response = await sendPostForReclassification(data);

    return response.data;
  },
);

export const reactiveProfile = createAsyncThunk('influencer-card/reactive', async (permalink) => {
  const response = await reactiveProfileService({ profiles: [permalink] });

  return response.data;
});

export const influencerDetailsSlice = createSlice({
  name: 'influencerDetails',
  initialState: initialStateInfluencer,
  reducers: {
    updateFavorite: (state, { payload }) => {
      state.isFavorite = payload;
    },
    updateDemographicsStatus: (state, { payload }) => {
      state.status.fetchDemographicsData = payload;
    },
    clearPostReclassificationStatus: (state) => {
      state.status.sendPostReclassification = StateStatus.idle;
    },
    clearPostStatus: (state) => {
      state.status.fetchPosts = StateStatus.idle;
    },
    updateClearInfluencer: (state) => {
      state.status = { ...allStates };

      state.influencer = new InfluencerModel();
      (state.demographics = {
        isExists: false,
        hasError: false,
        //
        charts: new DemographicsModel(),
      }),
        // posts
        (state.postPage = 0);
      state.totalPosts = 0;

      // summary posts
      state.sPostPage = 0;
      state.sTotalPosts = 0;

      // aux
      state.errorMsg = null;
      state.isFavorite = false;
      state.filterCategory = null;
      state.selectedInteractionDay = null;
    },
    updateFilterCategory: (state, action) => {
      state.filterCategory = action.payload;
    },
    updateFilterDataType: (state, action) => {
      state.filterDataType = action.payload;
    },
    updateIndex: (state, action) => {
      state.index = action.payload;
    },
    updateSelectedInteractionDay: (state, action) => {
      state.selectedInteractionDay = action.payload;
    },
    clearPosts: (state) => {
      state.influencer.posts = [];
    },
  },
  extraReducers: {
    [fetch.pending]: (state) => {
      state.status.fetch = StateStatus.loading;
    },
    [fetch.fulfilled]: (state, { payload }) => {
      const data = payload.influencer;
      const categories = payload.categories;
      const subCategories = payload.subCategories;
      const brands = payload.brands;

      const influencer = new InfluencerModel().detailsFromJson(
        data,
        categories,
        subCategories,
        brands,
      );

      if (payload.index != null && payload.index != undefined) {
        state.index = payload.index;
      }

      state.influencer = influencer;
      state.demographics = {
        isExists: false,
        hasError: false,
        //
        charts: new DemographicsModel(),
        statusLoading: null,
      };

      state.status.fetchDataset = StateStatus.idle;
      state.status.fetchVerifyExists = StateStatus.idle;
      state.status.fetchDemographicsData = StateStatus.idle;
      //
      state.status.fetch = StateStatus.succeeded;
    },
    [fetch.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.fetch = StateStatus.failed;
    },
    //
    [fetchInteractionsHistory.pending]: (state) => {
      state.status.fetchInteractionsHistory = StateStatus.loading;
    },
    [fetchInteractionsHistory.fulfilled]: (state, { payload }) => {
      state.influencer = state.influencer.addValuesByPeriod(payload.items, payload.days);

      if (payload.days === 60) {
        state.status.fetchInteractionsHistory = StateStatus.succeeded;
      } else {
        state.status.fetchInteractionsHistory = StateStatus.idle;
      }
    },
    [fetchInteractionsHistory.rejected]: (state) => {
      state.status.fetchInteractionsHistory = StateStatus.failed;
    },
    //
    [fetchSimilarInfluencers.pending]: (state) => {
      state.status.fetchSimilarInfluencers = StateStatus.loading;
    },
    [fetchSimilarInfluencers.fulfilled]: (state, { payload }) => {
      const { data, brands, categories } = payload;

      state.similarInfluencers = data.items.map((item) => {
        let influencer = new InfluencerModel();
        influencer.fromJson(item, brands, categories);

        return influencer;
      });

      state.status.fetchSimilarInfluencers = StateStatus.succeeded;
    },
    [fetchSimilarInfluencers.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.fetchSimilarInfluencers = StateStatus.failed;
    },

    // --------------------
    // ---- CATEGORIES ----
    // --------------------
    [fetchCategories.pending]: (state) => {
      state.status.fetchCategories = StateStatus.loading;
    },
    [fetchCategories.fulfilled]: (state, { payload }) => {
      state.influencer = state.influencer.addCategories(payload);

      state.status.fetchCategories = StateStatus.succeeded;
    },
    [fetchCategories.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.fetchCategories = StateStatus.failed;
    },

    // ---------------------
    // ------- POSTS -------
    // ---------------------
    [fetchPosts.pending]: (state) => {
      state.status.fetchPosts = StateStatus.loading;
    },
    [fetchPosts.fulfilled]: (state, { payload }) => {
      state.postPage = payload['page'] || 0;
      state.totalPosts = payload['count'] || 0;

      let posts = [];

      if (payload['items'] && payload['items'].length > 0) {
        posts = payload['items'].map((post) => {
          return new PostModel().fromJson(post);
        });
      }

      state.influencer = state.influencer.addPosts(posts);

      state.status.fetchPosts = StateStatus.succeeded;
    },
    [fetchPosts.rejected]: (state, action) => {
      state.postPage = 0;
      state.totalPosts = 0;

      state.errorMsg = action.error.message;
      state.status.fetchPosts = StateStatus.failed;
    },
    [fetchPostsByDate.pending]: (state) => {
      state.status.fetchPostsByDate = StateStatus.loading;
    },
    [fetchPostsByDate.fulfilled]: (state, { payload }) => {
      let posts = [];
      let interactionsTotal = 0;

      if (payload['items'] && payload['items'].length > 0) {
        posts = payload['items'].map((postData) => {
          const post = new PostModel().fromJson(postData);

          interactionsTotal += post.interactionsTotal;
          return post;
        });
      }
      state.postsByDate = posts;
      state.interactionsTotal = interactionsTotal;

      state.status.fetchPostsByDate = StateStatus.succeeded;
    },
    [fetchPostsByDate.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.fetchPostsByDate = StateStatus.failed;
    },

    // ---------------------
    // -- INFLUENCER CARD --
    // ---------------------
    [fetchInfluencerCard.pending]: (state) => {
      state.status.fetchInfluencerCard = StateStatus.loading;
    },
    [fetchInfluencerCard.fulfilled]: (state) => {
      state.status.fetchInfluencerCard = StateStatus.idle;
    },
    [fetchInfluencerCard.rejected]: (state, { error }) => {
      state.err = error.message || 'Não foi possível achar o usuário.';

      state.status.fetchInfluencerCard = StateStatus.failed;
    },

    // --------------------
    // ------ CHARTS ------
    // --------------------
    [fetchScores.pending]: (state) => {
      state.status.fetchScores = StateStatus.loading;
    },
    [fetchScores.fulfilled]: (state, { payload }) => {
      state.influencer = state.influencer.addScoresChart(payload.items, payload.category);

      state.status.fetchScores = StateStatus.succeeded;
    },
    [fetchScores.rejected]: (state) => {
      state.status.fetchScores = StateStatus.failed;
    },
    //
    [fetchFollowers.pending]: (state) => {
      state.status.fetchFollowers = StateStatus.loading;
    },
    [fetchFollowers.fulfilled]: (state, { payload }) => {
      state.influencer = state.influencer.addFollowersChart(payload.items);

      state.status.fetchFollowers = StateStatus.succeeded;
    },
    [fetchFollowers.rejected]: (state) => {
      state.status.fetchFollowers = StateStatus.failed;
    },
    //
    [fetchInteractions.pending]: (state) => {
      state.status.fetchInteractions = StateStatus.loading;
    },
    [fetchInteractions.fulfilled]: (state, { payload }) => {
      const { items, means, total } = payload;

      state.influencer = state.influencer.addInteractionChart({ items, means, total });

      state.status.fetchInteractions = StateStatus.succeeded;
    },
    [fetchInteractions.rejected]: (state) => {
      state.status.fetchInteractions = StateStatus.failed;
    },
    //
    [fetchCategories.pending]: (state) => {
      state.status.fetchCategories = StateStatus.loading;
    },
    [fetchCategories.fulfilled]: (state, { payload }) => {
      state.influencer = state.influencer.addCategories(payload.items);

      state.status.fetchCategories = StateStatus.succeeded;
    },
    [fetchCategories.rejected]: (state) => {
      state.status.fetchCategories = StateStatus.failed;
    },
    //
    [fetchPostsInterval.pending]: (state) => {
      state.status.fetchPostsInterval = StateStatus.loading;
    },
    [fetchPostsInterval.fulfilled]: (state, { payload }) => {
      state.influencer = state.influencer.addPostsInterval(payload.items);

      state.status.fetchPostsInterval = StateStatus.succeeded;
    },
    [fetchPostsInterval.rejected]: (state) => {
      state.status.fetchPostsInterval = StateStatus.failed;
    },

    // --------------------
    // --- DEMOGRAPHICS ---
    // --------------------
    [fetchDataset.pending]: (state) => {
      state.status.fetchDataset = StateStatus.loading;
    },
    [fetchDataset.fulfilled]: (state, { payload }) => {
      state.demographicsDataset = payload.object;

      state.status.fetchDataset = StateStatus.succeeded;
    },
    [fetchDataset.rejected]: (state) => {
      state.status.fetchDataset = StateStatus.failed;
    },
    [fetchVerifyExists.pending]: (state) => {
      state.status.fetchVerifyExists = StateStatus.loading;
    },
    [fetchVerifyExists.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.demographics.isExists = payload.exists;
      } else {
        state.demographics.hasError = true;
      }

      state.status.fetchVerifyExists = StateStatus.succeeded;
    },
    [fetchVerifyExists.rejected]: (state) => {
      state.status.fetchVerifyExists = StateStatus.failed;
    },
    //
    [fetchDemographicsData.pending]: (state) => {
      state.status.fetchDemographicsData = StateStatus.loading;
    },
    [fetchDemographicsDataUpdate.pending]: (state) => {
      state.status.fetchDemographicsDataUpdate = StateStatus.loading;
      state.demographics.updateStatus = StateStatus.loading;
    },
    [fetchDemographicsData.fulfilled]: (state, { payload }) => {
      state.demographics.charts = new DemographicsModel().fromJson(payload.data, payload.dataset);

      if (payload['data'] && payload['data']['loading_status']) {
        state.demographics.statusLoading = payload['data']['loading_status'];

        if (payload.data.loading_status === 'completed') {
          state.demographics.hasDemographic = Boolean(payload.data);
          state.demographics.updateStatus = payload['data']['update_status'];
        }
      }

      state.status.fetchDemographicsData = StateStatus.succeeded;
    },
    [fetchDemographicsDataUpdate.fulfilled]: (state, { payload }) => {
      if (payload['data'] && payload['data']['loading_status']) {
        state.demographics.statusLoading = payload['data']['loading_status'];
        state.demographics.updateStatus = StateStatus.loading;
      }
    },
    [fetchDemographicsData.rejected]: (state) => {
      state.demographics.hasError = true;

      state.status.fetchDemographicsData = StateStatus.failed;
    },

    // ---------------------
    // ------- NOTES -------
    // ---------------------
    [postNote.pending]: (state) => {
      state.status.postNote = StateStatus.loading;
    },
    [postNote.fulfilled]: (state, { payload }) => {
      const newNote = new NoteModel().fromJson(payload);
      const newList = [...state.influencer.notes, newNote];

      state.influencer = state.influencer.addNotes(newList);

      state.totalNotes = state.totalNotes + 1;

      state.status.postNote = StateStatus.succeeded;
    },
    [postNote.rejected]: (state) => {
      state.status.postNote = StateStatus.failed;
    },
    //
    [setNote.pending]: (state) => {
      state.status.setNote = StateStatus.loading;
    },
    [setNote.fulfilled]: (state, { payload }) => {
      const { index } = payload;

      let newList = state.influencer.notes;

      const oldFixed = newList[0];
      const newFixed = newList[index];

      oldFixed.isFixed = false;
      newFixed.isFixed = true;

      newList[0] = newFixed;
      newList[index] = oldFixed;

      state.influencer = state.influencer.addNotes(newList);

      state.status.setNote = StateStatus.succeeded;
    },
    [setNote.rejected]: (state) => {
      state.status.setNote = StateStatus.failed;
    },
    //
    [updateNote.pending]: (state) => {
      state.status.updateNote = StateStatus.loading;
    },
    [updateNote.fulfilled]: (state, { payload }) => {
      const { data, index } = payload;
      let notes = state.influencer.notes;

      if (notes[index]) notes[index] = new NoteModel().fromJson(data);

      state.influencer = state.influencer.addNotes(notes);
      //
      state.status.updateNote = StateStatus.succeeded;
    },
    [updateNote.rejected]: (state) => {
      state.status.updateNote = StateStatus.failed;
    },
    //
    [deleteNote.pending]: (state) => {
      state.status.deleteNote = StateStatus.loading;
    },
    [deleteNote.fulfilled]: (state, { payload }) => {
      let notes = state.influencer.notes || [];

      notes = notes.filter((item) => item.id != payload.id);

      if (notes[0]) notes[0].isFixed = true;

      state.totalNotes = state.totalNotes - 1;

      state.influencer = state.influencer.addNotes(notes);
      //
      state.status.deleteNote = StateStatus.succeeded;
    },
    [deleteNote.rejected]: (state) => {
      state.status.deleteNote = StateStatus.failed;
    },
    //
    [notes.pending]: (state) => {
      state.status.notes = StateStatus.loading;
    },
    [notes.fulfilled]: (state, { payload }) => {
      state.notesPage = payload['page'] || 0;
      state.notesPages = payload['pages'] || 0;
      state.totalNotes = payload['total'] || 0;

      let posts = [];

      if (payload['items'] && payload['items'].length > 0) {
        posts = payload['items'].map((post) => {
          return new NoteModel().fromJson(post);
        });
      }

      const allNotes = [...state.influencer.notes, ...posts];
      const ids = allNotes.map(({ id }) => id);
      const filteredNotes = allNotes.filter(({ id }, index) => !ids.includes(id, index + 1));

      state.influencer = state.influencer.addNotes(filteredNotes);

      state.status.notes = StateStatus.succeeded;
    },
    [notes.rejected]: (state, action) => {
      state.postPage = 0;
      state.totalPosts = 0;

      state.errorMsg = action.error.message;
      state.status.notes = StateStatus.failed;
    },

    // ---------------------
    // --- SUMMARY POSTS ---
    // ---------------------
    [fetchSummaryPosts.pending]: (state) => {
      state.status.fetchSummaryPosts = StateStatus.loading;
    },
    [fetchSummaryPosts.fulfilled]: (state, { payload }) => {
      state.sPages = payload['pages'] || 0;
      state.sPostPage = payload['page'] || 0;
      state.sTotalPosts = payload['count'] || 0;

      let posts = [];

      if (payload['items'] && payload['items'].length > 0) {
        posts = payload['items'].map((post) => {
          return new PostModel().fromJson(post);
        });
      }

      state.influencer = state.influencer.addSummaryPosts(posts);

      state.status.fetchSummaryPosts = StateStatus.succeeded;
    },
    [fetchSummaryPosts.rejected]: (state, action) => {
      state.postPage = 0;
      state.totalPosts = 0;

      state.errorMsg = action.error.message;
      state.status.fetchSummaryPosts = StateStatus.failed;
    },

    // ------------------------
    // --- RECLASSIFICATION ---
    // ------------------------
    [sendPostReclassification.pending]: (state) => {
      state.status.sendPostReclassification = StateStatus.loading;
    },
    [sendPostReclassification.fulfilled]: (state) => {
      state.status.sendPostReclassification = StateStatus.succeeded;
    },
    [sendPostReclassification.rejected]: (state) => {
      state.status.sendPostReclassification = StateStatus.failed;
    },

    [reactiveProfile.pending]: (state) => {
      state.status.reactiveProfile = StateStatus.loading;
    },
    [reactiveProfile.fulfilled]: (state) => {
      state.status.reactiveProfile = StateStatus.succeeded;
    },
    [reactiveProfile.rejected]: (state) => {
      state.status.reactiveProfile = StateStatus.failed;
    },
  },
});

export const {
  clearPosts,
  clearPostStatus,
  updateIndex,
  updateFavorite,
  updateFilterCategory,
  updateFilterDataType,
  updateClearInfluencer,
  updateSelectedInteractionDay,
  updateDemographicsStatus,
  clearPostReclassificationStatus,
} = influencerDetailsSlice.actions;

export const selectInfluencerDetailsSlice = (state) => state.influencerDetails;

export const selectFilterCategory = (state) => state.influencerDetails.filterCategory;

export const selectFilterDataType = (state) => state.influencerDetails.filterDataType;
