import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createBriefing } from './service';
import StateStatus from '../../../utils/status';

const initialState = {
  status: {
    sendBriefing: StateStatus.idle,
  },
  plId: null,
  errMsg: null,
  airaMsgs: [],
  curationFinalized: false,
};

export const sendBriefing = createAsyncThunk('curation/send-form', async (data) => {
  const result = await createBriefing(data);
  return result.data;
});

export const curationSlice = createSlice({
  name: 'curation',
  initialState,
  reducers: {
    clearCuration: (state) => {
      state.plId = null;
      state.errMsg = null;
      state.airaMsgs = [];
      state.status.sendBriefing = StateStatus.idle;
    },
  },
  extraReducers: {
    [sendBriefing.pending]: (state) => {
      state.status.sendBriefing = StateStatus.loading;
    },
    [sendBriefing.fulfilled]: (state, { payload }) => {
      state.plId = payload.uid;

      state.status.sendBriefing = StateStatus.succeeded;
    },
    [sendBriefing.rejected]: (state, action) => {
      state.airaMsgs.push({ text: action.error.message });
      state.status.sendBriefing = StateStatus.failed;
    },
  },
});

export const { clearCuration } = curationSlice.actions;

export const selectCurationSlice = (state) => state.curation;
