import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import StateStatus from "../../../utils/status"
import { useDispatch, useSelector } from "react-redux"
import { clearAvatar, clearStatus, create, edit, selectPlannerListSlice, uploadAvatar } from "../../../features/private/PlannerList/slice"
// 
import { Form } from "antd"
import Button from "../Button"
import Input from "../Input"
// 
import "./index.scss"

const { useForm } = Form

const CreateEditPlannerList = ({
    id,
    name,
    avatar,
    isFavorite,
    onClose,
}) => {
    const { t } = useTranslation()
    // 
    const dispatch = useDispatch()
    const inputFileRef = useRef(null)
    // 
    const slice = useSelector(selectPlannerListSlice)
    // 
    const status = slice.status
    // 
    const [form] = useForm()
    // 
    const [fileSelected, updateFileSelected] = useState()
    const [error, updateError] = useState()

    useEffect(() => {
        if(status.uploadAvatar === StateStatus.succeeded) {
            dispatch(clearStatus())
            
            onCloseModal({
                name: form.getFieldValue("title"),
                url: slice.updatedAvatar
            })
        }
    }, [status])

    const onCloseModal = (data) => {
        dispatch(clearAvatar())
        onClose(data)
    }

    const onFinish = (values) => {
        if (name) {
            dispatch(edit({ ...values, id })).then(({ payload }) => {
                if(payload.uid && fileSelected) {
                    dispatch(uploadAvatar({
                        id: payload.uid,
                        file: fileSelected.file
                    }))
                } else {
                    dispatch(clearStatus())

                    onCloseModal({
                        name: form.getFieldValue("title"),
                    })
                }
            })
        } else {
            dispatch(create(values.title)).then(({ payload }) => {
                if(payload.uid && fileSelected && fileSelected.file) {
                    dispatch(uploadAvatar({
                        id: payload.uid,
                        file: fileSelected.file
                    }))
                } else {
                    onCloseModal()
                }
            })
        }
    }

    const handleChooseFile = (event) => {
        const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        // 
        const file = event.target.files[0]

        if (!file || !acceptedTypes.includes(file.type)) {
            updateFileSelected()
            updateError(t("Formato inválido"))
        } else if (file.size > 5000000) {
            updateFileSelected()
            updateError(t("Arquivo deve ser menor que 5MB"))
        } else {
            updateError()

            updateFileSelected(!file ? null : {
                file,
                url: URL.createObjectURL(event.target.files[0]),
            })
        }
    }
    
    return <div
        onClick={() => {
            dispatch(clearStatus())
            onCloseModal()
        }}
        className="pl-create-edit-modal"
    >
        <div
            className="pl-create-edit-content"
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <p className="title">{t((name || isFavorite) ? "Editar lista" : "Criar lista")}</p>

            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="title"
                    initialValue={name}
                    label={t("Nome da lista")}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (!value || `${value}`.trim().length < 1) {
                                    return Promise.reject(new Error(t("Informe um nome válido")))
                                } else {
                                    return Promise.resolve()
                                }
                            },
                        }),
                    ]}
                >
                    <Input
                        isDisabled={isFavorite}
                        placeholder={t("Digite um nome para a lista")}
                    />
                </Form.Item>

                <Form.Item
                    name="image"
                    initialValue={avatar}
                    label={t("Imagem da lista")}
                >
                    {(fileSelected || avatar) &&
                        <div
                            className="pl-avatar"
                            style={{ backgroundImage: `url(${(fileSelected?.url || avatar)})` }}
                        >
                            <i 
                                className="icon icon-pencil hover" 
                                onClick={() => inputFileRef.current.click()}
                            />
                        </div>
                    }

                    {(!fileSelected && !avatar) &&
                        <div 
                            className="pl-not-avatar hover"
                            onClick={() => inputFileRef.current.click()}
                        >
                            <i className="icon icon-image-square" />

                            <span>{t("Adicionar imagem de perfil")}</span>
                        </div>
                    }
        
                    <input 
                        id="avatar" 
                        type="file" 
                        ref={inputFileRef}
                        onChange={handleChooseFile} 
                    />

                    {error && <p className="text-error">{error}</p>}
                </Form.Item>
            </Form>

            <div className="buttons">
                <Button
                    className="outline-grey"
                    onClick={() => onCloseModal()}
                >
                    {t("Cancelar")}
                </Button>

                <Button
                    onClick={() => form.submit()}
                    isLoading={status.create === StateStatus.loading || status.edit === StateStatus.loading || status.uploadAvatar === StateStatus.loading}
                >
                    {t("Salvar informações")}
                </Button>
            </div>
        </div>
    </div>
}

CreateEditPlannerList.defaultProps = {
    isFavorite: false,
}

CreateEditPlannerList.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    avatar: PropTypes.string,
    isFavorite: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
}

export default CreateEditPlannerList