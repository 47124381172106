import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    indexActive: "0",
    collapsed: true,
}

export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        updateIndexActive: (state, action) => {
            state.indexActive = action.payload
        },
        updateCollapsed: (state, action) => {
            state.collapsed = action.payload
        },
    }
})

export const { updateIndexActive, updateCollapsed } = menuSlice.actions

export const selectMenuSlice = (state) => state.menu