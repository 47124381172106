import React from "react"
import PropTypes from "prop-types"
// 
import { selectObjAlert, showAlert } from "./slice"
import { useDispatch, useSelector } from "react-redux"
// 
import { Modal } from "antd"
import Button from "../Button"
// 
import "./index.scss"

const Alert = () => {
  const dispatch = useDispatch()
  // 
  const alertSlice = useSelector(selectObjAlert)
  // 
  const show= alertSlice.show || false
  const type= alertSlice.type || "success"
  const title= alertSlice.title
  const subtitle= alertSlice.subtitle
  const text= alertSlice.text
  const question= alertSlice.question
  const okText= alertSlice.okText
  const cancelText= alertSlice.cancelText
  const onOk= alertSlice.onOk
  const onCancel= alertSlice.onCancel
  const className= alertSlice.className

  const icons = {
    "success": <i className="icon icon-circle-check" />,
    "error": <i className="icon icon-circle-close" />,
    "warning": <i className="icon icon-alert" />,
    "info": <i className="icon icon-info-circle" />,
  }


  return <Modal
      title="Modal"
      open={show}
      onOk={() => onOk}
      onCancel={() => {
        dispatch(showAlert(false))
        if(onCancel) onCancel()
      }}
      okText="OK"
      cancelText="Cancel"
      className={`modal ${className}`}
    >
      <div className={`container-icon container-` + type} >{icons[type]}</div>

      <div className="container-content">
        <h2 className={`title title-` + type}>{title || type}</h2>
        
        {subtitle && <h3 className="subtitle">{subtitle}</h3>}
        
        {text && <div className="text">{text}</div>}

        {question && <div className="question">{question}</div>}

        <div className="buttons">
          {cancelText && onCancel && 
            <Button 
              className="outline-grey"
              outline={true}
              onClick={() => {
                onCancel()
              }}
            >
              {cancelText}
            </Button>
          }

          {okText && onOk && 
            <Button  
              onClick={() => onOk()}
              className={(type === "info" || type === "success") ? "" : "pattern-error"}
            >
              {okText}
            </Button>
          } 
        </div>
    </div>
  </Modal>
}

Alert.defaultProps = {
  show: false,
  type: "success",
  okText: "Ok",
  cancelText: "Cancel",
  onOk: () => {},
  onCancel: () => {},
}

Alert.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  question: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
}

export default Alert