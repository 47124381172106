import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Influncer from '../../../models/InfluencerModel';
import StateStatus from '../../../utils/status';
import { getAutocompletes, getQuota, getSearch } from './service';

const initialState = {
  search: {
    page: 0,
  },
  lastSearch: {},
  lastSearchFilter: null,
  status: {
    fetch: StateStatus.idle,
    fetchAutocompletes: StateStatus.idle,
  },

  autocompletes: {},

  results: [],
  totalPages: 0,
  influencersTotal: 0,
  percentageQuota: 0,
  renewQuotaDate: '',
};

export const fetch = createAsyncThunk('search/', async ({ page, listId, sort }, thunkAPI) => {
  const { home, app } = thunkAPI.getState();

  let data = {
    ...home.search,
    page,
    planner_list_id: listId,
    items_per_page: 12,
  };

  if (sort) data['sort'] = sort;
  if (!sort) delete data.sort;
  if (data['categories'] && !data['category_relevance']) data['category_relevance'] = 0;

  Object.keys(data).forEach((key) => {
    if (data[key] == null) delete data[key];
  });

  const response = await getSearch(data);

  const results = response.data.items.map((item) => {
    const influencer = new Influncer();
    influencer.fromJson(item, app.brands.object, app.categories.list);
    return influencer;
  });

  return {
    data: data,
    page: page,
    items: results,
    totalPages: response.data.pages,
    influencersTotal: response.data.count,
  };
});

export const fetchQuota = createAsyncThunk('search/quota', async () => {
  const response = await getQuota();

  return response.data;
});

export const fetchAutocompletes = createAsyncThunk(
  'search/autocomplete',
  async ({ type, query }) => {
    const result = await getAutocompletes(type, query);

    return {
      type,
      data: result.data,
    };
  },
);

export const homeSlice = createSlice({
  name: 'home',
  initialState: initialState,
  reducers: {
    clearSearchValues: (state) => {
      const sort = state.search?.sort;

      state.search = {
        page: 0,
        sort: sort,
      };
    },
    clearResults: (state) => {
      state.results = [];
    },
    updateResults: (state, { payload }) => {
      state.results = payload || [];
    },
    updateSearchData: (state, { payload }) => {
      if (payload && typeof payload === 'object') {
        state.lastSearchFilter = payload;

        state.search = {
          ...state.search,
          ...payload,
        };
      }
    },
  },
  extraReducers: {
    [fetch.pending]: (state) => {
      state.status.fetch = StateStatus.loading;
    },
    [fetch.fulfilled]: (state, { payload }) => {
      state.search = {
        ...state.search,
        sort: payload.data.sort,
        page: payload.data.page,
      };

      state.totalPages = payload.totalPages;
      state.influencersTotal = payload.influencersTotal;

      state.results = payload.items;

      state.lastSearchFilter = 'page';

      delete payload.data.page;
      delete payload.data.flag;

      state.lastSearch = payload.data;

      state.status.fetch = StateStatus.succeeded;
    },
    [fetch.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.fetch = StateStatus.failed;
    },
    [fetchQuota.pending]: (state) => {
      state.status.fetchQuota = StateStatus.loading;
    },
    [fetchQuota.fulfilled]: (state, { payload }) => {
      const renew = payload.renew;
      const quota = payload.quotas ? payload.quotas.search : 0;

      if (renew) state.renewQuotaDate = renew;
      if (quota) state.percentageQuota = quota;

      state.status.fetchQuota = StateStatus.succeeded;
    },
    [fetchQuota.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.fetchQuota = StateStatus.failed;
    },
    [fetchAutocompletes.pending]: (state) => {
      state.status.fetchAutocompletes = StateStatus.loading;
    },
    [fetchAutocompletes.fulfilled]: (state, { payload }) => {
      state.autocompletes[payload.type] = payload.data.items || [];

      state.status.fetchAutocompletes = StateStatus.succeeded;
    },
    [fetchAutocompletes.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
      state.status.fetchAutocompletes = StateStatus.failed;
    },
  },
});

export const { clearSearchValues, clearResults, updateResults, updateSearchData } =
  homeSlice.actions;

export const selectHomeSlice = (state) => state.home;

export const selectHomeFilter = (state) => state.home.search;
