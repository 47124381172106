import React from "react"
import { useTranslation } from "react-i18next"
// 
import { Skeleton } from "antd"
// 
import "./styles.scss"

const SkeletonComparativeTable = () => {
    const { t } = useTranslation()

    const columns = [
        {title: t("Influenciador"), skeleton: <div className="div-flex"><Skeleton.Avatar active /> <Skeleton paragraph={false} active /></div>, span: 2},
        {title: t("Seguidores"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Engajamento"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Média de interações"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Airscore"), skeleton: <Skeleton paragraph={false} active />, span: 2},
        {title: t("Assuntos"), skeleton: <div className="div-flex td-categories"><Skeleton.Avatar active /> <Skeleton.Avatar active /> <Skeleton.Avatar active /></div>, span: 2},
    ]

    return (
        <div className="skeleton-aira-tasting-table">
             <table>
                <thead>
                        <tr>
                            {columns.map((item) => {
                                return (
                                    <th key={`th-${item.alias}`} className="interaction-value">
                                        <span>{item.title}</span>
                                    </th>
                                );
                            })}
                        </tr>
                </thead>
                
                <tbody>
                    {[0, 1, 2, 3].map((_, idx) => {
                        return (
                            <tr key={`tr-${idx}`}>
                                {columns.map((item, index) => (
                                    <td key={`${idx} - ${index}`} span={item.span}>
                                        {item.skeleton}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

SkeletonComparativeTable.propTypes = {}

export default SkeletonComparativeTable
