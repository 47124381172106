import { Dropdown as AntDropdown } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';

const Dropdown = ({ children, options, onChange, onSelect, overlayStyle }) => {
  const { t } = useTranslation();

  return (
    <AntDropdown
      trigger="click"
      autoAdjustOverflow={false}
      placement="bottomRight"
      className="drop-down"
      destroyPopupOnHide
      overlayStyle={overlayStyle}
      menu={{
        items: options,
        mode: 'vertical',
        onClick: ({ key }) => onSelect(key),
      }}
      onOpenChange={onChange}
    >
      {children ? (
        children
      ) : (
        <a className="ant-dd-children" onClick={(event) => event.preventDefault()}>
          {t('Selecione')}
          <i className="icon icon-down" />
        </a>
      )}
    </AntDropdown>
  );
};

Dropdown.defaultProps = {
  options: [],
  hasIcon: false,
  onSelect: () => {},
  onChange: () => {},
  overlayStyle: {},
};

Dropdown.propTypes = {
  hasIcon: PropTypes.bool,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  children: PropTypes.element.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  overlayStyle: PropTypes.object,
};

export default Dropdown;
