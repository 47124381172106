import api from '../service/api';
import routes from '../service/apiRoutes';
import { whitoutInterceptorsApi } from '../service/whitoutInterceptorsApi';

export const auth = (token) =>
  whitoutInterceptorsApi.post(
    `${process.env.REACT_APP_BASEURL}${routes.auth}?redirect_token=${token}`,
  );
export const getUser = () => api.get(routes.user);
export const getClient = () => api.get('/client/me');
export const getCategories = () => api.get(routes.categories);
export const getBrands = () => api.get(routes.brands);
export const getPlaces = () => api.get(routes.places);
export const postAction = (data) => api.post(routes.user_action, data);
export const NPSSearch = (data) => api.post('/client/nps', data);
