import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import Input from "../../../../../../../common/components/Input"
import Button from "../../../../../../../common/components/Button"
import CheckBox from "../../../../../../../common/components/CheckBox"
// 
import "./index.scss"

const MultiSelectBrands = ({
    onChange,
    options,
    hasSearch,  
    placeholder,
    selecteds,
    isOpenPopover,
}) => {
    const { t } = useTranslation()
    // 
    const [inputValue, updateInputValue] = useState("")
    const [filteredOptions, updateFilteredOptions] = useState([])

    useEffect(() => {
        if(!isOpenPopover) updateInputValue("")
    }, [isOpenPopover])

    useEffect(() => {
        if(inputValue.length >= 3) {
            updateFilteredOptions(options.filter(item => item["key"].toLowerCase().includes(inputValue.toLowerCase())))
        } else {
            updateFilteredOptions([])
        }
    }, [options, inputValue])
    
    return <div className="multi-select">
        {hasSearch && <Input
            value={inputValue}
            placeholder={placeholder}
            onChange={(e) => {
                const text = e.target.value

                updateInputValue(text)
            }}
            prefix={<i className="icon icon-search" />}
        />}

        <div className="options">
            {filteredOptions.length < 1 &&
                <p className="text-empty">
                    {t(inputValue.length > 3 ? 
                        "Sem resultados"
                        :
                        "Informe pelo menos três letras para as opções aparecerem"
                    )}
                </p>
            }

            {filteredOptions.map(option => (
                <CheckBox
                    key={option["value"]}
                    text={option["key"]}
                    checked={selecteds[option["value"]] ? selecteds[option["value"]] === "include" : null}
                    indeterminate={selecteds[option["value"]] ? selecteds[option["value"]] === "exclude" : null}
                    onChange={() => {
                        const value = selecteds[option["value"]]
                        
                        onChange({ 
                            ...selecteds, 
                            ...{ 
                                [option["value"]]: 
                                    (!value ? 
                                        "include" 
                                        : 
                                        value === "include" ? "exclude" : null
                                    )
                            }, 
                    })
                    }}
                />
            ))}
        </div>

        <Button
            className="outline-light btn-clear"
            onClick={() => {
                updateInputValue("")
                onChange({})
            }}
        >
            {t("Limpar filtros")}
        </Button>
    </div>
}

MultiSelectBrands.defaultProps = {
    options: [],
    selecteds: {},
    hasSearch: true,
    isLoading: false,
    isDisabled: false,
    isOpenPopover: false,
    onChange: () => { },
    onNewSelecteds: () => { },
}

MultiSelectBrands.propTypes = {
    children: PropTypes.element,
    onChange: PropTypes.func,
    hasSearch: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isOpenPopover: PropTypes.bool,
    isLoading: PropTypes.bool,
    placeholder: PropTypes.string,
    selecteds: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.object),
}

export default MultiSelectBrands