import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
// 
import { useDispatch, useSelector } from "react-redux"
import { selectInfluencerDetailsSlice, fetchInteractionsHistory as generalFetchInteractionsHistory } from "../../../InfluencerCard/slice"
import { fetchInteractionsHistory, fetchPublipostData, selectInfluencerDetailsPDFSlice } from "../../slice"
import StateStatus from "../../../../../utils/status"
// 
import Loading from "../../../../../common/components/Loading"
// 
import { buildValue } from "../../../../../utils/formatters"
// 
import "./index.scss"

const PartGeneralData = () => {
    const { t } = useTranslation()
    // 
    const dispatch = useDispatch()
    const params = useParams()
    // 
    const slice = useSelector(selectInfluencerDetailsSlice)
    const slicePDF = useSelector(selectInfluencerDetailsPDFSlice)
    // 
    const id = params.id
    // 
    const influencer = slice.influencer
    const status = slice.status.fetch
    // const statusHistory = slice.status.fetchInteractionsHistory
    // 
    const influencerPDF = slicePDF.influencer
    const statusPDF = slicePDF.status.fetchPublipostData
    // const statusPDFHistory = slicePDF.status.fetchInteractionsHistory
    // 
    const generalValues = [
        { text: t("Seguidores"), value: buildValue("humanize", influencer.followers) },
        { text: t("Engajamento"), value: `${influencer.engagementRate.replace(".", ",")}%` },
        { text: t("Airscore"), value: influencer.airscore },
        { text: t("Média de interações"), value: buildValue("humanize", influencer.meansInteractions.total) },
        { text: t("Estimativa de alcance"), value: buildValue("humanize", influencer.deliveryFee) },
        influencer.network !== "instagram" && { text: t("Média de visualizações"), value: buildValue("humanize", influencer.meansInteractions.views) },
    ].filter(item => item)
    const [publipostValues, updatePublipostValues] = useState([])

    useEffect(() => {
        dispatch(fetchPublipostData({ id }))
    }, [])

    useEffect(() => {
        if (statusPDF === StateStatus.succeeded) {
            dispatch(fetchInteractionsHistory({ days: 60, id }))
            dispatch(fetchInteractionsHistory({ days: 30, id }))
            // general
            dispatch(generalFetchInteractionsHistory({ days: 60, id }))
            dispatch(generalFetchInteractionsHistory({ days: 30, id }))

            updatePublipostValues([
                { text: t("Seguidores"), value: buildValue("humanize", influencerPDF.followers) },
                { text: t("Engajamento"), value: `${influencerPDF.engagementRate.replace(".", ",")}%` },
                { text: t("Airscore"), value: influencerPDF.airscore },
                { text: t("Média de interações"), value: buildValue("humanize", influencerPDF.meansInteractions.total) },
                { text: t("Estimativa de alcance"), value: buildValue("humanize", influencerPDF.deliveryFee) },
                influencerPDF.network !== "instagram" && { text: t("Média de visualizações"), value: buildValue("humanize", influencerPDF.meansInteractions.views) },
            ].filter(item => item))
        }
    }, [statusPDF])

    return <div className="influencer-general-data">
        <div className="title-part">
            <i className="icon icon-make-up-star" />

            {t("Dados do influenciador")}
        </div>

        {status === StateStatus.loading && <Loading />}

        {status === StateStatus.failed &&
            <p className="text-error">{t("Erro ao pegar as categorias")}</p>
        }

        {status === StateStatus.succeeded &&
            <div className="junction-data">
                <div className="general-data-card">
                    <div className="subtitle-part">  
                        <i className="icon icon-business-chart" />

                        {t("Dados Gerais")}
                    </div>

                    {generalValues.map((interaction, i) =>
                        <div className={`interaction ${generalValues.length - 1 === i && influencer.network === "instagram" ? "full" : ""}`} key={`general-${i}`}>
                            <div className="interaction-value">{interaction?.value}</div>
                            <div className="interaction-name">{interaction?.text}</div>
                        </div>
                    )}

                    <br/>

                    <div className="part-followers">
                        <div className="subtitle-part">                        
                            <i className="icon icon-group" />
                            
                            {t("Base de seguidores")}
                        </div>

                        {[...influencer.followersPerPeriod, { period: t("Atual"), value: influencer.followers}].map((follower, i) =>
                            <div className="follower" key={`general-follower-${i}`}>
                                <div className="value">{buildValue("humanize", follower?.value)}</div>
                                <div className="name">{follower?.period} {t(follower?.period === t("Atual") ? "" : "dias")}</div>
                            </div>
                        )}
                    </div>

                    <br/>

                    <div className="part-airscore">
                        <div className="subtitle-part">
                            <i className="icon icon-airscore" />
                            {t("Airscore")}
                        </div>

                        {[...influencer.scoresPerPeriod, { period: t("Atual"), value: influencer.airscore}].map((score, i) =>
                            <div className="score" key={`general-score-${i}`}>
                                <div className="value">{buildValue("humanize", score?.value)}</div>
                                <div className="name">{score?.period} {t(score?.period === t("Atual") ? "" : "dias")}</div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="publipost-card">
                    <div className="subtitle-part">
                        <i className="icon icon-business-chart-1" />

                        {t("Dados Publipost")}
                    </div>

                    {statusPDF === StateStatus.loading && <Loading />}

                    {statusPDF === StateStatus.failed && <p className="text-error">{t("ERROR")}</p>}

                    {statusPDF === StateStatus.succeeded &&
                        publipostValues.map((interaction, i) =>
                            <div className={`interaction ${publipostValues.length - 1 === i && influencer.network === "instagram" ? "full" : ""}`} key={`publipost-${interaction.text}`}>
                                <div className="interaction-value">{interaction?.value}</div>
                                <div className="interaction-name">{interaction.text}</div>
                            </div>
                        )
                    }

                    <br/>

                    <div className="part-followers">
                        <div className="subtitle-part">                        
                            <i className="icon icon-group" />
                            
                            {t("Base de seguidores")}
                        </div>

                        {[...influencerPDF.followersPerPeriod, { period: t("Atual"), value: influencerPDF.followers}].map((follower, i) =>
                            <div className="follower" key={`general-follower-${i}`}>
                                <div className="value">{buildValue("humanize", follower?.value)}</div>
                                <div className="name">{follower?.period} {t(follower?.period === t("Atual") ? "" : "dias")}</div>
                            </div>
                        )}
                    </div>

                    <br/>

                    <div className="part-airscore">
                        <div className="subtitle-part">
                            <i className="icon icon-airscore" />
                            {t("Airscore")}
                        </div>

                        {[...influencerPDF.scoresPerPeriod, { period: t("Atual"), value: influencerPDF.airscore}].map((score, i) =>
                            <div className="score" key={`publipost-score-${i}`}>
                                <div className="value">{buildValue("humanize", score?.value)}</div>
                                <div className="name">{score?.period} {t(score?.period === t("Atual") ? "" : "dias")}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        }
    </div>
}

export default PartGeneralData