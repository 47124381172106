import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import { selectMenuSlice } from "../SideMenu/slice"
import { useSelector } from "react-redux"
// 
import InputCurrency from "../InputCurrency"
// 
import "./index.scss"

const ModalSimulation = ({
    value,
    influencersCount,
    onApplication,
    onRemoveSelection,
}) => {
    const { t } = useTranslation()
    // 
    const menuSlice = useSelector(selectMenuSlice)
    // 
    const [newValue, setNewValue] = useState()
    const [showModal, setShowModal] = useState(true)
    
    useEffect(() => {
        setNewValue(value)
    }, [value])

    useEffect(() => {
        if(influencersCount > 0) {
            setShowModal(true)
        } else {
            closeSimulationModal()
        }
    }, [influencersCount])

    const closeSimulationModal = () => {
        const modal = document.querySelector(".modal-simulation")

        if(modal) {
            modal.classList.add("closed")

            setTimeout(() => {
                onRemoveSelection()

                setShowModal(false)
            }, 250);
        }
    }

    return showModal && 
        <div className={`modal-simulation ${menuSlice.collapsed ? "collapsed" : ""}`}>
            {influencersCount > 0 &&
                <div className="tag-influencers-count">
                    <span className="count">{influencersCount}</span>

                    <span>
                        {t(influencersCount === 1 ? "influenciador selecionado" : "influenciadores selecionados")}
                    </span>
                </div>
            }

            <div className="simulation-input">
                <span className="modal-simulation-header-title">
                    {t("Inserir o valor a ser investido")}
                </span>

                <InputCurrency
                    hasFocus={true}
                    isOutlined={true}
                    placeholder={t("R$ 0,00")}
                    value={newValue ? parseInt(newValue * 100) : 0}
                    onChange={(value) => value ? setNewValue(value / 100) : null}
                />
            </div>

            <div className="buttons">
                <div
                    className="button outline-dark hover"
                    onClick={() => onRemoveSelection()}
                >
                    {t("Remover seleção")}

                    <i className="icon icon-clear" />
                </div>

                <div
                    className="button outline-primary hover"
                    onClick={() => onApplication(newValue)}
                >
                    {t("Aplicar valor a seleção")}

                    <i className="icon icon-check" />
                </div>
            </div>

            <i
                className="icon icon-close hover"
                onClick={() => {
                    closeSimulationModal()
                }}
            />
        </div>
}
ModalSimulation.defaultProps = {
    hasFavorite: true,
    influencersCount: 0,
}

ModalSimulation.propTypes = {
    value: PropTypes.any,
    influencersCount: PropTypes.number,
    onApplication: PropTypes.func.isRequired,
    onRemoveSelection: PropTypes.func.isRequired,
}

export default ModalSimulation