import React from "react"
import PropTypes from "prop-types"
// 
import { useTranslation } from "react-i18next"
// 
import noBrands from "../../assets/icons/no_brands.svg"
import noHashtags from "../../assets/icons/no_hashtag.svg"
import noGeo from "../../assets/icons/no_geolocation.svg"
import noProfiles from "../../assets/icons/no_profile.svg"
import noCategory from "../../assets/icons/no_category.svg"
import noDetailedCategory from "../../assets/icons/no_detailed_categories.svg"
import noPlanner from "../../assets/icons/no_planner-list.svg"
// 
import "./index.scss"

const BlankItem = ({ 
  type, 
  height 
}) => {
  const { t } = useTranslation()

  const setup = {
    "no-brands": { img: noBrands, text: t("Nenhuma marca mencionada pelo influenciador") },
    "no-hashtags": { img: noHashtags, text: t("Nenhuma hashtag mencionada pelo influenciador") },
    "no-geo": { img: noGeo, text: t("Nenhum local mencionado pelo influenciador") },
    "no-profiles": { img:  noProfiles, text: t("Nenhum perfil mencionado pelo influenciador") },
    "no-categories": { img: noCategory, text: t("Nenhuma categoria mencionado pelo influenciador") },
    "no-detailed-categories": { img: noDetailedCategory, text: t("Nenhuma categoria mencionado pelo influenciador") },
    "no-planner-lists": { img: noPlanner, text: null }
  }

  return <div className="blank-item">
    <img width="auto" height={height} src={setup[type].img} />
    
    {setup[type].text && <span>{setup[type].text}</span>}
  </div>
}

BlankItem.defaultProps = {
  height: "auto"
}

BlankItem.propTypes = {
  type: PropTypes.string,
  height: PropTypes.string
}


export default BlankItem