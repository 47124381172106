import { Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../common/components/Button';
import Header from '../../../common/components/Header';
import Input from '../../../common/components/Input';
import StateStatus from '../../../utils/status';
import { updateTabTitle } from '../../../utils/updateTabTitle';

import { selectAddCategorySlice, sendCategory } from './slice';

import './index.scss';

const { useForm } = Form;

const AddCategory = () => {
    const { t } = useTranslation();
    const [form] = useForm();
    const dispatch = useDispatch();
    const slice = useSelector(selectAddCategorySlice);

    updateTabTitle('Adicionar categoria - AirSearch');

    const onFinish = (data) => {
        dispatch(sendCategory({ categories: [data] }));

        form.resetFields();
    };

    const status = slice.status.sendCategory;

    return (
        <div className="add-category-page background-page">
            <div className="add-category-content scroll-content">
                <Header
                    title={t('Solicitação para inclusão de categoria')}
                    className="add-category-header"
                ></Header>

                <div className="add-category-form">
                    <h2 className="title-form">{t('Formulário de solicitação')}</h2>

                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            name="category_name"
                            label={t('Nome da categoria')}
                            rules={[
                                {
                                    required: true,
                                    message: (
                                        <span className="normal-12">
                                            {t('Informe a categoria')}
                                        </span>
                                    ),
                                },
                            ]}
                        >
                            <Input placeholder={t('Digite um nome para a categoria')} />
                        </Form.Item>

                        <Form.Item
                            name="category_observations"
                            label={t('Por quais motivos deseja adicionar esta categoria?')}
                            rules={[
                                {
                                    required: true,
                                    min: 100,
                                    message: (
                                        <span className="normal-12">
                                            {t(
                                                'Informe o que deseja encontrar em pelo menos 100 caracteres',
                                            )}
                                        </span>
                                    ),
                                },
                            ]}
                        >
                            <textarea
                                className="ant-input ant-input-lg input input-full"
                                placeholder={t('Digite sua justificativa.')}
                            />
                        </Form.Item>

                        <div className="description">
                            <p>
                                <b>{t('Atenção')}: </b>
                                {t(
                                    `Ao clicar em "Enviar solicitação" avaliaremos seu pedido manualmente. Você será notificado quando houver a avaliação.`,
                                )}
                            </p>
                        </div>

                        <Button
                            onClick={() => form.submit()}
                            isLoading={status === StateStatus.loading}
                        >
                            {t('Enviar solicitação')}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AddCategory;
