import { add, isAfter } from 'date-fns';
import cookies from 'js-cookie';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { NPSSearch } from '../../../app/service';
import { toggleShowNPS } from '../../../app/slice';

import feedback from '../Feedback';

export const useNetPromoterScore = () => {
  const textAreaRef = useRef(null);

  const dispatch = useDispatch();
  const { collapsed } = useSelector((state) => state.menu);
  const showNPS = useSelector((state) => state.app.show.nps);

  const {
    register,
    handleSubmit: hookFormSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm();

  const { ref, ...rest } = register('description');
  const score = watch('score');
  const descriptionValue = watch('description');
  const showDescription = !!score;

  useEffect(() => {
    const latestNPSView = cookies.get('NPSLastDate');
    const currentDate = new Date();

    if (showNPS && (!latestNPSView || isAfter(currentDate, latestNPSView))) {
      dispatch(toggleShowNPS(true));
    }
  }, []);

  useEffect(() => {
    if (textAreaRef.current) {
      const textArea = textAreaRef.current;
      textArea.style.height = '52px';
      const scrollHeight = textArea.scrollHeight;

      textArea.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, descriptionValue]);

  const handleSubmit = hookFormSubmit(async (data) => {
    try {
      await NPSSearch(data);
      feedback.success('Pesquisa respondida com sucesso.');

      dispatch(toggleShowNPS(false));
    } catch (error) {
      feedback.error('Falha ao responder pesquisa.');
    }
  });

  const handleCloseBar = () => {
    const currentDate = add(new Date(), {
      days: 1,
    }).toISOString();

    cookies.set('NPSLastDate', currentDate, { expires: 1 });
    dispatch(toggleShowNPS(false));
  };

  const handleTextAreaChange = (event, rest) => {
    const newValue = event.target.value.replace(/\n/g, '').replace(/\s{2,}/g, ' ');
    event.target.value = newValue;
    rest.onChange(event);
  };

  return {
    showNPS,
    collapsed,
    handleCloseBar,
    handleSubmit,
    register,
    showDescription,
    textAreaRef,
    ref,
    rest,
    isSubmitting,
    handleTextAreaChange,
  };
};
