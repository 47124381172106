import facebookImg from '../../../../../common/assets/icons/facebook.svg';
import instagramImg from '../../../../../common/assets/icons/instagram.svg';
import tiktokImg from '../../../../../common/assets/icons/tiktok.svg';
import twitchImg from '../../../../../common/assets/icons/twitch.svg';
import twitterImg from '../../../../../common/assets/icons/x-twitter.svg';
import youtubeImg from '../../../../../common/assets/icons/youtube.svg';

export const columns = [
  { key: 'name', title: 'Nome da lista', span: 7 },
  { key: 'networks', title: 'Redes Sociais', span: 2 },
  { key: 'createdAt', title: 'Criada em', span: 2 },
  { key: 'size', title: 'Perfis', span: 1 },
  { key: 'created', title: 'Dono', span: 2 },
  { key: 'created-by-aira', title: '', span: 1 },
  { key: 'options', title: '', span: 1 },
];

export const optionsSortBy = [
  { key: 'Minhas listas', value: 'owner' },
  { key: 'Últimas atualizadas', value: 'updated_at desc' },
  { key: 'Data (1-9)', value: 'created_at asc' },
  { key: 'Data (9-1)', value: 'created_at desc' },
  { key: 'Número de influenciadores (1-9)', value: 'total asc' },
  { key: 'Número de influenciadores (9-1)', value: 'total desc' },
  { key: 'Nome (A-Z)', value: 'title asc' },
  { key: 'Nome (Z-A)', value: 'title desc' },
];

export const socialMediaImages = {
  instagram: instagramImg,
  facebook: facebookImg,
  tiktok: tiktokImg,
  twitter: twitterImg,
  youtube: youtubeImg,
  twitch: twitchImg,
};
