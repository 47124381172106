const deliveryCharges = {
    "youtube": {
        "mega": 0.04,
        "macro": 0.05,
        "inter": 0.07,
        "micro": 0.08,
        "nano": 0.08,
    },
    "tiktok": {
        "mega": 0.04,
        "macro": 0.05,
        "inter": 0.07,
        "micro": 0.08,
        "nano": 0.08,
    },
    "twitter": {
        "mega": 0.04,
        "macro": 0.05,
        "inter": 0.07,
        "micro": 0.08,
        "nano": 0.08,
    },
    "facebook": {
        "mega": 0.04,
        "macro": 0.05,
        "inter": 0.07,
        "micro": 0.08,
        "nano": 0.08,
    },
    "instagram": {
        "mega": 0.04,
        "macro": 0.05,
        "inter": 0.07,
        "micro": 0.08,
        "nano": 0.08,
    },
    "twitch": {
        "mega": 0,
        "macro": 0,
        "inter": 0,
        "micro": 0,
        "nano": 0,
    },
}

const classificationDeliveryCharge = (followers) => {
    if (followers) {
        if (followers >= 1000000) return "mega"
        if (followers >= 500000 && followers < 1000000) return "macro"
        if (followers >= 50000 && followers < 500000) return "inter"
        if (followers >= 10000 && followers < 50000) return "micro"
        if (followers >= 1000 && followers < 10000) return "nano"
    }
} 

export const calcDeliveryCharge = (followers, network) => {
    const classification = classificationDeliveryCharge(followers)
    let percentage = 0;
    
    if (classification) {
        percentage = deliveryCharges[network][classification]
    }
    
    return followers * percentage
}