import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import aira from '../../../common/assets/images/aira.png';
import Header from '../../../common/components/Header';
import StateStatus from '../../../utils/status';
import { updateTabTitle } from '../../../utils/updateTabTitle';
import {
  checkAiraHasBeenUsed,
  clearPlannerList,
  fetch,
  selectPlannerListDetailsSlice,
} from '../PlannerListCard/slice';

import CurationConcluded from './components/CurationConcluded';
import CurationForm from './components/CurationForm';
import Presentation from './components/Presentation';
import { clearCuration, selectCurationSlice, sendBriefing } from './slice';

import './index.scss';

const { useForm } = Form;

const Aira = () => {
  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slice = useSelector(selectCurationSlice);
  const plSlice = useSelector(selectPlannerListDetailsSlice);
  const [form] = useForm();

  updateTabTitle('Assistente de curadoria - AirSearch');

  const pl = plSlice.data;
  const fetchStatus = plSlice.status.fetch;
  const createdByAira = pl.createdByAira;
  const airaStatus = pl.status;

  const status = slice.status.sendBriefing;

  const onFinish = (values) => {
    if (current === 1 && values['briefing_text']) {
      dispatch(checkAiraHasBeenUsed());

      dispatch(
        sendBriefing({
          briefing: values['briefing_text'],
        }),
      );
    } else if (current < 2) {
      if (form.getFieldError()) {
        setCurrent(current + 1);
      }
    }
  };

  const clearCurationAndGoToPlannerList = () => {
    setCurrent(0);
    dispatch(clearCuration());
    dispatch(clearPlannerList());

    navigate(`/planner-lists/${slice.plId}/comparative-table?redirect=true`);
  };

  useEffect(() => {
    dispatch(clearPlannerList());

    setCurrent(0);
  }, []);

  useEffect(() => {
    if (status === StateStatus.succeeded) dispatch(fetch({ id: slice.plId }));
    if (status === StateStatus.failed) dispatch(fetch({ id: slice.plId }));
  }, [status]);

  useEffect(() => {
    if (
      fetchStatus === StateStatus.succeeded &&
      status === StateStatus.succeeded &&
      airaStatus === 'finished'
    ) {
      clearCurationAndGoToPlannerList();
    }
  }, [fetchStatus, status]);

  useEffect(() => {
    const isFinished = pl.status && pl.status === 'finished';

    if (status === StateStatus.succeeded && fetchStatus !== StateStatus.loading) {
      let interval;

      if (isFinished) {
        clearInterval(interval);
      } else {
        interval = setInterval(() => {
          dispatch(fetch({ id: slice.plId }));
        }, 3500);
      }

      return () => clearInterval(interval);
    }
  }, [fetchStatus]);

  useEffect(() => {
    setSteps([
      {
        title: t('Marca'),
        content: <Presentation onFinish={() => form.submit()} />,
      },
      {
        title: t('Vertentes'),
        content: <CurationForm onFinish={() => form.submit()} />,
      },
      {
        title: t('Finalização'),
        content: <CurationConcluded onFinish={() => form.submit()} />,
      },
    ]);
  }, [current]);

  if (
    ['assistant_creating', 'assistant_capturing', 'assistant_scoring', 'started'].includes(
      airaStatus,
    ) ||
    (createdByAira && airaStatus === 'waiting')
  ) {
    return (
      <div className="aira-loading">
        <h1>Aira</h1>
        <div>
          <img className="aira" src={aira} alt={t('mulher  do cabelo preto acenando')} />
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle
              className="loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#ED276A"
              strokeWidth="1"
            />
          </svg>
        </div>
        <p>Buscando pelos melhores influenciadores para nossa curadoria.</p>
      </div>
    );
  }

  return (
    <div className="aira-page background-page">
      <div className="aira-content scroll-content">
        <Header title={t('Assistente de curadoria')} className="aira-header" />

        <div className="aira-forms">
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <div className="current-form">{steps[current] && steps[current].content}</div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Aira;
