import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
// 
import { Tooltip } from "antd"
import Avatar from "../../../../../common/components/Avatar"
import CheckBox from "../../../../../common/components/CheckBox"
import ButtonModalLists from "../../../../../common/components/ButtonModalLists"
import PriceSimulator from "../../../../../common/components/PriceSimulator"
import ModalSimulation from "../../../../../common/components/ModalSimulation"
// 
import emptyImage from "../../../../../common/assets/images/empty-influencers.png"
import { buildValue } from "../../../../../utils/formatters"
// 
import "./index.scss"
import "../Table/index.scss"

const SimulationTable = ({
    influencers,
    variation,
    openModal,
    onChangePrice,
    toPdf
}) => {
    const { t } = useTranslation()
    // 
    const [sorts, updateSorts] = useState(["name"])
    const [boolBigger, updateBoolBigger] = useState(false)
    const [arrowLocation, updateArrowLocation] = useState(4)
    const [orderedInfluencers, updateOrderedInfluencers] = useState([])
    // 
    const [simulationValues, updateSimulationValues] = useState({})
    const [influencerSelecteds, updateInfluencerSelecteds] = useState([])
    // 
    const columns = [
        { alias: "name", title: t("Influenciador"), span: 2 },
        { alias: "simulation", title: t("Valor do investimento"), tooltip: t("Valor a investir"), span: 2, withTotal: true },
        { alias: "interactions", title: t("Soma das interações"), span: 2, withTotal: true },
        !toPdf && { alias: "", title: t("Listas"), span: 2 },
    ].filter(column => column)

    const orderBy = (obj = "perNumber", index, withTotal = false) => {
        if (columns[index].title !== t("Minhas listas")) {
            updateArrowLocation(index)
        }

        if (!sorts.includes(obj)) {
            const newOrder = [...orderedInfluencers].sort((a, b) => {
                if (obj === "name") {
                    if (a[obj].toLowerCase() > b[obj].toLowerCase()) { return -1 }
                    if (a[obj].toLowerCase() < b[obj].toLowerCase()) { return 1 }
                    return 0
                } else {
                    if ((withTotal ? (a[obj] ?? 0) : a[obj]) > (withTotal ? (b[obj] ?? 0) : b[obj])) { return -1 }
                    if ((withTotal ? (a[obj] ?? 0) : a[obj]) < (withTotal ? (b[obj] ?? 0) : b[obj])) { return 1 }
                    return 0
                }

            })
            updateBoolBigger(true)
            updateOrderedInfluencers(newOrder)
            updateSorts((oldArray) => [...oldArray, obj])
        } else {
            const newOrder = [...orderedInfluencers].sort((a, b) => {
                if (obj === "name") {
                    if (a[obj].toLowerCase() < b[obj].toLowerCase()) { return -1 }
                    if (a[obj].toLowerCase() > b[obj].toLowerCase()) { return 1 }
                    return 0
                } else {
                    if ((withTotal ? (a[obj] ?? 0) : a[obj]) < (withTotal ? (b[obj] ?? 0) : b[obj])) { return -1 }
                    if ((withTotal ? (a[obj] ?? 0) : a[obj]) > (withTotal ? (b[obj] ?? 0) : b[obj])) { return 1 }
                    return 0
                }
            })
            updateBoolBigger(false)
            updateOrderedInfluencers(newOrder)
            updateSorts(sorts.filter(item => item !== obj))
        }
    }

    const calcPrice = (influencer, interaction) => {
        let price = 0
        let count = influencer[variation][interaction]

        if (count && simulationValues[influencer.id]) {
            price = (simulationValues[influencer.id] / count)
        }

        return price
    }


    useEffect(() => {
        if (orderedInfluencers.length < 1) {
            updateOrderedInfluencers(influencers ?? [])
        }
    }, [])

    useEffect(() => {
        const newObj = {}
        influencers.forEach(inf => newObj[inf.id] = inf.price)
        updateSimulationValues(newObj)
    }, [influencers])

    return <div className="simulation-container table-container">
        {orderedInfluencers.length > 0 &&
            <table>
                {/* head */}
                <thead>
                    <tr>
                        {columns.map((item, key) => {
                            if (item.alias === "interactions" && influencers.length > 0 && influencers[0].interactions) {
                                return <>
                                    {[...Object.keys(influencers[0][variation] ?? {})].map((interaction, key) => (
                                        <th
                                            key={`th-${variation}-${key}`}
                                            className={`interaction-value interaction-value-${interaction}`}
                                        >
                                            <Tooltip title={interaction !== "total" && `${t("Custo por")} ${t(interaction)}`}>
                                                {interaction === "total" ? t("Interações") : t(interaction)}
                                            </Tooltip>
                                        </th>
                                    ))}
                                </>
                            } else if (item.alias === "simulation") {
                                return <th key={`th-${item.alias}`}>
                                    <div>
                                        {!toPdf && <CheckBox
                                            key={key}
                                            indeterminate={influencerSelecteds.length === influencers.length}
                                            onChange={() => {
                                                if (influencerSelecteds.length === influencers.length) {
                                                    updateInfluencerSelecteds([])
                                                } else {
                                                    updateInfluencerSelecteds(influencers.map(inf => inf.id))
                                                }
                                            }}
                                        />}

                                        <Tooltip title={item.tooltip}>
                                            {item.title}
                                        </Tooltip>

                                        <ModalSimulation
                                            influencersCount={influencerSelecteds.length}
                                            onApplication={(value) => {
                                                let newValues = {}

                                                influencerSelecteds.forEach(id => {
                                                    newValues[id] = (value ? value : 0)
                                                    onChangePrice((value ? value : 0), id)
                                                })

                                                updateSimulationValues({ ...simulationValues, ...newValues})
                                                updateInfluencerSelecteds([])
                                            }}
                                            onRemoveSelection={() => {
                                                updateInfluencerSelecteds([])
                                            }}
                                        />
                                    </div>
                                </th>
                            } else {
                                return <th
                                    key={`th-${item.alias}-${key} hover`}
                                    rowSpan={item.span}
                                    onClick={() => orderBy(item.alias, key, item.withTotal)}
                                >
                                    <Tooltip title={item.tooltip}>
                                        {item.title}
                                    </Tooltip>

                                    {key === arrowLocation && <i className={`icon icon-down ${boolBigger ? "bigger" : "smaller"}`} />}
                                </th>
                            }

                        })}
                    </tr>
                </thead>

                {/* body */}
                <tbody>
                    {orderedInfluencers.map(influencer => {
                        return (
                            <tr key={influencer.id}>
                                <td className="td-influencer" onClick={() => openModal(influencer)}>
                                    <div className="div-flex">
                                        <Avatar
                                            size={24}
                                            src={influencer.avatar}
                                            onClick={() => openModal(influencer)}
                                        />

                                        <div className="influencer-name">
                                            {influencer.name}

                                            {toPdf && 
                                                <span>@{influencer.username}</span>
                                            }
                                        </div>
                                    </div>
                                </td>

                                <td className="td-simulation">
                                    <div>
                                        {!toPdf && <CheckBox
                                            key={influencer.id}
                                            indeterminate={influencerSelecteds.includes(influencer.id)}
                                            onChange={() => {
                                                if (influencerSelecteds.includes(influencer.id)) {
                                                    updateInfluencerSelecteds(influencerSelecteds.filter(influencerId => influencerId != influencer.id))
                                                } else {
                                                    updateInfluencerSelecteds([...influencerSelecteds, influencer.id])
                                                }
                                            }}
                                        />}

                                        <PriceSimulator
                                            value={simulationValues[influencer.id] ? simulationValues[influencer.id] : 0}
                                            onApplication={(value) => {
                                                updateSimulationValues({ ...simulationValues, [influencer.id]: value })
                                                onChangePrice(value, influencer.id)
                                            }}
                                        />
                                    </div>
                                </td>

                                {Object.keys(influencer[variation] ?? {}).map(interaction => {
                                    return <td
                                        key={`${influencer.id}-${variation}-${interaction}`}
                                        className={`interaction-value interaction-value-${interaction}`}
                                    >
                                        <Tooltip title={`R$ ${calcPrice(influencer, interaction)}`.replace(".", ",")}>
                                            {buildValue("currency", calcPrice(influencer, interaction))}
                                        </Tooltip>
                                    </td>
                                })}

                                {!toPdf && <td className="td-my-lists">
                                    <ButtonModalLists
                                        influencerId={influencer.id}
                                        influencerNetwork={influencer.network}
                                        removeInfluencer={() => {
                                            updateOrderedInfluencers(orderedInfluencers.filter(item => item.id != influencer.id))
                                        }}
                                    >
                                        <Tooltip title={t("Minhas Listas")}>
                                            <i className="icon icon-plus" />
                                        </Tooltip>
                                    </ButtonModalLists>
                                </td>}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        }

        {orderedInfluencers.length < 1 &&
            <img src={emptyImage} className="empty-image" alt={t("Imagem simbolizando que a listá está vazia")} />
        }
    </div>
}

SimulationTable.defaultProps = {
    influencers: [],
    toPdf: false
}

SimulationTable.propTypes = {
    variation: PropTypes.string,
    openModal: PropTypes.func,
    onChangePrice: PropTypes.func,
    toPdf: PropTypes.bool,
    influencers: PropTypes.arrayOf(PropTypes.object),
}

export default SimulationTable